<script>
  import {mapActions,mapGetters} from "vuex";
  import API from "src/core/Api";
  import Box from "src/modules/medico/views/fichas/Box.vue";
export default { 
 data() {
        return {
        //Laboratorio preferente

        laboratorio_preferente:{
          nombre: '',
          ciudad: '',
          ubicacion: '',
          direccion: '',
        },
        // alergias_adicionales:[
        //   {
        //     medicamento: '',
        //     tipo_reaccion: '',
        //   }
        // ]

        historia_social:{
          smoke: '0',
          smoke_frecuency: '',
          how_long_smoked: '',
          smokeless_tobacco: '0',
          tobacco_free_cigarette: '0',
          chewing_tabacco: '0',
          ready_to_quit_smoke: '0',
          packs_per_day: 0,
          smoke_advice: '0',
          former_smoker: '0',
          consume_alchool: '0',
          amount_alchool: 0,
          sexual_activity: '0',
          use_contraceptives: '',
          type_contraceptives: '',
          advanced_directive: '0',
          directive_type: '',
          appoint_name: '',
          directive_advice: '0',
        },
        
        numberAllergies: 1,
        numberMedicine: 1,


        puntuaciones_auass: {
    1:{
        pregunta: "¿Con qué frecuencia ha tenido la sensación de no vaciar completamente la vejiga después de terminar de orinar?",
        puntaje: 1
    },
    2:{
        pregunta: "¿Con qué frecuencia ha tenido que volver a orinar menos de 2 horas después de terminar de orinar?",
        puntaje: 1
    },
    3:{
        pregunta: "¿Con qué frecuencia se ha detenido y vuelto a empezar varias veces al orinar?",
        puntaje: 1
    },
    4:{
        pregunta: "¿Con qué frecuencia le ha resultado difícil posponer la micción?",
        puntaje: 1
    },
    5:{
        pregunta: "¿Con qué frecuencia ha tenido un flujo débil?",
        puntaje: 1
    },
    6:{
        pregunta: "¿Con qué frecuencia ha tenido que pujar o esforzarse para empezar a orinar?",
        puntaje: 1
    },
    7:{
        pregunta: "¿Cuántas veces normalmente te levantaste por la noche para orinar desde que te acostaste hasta que te levantaste?",
        puntaje: 1
    }
  },

        salud_sexual:{
          1:{
            pregunta:"¿Cómo califica su confianza en que podrá lograr y mantener una erección?",
            puntaje:0,
            label:["Nulo","Muy Bajo","Bajo","Moderado","Alto","Muy Alto"]
          },
          2:{
            pregunta:" Cuando tuvo erecciones con estimulación sexual, ¿con qué frecuencia sus erecciones fueron lo suficientemente duras para la penetración (entrar a su pareja)?",
            puntaje:0,
            label:["Sin actividad sexual","Casi nunca o nunca","Algunas veces","A veces","La mayoria de las veces","Casi siempre o siempre"]
          },
          3:{
            pregunta:"Durante las relaciones sexuales, ¿con qué frecuencia pudo mantener su erección?",
            puntaje:0,
            label:["No intentó tener relaciones sexuales","Casi nunca o nunca","Algunas veces","A veces","La mayoría de las veces","Casi siempre o siempre"]
          },
          4:{
            pregunta:"Durante las relaciones sexuales, ¿qué tan difícil fue mantener la erección hasta el final de las relaciones sexuales?",
            puntaje:0,
            label:["No intentó tener relaciones sexuales","Extremadamente difícil","Muy dificil","Difícil","Ligeramente Difícil","No es difícil"]
          },
          5:{
            pregunta:"Cuando intentó tener relaciones sexuales, ¿con qué frecuencia le resultó satisfactoria?",
            puntaje:0,
            label:["No intentó tener relaciones sexuales","Casi nunca o nunca","Algunas veces","A veces","La mayoría de las veces","Casi siempre o siempre"]
          }
        },

        condicion_urinaria:{
          selection:''
        },
 }},
 mounted(){
  const self = this;

        // Cargo laboratorio preferente
        Object.keys(self.laboratorio_preferente).map(function (value) {
          if (self.GetMedicoFichaActive.metadata.laboratorio_preferente!= undefined){
            if (self.GetMedicoFichaActive.metadata.laboratorio_preferente["laboratorio_preferente_"+value]!= undefined) self.laboratorio_preferente[value] = self.GetMedicoFichaActive.metadata.laboratorio_preferente["laboratorio_preferente_"+value];
          }
        });

        //Cargo historia social
        Object.keys(self.historia_social).map(function (value) {
          if (self.GetMedicoFichaActive.metadata.historia_social!= undefined) {
                if (self.GetMedicoFichaActive.metadata.historia_social["historia_social_"+value]!=undefined) self.historia_social[value] = self.GetMedicoFichaActive.metadata.historia_social["historia_social_"+value];
          }
        });

        //Cargo Puntajes AUASS
        Object.keys(self.puntuaciones_auass).map(function (value) {
          if (self.GetMedicoFichaActive.metadata.puntuaciones_auass) {
                if (self.GetMedicoFichaActive.metadata.puntuaciones_auass["puntuaciones_auass_"+value]) self.puntuaciones_auass[value].puntaje = self.GetMedicoFichaActive.metadata.puntuaciones_auass["puntuaciones_auass_"+value];
          }
        });

        //Cargo condición urinaria
        self.GetMedicoFichaActive.metadata.condicion_urinaria && (self.condicion_urinaria=self.GetMedicoFichaActive.metadata.condicion_urinaria);

        //Cargo salud sexual
        Object.keys(self.salud_sexual).map(function (value) {
          if (self.GetMedicoFichaActive.metadata.salud_sexual) {
                if (self.GetMedicoFichaActive.metadata.salud_sexual["salud_sexual_"+value]) self.salud_sexual[value].puntaje = self.GetMedicoFichaActive.metadata.salud_sexual["salud_sexual_"+value];
          }
        });

 },
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
 },
 methods:{
  ...mapActions({
}),
  saveDataCU(){
    const self = this;

    
    API.send('POST', 'orders/metadata/save/', {
          orderId: self.GetMedicoFichaActive.orden.id,
          slug: 'condicion_urinaria',
          detail: self.condicion_urinaria,
          uniqueMeta: 1,
      }, function (response) {
          API.showSuccessAlert(response.msg);
      },
      function (response) {
          API.showErrorAlert(response.msg);
      });

  },
  saveDataSS(){
    const self = this;

    const arrToSave = {};
            Object.keys(self.salud_sexual).map(function (value) {
                      arrToSave["salud_sexual_"+value] = self.salud_sexual[value].puntaje;
                  });

      API.send('POST', 'orders/metadata/save/', {
          orderId: self.GetMedicoFichaActive.orden.id,
          slug: 'salud_sexual',
          detail: arrToSave,
          uniqueMeta: 1,
      }, function (response) {
          API.showSuccessAlert(response.msg);
      },
      function (response) {
          API.showErrorAlert(response.msg);
      });
  },
  saveDataAUASS(){

    const self = this;

    const arrToSave = {};
            Object.keys(self.puntuaciones_auass).map(function (value) {
                      arrToSave["puntuaciones_auass_"+value] = self.puntuaciones_auass[value].puntaje;
                  });


    API.send('POST', 'orders/metadata/save/', {
        orderId: self.GetMedicoFichaActive.orden.id,
        slug: 'puntuaciones_auass',
        detail: arrToSave,
        uniqueMeta: 1,
    }, function (response) {
        API.showSuccessAlert(response.msg);
    },
    function (response) {
        API.showErrorAlert(response.msg);
    });
  },
  saveDataSocialHistory(){
    const self = this;
    const arrToSave = {};
            Object.keys(self.historia_social).map(function (value) {
                      arrToSave["historia_social_"+value] = self.historia_social[value];
                  });

            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'historia_social',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
  },
  saveDataPreferentLab(){
    const self = this;

    const arrToSave = {};
          Object.keys(self.laboratorio_preferente).map(function (value) {
                      arrToSave["laboratorio_preferente_"+value] = self.laboratorio_preferente[value];
                  });

            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'laboratorio_preferente',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
  },
  sumarPuntajes() {
  const self = this;
  let totalPuntajes = 0;
  Object.keys(self.salud_sexual).map(function (value) {
      totalPuntajes+= Number(self.salud_sexual[value].puntaje)       
  });

  return totalPuntajes;
},
totalLabel(mode){
  let value= this.sumarPuntajes();

  if (mode == "label") {
    if (value<=7) {
    return "Grave"
  }else if (value>7 && value<=11) {
    return "Moderada"
  }else if (value>11 && value<=16) {
    return "De leve a moderada"
  }else if (value>16 && value<=23){
    return "Leve"
  }else{
    return "Buen estado"
  }
  }else if (mode == "card") {
    if (value<=7) {
    return "bg-danger"
  }else if (value>7 && value<=11) {
    return "bg-warning"
  }else if (value>11 && value<=16) {
    return "bg-success"
  }else if (value>16 && value<=23){
    return "bg-info"
  }else{
    return "bg-primary"
  }
  }  
},
addAllergies(){
    this.numberAllergies++;
  },
  addMedicine(){
    this.numberMedicine++;
  },
  changeSmoke(){
    this.Smoke=!this.Smoke;
  },
  changeAlchool(e){
    this.Alchoool=e.target.value;
  },
  changeContraceptive(e){
    this.Contraceptive=e.target.value;
  },
  changeDirective(e){
   this.Directive=e.target.value;
  },
  changeDirectiveTipe(e){
   this.DirectiveTipe=e.target.value;
  }
 },
 components:{
  Box
 },
 }
</script>
 
 
<template>
  <!-- INFO LAB -->
  <box class="boxx" title="Laboratorio Preferente" icon="fa-solid fa-flask-vial">

<section class="favoritelab row">

  <div class="col-12 row">
    <div class="form-group col-12 col-md-4">
            <label for="medic">Nombre de laboratorio preferente</label>
                <input type="text" class="form-control" id="whattimesmoke" placeholder="Ingrese aquí..." aria-describedby="searchLab" v-model="laboratorio_preferente.nombre" @change="saveDataPreferentLab">
                <small id="searchLab" class="form-text text-muted">Ingresa el nombre de tu laboratorio preferido.</small>
        </div>

        <div class="form-group col-12 col-md-4">
            <label for="medic">Ciudad</label>
                <input type="text" class="form-control" id="whattimesmoke" placeholder="Ingrese aquí..." aria-describedby="CityLab" v-model="laboratorio_preferente.ciudad" @change="saveDataPreferentLab">
                <small id="CityLab" class="form-text text-muted">Ciudad del laboratorio preferido.</small>
        </div>

        <div class="form-group col-12 col-md-4">
            <label for="medic">Ubicacion/Zona</label>
                <input type="text" class="form-control" id="whattimesmoke" placeholder="Ingrese aquí..." aria-describedby="ZoneLab" v-model="laboratorio_preferente.ubicacion" @change="saveDataPreferentLab">
                <small id="ZoneLab" class="form-text text-muted">Ubicacion/Zona del laboratorio preferido.</small>
        </div>

        <div class="form-group col">
            <label for="medic">Direccion</label>
                <input type="text" class="form-control" id="whattimesmoke" placeholder="Ingrese aquí..." v-model="laboratorio_preferente.direccion" @change="saveDataPreferentLab">
                
        </div>
  </div>

</section>
</box>


<!-- COMENTADO POR SER REDUNDANTE RESPECTO, CAMPOS PARECIDOS EN ANTECEDENTES -->
<!-- ALERGIAS ADICIONALES -->
<!-- <box title="Alergias" icon="fa-solid fa-lungs-virus">

<section class="Allergies row">

<table class="table col-12">
  <thead class="thead-dark">
    <tr>
      <th scope="col">Medicamento</th>
      <th scope="col">Tipo de Reacción</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="index in numberAllergies" :key="index">
      <td>
        <div class="form-group">
                <input type="text" class="form-control" id="medicine" :placeholder="'Ingrese medicamento '+ index">
        </div>
      </td>
      <td>
        <div class="form-group">
                <input type="text" class="form-control" id="raction" placeholder="Ingrese Sintomas o reacciones...">
        </div>
      </td>
    </tr>
  </tbody>
</table>
<div class="col-12">
  <button type="button" @click="addAllergies" class="btn btn-primary addAllergies">Agregar alergia <i class="fa-solid fa-square-plus"></i></button>
</div>

</section>
</box> -->
  <!-- MEDICAMENTOS EN ACTUAL CONSUMO -->
<!-- <box title="Medicamentos en actual consumo" icon="fa-solid fa-capsules">

<section class="Medicine row">

  <table class="table col-12">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Medicamento</th>
        <th scope="col">Dosis</th>
        <th scope="col">Frecuencia</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="index in numberMedicine" :key="index">
        <td>
          <div class="form-group">
                  <input type="text" class="form-control" id="medicine" :placeholder="'Ingrese medicamento '+ index">
          </div>
        </td>
        <td>
          <div class="form-group">
                  <input type="text" class="form-control" id="dose" placeholder="Ingrese la dosis...">
          </div>
        </td>
        <td>
          <div class="form-group">
                  <input type="text" class="form-control" id="frecuency" placeholder="Ingrese la frecuencia de comsumo...">
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="col-12">
    <button type="button" @click="addMedicine" class="btn btn-primary addAllergies">Agregar medicamento <i class="fa-solid fa-square-plus"></i></button>
  </div>

</section>

</box> -->


 <!-- HISTORIA SOCIAL -->
<box class="boxx" title="Historia social" icon="fa-solid fa-file-medical">
      
      <div class="socialhistory col-12 row">
        
        <div class="col-12 row mb-3">
          <div class=" col-12">
          <p>¿Usted fuma?</p>
          <label class="toggle">
            <input class="toggle-checkbox" type="checkbox" :checked="historia_social.smoke" @change="saveDataSocialHistory" v-model="historia_social.smoke" />
            <div class="toggle-switch"></div>
            <span class="toggle-label"></span>
          </label>
        </div>
        </div>

        <div v-if="historia_social.smoke" class="smoke col-12 row">
          <div class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">Frecuencia de consumo</label>
                  <select class="form-control" v-model="historia_social.smoke_frecuency" @change="saveDataSocialHistory">
                      <option value="everyday">
                         Todos los dias
                      </option>
                      <option value="sometimes">
                         Ocasionalmente
                      </option>
                  </select>
              </div>

          <div class="form-group col-12 col-md-4">
              <label for="medic">¿Hace cuanto fuma?</label>
                  <input type="text" class="form-control" id="whattimesmoke" placeholder="Ingrese aquí..." v-model="historia_social.how_long_smoked" @change="saveDataSocialHistory">
          </div>

          <div class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">¿Cosume tabaco sin humo?</label>
                  <select class="form-control"  v-model="historia_social.smokeless_tobacco" @change="saveDataSocialHistory">
                      <option value="1">
                         Si
                      </option>
                      <option value="0">
                         No
                      </option>
                  </select>
            </div>

            <div class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">¿Cosume cigarrillo sin tabaco?</label>
                  <select class="form-control" v-model="historia_social.tobacco_free_cigarette" @change="saveDataSocialHistory">
                      <option value="1">
                         Si
                      </option>
                      <option value='0'>
                         No
                      </option>
                  </select>
            </div>

            <div class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">¿Cosume tabaco masticable?</label>
                  <select class="form-control" v-model="historia_social.chewing_tabacco" @change="saveDataSocialHistory">
                      <option value="1">
                         Si
                      </option>
                      <option value="0">
                         No
                      </option>
                  </select>
            </div>

            
            <div class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">¿Estas listo para dejar de fumar?</label>
                  <select class="form-control" v-model="historia_social.ready_to_quit_smoke" @change="saveDataSocialHistory">
                      <option value="1">
                         Si
                      </option>
                      <option value="0">
                         No
                      </option>
                  </select>
            </div>


          <div class="form-group col-12 col-md-4">
              <label for="medic">Paquetes por dia</label>
                  <input type="number" class="form-control" id="smoke" aria-describedby="smokeHelp" v-model="historia_social.packs_per_day" @change="saveDataSocialHistory">
          </div>

          <div class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">¿Desea asesoramiento para dejar de fumar?</label>
                  <select class="form-control" v-model="historia_social.smoke_advice" @change="saveDataSocialHistory">
                      <option value="1">
                         Si
                      </option>
                      <option value="0">
                         No
                      </option>
                  </select>
            </div>

        </div>

        <div class="smoke col-12 row">

          <div v-if="!historia_social.smoke" class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">¿Es usted excomsumidor de tabaco?</label>
                  <select class="form-control" v-model="historia_social.former_smoker" @change="saveDataSocialHistory">
                      <option value="1">
                         Si
                      </option>
                      <option value="0">
                         No
                      </option>
                  </select>
            </div>
            
            <div  class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">¿Consume alchool?</label>
                  <select class="form-control"
                  v-model="historia_social.consume_alchool" @change="saveDataSocialHistory">
                      <option value="1">
                         Si
                      </option>
                      <option value="0">
                         No
                      </option>
                  </select>
            </div>

            <div v-if="historia_social.consume_alchool" class="form-group col-12 col-md-4">
              <label for="medic">Cantidad de alchool por semana (oz.)</label>
                  <input type="number" class="form-control" id="alchool" placeholder="Ingrese aquí..."
                  @change="saveDataSocialHistory"
                  v-model="historia_social.amount_alchool">
            </div>

            <div  class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">Actividad sexual</label>
                  <select class="form-control" @change="saveDataSocialHistory"
                  v-model="historia_social.sexual_activity">
                      <option value="1">
                         Si
                      </option>
                      <option value="0">
                         No
                      </option>
                  </select>
            </div>

            <div  class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">Frecuencia de uso de anticonceptivos</label>
                  <select class="form-control" @change="saveDataSocialHistory"
                  v-model="historia_social.use_contraceptives">
                      <option value="always">
                        Siempre
                      </option>
                      <option value="sometimes">
                         Aveces
                      </option>
                      <option value="never">
                         Nunca
                      </option>
                  </select>
            </div>

 
            <div v-if="historia_social.use_contraceptives!=='never' && historia_social.use_contraceptives!==''" class="form-group col-12 col-md-4">
              <label for="medic">Que anticonceptivo usa</label>
                  <input type="text" class="form-control" id="anticonceptivo" placeholder="Ingrese aquí..."
                  @change="saveDataSocialHistory"
                  v-model="historia_social.type_contraceptives">
            </div>

        </div>

        <div class="directive col-12 row">

          <div  class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">¿Tiene una directiva anticipada vigente?</label>
                  <select class="form-control" @change="saveDataSocialHistory"
                  v-model="historia_social.advanced_directive">
                      <option value="1">
                        Si
                      </option>
                      <option value="0">
                         No
                      </option>
                  </select>
            </div>

            <div v-if="Directive==='Yes'"  class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">Tipo de directiva anticipada</label>
                  <select class="form-control" @change="saveDataSocialHistory"
                  v-model="historia_social.directive_type">
                      <option value="written">
                        Escrita
                      </option>
                      <option value="digital">
                         Digital
                      </option>
                      <option value="oral">
                         Oral
                      </option>
                      <option value="appoint">
                         Designación de sustituto
                      </option>
                      <option value="other">
                         Otro
                      </option>
                  </select>
            </div>

            <div v-if="historia_social.directive_type === 'appoint'" class="form-group col-12 col-md-4">
              <label for="medic">Nombre del sustituto</label>
                  <input type="text" class="form-control" id="appointName" aria-describedby="appointHelp"
                  @change="saveDataSocialHistory"
                  v-model="historia_social.appoint_name">
            </div>

            <div  class="form-group col-12 col-md-4">
                  <label class="mr-sm-2">¿Esta interesado en información sobre directivas anticipadas?</label>
                  <select class="form-control" @change="saveDataSocialHistory"
                  v-model="historia_social.directive_advice">
                      <option value="1">
                        Si
                      </option>
                      <option value="0">
                         No
                      </option>
                  </select>
            </div>

        </div>
        
      </div>
 
</box>

<!-- PUNTUACION DE SISTEMA AUASS -->
<box title="Puntuación des sistema AUASS" icon="fa-regular fa-star">
      <section class="background row">
        <div v-for="puntuacion in puntuaciones_auass" class="form-group col-12 col-md-4" :key="puntuacion.index">
          <label for="formControlRange">{{ puntuacion.pregunta }}</label>
          <input v-model="puntuacion.puntaje" type="range" min="1" max="5" step="1" class="form-control-range" id="formControlRange" aria-describedby="Frecuency"
          @change="saveDataAUASS"
          >
          <small id="Frecuency" class="form-text" :class="
          puntuacion.puntaje==1 && 'text-primary' ||
          puntuacion.puntaje==2 && 'text-success'||
          puntuacion.puntaje==3 && 'text-muted' ||
          puntuacion.puntaje==4 && 'text-warning'||
          puntuacion.puntaje==5 && 'text-danger'
          ">{{ 
          puntuacion.puntaje==1 && "Muy Baja" ||
          puntuacion.puntaje==2 && "Baja"||
          puntuacion.puntaje==3 && "Media" ||
          puntuacion.puntaje==4 && "Alta"||
          puntuacion.puntaje==5 && "Muy Alta"
            }}</small>
        </div>

      </section>
</box>

<!-- PREGUNTA DE CONDICION URINARIA -->
<box title="Si pasara el resto de su vida con su condición urinaria actual ¿Como se sentiría al respecto?" icon="fa-solid fa-clipboard-question">

<section class="row">
  <div class="form-group col-12 col-lg-4">
    <select class="form-control" @change="saveDataCU"
    v-model="condicion_urinaria.selection">
      <option selected>
            Seleccione una opcion
        </option>
        <option value="Encantado">
          Encantado
        </option>
        <option value="Complacido">
          Complacido
        </option>
        <option value="Mayormente satisfecho">
          Mayormente satisfecho
        </option>
        <option value="Mayormente insatisfecho">
          Mayormente insatisfecho
        </option>
        <option value="Infeliz">
          Infeliz
        </option>
        <option value="Terrible">
          Terrible
        </option>
    </select>
  </div>
</section>
</box>

<!-- INVENTARIO DE SALUD SEXUAL -->
<box class="boxx" title="Inventario de salud sexual" icon="fa-solid fa-bed-pulse">
    
    <section class="background row">
        <p class="col-12">La salud sexual es una parte importante del bienestar físico y emocional general de un individuo. La disfunción eréctil, también conocida como impotencia, es un tipo de afección médica muy común que afecta la salud sexual. Afortunadamente, existen muchas opciones de tratamiento diferentes para la disfunción eréctil. Este cuestionario está diseñado para ayudarle a usted y a su médico a identificar si puede estar experimentando disfunción eréctil. Si es así, puede optar por discutir las opciones de tratamiento con su médico.</p>

        <div v-for="question in salud_sexual" :key="question.pregunta" class="form-group col-12 col-md-4 row-gap-5 ">
          <label for="formControlRange">{{ question.pregunta }}</label>
          <input v-model="question.puntaje" type="range" min="0" max="5" step="1" class="form-control-range" id="formControlRange" aria-describedby="label"
          @change="saveDataSS">
          <small id="label" class="form-text" :class="
          question.puntaje==0 && 'text-danger' ||
          question.puntaje==1 && 'text-warning' ||
          question.puntaje==2 && 'text-muted'||
          question.puntaje==3 && 'text-success' ||
          question.puntaje==4 && 'text-info'||
          question.puntaje==5 && 'text-primary'
          ">{{question.label[question.puntaje]}}</small>
        </div>

        <div class="m-2 col-12 row">
          <div class="card text-white mb-3 " :class="totalLabel('card')">
          <div class="card-header " :class="totalLabel('card')">Total</div>
          <div class="card-body">
            <h5 class="card-title text-white font-weight-bold">{{sumarPuntajes()}}</h5>
            <p class="card-text">Estado precalculado de disfunción eréctil: {{ totalLabel("label") }}</p>
          </div>
          </div>
        </div>

  
      </section>
    
  </box>

  

</template>
 
 
<style scoped>
 
.background{
  row-gap: 10px;
 }
.card{
  box-sizing: border-box;
  min-width: 5rem;
  max-width: 50rem;
  min-height: 8rem;
  max-height: 10rem;
}
.card-header{
  display: flex;
  align-items: center;
  min-height: 2rem;
  max-height: 3rem;
}

.card-body{
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  min-height: 4rem;
  max-height: 5rem;
}

.favoritelab{
  row-gap: 15px;
 }

 .addAllergies{
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
 }

 .socialhistory{
   row-gap: 15px;
}

.smoke{
   row-gap: 15px;
}

.directive{
   row-gap: 15px;
}

</style>