export const CONFIG = {
    defaultLang: 'es',
    apiUrl: process.env.ANY_URL,
    getWsUrl: function (endpoint) {
        // Verificar si el endpoint ya contiene 'https://' al inicio
        if (endpoint.startsWith('https://')) {
            return endpoint; // Si ya tiene https://, retornar el endpoint completo
        } else {
            return process.env.ANY_URL + '/' + endpoint; // Si no, agregarlo al final
        }
    },
    localStorageDisk: process.env.ANY_DISK,
    appVersion: "1.1.9"
};

