<script>
 import { VueperSlides, VueperSlide } from 'vueperslides'
 import 'vueperslides/dist/vueperslides.css'
 
export default { 
 data() {
        return {
        //data
 }},
 components:{
    VueperSlides, 
    VueperSlide
 },
 props:{
 editIndex: Number,
 procedimientos: Object,
 },
 mounted(){
  console.log(this.editIndex);
 },
 }
</script>
 
 
<template>
  <div class="d-flex flex-column justify-content-between content">
    <div v-if="Object.keys(procedimientos.content[editIndex].imagenes).length" class="imgContent">
      <table class="table">
        <thead class="thead-light">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in procedimientos.content[editIndex].imagenes" :key="key">
            <td>{{ item.nombre }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="d-flex justify-content-center mt-4">
          <p class=" text-muted">Este procedimiento aún no tiene imágenes agregados</p>
    </div>
      <div class="d-flex justify-content-start addImgButton">
        <label for="imageInput" class="btn btn-primary">
          <input type="file" id="imageInput" accept="image/*" />
          Agregar imagen <i class="fa-solid fa-images"></i>
        </label>
      </div>
  </div>
</template>
 
 
<style scoped>
 

 .content{
  width: 100%;
  height: calc( 100% - 60px );
}

.imgContent{
  width: 100%;
  height: calc( 100% - 40px );
  overflow: hidden;
  overflow-y: auto;
}

.addImgButton{
  width: 100%;
  height: 45px;
}

 /* .custom-file-upload {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.custom-file-upload:hover {
  background-color: #0056b3;
} */

/* Oculta el input de tipo file */
#imageInput {
  display: none;
}

</style>