<template>
    <div class="order-search" v-show="showProductSearchBox" v-on:click.self="hideSearch">
        <div class="search-container h-100">
            <div class="text-center mb-3">
                <h4>Buscar productos y servicios</h4>
            </div>
            <div class="input-group">
                <input type="text" class="form-control searchField" placeholder="Búsqueda de productos por nombre" v-model="searchTerm" v-on:keyup.enter="onEnter"/>
                <div class="input-group-append" @click="searchTerm = ''">
                    <span class="input-group-text" id="basic-addon2"><i class="fa fa-times"></i></span>
                </div>
            </div>
            <div>
                <small>
                    Búsqueda por nombre de producto o servicio, sku
                </small>
            </div>
            <div class="clientSearchButtons text-center mb-2 mt-1">
                <button type="button" class="btn btn-primary mr-3" @click="search">
                    <i class="fa fa-search"></i> <span>Buscar</span>
                </button>
                <button type="button" class="btn btn-dark mr-3" @click="hideSearch">
                    <i class="fa fa-close"></i> <span>Cerrar</span>
                </button>
<!--                <button v-if="searched" type="button" class="btn btn-primary mt-3">
                    <i class="fa fa-plus"></i> <span>Crear producto</span>
                </button>-->
            </div>
            <div v-show="this.tipoOrden === 'recurrente'" class="mt-4 text-center">
                Se muestran solo productos habilitados para recurrencia
            </div>
            <div class="search-response row">
                <div class="col-12 h-100" v-for="item in searchResults" :key="item.id">
                    <div class="media">
                        <div class="media-body">
                            <div class="row">
                                <div class="col-4 col-md-2">
                                    <img v-if="item.thumbnail !== ''" :src="item.thumbnail" class="img-thumbnail">
                                    <i v-else class="fa fa-image fa-3x"></i>
                                </div>
                                <div class="col-8 col-md-10">
                                    <span class="text-primary">{{item.nombre}}</span><br/>
                                    <span class="text-dark">SKU: {{item.sku}}</span><br/>
                                    <div v-if="parseInt(item.recurrente) === 1">
                                        <span class="text-success"><i class="fas fa-sync mr-1"></i> Recurrente</span><br/>
                                    </div>
                                    <div v-if="item.onSale">
                                        Precio: <span class="font-weight-bold">{{item.precioOnSale}}</span><br/>
                                    </div>
                                    <div v-else>
                                        Precio: <span class="font-weight-bold">{{item.precio}}</span><br/>
                                    </div>
                                    <span>Disponibles:</span> <span class="text-dark">{{ item.stock }}</span>
                                    <div class="d-block d-sm-none add-option btn btn-outline-success btn-sm mt-2" @click="selectProduct(item)">
                                        Agregar
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-none d-sm-block add-option btn btn-outline-success" @click="selectProduct(item)">
                            Agregar
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import API from "src/core/Api";
import {mapActions} from "vuex";
import Tools from "../../../core/Tools";

export default {
    name: "order-search",
    components: {},
    props: {
        showProductSearchBox: {
            type: Boolean,
            default: false // Valor predeterminado de false
        },
        tipoOrden: {
            type: String,
            default: false // Valor predeterminado 'default'
        },
        tipoProductos: {
            type: Array,
            default: () => [] // Valor predeterminado es un array vacío
        },
        disableNotify: {
            type: Boolean,
            default: false // Valor predeterminado de false
        },
        redirectLink: {
            type: String,
            default: '' // Valor predeterminado es una cadena vacía
        }
    },
    data() {
        return {
            filterByType: '',
            searchResults: {},
            pageSelected: 1,
            limit: 10,
            filter: 0,
            searchTerm: ''
        };
    },
    watch: {
        showProductSearchBox: function (val) {
            if (val) {
                this.search();
                document.body.classList.add("overflowLocked");
            }
            else {
                document.body.classList.remove("overflowLocked");
            }
        },
        showClientSearch: function (val) {
            if (val) {
                document.body.classList.add("overflowLocked");
            }
            else {
                document.body.classList.remove("overflowLocked");
            }
        },
    },
    mounted() {
        this.filterByType = (!this.tipoProductos) ? 'all' : this.tipoProductos;
        //this.search();
    },
    methods: {
        ...mapActions({
            OrderAddProductToPos: 'OrderAddProductToPos',
        }),
        hideSearch: function () {
            this.$emit('update:showProductSearchBox', false);
            this.searchTerm = '';
        },
        onEnter: function() {
            this.search();
        },
        search() {
            const self = this;

            let urlToSearch = '';

            if (this.tipoOrden === 'directo') {
                urlToSearch = 'products/all/1/'+self.pageSelected+'/'+self.limit+'/'+self.filter;

                if (this.searchTerm !== '') {
                    urlToSearch = 'products/search/'+this.searchTerm;
                }
            }
            else {
                urlToSearch = 'products/all/recurrente/1';

                if (this.searchTerm !== '') {
                    urlToSearch = 'products/search/recurrente/'+this.searchTerm;
                }
            }

            const dataSearch = {
                term: this.searchTerm,
                page: self.pageSelected,
                perPage: self.limit,
                filter: self.filter,
                filterByType: self.filterByType,
            }

            API.send('POST', 'products/do-search', dataSearch,
                function (response) {
                    if (response.status) {
                        if(Tools.isEmptyString(response.data.productos)){
                            self.searchResults = response.data;
                        }
                        else{
                            self.searchResults = response.data.productos;
                        }

                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },
        selectProduct: function (product) {
            if(this.redirectLink){
                this.$router.push(this.redirectLink + product.id)
            }
            else{
                this.$emit('productSelected', product);
                this.$store.dispatch('OrderAddProductToPos', product);
            }

        }
    },
};
</script>
