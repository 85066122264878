<template>
    <layout :active="37">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Impresión de gafetes y credenciales</h4>
                <img src="staticAssets/formBuilder/docsplus.png" style="max-width: 150px">
            </div>
            <div class="card-body ticketPrinter">
                <div class="text-muted">
                    Generar un gafete o credencial basada en un formato +Docs
                </div>
                <div class="row mb-4 mt-3">
                    <div class="col-12">
                        <label>
                            Selecciona la plantilla
                        </label>
                        <select v-model="formularioSelected" class="form-control" @change="templateChanged">
                            <option v-for="item in docsForms" :value="item.token" :key="'fdoc'+item.id">{{ item.descripcion }}</option>
                        </select>
                        <!--<button @click="templateChanged">Actualizar</button>-->
                    </div>
                </div>
                <div>
                    <div class="mt-3">
                        <h5>Impresión de credenciales</h5>
                        <hr>
                        <div class="row" v-if="!proccessStarted">
                            <div class="col-12 col-sm-6">
                                <label>Ingresar código de entrada</label>
                                <div class="input-group mb-3">
                                    <input type="text" v-model="codigoOrden" class="form-control" placeholder="00000000000"/>
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" type="button" @click="getOrderData">Validar código</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-3">
                                <label>&nbsp;</label>
                                <button class="btn btn-primary w-100" @click="validatorStarted = true"><i class="fas fa-qrcode mr-2"></i>Escanear QR</button>
                                <QrReader @qrScanned="qrScanned"  @readerStop="readerStop" v-if="validatorStarted"></QrReader>
                            </div>
                            <div class="col-12 col-sm-3">
                                <label>&nbsp;</label>
                                <button class="btn btn-primary w-100" @click="impresionSinOrden = true; proccessStarted = true"><i class="fas fa-user-check mr-2"></i>Sin una orden</button>
                            </div>
                        </div>
                        <div v-else class="text-center">
                            <button class="btn btn-outline-danger btn-sm" @click="resetSearch"><i class="fas fa-undo mr-2"></i>Reiniciar búsqueda</button>
                        </div>
                    </div>
                    <div class="mt-4" v-if="token !== ''">
                        <h5 class="text-primary">Detalle de orden</h5>
                        <div>
                            <div><b>Código de orden: </b>{{codigoOrden}}</div>
                            <div><b>Nombre cliente: </b>{{orderDetail.n}}</div>
                            <div><b>Fecha: </b>{{orderDetail.f}}</div>
                        </div>
                        <ul class="list-group mt-3">
                            <li :class="{'list-group-item selectedItem': parseInt(noEntrada) === parseInt(item.num), 'list-group-item': parseInt(noEntrada) !== parseInt(item.num)}"  v-for="item in orderDetail.d" :key="item.id">
                                <div class="row">
                                    <div class="col-12 col-sm-8">
                                        {{item.prod}}
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <button @click="selectDetalle(item.num, item.id)" class="btn btn-primary w-100"><i class="fas fa-print mr-2"></i>Seleccionar</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-4" v-if="(Object.keys(tplDescription).length > 0 && noEntrada !== false && proccessStarted) || impresionSinOrden === true">
                        <h5>Datos para impresión</h5>
                        <hr>
                        <div class="row">
                            <template  v-for="item in tplDescription" :key="item.id">
                                <div class="col-12 col-sm-6" v-if="item.type === 'vcard_field'">
                                    <label class="form-label">{{item.n}}</label>
                                    <input type="text" class="form-control" v-model="item.val"/>
                                </div>
                                <div class="col-12 col-sm-6" v-if="item.type === 'type_field_text'">
                                    <label class="form-label">{{item.id}}</label>
                                    <input type="text" class="form-control" v-model="item.val"/>
                                </div>
                            </template>
                        </div>
                        <div class="mt-3">
                            <button @click="printCredential" class="btn btn-primary w-100"><i class="fas fa-print mr-2"></i> Imprimir credencial</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import API from "src/core/Api";
//import Tools from "src/core/Tools";
import QrReader from 'src/components/QrReader';
import {mapGetters} from "vuex";
import layout from "/src/modules/order/components/settings/settingLayout.vue";
//import {store} from 'src/store'
//import DataValidation from "src/core/DataValidation";

export default {
    components: {
        layout,
        QrReader,
    },
    data() {
        return {
            validatorStarted: false,
            scannedInfo: {},
            token: '',
            proccessStarted: false,
            impresionSinOrden: false,

            // escaneo de orden
            noEntrada: false,
            ordenDetail: false,
            codigoOrden: '',
            qrScannedResult: '',
            orderDetail: {},

            docsForms: {},
            formularioSelected: false,
            tplDescription: {},
        };
    },
    watch: {
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo'
        })
    },
    mounted() {
        this.getDocsForms();
    },
    methods: {
        resetSearch() {
            const self = this;
            this.token = '';
            this.scannedInfo = {};
            this.orderDetail = {};
            this.validatorStarted = false;
            this.ordenDetail = false;
            this.codigoOrden = '';
            this.qrScannedResult = '';
            this.impresionSinOrden = false;
            this.proccessStarted = false;

            Object.keys(self.tplDescription).map(function (a){
                self.tplDescription[a].val = '';
            })
        },
        qrScanned(data) {
            this.qrScannedResult = data;
            let token = /[^/]*$/.exec(this.qrScannedResult);
            this.token = (typeof token[0] !== 'undefined') ? token[0] : false;
            this.getScannedInfo();
        },
        readerStart() {
            this.validatorStarted = true;
        },
        readerStop() {
            this.validatorStarted = false;
        },
        getScannedInfo() {
            const self = this;
            if (self.token !== '') {
                API.send('POST', 'formularios/verifyAssistance', {
                        token: self.token
                    },
                    function (response) {
                        if (response.status) {
                            self.scannedInfo = response.data;
                            self.codigoOrden = self.scannedInfo.c;
                            self.noEntrada = self.scannedInfo.dn;
                            self.orderDetailId = self.scannedInfo.od;
                            self.proccessStarted = true;
                            self.getOrderData();
                        }
                        self.readerStop();
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
        },
        getOrderData() {
            const self = this;
            API.send('POST', 'formularios/ticket-printer/order-data', {
                    codigoOrden: self.codigoOrden,
                },
                function (response) {
                    self.orderDetail = response.data;
                    self.token = self.orderDetail.t
                    self.proccessStarted = true;

                    /* 'ticket_vcard_qr' => 'Código QR (V-Card)',
                        'ticket_vcard_name' => 'Nombre',
                        'ticket_vcard_last_name' => 'Apellido',
                        'ticket_vcard_phone' => 'Teléfono',
                        'ticket_vcard_phone_o' => 'Teléfono oficina',
                        'ticket_vcard_email' => 'Correo electrónico',
                        'ticket_vcard_company' => 'Compañia',
                        'ticket_vcard_role' => 'Rol o puesto',
                        'ticket_vcard_dir' => 'Dirección',*/
                    Object.keys(self.tplDescription).map(function (a){

                        if (self.tplDescription[a].id === 'ticket_vcard_name') {
                            self.tplDescription[a].val = self.orderDetail.n;
                        }
                        if (self.tplDescription[a].id === 'ticket_vcard_phone') {
                            self.tplDescription[a].val = self.orderDetail.tl;
                        }
                        if (self.tplDescription[a].id === 'ticket_vcard_email') {
                            self.tplDescription[a].val = self.orderDetail.c;
                        }
                    })
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        selectDetalle(num, ordenDetail) {
            this.noEntrada = num;
            this.orderDetailId = ordenDetail;
        },
        getDocsForms() {
            const self = this;
            API.send('GET', 'formularios/all', self.variacion, function (response) {
                    self.docsForms = response.data;
                    //API.showSuccessNotify(response.msg);
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        templateChanged() {
            const self = this;
            API.send('POST', 'formularios/docs-plus/template-describe', {
                    token: self.formularioSelected
                },
                function (response) {
                    self.tplDescription = response.data;
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        printCredential() {
            const self = this;
            let data = [];
            Object.keys(self.tplDescription).map(function (a){
                data.push({
                    'id': self.tplDescription[a].id,
                    'val': self.tplDescription[a].val,
                })
            })

            API.send('POST', 'formularios/ticket-printer/print-credential', {
                    token: self.token,
                    orderDetailId: self.orderDetailId,
                    noEntrada: self.noEntrada,
                    formToken: self.formularioSelected,
                    fields: data,
                },
                function (response) {
                    if (response.status) {
                        const myWindow=window.open(response.data,'','width=900,height=800');
                        myWindow.focus();
                        setTimeout(function () {
                            myWindow.print();
                        }, 1000);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
    },
};
</script>
