<template>
    <control-comercios-layout :active="60">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Listado de concilias</h4>
                <div class="float-right">
                    <a @click="$router.push('/sub-commerce/concilia/0')" role="button" tabindex="0" class="btn btn-primary px-4 mr-3">
                        Crear nueva
                    </a>
                </div>
            </div>
            <div class="card-body">
                <div class="form">
                    <ul class="linked_account">
                        <li v-for="item in concilias" :key="item.id">
                            <div class="media d-block">
                                <div class="row">
                                    <div class="col-9">
                                        <h5 class="mt-0 mb-1">{{ item.nombres }} {{ item.apellidos }}</h5>
                                        <div class="row">
                                            <div class="col-12">
                                                <h5>Identificador de concilia: {{item.id}}</h5>
                                            </div>
                                            <div class="col-12 text-primary">
                                                <span v-if="item.type === 'pago_terceros'">Pago de terceros a clientes</span>
                                                <span v-if="item.type === 'comision_socios'">Pago de comisiones a socios</span>
                                                <span v-if="item.type === 'comision_procesador'">Cobro de comisiones a procesador</span>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <div><b>Fecha inicial:</b> {{dayjs(item.fechaInicial).format('DD-MM-YYYY')}}</div>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <div><b>Fecha final:</b> {{dayjs(item.fechaFinal).format('DD-MM-YYYY')}}</div>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <div><b>Creada:</b> {{dayjs(item.createdAt).format('DD-MM-YYYY')}}</div>
                                            </div>
                                        </div>
                                        <!--<div>
                                            <b>Roles asignados:</b> &nbsp; <span v-for="rol in item.roles" :key="rol.id + '_rol'" class="mr-2 badge badge-primary">{{rol.nombre}}</span>
                                        </div>-->
                                    </div>
                                    <div class="col-3 text-right">
                                        <div class="edit-option text-right">
                                            <a @click="$router.push('/sub-commerce/concilia/' + item.id)">
                                                <i class="fa fa-pencil"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="col-md-12 col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Cambiar información</h4>
                        </div>
                        <div class="card-body">
                            <form class="form form-horizontal">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="mb-1 row">
                                            <div class="col-sm-3">
                                                <label class="col-form-label" for="first-name">Búsqueda por DPI</label>
                                            </div>
                                            <div class="col-sm-9">
                                                <input type="text" id="first-name" class="form-control" name="fname" placeholder="DPI">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="mb-1 row">
                                            <div class="col-sm-3">
                                                <label class="col-form-label" for="first-name">Nombres</label>
                                            </div>
                                            <div class="col-sm-9">
                                                <input type="text" id="first-name" class="form-control" name="fname" placeholder="Nombres">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="mb-1 row">
                                            <div class="col-sm-3">
                                                <label class="col-form-label" for="first-name">Primer apellido</label>
                                            </div>
                                            <div class="col-sm-9">
                                                <input type="text" id="first-name" class="form-control" name="fname" placeholder="Primer apellido">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="mb-1 row">
                                            <div class="col-sm-3">
                                                <label class="col-form-label" for="first-name">Segundo apellido</label>
                                            </div>
                                            <div class="col-sm-9">
                                                <input type="text" id="first-name" class="form-control" name="fname" placeholder="Segundo Apellido">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="mb-1 row">
                                            <div class="col-sm-3">
                                                <label class="col-form-label" for="email-id">Email</label>
                                            </div>
                                            <div class="col-sm-9">
                                                <input type="email" id="email-id" class="form-control" name="email-id" placeholder="Email">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="mb-1 row">
                                            <div class="col-sm-3">
                                                <label class="col-form-label" for="contact-info">Teléfono</label>
                                            </div>
                                            <div class="col-sm-9">
                                                <input type="number" id="contact-info" class="form-control" name="contact" placeholder="Teléfono">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="mb-1 row">
                                            <div class="col-sm-3">
                                                <label class="col-form-label" for="contact-info">Monto pagado</label>
                                            </div>
                                            <div class="col-sm-9">
                                                <input type="number" id="contact-info" class="form-control" name="contact" placeholder="Monto pagado">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="mb-1 row">
                                            <div class="col-sm-3">
                                                <label class="col-form-label" for="password">Agencia</label>
                                            </div>
                                            <div class="col-sm-9">
                                                <select>
                                                    <option>CHIQUIMULA</option>
                                                    <option>Bimensual</option>
                                                    <option>Trimestral</option>
                                                    <option>Semestral</option>
                                                    <option>Anual</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="mb-1 row">
                                            <div class="col-sm-3">
                                                <label class="col-form-label" for="password">Frecuencia de pago</label>
                                            </div>
                                            <div class="col-sm-9">
                                                <select>
                                                    <option>Mensual</option>
                                                    <option>Bimensual</option>
                                                    <option>Trimestral</option>
                                                    <option>Semestral</option>
                                                    <option>Anual</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-9 offset-sm-3">
                                        <div class="mb-1">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck1">
                                                <label class="form-check-label" for="customCheck1">ELIMINAR</label>
                                            </div>
                                        </div>
                                        <div class="mb-1">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck1">
                                                <label class="form-check-label" for="customCheck1">CANCELAR</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-9 offset-sm-3">
                                        <button type="reset" class="btn btn-primary me-1 waves-effect waves-float waves-light">Guardar</button>
                                        <button type="reset" class="btn btn-outline-secondary waves-effect">Refrescar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </control-comercios-layout>
</template>
<script>
import controlComerciosLayout from "../../components/settings/controlComerciosLayout.vue";
import API from "src/core/Api";
import FileUploader from 'src/components/files/FileUploader';
import Multiselect from 'vue-multiselect'
import {store} from "src/store";
import dayjs from "dayjs";


export default {
    components: {
        controlComerciosLayout,
    },
    data() {
        return {
            concilias: {},
        }
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        dayjs,
        loadItems() {
            const self = this;
            API.send('GET', 'stores/concilia/list', {},
            function (data) {
                self.concilias = data.data;
            },
            function (data) {
                API.showErrorAlert(data.msg);
            });
        },
    },
};
</script>
