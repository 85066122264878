<template>
    <div class="container mt-5">
        <h2 class="text-center">Encuesta de Satisfacción de Servicios</h2>
        <form @submit.prevent="submitForm">
            <!-- Pregunta 1 -->
            <div class="form-group">
                <label class="text-center d-block">1. ¿Cómo calificas la atención al cliente?</label>
                <div class="btn-group btn-group-toggle d-flex justify-content-center" data-toggle="buttons">
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction1 === '1' }">
                        <input type="radio" name="satisfaction1" value="1" v-model="satisfaction1" />
                        😢
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction1 === '2' }">
                        <input type="radio" name="satisfaction1" value="2" v-model="satisfaction1" />
                        😞
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction1 === '3' }">
                        <input type="radio" name="satisfaction1" value="3" v-model="satisfaction1" />
                        😐
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction1 === '4' }">
                        <input type="radio" name="satisfaction1" value="4" v-model="satisfaction1" />
                        😊
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction1 === '5' }">
                        <input type="radio" name="satisfaction1" value="5" v-model="satisfaction1" />
                        😁
                    </label>
                </div>
            </div>

            <!-- Pregunta 2 -->
            <div class="form-group">
                <label class="text-center d-block">2. ¿Cómo calificas la rapidez del servicio?</label>
                <div class="btn-group btn-group-toggle d-flex justify-content-center" data-toggle="buttons">
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction2 === '1' }">
                        <input type="radio" name="satisfaction2" value="1" v-model="satisfaction2" />
                        😢
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction2 === '2' }">
                        <input type="radio" name="satisfaction2" value="2" v-model="satisfaction2" />
                        😞
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction2 === '3' }">
                        <input type="radio" name="satisfaction2" value="3" v-model="satisfaction2" />
                        😐
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction2 === '4' }">
                        <input type="radio" name="satisfaction2" value="4" v-model="satisfaction2" />
                        😊
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction2 === '5' }">
                        <input type="radio" name="satisfaction2" value="5" v-model="satisfaction2" />
                        😁
                    </label>
                </div>
            </div>

            <!-- Pregunta 3 -->
            <div class="form-group">
                <label class="text-center d-block">3. ¿Cómo calificas la calidad del servicio?</label>
                <div class="btn-group btn-group-toggle d-flex justify-content-center" data-toggle="buttons">
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction3 === '1' }">
                        <input type="radio" name="satisfaction3" value="1" v-model="satisfaction3" />
                        😢
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction3 === '2' }">
                        <input type="radio" name="satisfaction3" value="2" v-model="satisfaction3" />
                        😞
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction3 === '3' }">
                        <input type="radio" name="satisfaction3" value="3" v-model="satisfaction3" />
                        😐
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction3 === '4' }">
                        <input type="radio" name="satisfaction3" value="4" v-model="satisfaction3" />
                        😊
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction3 === '5' }">
                        <input type="radio" name="satisfaction3" value="5" v-model="satisfaction3" />
                        😁
                    </label>
                </div>
            </div>

            <!-- Pregunta 4 -->
            <div class="form-group">
                <label class="text-center d-block">4. ¿Recomendarías nuestro servicio?</label>
                <div class="btn-group btn-group-toggle d-flex justify-content-center" data-toggle="buttons">
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction4 === '1' }">
                        <input type="radio" name="satisfaction4" value="1" v-model="satisfaction4" />
                        😢
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction4 === '2' }">
                        <input type="radio" name="satisfaction4" value="2" v-model="satisfaction4" />
                        😞
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction4 === '3' }">
                        <input type="radio" name="satisfaction4" value="3" v-model="satisfaction4" />
                        😐
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction4 === '4' }">
                        <input type="radio" name="satisfaction4" value="4" v-model="satisfaction4" />
                        😊
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction4 === '5' }">
                        <input type="radio" name="satisfaction4" value="5" v-model="satisfaction4" />
                        😁
                    </label>
                </div>
            </div>

            <!-- Pregunta 5 -->
            <div class="form-group">
                <label class="text-center d-block">5. ¿Cómo calificas la experiencia general?</label>
                <div class="btn-group btn-group-toggle d-flex justify-content-center" data-toggle="buttons">
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction5 === '1' }">
                        <input type="radio" name="satisfaction5" value="1" v-model="satisfaction5" />
                        😢
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction5 === '2' }">
                        <input type="radio" name="satisfaction5" value="2" v-model="satisfaction5" />
                        😞
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction5 === '3' }">
                        <input type="radio" name="satisfaction5" value="3" v-model="satisfaction5" />
                        😐
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction5 === '4' }">
                        <input type="radio" name="satisfaction5" value="4" v-model="satisfaction5" />
                        😊
                    </label>
                    <label class="btn btn-outline-secondary mx-1" :class="{ active: satisfaction5 === '5' }">
                        <input type="radio" name="satisfaction5" value="5" v-model="satisfaction5" />
                        😁
                    </label>
                </div>
            </div>

            <div class="form-group mt-4 text-center">
                <button type="submit" class="btn btn-success">Enviar Encuesta</button>
            </div>
        </form>

        <!-- Mensaje de confirmación -->
        <div v-if="submitted" class="alert alert-info mt-3 text-center">
            ¡Gracias por tu respuesta!
            <ul>
                <li>Atención al cliente: {{ satisfaction1 }}</li>
                <li>Rapidez del servicio: {{ satisfaction2 }}</li>
                <li>Calidad del servicio: {{ satisfaction3 }}</li>
                <li>Recomendación: {{ satisfaction4 }}</li>
                <li>Experiencia general: {{ satisfaction5 }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            satisfaction1: null,
            satisfaction2: null,
            satisfaction3: null,
            satisfaction4: null,
            satisfaction5: null,
            submitted: false
        };
    },
    methods: {
        submitForm() {
            this.submitted = true;
        }
    }
};
</script>

<style scoped>
.btn-group .btn {
    font-size: 2rem;
}
</style>
