<template>
    <box title="Listado de adjuntos" icon="fa fa-paperclip">
        <div v-if="typeof GetMedicoFichaActive.metadata.attach === 'undefined'">
            <div class="text-center text-danger">No tiene adjuntos cargados</div>
        </div>
        <div v-else>
            <div v-for="(attachCat, key) in GetMedicoFichaActive.metadata.attach" :key="key">
                <template v-if="(key==='L') || (key==='I') || (key==='O')">
                    <box :title="selectBoxTitle(key)" :icon="selectBoxIcon(key)" :contraction="true" >   
                        <div class="row">
                            <div class="col-12 col-sm-4" v-for="item in attachCat" :key="item.id">
                                <div class="attachmentLine">
                                    <div class="mb-3">
                                        <h6 class="font-weight-bold">{{item.detail.name || 'Sin nombre'}} <!--<span @click="item.detail.editing = true"><i class="fa fa-edit text-primary ml-3"></i></span>--></h6>
                                        <hr class="my-1">
                                        <div v-if="item.detail.editing">
                                            <div class="mb-2">
                                                <div><b>Nombre:</b></div>
                                                <input type="text" v-model="item.detail.name" class="form-control" />
                                            </div>
                                            <div class="mb-2">
                                                <div><b>Tipo:</b></div>
                                                <select class="form-control" v-model="item.detail.cat">
                                                    <option value="L">Laboratorios</option>
                                                    <option value="I">Imágenes</option>
                                                    <option value="O">Otros</option>
                                                </select>
                                            </div>
                                            <div class="text-right">
                                                <button class="btn btn-sm btn-primary mt-3 mr-3" @click="editAdjunto(item)">Guardar</button>
                                                <button class="btn btn-sm btn-danger mt-3" @click="item.detail.editing = false">Cancelar</button>
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-9">
                                            <div>
                                                <b>Fecha de carga:</b> {{ dayjs(item.createdAt).format('DD-MM-YYYY H:mm:ss') || '' }}
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 text-center">
                                            <div v-if="item.urlTMP.fileUrl" :key="item.urlTMP.fileUrl">
                                                <object :data="item.urlTMP.fileUrl" type="application/pdf" width="100%" height="600">
                                                    <p>El visor de PDF no está disponible en tu navegador. Puedes <a :href="item.urlTMP.fileUrl">descargar el PDF</a> en su lugar.</p>
                                                </object>
                                            </div>
                                            <div v-else>
                                                Sin PDF adjunto.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">                   
                                        <div class="col-12 d-flex justify-content-end align-items-center p-2">
                                            <div class="m-1 btn btn-success"  @click="sendAdjunto(item, key)">
                                                Enviar <i class="fas fa-envelope cursor-pointer"></i>
                                            </div>
                                            <div class="m-1 btn btn-primary"  @click="downloadAdjunto(item)">
                                                Descargar <i class="fas fa-download cursor-pointer"></i>
                                            </div>
                                            <div class="m-1 btn btn-danger"  @click="deleteAdjunto(item)">
                                                Eliminar <i class="fas fa-trash cursor-pointer"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="attachCat.length===0" class="w-100 p-3 d-flex justify-content-center align-items-center">
                                <p>Sin Adjuntos</p>
                            </div>
                        </div>
                    </box>
                </template>
            </div>
        </div>
        <hr>
        <div>
            <h5 class="text-primary">Carga de adjuntos <i class="fa fa-upload ml-3"></i></h5>
        </div>
        <div class="row mt-5">
            <div class="col-12 col-sm-4">
                <div>
                    <h5>Cargar Laboratorios</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <FileUploader
                                url="orders/metadata-attachments/save"
                                :accepted-mime="[
                                    'image/*',
                                    'application/pdf',
                                    'application/x-xls',
                                    'application/vnd.ms-excel',
                                    'application/msword',
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                    'application/dicom', // .dcm
                                    'application/hdr',    // .hdr
                                    'application/nii',    // .nii
                                    'application/ktx',
                                ]"
                                remove-file-on-finish="1"
                                :extra-data-form="laboratoriosUploadExtraDataForm"
                                @fileSuccess="fileSuccess"
                                ></FileUploader>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div>
                    <h5>Cargar Imágenes</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <FileUploader url="orders/metadata-attachments/save"
                                :accepted-mime="['image/*', 'application/pdf']"
                                    remove-file-on-finish="1"
                                    :extra-data-form="imagenesUploadExtraDataForm"
                                    @fileSuccess="fileSuccess"></FileUploader>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div>
                    <h5>Cargar otros adjuntos</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <FileUploader
                                url="orders/metadata-attachments/save"
                                :accepted-mime="[
                                    'image/*',
                                    'file/.dcm',
                                    'application/pdf',
                                    'application/x-xls',
                                    'application/vnd.ms-excel',
                                    'application/msword',
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                    'application/octet-stream',
                                ]"
                                remove-file-on-finish="1"
                                :extra-data-form="otrosUploadExtraDataForm"
                                @fileSuccess="fileSuccess"
                            ></FileUploader>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </box>
</template>
<script>
import API from "src/core/Api";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import Slider from '@vueform/slider';

import Box from "src/modules/medico/views/fichas/Box.vue";
import FileUploader from "src/components/files/FileUploader.vue";
import Tools from "src/core/Tools";

// Componentes

export default {
    components: {
        FileUploader,
        Box,
        Slider
    },
    props: [
        'type',
    ],
    data() {
        return {
            adjunto: {},
            laboratoriosUploadExtraDataForm: {
                orderId: 0,
                category: 'L',
            },
            imagenesUploadExtraDataForm: {
                orderId: 0,
                category: 'I',
            },
            otrosUploadExtraDataForm: {
                orderId: 0,
                category: 'O',
            },
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {
    },
    mounted() {
        this.laboratoriosUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id
        this.imagenesUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id
        this.otrosUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id
    },
    methods: {
        ...mapActions({
            OrderSaveMetadata: 'OrderSaveMetadata',
        }),
        dayjs,
        Numbro,
        selectBoxTitle(key){
            if(key==='L'){
                return 'Laboratorios';
            }else if(key==='I'){
                return 'Imagenes';
            }else if(key==='O'){
                return 'Otros';
            }
        },
        selectBoxIcon(key){
            if(key==='L'){
                return 'fa fa-vial';
            }else if(key==='I'){
                return 'fa-solid fa-image';
            }else if(key==='O'){
                return 'fa fa-file';
            }
        },
        // Signos vitales
        resetSignoVital() {
            this.signoVital = {
                id: 0,
                detail: {
                    fechaHora: dayjs().format('DD-MM-YYYY HH:mm'),
                    presionArterial: '',
                    frecuenciaCardiaca: '',
                    frecuenciaRespiratoria: '',
                    saturacionDeOxigeno: '',
                    temperatura: '',
                    peso: '',
                    talla: '',
                    IMC: '',
                    glicemia: '',
                    estadoDeConciencia: '',
                    dolor: 0,
                }
            };
        },
        cancelEdit() {
            this.showEdition = false;
            this.resetSignoVital();
        },
        saveSignos() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.signoVital.id,
                    slug: 'preclinica_signos_v',
                    detail: self.signoVital.detail,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEdit();
        },
        addNewSignoVital() {
            this.resetSignoVital();
            this.showEdition = true;
        },
        editSignoVital(item) {
            this.resetSignoVital();
            if (!item.detail.dolor) item.detail.dolor = 0; // arreglo el dolor por si viene null
            this.signoVital = item;
            this.showEdition = true;
        },
        deletesignoVital(item, key) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },

        // subida de archivos
        fileSuccess(data) {
            this.gallery = Tools.pushToObject(this.gallery, data);
            this.emitter.emit("medico-ficha-refresh");
        },
        downloadAdjunto(item) {
            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata-attachments/download', {
                    metadataId: item.id,
                }, function (response) {
                    if (response.data) {
                        window.open(response.data);
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        sendAdjunto(item, tipoAdjunto){
          API.send('POST', 'orders/metadata-attachments/send', {
                metadataId: item.id,
                orderId: this.GetMedicoFichaActive.orden.id,
                title: tipoAdjunto
              }, function (response) {
                API.showSuccessAlert(response.msg);
              },
              function (response) {
                API.showErrorAlert(response.msg);
              });
        },
        deleteAdjunto(item) {
            const self = this;
            // Guardo y luego emito el evento
            API.showConfirm('Espere', 'Si elimina este adjunto, no podrá recuperarlo. ¿Desea continuar?', function () {
                API.send('POST', 'orders/metadata-attachments/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },
    },
};

</script>
