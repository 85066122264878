<template>
    <div class="pos-view">
        <div class="pos-header">
            <div class="row">
                <div class="col-12 col-sm-3">
                    <img :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre" style="margin: auto" class="logo"/>
                </div>
                <div class="col-12 col-sm-9">
                    <div class="searchField input-group" style="max-width: 400px">
                        <input type="text" class="form-control input" placeholder="Búsqueda de productos por nombre" v-model="searchTerm" v-on:keyup.enter="search"/>
                        <div class="input-group-append cursor-pointer" @click="search">
                            <span class="input-group-text"><i class="fa fa-search"></i></span>
                        </div>
                        <div class="input-group-append cursor-pointer" @click="searchTerm = ''; search()" v-if="searchTerm !== ''">
                            <span class="input-group-text text-danger"><i class="fa fa-times"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons-config">
                <!--<div class="btn btn-dark mr-2" @click="showVarList">
                    <i class="fas fa-list-check"></i>
                </div>-->
                <button class="btn btn-success mr-2" @click="insertCodeModal">
                    <i class="fas fa-barcode mr-2"></i>Ingresar código
                </button>
                <div class="btn btn-dark mr-2" @click="saveForm">
                    <i class="fas fa-plus-circle mr-2"></i> Nueva venta
                </div>
                <div class="btn btn-warning" @click="$router.push('/dashboard')">
                    <i class="fas fa-door-closed"></i>
                </div>
            </div>
        </div>
        <div class="pos-container">
            <div class="row m-0">
                <div class="col-12 col-sm-8 p-0 pos-products">
                    <div class="sellerInfo">
                        Vendedor: {{ AuthGetUserInfo.nombres }} {{ AuthGetUserInfo.apellidos }}
                        <span>{{ AuthGetUserInfo.email }}</span>
                        <a @click="handleCloseCash" class="text-success text-decoration-underline ml-4 float-right" v-tooltip="'Se hará corte de caja, ya sea parcial o diario.'">Cierre de caja</a>
                    </div>
                    <div class="pos-category-buttons">
                        <div class="itemCategory">
                            Bebidas
                        </div>
                        <div class="itemCategory">
                            Almuerzos
                        </div>
                        <div class="itemCategory">
                            Desayunos
                        </div>
                    </div>
                    <div class="row pos-products-container">
                        <div class="col-12 col-sm-3 pos-product-item-container" v-if="!searched">
                            <div class="pos-product-item" @click="modalMontoVariableModalOpen()">
                                <div class="pos-product-item-image-container" :style="{'background': 'url(/staticAssets/pos/cobrodirecto.png)'}">
                                    <!--<img class="pos-product-item-image" src="/staticAssets/pos/cobrodirecto.png">-->
                                </div>
                                <div class="pos-product-item-sku">
                                    SKU: MONTO DIRECTO
                                </div>
                                <div class="pos-product-item-title">
                                    Cobro Directo Sin Producto
                                </div>
                                <div class="pos-product-item-price">
                                    <span>Monto Variable</span>
                                </div>
                                <div class="pos-product-item-stock">
                                    <span class="text-dark"><i class="fas fa-infinity"></i></span>
                                </div>
                                <div class="d-block d-sm-none add-option btn btn-outline-success btn-sm mt-2" @click="selectProduct(item)">
                                    Agregar
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-3 pos-product-item-container" v-for="prod in searchResults" :key="'prod' + prod.id">
                            <div class="pos-product-item" @click="selectProduct(prod)">
                                <div class="pos-product-item-image-container" :style="{'background': 'url(' + (prod.thumbnail !== '' ? prod.thumbnail : '/staticAssets/pos/producto-sin-imagen.png') + ')'}">
                                </div>
                                <div class="pos-product-item-sku">
                                    SKU: {{ prod.sku }}
                                </div>
                                <div class="pos-product-item-title">
                                    {{ cropName(prod.nombre) }}
                                </div>
                                <div class="pos-product-item-price">
                                    <span :class="{'offer': prod.onSale}">{{ Numbro((prod.precio) ? parseFloat(prod.precio) : 0).formatCurrency() }}</span>
                                    <span class="pos-product-item-price-offer font-weight-bold" v-if="prod.onSale">{{ Numbro((prod.precioOnSale) ? parseFloat(prod.precioOnSale) : 0).formatCurrency() }}</span><br/>
                                </div>
                                <div class="pos-product-item-stock">
                                    <span class="text-dark">{{ prod.stock }} disponibles</span>
                                </div>
                                <div class="d-block d-sm-none add-option btn btn-outline-success btn-sm mt-2" @click="selectProduct(item)">
                                    Agregar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 pos-bar">
                    <div class="posSaleHeader">
                        <div class="row">
                            <div class="col-12 col-sm-10">
                                <div v-show="!OrderGetClientSelected.taxId" class="buyer-info">
                                    <div class="media">
                                        <div class="media-body text-left">
                                            <div class="mb-2">
                                                <span class="font-weight-bold">Sin cliente seleccionado</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="OrderGetClientSelected.taxId">
                                    <!--<span class="text-muted"><small>Cliente</small></span>-->
                                    <div class="font-weight-semi-bold">NIT: {{ OrderGetClientSelected.taxId }}</div>
                                    <div class="font-weight-bold">
                                        <i class="fas fa-user mr-2"></i>{{ OrderGetClientSelected.nombres }} {{ OrderGetClientSelected.apellidos }}
                                    </div>
                                    <div class="text-small" v-show="OrderGetClientSelected.email">{{ OrderGetClientSelected.email }}</div>
                                    <!--<div v-show="OrderGetClientSelected.telefono">Tel. {{ OrderGetClientSelected.telefono }}</div>-->
                                </div>
                            </div>
                            <div class="col-12 col-sm-2 text-center">
                                <button class="btn btn-primary" @click="clientModalSearch = true">
                                    <i class="fas fa-user-edit" v-if="OrderGetClientSelected.taxId"></i>
                                    <i class="fas fa-user-plus" v-if="!OrderGetClientSelected.taxId"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="posSale" :class="{'posSale': !emitirFactura, 'posSale posSaleShort': emitirFactura}">
                        <div class="itemPosSale" v-for="item in OrderGetPosCart" :key="item.id">
                            <div class="row m-0">
                                <div class="col-1 p-0 pt-2 itemToggleIcon" @click="toggleProduct(item.product.id)">
                                    <i :class="{'fas fa-chevron-right': !item.toggle, 'fas fa-chevron-down': item.toggle }"></i>
                                </div>
                                <div class="col-11 p-0">
                                    <div class="row m-0 mb-2">
                                        <div class="col-sm-11 p-0 itemPosName">{{ item.product.nombre }}</div>
                                        <div class="col-sm-1 p-0 itemPosPricing">
                                            <i @click="removeProduct(item.product.id)" class="fas fa-trash text-danger cursor-pointer"></i>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-sm-3 p-0 itemPosQuantity">
                                            <span class="font-weight-semi-bold">Cant:</span> {{ Numbro((item.quantity) ? parseFloat(item.quantity) : 0).format({thousandSeparated: true}) }}
                                        </div>
                                        <div class="col-12 col-sm-6 p-0">
                                            <span class="font-weight-semi-bold">SKU:</span> {{ item.product.sku || 'N/D' }}
                                        </div>
                                        <div class="col-sm-3 p-0 itemPosPricing">
                                            <div v-if="item.product.onSale">
                                                {{ Numbro((item.product.precioOnSale) ? parseFloat(item.product.precioOnSale) : 0).formatCurrency() }}
                                            </div>
                                            <div v-else>
                                                {{ Numbro((item.product.precio) ? parseFloat(item.product.precio) : 0).formatCurrency() }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.toggle" class="itemPosSaleDetail mt-2">
                                <div class="row">
                                    <div class="col-12 col-sm-5">
                                        <div class="input-group" v-if="!item.product.directAmount">
                                            <div class="input-group-prepend">
                                                <button class="btn btn-danger btn-sm" type="button" @click="decrement(item.product.id, item.quantity, item.product.precio)">
                                                    <i class="fas fa-minus-circle"></i></button>
                                            </div>
                                            <input class="form-control" type="text" v-model="item.quantity" @change="updatePosQuantity(item.product.id, item.quantity, item.product.precio)">
                                            <div class="input-group-append">
                                                <button class="btn btn-success btn-sm" type="button" @click="increment(item.product.id, item.quantity, item.product.precio)">
                                                    <i class="fas fa-plus-circle"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-7">
                                        <ul class="list-group text-right p-0" v-if="emitirFactura">
                                            <li class="list-group-item small p-0">IVA
                                                <span class="badge">{{ Numbro((item.product.iva) ? parseFloat(item.product.iva) : 0).formatCurrency() }}</span>
                                            </li>
                                            <li v-if="item.product.impuestoNombre" class="list-group-item small p-0">{{ item.product.impuestoNombre }}
                                                <span class="badge">{{ Numbro((item.product.impuestoTotal) ? parseFloat(item.product.impuestoTotal) : 0).formatCurrency() }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="{'posTotal': !emitirFactura, 'posTotal postTotalLarge': emitirFactura}">
                        <div class="row m-0 mb-2" v-if="emitirFactura">
                            <div class="col-12 col-sm-8  px-1" v-if="Object.keys(locaciones).length > 0">
                                <small class="text-muted">Localidad de facturación</small>
                                <multiselect v-model="locacionId"
                                             :options="locaciones"
                                             track-by="id"
                                             label="nombre"
                                             :close-on-select="true"
                                             :preserve-search="true"
                                             placeholder=""
                                             :multiple="false"
                                             :internal-search="true"
                                             :searchable="true"
                                             :show-labels="false"
                                >
                                </multiselect>
                            </div>
                            <div class="col-12 col-sm-4 px-1">
                                <small class="text-muted">Fecha factura</small>
                                <DatePicker v-model="invoiceDate" mode="date" :attributes="facturacionCalendar" :max-date="lastDayOfMonth" :min-date="minDateInvoice" :popover="{ visibility: 'click'}" :popover-align="'align-left'">
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                                    </template>
                                </DatePicker>
                            </div>
                        </div>
                        <div class="row m-0 mb-3">
                            <div class="col-6 px-1" v-if="Access('orders-pos-fel')">
                                <label class="toggle">
                                    <input class="toggle-checkbox" type="checkbox" v-model="emitirFactura"/>
                                    <div class="toggle-switch"></div>
                                    <span class="toggle-label">Emitir factura <i class="fa fa-question-circle" v-tooltip="'Se emitirá una factura tributaria correspondiente al monto de esta orden'"></i></span>
                                </label>
                            </div>
                            <div class="col-6 px-1" v-if="emitirFactura">
                                <label class="toggle">
                                    <input class="toggle-checkbox" type="checkbox" v-model="isCambiaria"/>
                                    <div class="toggle-switch"></div>
                                    <span class="toggle-label">Factura cambiaria</span>
                                </label>
                            </div>
                            <div class="col-6 px-1">
                                <label class="toggle" v-if="!emitirFactura">
                                    <input class="toggle-checkbox" type="checkbox" v-model="emitirRecibo"/>
                                    <div class="toggle-switch"></div>
                                    <span class="toggle-label">Enviar recibo <i class="fa fa-question-circle" v-tooltip="'Se enviará un recibo al correo electrónico'"></i></span>
                                </label>
                            </div>
                        </div>
                        <div class="row m-0 posTotalSubtotal">
                            <div class="col-sm-6 px-1">
                                Subtotal
                            </div>
                            <div class="col-sm-6 px-1 text-right">
                                {{ Numbro((subtotalProductos) ? parseFloat(subtotalProductos) : 0).formatCurrency() }}
                            </div>
                        </div>
                        <div class="row m-0 posTotalTax">
                            <div class="col-sm-6 px-1">
                                IVA
                            </div>
                            <div class="col-sm-6 px-1 text-right">
                                {{ Numbro((iva) ? parseFloat(iva) : 0).formatCurrency() }}
                            </div>
                        </div>
                        <div class="row m-0 posTotalTax">
                            <div class="col-6 col-sm-10 col-md-10 col-lg-9 px-1 text-left">
                                Otros impuestos
                            </div>
                            <div class="col-6 col-sm-2 col-md-2 col-lg-3 px-1 text-right">
                                {{ Numbro((impuestos) ? parseFloat(impuestos) : 0).formatCurrency() }}
                            </div>
                        </div>
                        <div class="row m-0 posTotalValue">
                            <div class="col-sm-6 px-1">
                                Total
                            </div>
                            <div class="col-sm-6 px-1 text-right">
                                {{ Numbro((montoProductos) ? parseFloat(montoProductos) : 0).formatCurrency() }}
                            </div>
                        </div>
                        <div class="row m-0 mt-4">
                            <div class="col-sm-6 px-1">
                                <button class="btn btn-warning w-100">
                                    <i class="fas fa-pause-circle mr-2"></i>Guardar orden
                                </button>
                            </div>
                            <div class="col-sm-6 px-1">
                                <button class="btn btn-success w-100" @click="modalPagoDeOrden = true">
                                    <i class="fas fa-arrow-alt-circle-right mr-2"></i>Continuar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--MODALES-->
        <div class="pos-modal" v-show="modalMontoVariable">
            <div class="pos-modal-container">
                <div class="pos-modal-header">
                    <h5 class="mb-3 text-center">Agregar monto variable</h5>
                    <hr>
                </div>
                <div class="pos-modal-body">
                    <div class="row">
                        <div class="col-8">
                            <div class="text-muted mb-1">Descripción del cobro</div>
                            <input type="text" class="form-control inputScanner" ref="inputScanner" placeholder="Escriba aquí la descripción del cobro" v-model="modalMontoVariableDesc" v-on:keyup.enter="selectMontoDirectoProduct()">
                        </div>
                        <div class="col-4">
                            <div class="text-muted mb-1">Monto a cobrar</div>
                            <input type="text" class="form-control inputScanner" placeholder="Escriba aquí el monto a cobrar" v-model="modalMontoVariableValor" @focus="$event.target.select()" v-on:keyup.enter="selectMontoDirectoProduct()">
                        </div>
                    </div>
                </div>
                <div class="pos-modal-footer">
                    <button class="btn btn-dark mr-3" @click="modalMontoVariableModalClose">
                        <i class="fas fa-arrow-left mr-2"></i>Cancelar
                    </button>
                    <button class="btn btn-success" @click="selectMontoDirectoProduct">
                        <i class="fas fa-check-circle mr-2"></i>Insertar
                    </button>
                </div>
            </div>
        </div>
        <div class="pos-modal" v-show="scannerBarcodeModal">
            <div class="pos-modal-container scannerModal">
                <div class="pos-modal-header">
                    <h5 class="mb-3 text-center">Ingrese el código o utilice el escáner</h5>
                    <hr>
                </div>
                <div class="pos-modal-body">
                    <div class="row mb-3">
                        <div class="col-8">
                            <div class="text-muted mb-1">Código de producto</div>
                            <input type="text" class="form-control inputScanner" ref="inputScanner" placeholder="Código" v-model="scannerCode" v-on:keyup.enter="scannerValidate(false)">
                        </div>
                        <div class="col-1 text-center" style="padding-top: 1.8em">
                            <span class="inputScanner">X</span>
                        </div>
                        <div class="col-3">
                            <div class="text-muted mb-1">Cantidad</div>
                            <input type="text" class="form-control inputScanner" v-model="scannerMultiply" @focus="$event.target.select()" @input="changeScannerMultiply">
                        </div>
                    </div>
                </div>
                <div class="pos-modal-footer">
                    <button class="btn btn-dark mr-3" @click="insertCodeModalClose">
                        <i class="fas fa-arrow-left mr-2"></i>Cancelar
                    </button>
                    <button class="btn btn-success" @click="insertCodeModalClose">
                        <i class="fas fa-check-circle mr-2"></i>Insertar
                    </button>
                </div>
            </div>
        </div>
        <div class="pos-modal" v-show="modalCierreCaja">
            <div class="pos-modal-container">
                <div class="pos-modal-header">
                    <h5 class="mb-3 text-center">Cierre de caja {{ dayjs().format('MMMM D, YYYY h:mm A') }}</h5>
                    <hr>
                </div>
                <div class="pos-modal-body">
                    <div class="mt-2">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Campo</th>
                                    <th scope="col">Valor</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row">Ventas</th>
                                    <td class="pl-3 ">{{ reportes.ventas.total }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cobros en efectivo</th>
                                    <td class="pl-3">{{ reportes.ventas.cash }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cobros en tarjeta</th>
                                    <td class="pl-3">{{ reportes.ventas.card + reportes.ventas.pos }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cobros por transferencia</th>
                                    <td class="pl-3">{{ reportes.ventas.bank }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cobros con link</th>
                                    <td class="pl-3">{{ reportes.ventas.link }}</td>
                                </tr>
                                <!-- <tr>
                                    <th scope="row">Cobros con POS NeoNet</th>
                                    <td>No se encontraron ventas sin cierre</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cobros con POS Credomátic</th>
                                    <td>No se encontraron ventas sin cierre</td>
                                </tr> -->
                                <!-- <tr>
                                    <th scope="row">Efectivo Teórico</th>
                                    <td>No se encontraron ventas sin cierre</td>
                                </tr> -->
                                <tr>
                                    <th scope="row">Venta estimada</th>
                                    <td class="pl-3">{{ reportes.estv.total }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Diferencia</th>
                                    <td class="pl-3">{{ handleSalesDiference() }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Efectivo real</th>
                                    <td><input v-model="realCash" type="number" class="form-control"></td>
                                </tr>
                                <tr>
                                    <th scope="row">Gastos</th>
                                    <td><input v-model="bills" type="number" class="form-control"></td>
                                </tr>
                                <tr>
                                    <th scope="row">Observaciones</th>
                                    <td>
                                        <textarea v-model="observations" class="form-control textCamp" placeholder="Ingresa información adicional del cierre de caja"></textarea>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <table class="table">
                                <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Campo</th>
                                    <th scope="col">Valor</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row">Ventas</th>
                                    <td class="pl-3 ">{{ reportes.ventas.total }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cobros en efectivo</th>
                                    <td class="pl-3">{{ reportes.ventas.cash }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cobros en tarjeta</th>
                                    <td class="pl-3">{{ reportes.ventas.card + reportes.ventas.pos }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cobros por transferencia</th>
                                    <td class="pl-3">{{ reportes.ventas.bank }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cobros con link</th>
                                    <td class="pl-3">{{ reportes.ventas.link }}</td>
                                </tr>
                                <!-- <tr>
                                    <th scope="row">Cobros con POS NeoNet</th>
                                    <td>No se encontraron ventas sin cierre</td>
                                </tr>
                                <tr>
                                    <th scope="row">Cobros con POS Credomátic</th>
                                    <td>No se encontraron ventas sin cierre</td>
                                </tr> -->
                                <!-- <tr>
                                    <th scope="row">Efectivo Teórico</th>
                                    <td>No se encontraron ventas sin cierre</td>
                                </tr> -->
                                <tr>
                                    <th scope="row">Venta estimada</th>
                                    <td class="pl-3">{{ reportes.estv.total }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Diferencia</th>
                                    <td class="pl-3">{{ handleSalesDiference() }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Efectivo real</th>
                                    <td><input v-model="realCash" type="number" class="form-control"></td>
                                </tr>
                                <tr>
                                    <th scope="row">Gastos</th>
                                    <td><input v-model="bills" type="number" class="form-control"></td>
                                </tr>
                                <tr>
                                    <th scope="row">Observaciones</th>
                                    <td>
                                        <textarea v-model="observations" class="form-control textCamp" placeholder="Ingresa información adicional del cierre de caja"></textarea>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="pos-modal-footer">
                    <button class="btn btn-dark mr-3" @click="modalCierreCaja = false;">
                        <i class="fas fa-arrow-left mr-2"></i>Cancelar
                    </button>
                    <button class="btn btn-success" @click="modalCierreCaja = false;">
                        <i class="fas fa-check-circle mr-2"></i>Realizar cierre
                    </button>
                </div>
            </div>
        </div>
        <div class="pos-modal" v-show="modalPagoDeOrden">
            <div class="pos-modal-container">
                <div class="pos-modal-header">
                    <h5 class="mb-3 text-center">Método de pago</h5>
                    <hr>
                </div>
                <div class="pos-modal-body">
                    <div class="row">
                        <div class="col-12 col-sm-8">
                            <div class="mb-3" v-if="paymentMethod !== ''">
                                <button class="btn btn-dark btn-sm" @click="paymentMethod = ''">
                                    <i class="fas fa-arrow-left mr-2"></i>Otra forma de pago
                                </button>
                            </div>
                            <div class="row" v-if="paymentMethod === ''">
                                <div class="col-6">
                                    <div class="metodoPagoItem" @click="paymentMethod = 'later_on_store';">
                                        <div class="metodoPagoIcono">
                                            <i class="fas fa-qrcode"></i>
                                        </div>
                                        <div class="metodoPagoTitle">Link de pago</div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="metodoPagoItem" @click="paymentMethod = 'cash'">
                                        <div class="metodoPagoIcono">
                                            <i class="fas fa-money-bill"></i>
                                        </div>
                                        <div class="metodoPagoTitle">Efectivo</div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="metodoPagoItem" @click="paymentMethod = 'pos'">
                                        <div class="metodoPagoIcono">
                                            <i class="fas fa-receipt"></i>
                                        </div>
                                        <div class="metodoPagoTitle">Voucher de pago</div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="metodoPagoItem" @click="paymentMethod = 'bank'">
                                        <div class="metodoPagoIcono">
                                            <i class="fas fa-bank"></i>
                                        </div>
                                        <div class="metodoPagoTitle">Transferencia</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="paymentMethod === 'later_on_store'" class="text-left">
                                    <h5>Pago con link de pago</h5>
                                    <div>
                                        Se generará un enlace de pago, con este enlace, el cliente podrá pagar la orden desde cualquier dispositivo.
                                    </div>
                                </div>
                                <div v-if="paymentMethod === 'cash'" class="text-left">
                                    <h5>Pago con efectivo</h5>
                                    <div>
                                        <label>
                                            Efectivo recibido:
                                        </label>
                                        <input class="form-control" type="text" @keyup="calculateCash">
                                    </div>
                                    <div class="mt-3">
                                        <span v-if="cashVueltoAmount >= 0" class="text-success font-weight-semi-bold">
                                            Entregar {{ Numbro((cashVueltoAmount) ? parseFloat(cashVueltoAmount) : 0).formatCurrency() }} de cambio
                                        </span>
                                        <span v-if="cashVueltoAmount < 0" class="text-danger">El monto no cubre el total de la orden</span>
                                    </div>
                                </div>
                                <div v-if="paymentMethod === 'bank'" class="text-left">
                                    <h5>Pago con transferencia bancaria</h5>
                                    <div>
                                        <label>
                                            Número de confirmación:
                                        </label>
                                        <input class="form-control" type="text" placeholder="Escribe aquí el número de confirmación de la transferencia" v-model="bankTransferCode">
                                    </div>
                                    <div class="mt-3">
                                        <label>
                                            Monto recibido:
                                        </label>
                                        <input class="form-control" type="text" placeholder="Escribe el monto de la transferencia" v-model="bankTransferAmount">
                                    </div>
                                    <div class="mt-3">
                                        <span v-if="cashVueltoAmount < 0" class="text-danger">
                                            El monto no cubre el total de la orden
                                        </span>
                                    </div>
                                </div>
                                <div v-if="paymentMethod === 'pos'" class="text-left">
                                    <h5>Pago con voucher</h5>
                                    <div>
                                        <label>
                                            Ingrese el número de autorización del voucher
                                        </label>
                                        <input class="form-control" type="text" v-model="authVoucher">
                                        <label class="toggle mt-2 mr-1" v-if="paymentMethod === 'pos'">
                                            <input class="toggle-checkbox" type="checkbox" v-model="tipoVoucher" name="tipoVoucher" value="neonet"/>
                                            <div class="toggle-switch"></div>
                                            <span class="toggle-label">Neonet</span>
                                        </label>
                                        <label class="toggle mt-2" v-if="paymentMethod === 'pos'">
                                            <input class="toggle-checkbox" type="checkbox" v-model="tipoVoucher" name="tipoVoucher" value="bac"/>
                                            <div class="toggle-switch"></div>
                                            <span class="toggle-label">BAC Credomatic</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <h5>Resumen de orden</h5>
                            <div class="row m-0 posTotalSubtotal">
                                <div class="col-sm-6 px-1">
                                    Subtotal
                                </div>
                                <div class="col-sm-6 px-1 text-right">
                                    {{ Numbro((subtotalProductos) ? parseFloat(subtotalProductos) : 0).formatCurrency() }}
                                </div>
                            </div>
                            <div class="row m-0 posTotalTax">
                                <div class="col-sm-6 px-1">
                                    IVA
                                </div>
                                <div class="col-sm-6 px-1 text-right">
                                    {{ Numbro((iva) ? parseFloat(iva) : 0).formatCurrency() }}
                                </div>
                            </div>
                            <div class="row m-0 posTotalTax">
                                <div class="col-6 col-sm-10 col-md-10 col-lg-9 px-1 text-left">
                                    Otros impuestos
                                </div>
                                <div class="col-6 col-sm-2 col-md-2 col-lg-3 px-1 text-right">
                                    {{ Numbro((impuestos) ? parseFloat(impuestos) : 0).formatCurrency() }}
                                </div>
                            </div>
                            <div class="row m-0 posTotalValue">
                                <div class="col-sm-6 px-1">
                                    Total
                                </div>
                                <div class="col-sm-6 px-1 text-right">
                                    {{ Numbro((montoProductos) ? parseFloat(montoProductos) : 0).formatCurrency() }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pos-modal-footer">
                    <button class="btn btn-dark mr-3" @click="modalPagoDeOrden = false">
                        <i class="fas fa-arrow-left mr-2"></i>Cancelar
                    </button>
                    <button class="btn btn-success" @click="createOrder">
                        <i class="fas fa-check-circle mr-2"></i>Pagar orden
                    </button>
                </div>
            </div>
        </div>
        <order-client-search v-model:show-client-search="clientModalSearch"></order-client-search>
    </div>
</template>


<script>
import "/src/layout/admin/assets/css/POS.css";
import {mapGetters} from "vuex";
import onScan from "onscan.js";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import Multiselect from 'vue-multiselect';
import dayjs from "dayjs";
import OrderClientSearch from "src/modules/order/components/OrderClientSearch.vue";

export default {
    components: {
        OrderClientSearch,
        DatePicker,
        Multiselect,
    },
    computed: {
        ...mapGetters({
            Access: 'Access',
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
            OrderGetPosCart: 'OrderGetPosCart',
            OrderGetClientSelected: 'OrderGetClientSelected',
        })
    },
    data() {
        return {
            // Modal de escáner
            scannerBarcodeModal: false,
            scannerMultiply: 1,
            scannerCode: '',

            // Modal de monto variable
            modalMontoVariable: false,
            modalMontoVariableDesc: '',
            modalMontoVariableValor: 0,

            // modal pago de orden
            modalPagoDeOrden: false,
            paymentMethod: '',
            authVoucher: '',
            tipoVoucher: '',
            referenceVoucher: '',
            cashVueltoAmount: 0,
            cashReceived: 0,
            bankTransferAmount: '',
            bankTransferCode: '',

            // búsqueda de clientes
            clientModalSearch: false,

            // productos
            searched: false,
            searchResults: {},
            pageSelected: 1,
            searchTerm: '',
            limit: 10,
            filter: 0,

            // locaciones
            locaciones: {},
            locacionId: {id: 0},

            // cierre de caja
            modalCierreCaja: false,
            reportes: {
                "ventas": {
                    "bank": 0,
                    "card": 0,
                    "cash": 0,
                    "link": 0,
                    "mv": 0,
                    "pos": 0,
                    "sin_pago": 0,
                    "total": 0
                },
                "ordenes": {
                    "pos": 0,
                    "tienda": 0,
                    "mv": 0,
                    "total": 0,
                },
                "estv": {
                    "pos": 0,
                    "tienda": 0,
                    "total": 0
                }
            },
            observations: '',
            realCash: 0,
            bills: 0,

            // totales y facturación
            emitirFactura: false,
            montoProductos: 0,
            subtotalProductos: 0,
            facturaDetails: {},
            reciboDetails: {},
            customEmailSendFactura: '',
            customEmailSendRecibo: '',
            invoiceDate: new Date(),
            lastDayOfMonth: '',
            minDateInvoice: '',
            isCambiaria: false,
            impuestos: 0,
            iva: 0,
            detalleImpuesto: [],
            facturacionCalendar: [
                {
                    dot: true,
                    key: 'today',
                    highlight: false,
                    dates: new Date(),

                }
            ],

        };
    },
    watch: {
        showClientSearch: function (val) {
            if (val) {
                document.body.classList.add("overflowLocked");
            }
            else {
                document.body.classList.remove("overflowLocked");
            }
        },
        showProductSearchBox: function (val) {
            if (val) {
                document.body.classList.add("overflowLocked");
            }
            else {
                document.body.classList.remove("overflowLocked");
            }
        },
        OrderGetPosCart: function () {
            this.calculateProductsAmount();
        },
        cuotasPago(val) {
            if (val > 0) {
                this.cuotasAmount = (parseFloat(this.montoProductos) / parseInt(val));
            }
            this.cuotasPago = val;
        },
        paymentMethod: function() {
            this.authVoucher = '';
            this.tipoVoucher = '';
            this.referenceVoucher = '';
            this.cashVueltoAmount = 0;
            this.cashReceived = 0;
            this.bankTransferAmount = '';
            this.bankTransferCode = '';
        },
    },
    mounted() {

        const self = this;

        // arranca con una búsqueda
        self.search();

        // escáner
        self.listenScan();

        // locaciones
        if (this.Access('orders-pos-fel')) {
            this.getLocaciones();
        }

        this.calculateProductsAmount();
    },
    methods: {
        dayjs,
        Numbro,

        // ayudas
        cropName(value) {
            if (value.length > 45) {
                return value.substr(0, 45) + '...';
            }
            else {
                return value;
            }
        },

        // escáner y códigos
        listenScan() {
            const self = this;
            if (typeof document.scannerDetectionData === 'undefined') {
                onScan.attachTo(document);
            }
            document.addEventListener('scan', function (sScancode) {
                if (typeof sScancode.detail !== 'undefined' && typeof sScancode.detail.scanCode !== 'undefined') {
                    console.log(sScancode.detail.scanCode);
                    self.scannerValidate(sScancode.detail.scanCode);
                }
            });
        },
        insertCodeModal() {
            const self = this;
            this.scannerBarcodeModal = true;
            this.scannerMultiply = 1;
            this.scannerCode = '';
            this.$nextTick(() => {
                //console.log(self.$refs["inputScanner"])
                self.$refs["inputScanner"].focus()
            })

        },
        insertCodeModalClose() {
            this.scannerBarcodeModal = false;
            this.scannerCode = '';
            this.scannerMultiply = 1;
        },
        changeScannerMultiply() {
            const self = this;
            setTimeout(function () {
                self.$refs["inputScanner"].focus()
            }, 1000)
        },
        scannerValidate(code) {
            const self = this;

            if (code && code !== '') {
                this.scannerCode = code;
            }
        },

        // búsqueda
        search() {
            const self = this;

            const dataSearch = {
                term: this.searchTerm,
                page: self.pageSelected,
                perPage: self.limit,
                filter: self.filter,
                filterByType: self.filterByType,
            }

            API.send('POST', 'products/do-search', dataSearch,
                function (response) {
                    if (response.status) {

                        self.searched = (self.searchTerm !== '');

                        if (Tools.isEmptyString(response.data.productos)) {
                            self.searchResults = response.data;
                        }
                        else {
                            self.searchResults = response.data.productos;
                        }

                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },

        // Locaciones
        getLocaciones() {
            const self = this;
            API.send('GET', 'locaciones/all/', {},
                function (response) {
                    if (response.status) {
                        self.locaciones = response.data;
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                });
        },

        // Operación del pos
        updatePosQuantity(idProduct, quantity, precio) {
            this.$store.dispatch('OrderChangeProductQuantity', {
                id: idProduct,
                quantity: quantity,
                precio: precio,
            })
        },
        toggleProduct(idProduct) {
            const self = this;
            self.$store.dispatch('OrderToggleProduct', {
                id: idProduct,
            })
        },
        removeProduct(idProduct) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function () {

                self.$store.dispatch('OrderRemoveProduct', {
                    id: idProduct,
                })
            })
        },
        increment(idProduct, quantity, precio) {
            quantity += 1;
            this.updatePosQuantity(idProduct, quantity, precio);

        },
        decrement(idProduct, quantity, precio) {
            if (quantity === 1) {
                return false;
            }
            else {
                quantity -= 1;
                this.updatePosQuantity(idProduct, quantity, precio);
            }
        },
        selectProduct: function (product) {
            this.$emit('productSelected', product);
            this.$store.dispatch('OrderAddProductToPos', product, true);
            this.scrollProductListDown();
        },
        scrollProductListDown() {
            this.$nextTick(() => {
                let divElement = document.getElementById('posSale');
                divElement.scrollTop = divElement.scrollHeight;
            });
        },

        // Selección de monto directo
        selectMontoDirectoProduct: function () {

            const tmpMontoDirecto = parseFloat(this.modalMontoVariableValor);

            if (!tmpMontoDirecto || (typeof tmpMontoDirecto !== 'number')) {
                API.showErrorNotify('Debe ingresar un monto válido');
                return false;
            }

            if (this.modalMontoVariableDesc === '') {
                API.showErrorNotify('Debe ingresar una descripción');
                return false;
            }

            const montoDirectoProduct = {
                id: '_DIRECT_AMOUNT_',
                nombre: this.modalMontoVariableDesc,
                precio: parseFloat(this.modalMontoVariableValor),
                clearTotal: true,
            };
            this.$store.dispatch('OrderAddProductToPos', montoDirectoProduct);
            this.scrollProductListDown();
            this.modalMontoVariableModalClose();
        },
        modalMontoVariableModalOpen: function () {
            this.modalMontoVariable = true;
        },
        modalMontoVariableModalClose: function () {
            this.modalMontoVariableValor = 0;
            this.modalMontoVariableDesc = '';
            this.modalMontoVariable = false;
        },

        // cálculo de valores
        cuotasPago(val) {
            if (val > 0) {
                this.cuotasAmount = (parseFloat(this.montoProductos) / parseInt(val));
            }
            this.cuotasPago = val;
        },
        calculateCash(event) {
            const cash = parseFloat(event.target.value.replace(/^0+/, ''));
            const total = parseFloat(this.montoProductos);
            this.cashReceived = cash;
            this.cashVueltoAmount = cash - total;
        },
        calculateProductsAmount() {
            this.iva = 0;
            this.subtotalProductos = 0;
            this.montoProductos = 0;
            this.impuestos = 0;
            //console.log(this.OrderGetPosCart);
            for (let item in this.OrderGetPosCart) {
                this.OrderGetPosCart[item]['product']['subTotal'] = 0;
                this.OrderGetPosCart[item]['product']['impuestoTotal'] = 0;
                this.OrderGetPosCart[item]['product']['iva'] = 0;

                if (typeof this.OrderGetPosCart[item] !== 'undefined') {

                    // si es un producto por pago directo
                    if (typeof this.OrderGetPosCart[item]['product']['id'] !== 'undefined' &&
                        typeof this.OrderGetPosCart[item]['product']['id'].startsWith === 'function' &&
                        this.OrderGetPosCart[item]['product']['id'].startsWith('_DIRECT_AMOUNT_')) {
                    }
                    const productPrice = (this.OrderGetPosCart[item]['product']['onSale']) ? parseFloat(this.OrderGetPosCart[item]['product']['precioOnSale']) : parseFloat(this.OrderGetPosCart[item]['product']['precio']);


                    if ((productPrice > 0) && (typeof this.OrderGetPosCart[item]['quantity'] !== 'undefined')) {
                        const amount = parseFloat(this.OrderGetPosCart[item]['quantity']) * parseFloat(productPrice);
                        this.OrderGetPosCart[item]['product']['subTotal'] = amount;
                        //console.log(productPrice);
                        //const iva = parseInt(this.OrderGetPosCart[item]['quantity']) * parseFloat(this.OrderGetPosCart[item]['product']['impuestos']);
                        if (this.emitirFactura) {
                            if (typeof this.OrderGetPosCart[item]['product']['impuestoMonto'] !== 'undefined' && this.OrderGetPosCart[item]['product']['impuestoMonto'] !== '0' && this.OrderGetPosCart[item]['product']['impuestoMonto'] !== '') {
                                const impuestos = parseInt(this.OrderGetPosCart[item]['quantity']) * parseFloat(this.OrderGetPosCart[item]['product']['impuestoMonto']);
                                this.subtotalProductos += impuestos + amount;

                                this.OrderGetPosCart[item]['product']['subTotal'] = impuestos + amount;
                                this.OrderGetPosCart[item]['product']['impuestoTotal'] = impuestos;
                                this.impuestos = parseFloat(this.impuestos) + impuestos;
                            }
                            // Si no tiene impuestos, entonces solo sumo el monto
                            else {
                                this.subtotalProductos += amount;
                            }

                            // IVA
                            const montoGravable = amount / 1.12;
                            this.OrderGetPosCart[item]['product']['iva'] = amount - montoGravable;
                            this.iva = parseFloat(this.iva) + Math.round(this.OrderGetPosCart[item]['product']['iva'] * 100) / 100;
                        }
                        else {
                            this.subtotalProductos += amount;
                        }
                    }
                }
            }
            this.montoProductos = (this.subtotalProductos + this.iva + this.impuestos).toFixed(2);
            this.subtotalProductos = this.subtotalProductos.toFixed(2);
            this.iva = this.iva.toFixed(2);
            this.cashVueltoAmount = 0;
        },

        // Cierre de caja
        handleCloseCash() {
            this.modalCierreCaja = !this.modalCierreCaja;
            if (this.modalCierreCaja) {
                this.getSalesData();
            }
        },
        getSalesData() {
            const self = this;

            API.send('GET', 'reports/general/' + "today", {},
                function (response) {
                    self.reportes = Tools.parseObjectToFloat(response.data);
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                });

        },
        handleSalesDiference() {
            return this.reportes.ventas.cash - (this.realCash - this.bills);
        },

        // crear orden
        createOrder(data) {

            const self = this;
            if (!data) {
                data = {};
            }

            API.showConfirm('Se generará una orden', '¿Desea continuar?', function () {

                const ordenDetalle = {};
                let counter = 0;
                for (let item in self.OrderGetPosCart) {
                    if (typeof self.OrderGetPosCart[item] !== 'undefined') {
                        // Verificar si el id del producto comienza con '_DIRECT_AMOUNT_'
                        if (typeof self.OrderGetPosCart[item]['product']['id'] !== 'undefined' &&
                            typeof self.OrderGetPosCart[item]['product']['id'].startsWith === 'function' &&
                            self.OrderGetPosCart[item]['product']['id'].startsWith('_DIRECT_AMOUNT_')) {
                            ordenDetalle[counter] = {
                                "productoId": 0,
                                "productoPrecio": self.OrderGetPosCart[item]['product']['precio'],
                                "productoCantidad": parseInt(self.OrderGetPosCart[item]['quantity']),
                                "productoDescMontoDirecto": self.OrderGetPosCart[item]['product']['nombre']
                            };
                        }
                        else {
                            // Si no comienza con '_DIRECT_AMOUNT_', procesar como de costumbre
                            const precio = (self.OrderGetPosCart[item]['product']['onSale']) ? self.OrderGetPosCart[item]['product']['precioOnSale'] : self.OrderGetPosCart[item]['product']['precio'];

                            ordenDetalle[counter] = {
                                "productoId": self.OrderGetPosCart[item]['product']['id'],
                                "productoPrecio": precio,
                                "productoCantidad": parseInt(self.OrderGetPosCart[item]['quantity']),
                            };
                        }
                        counter++;
                    }
                }

                if (Object.keys(ordenDetalle).length === 0) {
                    API.showErrorAlert('Su orden no contiene productos');
                    return false;
                }

                if (typeof self.OrderGetClientSelected.nombres === 'undefined') {
                    API.showErrorAlert('Debe seleccionar un cliente o Consumidor Final');
                    return false;
                }

                if (self.paymentMethod === 'later_on_store') {
                    self.emitirFactura = false;
                    self.emitirRecibo = false;
                    self.isCambiaria = false;
                    self.invoiceDate = new Date();
                }

                const dataPay = {
                    "pagoMetodo": self.paymentMethod, // card, pos, cash, qr, contra entrega, recoger en tienda
                    "pagoTcNombre": (typeof data.cardName !== 'undefined') ? data.cardName : '',
                    "pagoTcNumero": (typeof data.cardNumber !== 'undefined') ? data.cardNumber : '',
                    "pagoTcExp": (typeof data.cardMonth !== 'undefined') ? (data.cardMonth + "/" + ((typeof data.cardYear !== 'undefined') ? data.cardYear.toString().substr(2) : '')) : '',
                    "pagoTcCvv": (typeof data.cardCvv !== 'undefined') ? data.cardCvv : '',
                    "pagoCuotas": (typeof data.cardCuotas !== 'undefined') ? data.cardCuotas : '',
                    "pagoAfiliacion": (typeof self.afiliacionPago.method !== 'undefined') ? self.afiliacionPago.method : '',

                    "clienteNombre": (typeof self.OrderGetClientSelected.nombres !== 'undefined') ? self.OrderGetClientSelected.nombres + ' ' + self.OrderGetClientSelected.apellidos : '',
                    "clienteTaxId": (typeof self.OrderGetClientSelected.taxId !== 'undefined') ? self.OrderGetClientSelected.taxId : '',
                    "clienteEmail": (typeof self.OrderGetClientSelected.email !== 'undefined') ? self.OrderGetClientSelected.email : '',
                    "clienteTelefono": (typeof self.OrderGetClientSelected.telefono !== 'undefined') ? self.OrderGetClientSelected.telefono : '',
                    "clienteDireccion": (typeof self.OrderGetClientSelected.direccion !== 'undefined') ? self.OrderGetClientSelected.direccion : '',
                    /*"clientePais": self.paisLocationCobertura,
                    "clienteDepto": self.deptoLocationCobertura,
                    "clienteMuni": self.municipioLocationCobertura,*/

                    /*"envioDireccion": self.direccionEnvioText,
                    "envioPais": self.paisEnvio,
                    "envioDepartamento": self.deptoEnvio,
                    "envioMunicipio": self.municipioEnvio,
                    "envioComentario": self.comentarioEnvio,
                    "envioSeleccionado": {},*/

                    "ordenTiendaDomain": self.AuthGetUserInfo.s,
                    "ordenMonto": parseFloat(self.montoProductos),
                    "ordenDetalle": ordenDetalle,
                    "ordenDesactivarNotificaciones": 1,
                    "ordenId": self.orderCreatedId,

                    "facturaEmitir": self.emitirFactura,
                    "reciboEmitir": self.emitirRecibo,
                    "facturaCambiaria": self.isCambiaria,
                    "facturaFecha": self.invoiceDate,
                    "sucursalId": self.locacionId.id,
                }

                //console.log(dataPay);

                // arreglo shipping providers
                /*let counterShipping = 0;
                for (let sellBy in self.selectedShippingRates) {
                    dataPay['envioSeleccionado'][counterShipping] = {};
                    dataPay['envioSeleccionado'][counterShipping]['localidadId'] = sellBy;
                    dataPay['envioSeleccionado'][counterShipping]['provider'] = self.selectedShippingRates[sellBy]['provider'];
                    dataPay['envioSeleccionado'][counterShipping]['price'] = self.selectedShippingRates[sellBy]['precio'];
                    counterShipping++;
                }*/

                API.send('POST', 'payments/create-pay-order', dataPay,
                    function (response) {

                        if (typeof response.data !== 'undefined') {

                            // si es pago con tc
                            if (response.data.metodoPago === 'card') {

                                // Si trae información de pago con tc
                                if (typeof response.data.extra !== 'undefined') {

                                    // si es visanet o metodos sin redirección
                                    if (response.data.extra.redirectData === "none") {

                                        self.paymentOk = true;
                                        self.orderCreated = response.data.ordenCodigo;
                                        self.orderCreatedId = response.data.ordenId;
                                        self.orderPayLink = response.data.paylink;
                                        self.orderPayLinkQr = response.data.paylink_qr;
                                        if (typeof response.data.extra.factura !== 'undefined') {
                                            self.facturaDetails = response.data.extra.factura;
                                        }
                                        if (typeof response.data.extra.recibo !== 'undefined') {
                                            self.reciboDetails = response.data.extra.recibo;
                                        }
                                        self.$store.dispatch('OrderSaveCurrentOrder', self.orderCreatedId);
                                        // self.showOrderDetailsCreation = true;
                                        self.showCardPaymentForm = false;
                                        self.clearPos(true, false);
                                        self.$router.push('/orders');
                                    }
                                    // si es powertranz o cardinal
                                    else {
                                        self.payRedirectionData = response.data.extra.redirectData;
                                    }
                                }
                                else {
                                    API.showErrorAlert('Error al crear orden, el procesador de pagos no está disponible');
                                }
                            }
                            else {

                                if (self.paymentMethod === 'later_on_store') {
                                    self.paymentOk = false;
                                }
                                else {
                                    self.paymentOk = true;
                                }

                                self.orderCreated = response.data.ordenCodigo;
                                self.orderCreatedId = response.data.ordenId;
                                self.orderPayLink = response.data.paylink;
                                self.orderPayLinkQr = response.data.paylink_qr;
                                if (typeof response.data.extra.factura !== 'undefined') {
                                    self.facturaDetails = response.data.extra.factura;
                                }
                                if (typeof response.data.extra.recibo !== 'undefined') {
                                    self.reciboDetails = response.data.extra.recibo;
                                }
                                self.$store.dispatch('OrderSaveCurrentOrder', self.orderCreatedId);
                                // self.showOrderDetailsCreation = true;
                                self.clearPos(true, false);
                                self.$router.push('/orders')
                            }
                        }
                        else {
                            API.showErrorAlert('Error al crear orden, por favor, intente de nuevo');
                        }
                    },
                    function (response) {
                        if (typeof response.data.ordenId !== 'undefined') {
                            self.ordenCreadaId = response.data.ordenId;
                        }

                        if (typeof response.data.extra !== 'undefined' && typeof response.data.extra.error !== 'undefined') {
                            API.showErrorAlert(response.data.extra.error);
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    })
            })
        },

    }
};
</script>
