<script>
 
export default { 
 data() {
        return {
        //data
 }},
 props:{
    tamañoPincel: Number,
    w: Number,
    h: Number
 },
 mounted(){
 },
 methods:{
    handlerConfigModal(){
        this.$emit('handlerConfigModal')
    },
    cambiarTamañoPincel(event){
        // this.$emit('update:tamañoPincel', parseInt(event.target.value, 10));
        this.$emit('cambiarTamañoPincel',parseInt(event.target.value, 10))
        
    },
    cambiarTamañoLienzo(event){
        event.target.name
        this.$emit('cambiarTamañoLienzo',parseInt(event.target.value, 10),event.target.name )
    },
    handlerImgModal(){
      this.$emit('handlerConfigModal')
      this.$emit('handlerImgModal');
    }
 }
 }
</script>
 
 
<template>
  <div class="ModalMain scale-up-tr">
    <div class="ModalContainer">
        <i class="fa-solid fa-xmark close" active @click="handlerConfigModal"></i>
         <div class="d-flex flex-column align-items-center p-2 toolbox">
            <div class="form-group w-100">
                <label for="formControlRange">Tamaño de herramieta</label>
                <input type="range" min="1" max="200" step="1" class="form-control-range" id="formControlRange" :value="tamañoPincel"
        @input="cambiarTamañoPincel">
            </div>
            <div class="form-group w-100">
                <label for="formGroupExampleInput">Ancho de lienzo <i class="fa-solid fa-left-right"></i></label>
                <input type="number" name="w" class="form-control" id="formGroupExampleInput" :value="w" @change="cambiarTamañoLienzo" disabled >
            </div>
            <div class="form-group w-100">
                <label for="formGroupExampleInput">Alto de lienzo <i class="fa-solid fa-up-down"></i></label>
                <input type="number" name="h" class="form-control" id="formGroupExampleInput" :value="h" @change="cambiarTamañoLienzo" disabled >
            </div>
            <div class="form-group w-100 row mt-3">
               <button type="button" class="btn btn-primary col-12" id="selectImg" aria-describedby="img" @click="handlerImgModal">Seleccionar imagen <i class="fa-regular fa-image"></i></button>
               <small id="img" class="form-text text-muted col-12">Selecciona una imagen para usar en el lienzo</small>
            </div>
         </div>
    </div>
  </div>
</template>
 
 
<style scoped>
 
 .ModalMain{
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 50px;
    height: fit-content;
    display: flex;
    justify-content: center;
    overflow: hidden;
 }

 .ModalContainer{
    padding: 5px;
    z-index: 1;
    position: relative;
    width: 300px;
    min-height: 250px;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 }

 .toolbox{
    margin-top: 10px;
 }

 .close{
    position: absolute;
    top: 10px;
    right: 10px;
    transition: 0.3s;
 }

 .close:hover{
    transform: rotate(180deg);
    color: #f33a3a;
 }
 .scale-up-tr {
	-webkit-animation: scale-up-tr 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-tr 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-13 17:47:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-tr
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}


</style>