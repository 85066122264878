<template>
    <control-comercios-layout :active="60">
        <div class="card">
            <div class="mb-80">
                <div class="container h-100">
                    <form id="formData" @submit="save" method="post">
                        <div class="row justify-content-center h-100 align-items-center">
                            <div class="col-xl-12 col-md-12">
                                <div class="card" v-if="!process.resultFile">
                                    <div class="card-header">
                                        <h4 class="card-title">
                                            <router-link to="/products">
                                                <i class="back fa fa-arrow-circle-left"></i>
                                            </router-link>
                                            Concilia de pagos
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div>
                                                    Esta ventana te permite hacer la concilia de forma masiva, puedes utilizar un archivo .xlsx o .csv -
                                                    <a href="https://anysubscriptions-public.s3.us-east-2.amazonaws.com/_anysubscriptions.com/plantilla.xlsx">Descargar plantilla</a>
                                                </div>
                                                <div class="mt-4 text-dark text-center">
                                                    <i class="fa fa-arrow-circle-down"></i> Sube el archivo que contenga los pagos para cargar
                                                </div>
                                                <div class="mt-4">
                                                    <FileUploader url="products/massive/concilia" :accepted-mime="acceptedMime" remove-file-on-finish="0" :extra-data-form="formExtraData" @fileSuccess="fileSuccess" @errorUpload="errorUpload" v-model:file-uploaded="fileUploaded"></FileUploader>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card" v-if="preview.resultFile">
                                    <div class="card-header">
                                        <h4 class="card-title">
                                            Previsualización de datos
                                            <i class="fa fa-question-circle" v-tooltip="'Verifica si las columnas de tu archivo fueron detectadas correctamente'"></i>
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="text-center">
                                                    <div class="mb-4">
                                                        <h4>Se detectaron <span class="text-primary">{{preview.countRows}}</span> filas a procesar</h4>
                                                    </div>
                                                    <div>
                                                        La previsualización online está limitada a 5 filas por estado, para observar la previsualización completa, descargue el archivo.
                                                    </div>
                                                    <a class="btn btn-sm btn-primary mt-3" :href="preview.resultFile" target="_blank">Descargar archivo de previsualización</a>
                                                </div>
                                                <div class="mt-4">
                                                    <h6>Órdenes que se analizaran</h6>
                                                    <div class="products-massive-preview">
                                                        <table class="table table-striped">
                                                            <tr v-for="item in preview.created" :key="'uploaded_' + item">
                                                                <td v-for="cell in item" :key="'uploaded_'+ item + '_' + cell">
                                                                    {{ cell }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6>Órdenes que se actualizarán</h6>
                                                    <div class="products-massive-preview">
                                                        <table class="table table-striped">
                                                            <tr v-for="item in preview.updated" :key="'uploaded_' + item">
                                                                <td v-for="cell in item" :key="'uploaded_'+ item + '_' + cell">
                                                                    {{ cell }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6>Órdenes con errores</h6>
                                                    <div class="products-massive-preview">
                                                        <table class="table table-striped">
                                                            <tr v-for="item in preview.error" :key="'uploaded_' + item">
                                                                <td v-for="cell in item" :key="'uploaded_'+ item + '_' + cell">
                                                                    {{ cell }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="text-center mt-4">
                                                    <div class="btn btn-sm btn-success mt-3" @click="doProccess">Realizar carga y procesar concilia</div>
                                                    <div class="btn btn-sm btn-danger mt-3 ml-2" @click="$router.go()">Cancelar carga</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card" v-if="process.resultFile">
                                    <div class="card-header">
                                        <h4 class="card-title">
                                            Resultados de carga
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="text-center">
                                                    La carga ha finalizado, para ver los resultados, descargue el archivo.
                                                    <div class="mt-3">
                                                        <a class="btn btn-sm btn-primary mr-2" :href="process.resultFile" target="_blank">Descargar archivo de resultados</a>
                                                        <div @click="$router.go()" class="btn btn-sm btn-dark px-4">
                                                            Realizar otra carga
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </control-comercios-layout>
</template>
<script>
import controlComerciosLayout from "../../components/settings/controlComerciosLayout.vue";
import API from "src/core/Api";
import FileUploader from 'src/components/files/FileUploader';
//import Tools from "src/core/Tools";
import {mapGetters} from "vuex";

export default {
    components: {
        FileUploader,
        controlComerciosLayout
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        }),
    },
    data() {
        return {
            process: {},
            preview: {},
            formExtraData: {
                preview: 1
            },
            fileUploaded: [],
            acceptedMime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv'
        };
    },
    watch: {
        fileUploaded(value) {
            console.log(value);
        }
    },
    mounted: function () {

    },
    methods: {
        fileSuccess(data) {

            if (typeof data.type !== 'undefined') {
                if (data.type === 'preview') {
                    this.preview = data;
                }
                else {
                    this.process = data;
                    this.preview = {};
                }
            }
        },
        errorUpload(response) {

            if (Object.prototype.toString.call(response.data) === '[object Array]') {
                const errorsList = response.data.join(', ');
                API.showErrorNotify('Por favor revise campos', errorsList);
            }
        },
        doProccess() {
            if (typeof this.fileUploaded.pond !== 'undefined') {
                this.preview = {};
                this.formExtraData.preview = 0;
                this.fileUploaded.pond.processFile(this.fileUploaded.fileId);
            }
            else {
                API.showErrorNotify('Error al realizar concilia');
            }

        }
    },
};
</script>
