<!-- QUITÉ ESTE LAYOUT DE EL DASHBOARD MEDICO PORQUE ES REDUNDANTE YA EL CONTENEDOR DE DASHBOARD TIENE LAYOUT
CON LAS MISMAS FUNCIONALIDADES, EN EL CASO QUE ESTE EQUIVOCADO DE MI ANALISIS, PERDON 😥
-->
<template>
    <div id="main-wrapper" class="show">
        <div class="movil-nav">
            <div class="d-flex align-items-center mb-3">
            <div @click="handlerSidebarMovil" class="menu-icon mr-2 cursor-pointer">
                <i class="fa-solid fa-bars"></i>
            </div>
            <router-link class="brand-logo text-center" to="/dashboard">
                <img :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre" style="margin: auto" class="logo"/>
            </router-link>
        </div>
        </div>
        <sidebar v-bind:active="active" :showBar="show" @handlerSidebarMovil="handlerSidebarMovil"/>
        <!-- <search-bar v-if="search"/> -->
        <page-title v-if="title"/>
        <div class="startModals">
            <div class="startModalsContent"></div>
        </div>
        <slot/>
        <div class="appVersion">
            V.{{appVersion}}
        </div>
    </div>
</template>

<style scoped>
.logo{
    width: 150px;
}
</style>

<script>

import controlPanelPopupStyle from "/src/layout/admin/assets/css/popup.lazy.css";
import componentsStyleGlobal from "/src/layout/admin/assets/css/Components.lazy.css";
import controlPanelStyle from "./assets/style.lazy.css";


import PageTitle from "./PageTitle.vue";
import SearchBar from "./SearchBar.vue";
import Sidebar from "./Sidebar.vue";
import {CONFIG} from "src/config";
import {mapGetters} from "vuex";

export default {
    components: {Sidebar, PageTitle, SearchBar},
    props: {
        active: Number,
        title: Boolean,
        search: Boolean,
    },
    data() {
        return {
            appVersion: '',
            show : false,
        };
    },
    beforeCreate() {

    },
    unmounted() {
        controlPanelStyle.unuse();
        controlPanelPopupStyle.unuse();
        componentsStyleGlobal.unuse();
    },
    mounted() {
        controlPanelStyle.use();
        controlPanelPopupStyle.use();
        componentsStyleGlobal.use();
        this.appVersion = CONFIG.appVersion;
    },
    methods:{
        handlerSidebarMovil(){
            this.show = !this.show;
        }
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        })
    },
};
</script>

<style scoped>


@media (max-width: 768px) {
   .dashboard-medic {
        padding: 0;
    }

}

</style>
