import AssistanceValidator from "./views/AssistanceValidator";
import TicketPrinter from "./views/TicketPrinter.vue";
import FormList from "src/modules/form/views/FormList";
import FormBuilder from "src/modules/form/views/FormBuilder";
import OCRList from "src/modules/form/views/OCRList.vue";
import OCRForm from "src/modules/form/views/OCRForm.vue";

export default [
    {
        path: "/frm/assistance/validator",
        component: AssistanceValidator,
    },
    {
        path: "/frm/assistance/validate/:formToken",
        component: AssistanceValidator,
    },
    {
        path: "/frm/ticket/printer",
        component: TicketPrinter,
    },
    {
        path: "/forms-list",
        component: FormList,
    },
    {
        path: "/form-builder",
        component: FormBuilder,
    },
    {
        path: "/form-builder/:id",
        component: FormBuilder,
    },
    {
        path: "/docs-plus-ocr",
        component: OCRList,
    },
    {
        path: "/docs-plus-ocr/:id",
        component: OCRForm,
    },
];
