<template>
    <div>
        <FilePond
            :name="fieldName || 'file'"
            ref="pond"
            class-name="FileUploader"
            label-idle="Arrastra tus archivos aquí o haz clic para seleccionar..."
            :allow-multiple="multiple"
            allow-revert="false"
            :accepted-file-types="mimeValid"
            :max-files="fileMax"
            :server="server"
            :label-file-processing-error="labelFileProcessingError"
        />
    </div>
</template>


<script>

// Import FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
//import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import {CONFIG} from "src/config";
import {store} from "src/store";

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
    components: {
        FilePond,
    },
    props: [
        'fieldName',
        'url',
        'maxFiles',
        'extraDataForm',
        'acceptedMime',
        'removeFileOnFinish',
        'fileUploaded',
        'disableMultiple',
    ],
    mounted() {

        const maxFiles = parseInt(this.maxFiles) > 0;

        this.multiple = !(this.disableMultiple || this.disableMultiple === 'true');
        this.fileMax = (this.multiple) ? 150 : (maxFiles > 0 ? maxFiles : 1);
        this.mimeValid = this.acceptedMime;
    },
    data() {
        return {
            show: true,
            multiple: false,
            fileMax: 1,
            mimeValid: "",
            serverResponse: '',
            server: {
                // eslint-disable-next-line no-unused-vars
                process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

                    const self = this;

                    // creo la data
                    const formData = new FormData();
                    formData.append(fieldName, file, file.name);
                    for (let item in self.extraDataForm) {
                        formData.append(item, self.extraDataForm[item]);
                    }

                    // objeto xmlhttp
                    const request = new XMLHttpRequest();
                    request.open('POST', CONFIG.apiUrl + '/' + self.url);
                    request.setRequestHeader('Authorization', 'Bearer ' + store.getters.AuthGetToken);

                    request.upload.onprogress = (e) => {
                        progress(e.lengthComputable, e.loaded, e.total);
                    };

                    request.onload = function () {
                        if (request.status >= 200 && request.status < 300) {
                            self.finishUpload(request, load, error, file);
                        }
                        else {
                            // Can call the error method if something is wrong, should exit after
                            error('Error de conexión');
                        }
                    };

                    request.send(formData);

                    // Should expose an abort method so the request can be cancelled
                    return {
                        abort: () => {
                            // This function is entered if the user has tapped the cancel button
                            request.abort();

                            // Let FilePond know the request has been cancelled
                            abort();
                        },
                    };
                },
                revert: null,
            },
            labelFileProcessingError: () => {
                return this.serverResponse;
            },
            onprocessfileHandler: (error, file) => {
                const self = this;
                if (!error) {
                    this.$emit('update:fileUploaded', {
                        pond: self.$refs['pond'],
                        fileId: file.id,
                    });
                    if (this.removeFileOnFinish === '1') {
                        setTimeout(function () {
                            self.$refs['pond'].removeFile(file.id);
                        }, 1000)
                    }
                }
            },
        };
    },
    methods: {
        finishUpload(request, load, error, file) {
            const self = this;
            const res = JSON.parse(request.responseText);

            if (typeof res.status !== 'undefined') {
                if (res.status === 1) {
                    self.$emit('fileSuccess', res.data);
                    load(res.msg);

                    self.$emit('update:fileUploaded', {
                        pond: self.$refs['pond'],
                        fileId: file.id,
                    });
                    if (parseInt(self.removeFileOnFinish) === 1) {
                        console.log(file.id);
                        setTimeout(function () {
                            self.$refs['pond'].removeFile(file.id);
                        }, 1000)
                    }
                }
                else {
                    self.serverResponse = res.msg;
                    self.$emit('errorUpload', res);
                    error('Error');
                }
            }
            else {
                self.$emit('errorUpload', res);
                error('Error al subir archivo');
            }
        }
    }
};
</script>
