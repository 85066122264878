<script>
 
export default { 
 data() {
        return {
        //data
        View:true,
        newIndex:0,
 
 }},
 props:{
 editIndex: Number,
 procedimientos: Object,
 },
 methods:{
  handlerView(){
    this.View=!this.View;

    if(!this.View){
      if(Object.keys(this.procedimientos.content[this.editIndex].nota_observacion).length){
        this.newIndex = Number(Object.keys(this.procedimientos.content[this.editIndex].nota_observacion).length+1);

        while (this.procedimientos.content[this.editIndex].nota_observacion[this.newIndex]) {
          this.newIndex++;
        }

        this.procedimientos.content[this.editIndex].nota_observacion[this.newIndex]={
          titulo: '',
          descripcion: '',
        }
      }else{
        this.newIndex=1;
        this.procedimientos.content[this.editIndex].nota_observacion = {
          1: {
            titulo: '',
            descripcion: '',
          },
        };
      }

    }else{

      delete this.procedimientos.content[this.editIndex].nota_observacion[this.newIndex];
      this.newIndex=0;

    }
  },
  addNote(){

    if (this.procedimientos.content[this.editIndex].nota_observacion[this.newIndex].titulo == "" || this.procedimientos.content[this.editIndex].nota_observacion[this.newIndex].descripcion == "") {
      return alert("Completa datos del diagnostico")
    }
    this.$emit("saveAnychages")
    this.newIndex=0;
    this.View=!this.View;
  },
 }
 }
</script>
 
 
<template>
      <section v-if="View" class="d-flex flex-column justify-content-between mb-1">
        
        <div class="d-flex flex-wrap note-content m-2 scroll-area">
            <div v-if="Object.keys(procedimientos.content[editIndex].nota_observacion).length" v-for="(item, key) in procedimientos.content[editIndex].nota_observacion" class="card m-1 singleborderbox" :key="key">
              <div class="card-body">
                <h5 class="card-title mb-2">{{ item.titulo }}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{ procedimientos.content[editIndex].primary_data.nombre }}</h6>
                <p class="card-text">{{ item.descripcion }}</p>
              </div>
            </div>
          <div v-else class="w-100 d-flex justify-content-center mt-4">
            <p class=" text-muted">Este procedimiento aún no tiene notas u observaciones agregadas</p>
          </div>
        </div>
        <div class="button-div d-flex justify-content-start">
          <button type="button" @click="handlerView" class="btn btn-primary">Agregar nota   <i class="fa-solid fa-square-plus"></i></button>
        </div>
        
      </section>

      <section v-else class="d-flex flex-column justify-content-between mb-1">
        <div class="row">

          
        <div class="form-group col-12">
                      <label>Titulo</label>
                      <input type="text" class="form-control" v-model="procedimientos.content[editIndex].nota_observacion[newIndex].titulo"/>
        </div>
        <div class="form-group col-12">
                        <label>Procedimiento</label>
                        <input type="text" class="form-control"  v-model="procedimientos.content[editIndex].primary_data.nombre" disabled />
        </div>
        <div class="form-group col-12">
                        <label>Descripción</label>
                        <textarea  class="form-control" placeholder="Escribe aquí"  v-model="procedimientos.content[editIndex].nota_observacion[newIndex].descripcion"></textarea>
        </div>

        </div>

        <div class=" button-div d-flex justify-content-start mt-2">
          <button type="button" class="btn btn-secondary px-3" @click="handlerView">Cancelar</button>
          <button type="button" class="btn btn-primary px-3 ml-3" @click="addNote">Guardar</button>
        </div>

      </section>
</template>
 
 
<style scoped>
section{
  height: calc( 100% - 60px );
}
 .note-content{
  height: fit-content;
  max-height: calc( 100% - 40px );
  overflow: hidden;
  overflow-y: auto;
}

.button-div{
  height: 40px;
}


 .card{
  width: 22rem;
 }
</style>