<!-- Lienzo de dibujo para el sofware isiclinic construido con Fabric.js -->
<!-- Importar el componente en el lugar donde se desees usar para ver renderizado el componente.

  Condiciones de uso:
  
  1. En necesario que reciba un String con la letra de la categoria para la cual se usara, es para poder clasificar las imagenes guardadas en el s3

  2. Es opcional que reciba un Array con imagenes para ser mostradas como plantilla de la galeria de imagenes de la herramienta.
 

-->

<!-- TODO: Solucionar problema de CORS para poder editar imagenes ya guardadas y poder descargar el archivo  directamente al dispositvio -->

<script>
import { fabric } from 'fabric';
import ConfigModal from './components/ConfigModal.vue';
import ImgModal from './components/ImgModal.vue';
import SaveModal from './components/SaveModal.vue';
import {CONFIG} from "src/config";
import {store} from "src/store";
import {mapGetters} from "vuex";
import API from "src/core/Api";
import { shallowRef } from 'vue';

export default {
  //Para que el lienzo funcioné se debe pasar la categoria en la que se guardarán las imagenes
  //Tambien en necesario pasarle un Array de imagenes para mostrar como plantillas de lienzo
  props:{
    Category: String,
    Images: Array
  },
  data() {
    return {
      w:1000,
      h:700,
      colorSeleccionado:'#000000',
      backgroundColor: '#FFFFFF',
      tamañoPincel: 5,
      tipoPincel: 'Pencil',
      canvasHistory: [],
      canvasHistoryIndex: -1,
      objectDepthMap: new Map(),
      originalOpacities: new Map(),
      savedCanvas:'',
      isFreeDrawing: true,
      configModal: false,
      imgModal:false,
      showCanvas:true,
      saveModal: false,
      imagenesUploadExtraDataForm: {
                orderId: 0,
                category: '',
            },
    }
  },
  computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
  mounted() {
    this.initializeCanvas();
    this.imagenesUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  watch: {
    colorSeleccionado: 'actualizarColorPincel',
  },
  methods: {
    initializeCanvas() {
      // INICIALIZACION DEL CANVAS
      this.canvas = new fabric.Canvas(this.$refs.canvas,  {
      selection: true,
    });
      this.canvas.isDrawingMode = true;
      this.canvas.freeDrawingBrush.color = this.colorSeleccionado;
      this.canvas.freeDrawingBrush.width = this.tamañoPincel;
      this.canvas.backgroundColor = this.backgroundColor;
      this.canvas.on('path:created', () => {
      this.registrarHistorial();
    });
    // INICIALIZACION DE EL OBEJETO DE MOVER Y CAMBIO DE ESCALA
    fabric.Object.prototype.set({
      cornerStyle: 'circle',
      cornerColor: 'rgba(0,0,255,0.5)',
      transparentCorners: false,
      cornerSize: 12,
      padding: 5,
      rotatingPointOffset: 20,
    });

    //LISTENER PARA OBJETOS ACTIVOS
    this.canvas.on('object:selected', (e)=>{
      const obj = e.target;
      if (!obj) return;
      obj.set('opacity', 0.6);
      this.canvas.renderAll();
    });
    this.canvas.on('selection:cleared', (e)=>{
      if (e.deselected) {
        e.deselected.forEach((obj) => {
          obj.set('opacity', 1);
        });
        this.canvas.renderAll();
      }
    });
  
    // Listener para selección de objetos
    this.canvas.on('selection:created', this.onObjectSelected);
    this.canvas.on('selection:updated', this.onObjectSelected);
    this.canvas.on('selection:cleared', this.onSelectionCleared);


    // LISTENER PARA CADA QUE UNN OBJETO EN EL LIENZO CAMBIE SE GUARDA LA POSICION EN Z Y SE REGISTRE EN HISTORIAL
    this.canvas.on('object:modified',this.onReturnOpacity);
    // LISTENER PARA CUANDO DEN DOBLE CLICK A UN TEXTO PARA HABILITAR EDICIÓN
    this.canvas.on('mouse:dblclick', (opt) => {
      if (opt.target && opt.target.type === 'textbox') {
        opt.target.enterEditing();
        opt.target.hiddenTextarea.focus();
        this.registrarHistorial();
      }
    });

    //Escuchar eventos de movimiento y escalado
    this.canvas.on('object:scaling',this.onSmothOpacity);
    this.canvas.on('object:moving', this.onSmothOpacity);
    this.canvas.on('object:rotating', this.onSmothOpacity);

    window.addEventListener('keydown', (e)=>{
      if (e.key === 'Enter') {
        const activeObject = this.canvas.getActiveObject();
        if (activeObject) {
          this.canvas.discardActiveObject();
          activeObject.set('opacity', 1);
          this.canvas.renderAll();
          this.registrarHistorial();
        }
      }
    });

    this.registrarHistorial();
    },
    actualizarColorPincel() {
      this.canvas.freeDrawingBrush.color = this.colorSeleccionado;
    },
    toggleFreeDrawing(pencil) {
      if(pencil === 'Arrow'){
        this.tipoPincel=pencil;
        this.canvas.isDrawingMode=false;
      }else if ( pencil === 'Text') {
        this.tipoPincel=pencil;
        this.canvas.isDrawingMode=false;
        this.addText();
      }else{
        this.canvas.isDrawingMode=true;
        this.seleccionarPincel(pencil);
        console.log( 'spray')
      }

    },
    seleccionarPincel(pencil){
      if (pencil=== 'Eraser') {
        this.tipoPincel=pencil;
        this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
        this.canvas.freeDrawingBrush.color = 'White';
        this.canvas.freeDrawingBrush.width = this.tamañoPincel;
      }else{
        this.tipoPincel=pencil;
        this.canvas.freeDrawingBrush = new fabric[this.tipoPincel + 'Brush'](this.canvas);
        this.canvas.freeDrawingBrush.color = this.colorSeleccionado;
        this.canvas.freeDrawingBrush.width = this.tamañoPincel;
     
    }
    },
    cambiarTamañoPincel(tamaño){
      this.canvas.freeDrawingBrush.width = tamaño;
      this.tamañoPincel=tamaño;
    },
    cambiarTamañoLienzo(valor, dimension){
      this[dimension]=valor;
      const context = this.$refs.canvas.getContext('2d');
      context.clearRect(0, 0, this.w, this.h);
      this.refreshCanvas();

    },
    refreshCanvas(){
      this.showCanvas=!this.showCanvas;
     setTimeout(() => {
      this.canvas.clear();
      this.restaurarDesdeHistorial();
      this.showCanvas=!this.showCanvas;
      this.canvas.isDrawingMode = true;
      this.canvas.freeDrawingBrush.color = this.colorSeleccionado;
      this.canvas.freeDrawingBrush.width = this.tamañoPincel;
     }, 1000);
   
    },
    seleccionarActivo(pincel){
      if((this.tipoPincel==='Pencil')&& (pincel==='Pencil')){
        return 'active';
      }
      if((this.tipoPincel==='Spray') && (pincel==='Spray')){
        return 'active';
      }
      if((this.tipoPincel==='Eraser') && (pincel==='Eraser')){
        return 'active';
      }
      if((this.tipoPincel==='Arrow') && (pincel==='Arrow')){
        return 'active';
      }
      if((this.tipoPincel==='Text') && (pincel==='Text')){
        return 'active';
      }
      return '';
    },
    registrarHistorial() {
    const snapshot = this.canvas.toJSON();
    this.canvasHistory = this.canvasHistory.slice(0, this.canvasHistoryIndex + 1);
    this.canvasHistory.push(snapshot);
    this.canvasHistoryIndex = this.canvasHistory.length - 1;
    },
    deshacer() {
      if (this.canvasHistoryIndex > 0) {
        this.canvasHistoryIndex--;
        this.restaurarDesdeHistorial();
      }
      if (this.canvasHistoryIndex===0) {
        this.canvas.clear();
      }
    },
    rehacer() {
      if (this.canvasHistoryIndex < this.canvasHistory.length - 1) {
        this.canvasHistoryIndex++;
        this.restaurarDesdeHistorial();
      }
    },
    restaurarDesdeHistorial() {
      const snapshot = this.canvasHistory[this.canvasHistoryIndex];
      this.canvas.loadFromJSON(snapshot, () => {
        this.canvas.renderAll();
      });
    },
    saveCanvas() {
      this.savedCanvas = JSON.stringify(this.canvas.toJSON());
    },
    loadCanvas() {
      if (this.savedCanvas) {
        this.canvas.loadFromJSON(this.savedCanvas, () => {
          this.canvas.renderAll();
          this.loadObjectDepth();
        });
      }
    },
    saveObjectDepth(object) {
      this.objectDepthMap.set(object, this.canvas.getObjects().indexOf(object));
    },
    restoreObjectDepth(object) {
      const depth = this.objectDepthMap.get(object);
      if (depth !== undefined) {
        this.canvas.moveTo(object, depth);
      }
    },
    loadObjectDepth() {
      this.objectDepthMap.clear();
      this.canvas.getObjects().forEach((object, index) => {
        this.saveObjectDepth(object);
      });
    },
    handlerConfigModal(){
      this.configModal=!this.configModal
    },
    handlerImgModal(){
      this.imgModal=!this.imgModal
    },
    agregarImagenAlLienzo(imageUrl) {
    if (!this.canvas) {
      this.initializeCanvas();
      }
      this.canvas.clear();
      this.canvas.backgroundColor = this.backgroundColor;
      fabric.Image.fromURL(imageUrl, (img) => {
        const canvasWidth = this.canvas.width;
        const canvasHeight = this.canvas.height;

        const centerX = canvasWidth / 2;
        const centerY = canvasHeight / 2;

        img.set({
          left: centerX - img.width / 2,
          top: centerY - img.height / 2,
          scaleX: 1,
          scaleY: 1,
        });

        this.canvas.add(img);
        this.registrarHistorial();
      });
    },
    handlerSaveModal(){
    this.saveModal=!this.saveModal;
    },
    convertirLienzoImg({action,imgName, quality}){

    const dataURL = this.canvas.toDataURL({
        format: 'jpeg',
        quality: quality || 0.8,
      });
    const img = this.dataURLToBlob(dataURL,imgName);
    this.enviarImagen(img, imgName)
    if (action === "limpiar"){
      this.canvas.clear();
      this.canvas.backgroundColor = this.backgroundColor;
    }

    },
    enviarImagen(img,imgName){

    const self = this;

    // Crea un objeto FormData con los datos del archivo y los datos adicionales
    const formData = new FormData();
    formData.append('file', img, imgName );

    //Add dynamic Category tag
    self.imagenesUploadExtraDataForm.category = self.Category;

    // Agrega los datos adicionales del formulario
    for (let item in self.imagenesUploadExtraDataForm) {
      formData.append(item, self.imagenesUploadExtraDataForm[item]);
    }

    // Realiza una petición XMLHttpRequest al servidor
    const request = new XMLHttpRequest();
    request.open('POST',  CONFIG.apiUrl + '/orders/metadata-attachments/save');
    request.setRequestHeader('Authorization', 'Bearer ' + store.getters.AuthGetToken);
    request.upload.onprogress = (e) => {
      self.progress = e.loaded / e.total;
    };
    request.onload = () => {
      if (request.status >= 200 && request.status < 300) {
        this.handlerSaveModal();
        self.emitter.emit("medico-ficha-refresh");
        console.log(request);
        API.showSuccessAlert('Se ha guardado la imagen con exito!');
      } else {
        API.showErrorAlert('Error al guardar imagen');
      }
    };
    request.send(formData);
    },
    dataURLToBlob(dataURL, imgName) {
      const parts = dataURL.split(',');
      const contentType = parts[0].split(':')[1];
      const raw = Buffer.from(parts[1], 'base64');
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw[i];
      }

      return new Blob([uInt8Array], { type: contentType.replace(';base64', ''), name: imgName});
    },
    addText() {
      const self = this;

      const text = new fabric.Textbox('Texto de ejemplo', {
        left: 100,
        top: 100,
        width: 200,
        fontSize: 20,
        hasBorders: true,
        fontFamily: 'Arial',
        fill: self.colorSeleccionado,
        textAlign: 'left',
        editable: true,
      });
 

      this.canvas.add(text);
      this.canvas.setActiveObject(text);
      this.saveObjectDepth(text);
      this.canvas.renderAll();
      this.registrarHistorial();

    },
    onSmothOpacity(e){
      const obj = e.target;
      if (!obj) return;
        obj.set('opacity', 0.6);
        this.canvas.renderAll();
    },
    onReturnOpacity(e){
      const obj = e.target;
      if (!obj) return;
        obj.set('opacity',1);
        this.canvas.renderAll();
    },
    onObjectSelected(e) {
      const obj = e.target;
      if (!obj) return;
      if (!this.originalOpacities.has(obj)) {
        this.originalOpacities.set(obj, obj.opacity);
      }
      obj.set('opacity', 0.5);
      this.canvas.renderAll();
    },
    onSelectionCleared(e) {
      if (e.deselected) {
        e.deselected.forEach((obj) => {
          if (this.originalOpacities.has(obj)) {
            obj.set('opacity', this.originalOpacities.get(obj));
            this.originalOpacities.delete(obj);
          }
        });
        this.canvas.renderAll();
      }
    },
  
  },
  components:{
    ConfigModal,
    ImgModal,
    SaveModal
  }
};
</script>

<template>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div class="w-100 mt-3 mb-2 d-flex justify-content-center position-relative">
      <ConfigModal v-if="configModal" :tamañoPincel.sync="tamañoPincel" @handlerConfigModal="handlerConfigModal" @cambiarTamañoPincel="cambiarTamañoPincel"
      @cambiarTamañoLienzo="cambiarTamañoLienzo" :w="w" :h="h" @handlerImgModal="handlerImgModal"></ConfigModal>
      <ImgModal v-if="imgModal" @handlerImgModal="handlerImgModal" @agregarImagenAlLienzo="agregarImagenAlLienzo" :Images="Images" :Category="Category"></ImgModal>
      <SaveModal v-if="saveModal" @handlerSaveModal="handlerSaveModal" @guardarImagen="convertirLienzoImg"></SaveModal>
      <div class="py-2 px-3 toolbar">
        <i class="fa-solid fa-arrow-pointer" :class="seleccionarActivo('Arrow')" @click="toggleFreeDrawing('Arrow')"></i>
        <input type="color" id="drawing-color" v-model="colorSeleccionado">
        <i class="fa-solid fa-pencil" :class="seleccionarActivo('Pencil')" @click="toggleFreeDrawing('Pencil')"></i>
        <i class="fa-solid fa-spray-can" :class="seleccionarActivo('Spray')" @click="toggleFreeDrawing('Spray')"></i>
         <!-- TODO: Develop Text function  -->
        <i class="fa-solid fa-font" :class="seleccionarActivo('Text')" @click="toggleFreeDrawing('Text')"></i>
        <i class="fa-solid fa-eraser" :class="seleccionarActivo('Eraser')" @click="toggleFreeDrawing('Eraser')"></i>
        <i class="fa-solid fa-floppy-disk" @click="handlerSaveModal"></i>
        <i class="fa-solid fa-reply arrows" @click="deshacer"></i>
        <i class="fa-solid fa-share arrows" @click="rehacer"></i>
        <i class="fa-solid fa-gear" @click="handlerConfigModal"></i>
      </div>
    </div>
    <!-- Ajusta el ancho y alto según sea necesario -->
    <canvas v-if="showCanvas" ref="canvas" :width="w" :height="h" class="canvas"></canvas>
    <div v-else class="d-flex justify-content-center align-items-center p-5">
      <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    </div>
  </div>
</template>

  
<!-- Importa los estilos de Fabric.js -->
<style scoped>

.canvas {
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.toolbar{
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  gap: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.toolbar i{
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
  border-radius: 5px;
  transition: 0.2s;
}

.toolbar i:hover{
  color: #0ca7e0;
  background-color: #dddddd;
}
.toolbar #drawing-color {
    cursor: pointer;
    border: none;
    outline: none;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    transition: 0.2s;   
}

.active{
  color: #0ca7e0;
  background-color: #eeeeee;
  
}

.arrows:active,
.arrows:focus {
  color: #35a115;
  background-color: #c8c8c8;
  outline: none; /* Elimina el contorno predeterminado del foco */
}

</style>
