<script>
//Utils
import API from "/src/core/Api";
import {mapActions, mapGetters} from "vuex";

//Fabri js
import FreeDrawing from "/src/modules/medico/fabric/FreeDrawing.vue";

//Images
import Mammary from "/src/assets/Images/MammaryG.jpg";
import Fallopie from "/src/assets/Images/Fallopie.jpg"

//Box layout
import Box from '/src/modules/medico/views/fichas/Box.vue';

export default { 
 data() {
        return {
        //data
        Images:[Mammary,Fallopie],
        estado_paciente: {
            cardiopulmonar:{
                label: "Estado cardiopulmonar",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            tiroides:{
                label: "Estado de la glandula tiroides",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            mamas:{
                label: "Estado de la glandula mamaria o senos",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            abdomen:{
                label: "Estado del abdomen",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            vulva:{
                label: "Estado de la vulva (Exterior)",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            vagina:{
                label: "Estado de la vagina (Interior)",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            cervix:{
                label: "Estado del cervix",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            usg:{
                label: "USG Pelvico (Ultrasonido Pélvico)",
                value: "",
                placeholder: "Ingrese observaciones",
                type : "textarea"
            },
            utero:{
                label: "Estado del Utero",
                value: "",
                placeholder: "Ingrese descripción del estado",
                type : "textarea"
            },
            ovario_d:{
                label: "Observación del ovario derecho",
                value: "",
                placeholder: "Ingrese observaciones",
                type : "textarea"
            },
            ovario_i:{
                label: "Observación del ovario izquierdo",
                value: "",
                placeholder: "Ingrese observaciones",
                type : "textarea"
            },
            endometrio:{
                label: "Observación del endometrio",
                value: "",
                placeholder: "Ingrese observaciones",
                type : "textarea"
            },
        },

        endometriosis:{
            p:{
                label: "P",
                name:  "Peritoneo",
                value: false,
                levels: [
                    {name: "P1" , label: "∑ < 3cm" , value:false},
                    {name: "P2" , label: "∑ 3-7cm" , value:false},
                    {name: "P3" , label: "∑ > 7cm" , value:false}
                ],
                currentLevel: "",
                observations: "",
            },
            o:{
                label: "O",
                name:  "Ovario",
                value: false,
                levels: [
                    {name: "O1" , label: "∑ < 3cm" , value:false},
                    {name: "02" , label: "∑ 3-7cm" , value:false},
                    {name: "03" , label: "∑ > 7cm" , value:false}
                ],
                currentLevel: "",
                observations: "",
            },
            t:{
                label: "T",
                name:  "Trompa",
                value: false,
                levels: [
                    {name: "T1" , label: "Pared lateral pélvica" , value:false},
                    {name: "T2" , label: "Pared lateral pélvica del útero" , value:false},
                    {name: "T3" , label: "Pared lateral pélvica del útero, intestino y ligamentos uterosacros (USL" , value:false}
                ],
                currentLevel: "",
                observations: "",
            }
        },

        deep_endometriosis:{
            a:{
                label: "A",
                name:  "Compartimento anterior",
                value: false,
                levels: [
                    {name: "A1" , label: "< 1cm" , value:false},
                    {name: "A2" , label: "1-3cm" , value:false},
                    {name: "A3" , label: "> 3cm" , value:false}
                ],
                currentLevel: "",
                observations: "",
            },
            b:{
                label: "B",
                name:  "Compartimento medio",
                value: false,
                levels: [
                    {name: "B1" , label: "< 1cm" , value:false},
                    {name: "B2" , label: "1-3cm" , value:false},
                    {name: "B3" , label: "> 3cm" , value:false}
                ],
                currentLevel: "",
                observations: "",
            },
            c:{
                label: "C",
                name:  "Compartimento posterior",
                value: false,
                levels: [
                    {name: "C1" , label: "< 1cm" , value:false},
                    {name: "C2" , label: "1-3cm" , value:false},
                    {name: "C3" , label: "> 3cm" , value:false}
                ],
                currentLevel: "",
                observations: "",
            },
            f:{
                label: "F",
                name:  "Otras áreas profundas o no anatómicas especificadas",
                value: false,
                levels: [
                    {name: "A" , label: "Adenomiosis" , value:false},
                    {name: "B" , label: "Vejiga" , value:false},
                    {name: "I" , label: "Intestino" , value:false},
                    {name: "U" , label: "Uréter" , value:false}
                ],
                currentLevel: "",
                observations: "",
            }
        }
      }
},
 components:{
    FreeDrawing,
    Box,
 },
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
 mounted(){
  const self = this;

  if(self.GetMedicoFichaActive.metadata !== undefined) {

            if(self.GetMedicoFichaActive.metadata.estado_paciente){
                Object.keys(self.estado_paciente).map(function (value) {
                               self.estado_paciente[value].value = self.GetMedicoFichaActive.metadata.estado_paciente[value] || '';
                            });
            }
        
            if(self.GetMedicoFichaActive.metadata.endometriosis !== undefined){
                Object.keys(self.endometriosis).map(function(value){
                    Object.keys(self.endometriosis[value]).map(function(value2){
                        if(value2!== 'levels'){
                            self.endometriosis[value][value2] = self.GetMedicoFichaActive.metadata.endometriosis[value+value2]
                        }
                    })
                    if(self.endometriosis[value].currentLevel !== ''){
                        for (let lv of self.endometriosis[value].levels) {
                        if(self.endometriosis[value].currentLevel== lv.name){
                            lv.value = true;
                        }
                        }
                    }
                })
            } 
            if(self.GetMedicoFichaActive.metadata.deep_endometriosis !== undefined){
                Object.keys(self.deep_endometriosis).map(function(value){
                    Object.keys(self.deep_endometriosis[value]).map(function(value2){
                        if(value2!== 'levels'){
                            self.deep_endometriosis[value][value2] = self.GetMedicoFichaActive.metadata.deep_endometriosis[value+value2]
                        }
                    })
                    if(self.deep_endometriosis[value].currentLevel !== ''){
                        for (let lv of self.deep_endometriosis[value].levels) {
                        if(self.deep_endometriosis[value].currentLevel== lv.name){
                            lv.value = true;
                        }
                        }
                    }
                })
            }    
    }
 },
 methods:{
  saveGinec(slug){
      const self = this;
      let itemsTosave={};
      
      Object.keys(self[slug]).map(function (value) {
          itemsTosave[value]=self[slug][value].value;
      });
          
      API.send('POST', 'orders/metadata/save/', {
              orderId: self.GetMedicoFichaActive.orden.id,
              slug,
              detail: itemsTosave,
              uniqueMeta: 1,
          }, function (response) {
              API.showSuccessAlert(response.msg);
          },
          function (response) {
              API.showErrorAlert(response.msg);
          }); 
  },
  saveEndometriosis(){
    const self = this;
      let itemsTosave={};
      
      Object.keys(self.endometriosis).map(function (value) {
        Object.keys(self.endometriosis[value]).map(function (value2) {
            if (value2 !== 'levels'){
                itemsTosave[value+value2]=self.endometriosis[value][value2];
            }
        })
      }); 
      API.send('POST', 'orders/metadata/save/', {
              orderId: self.GetMedicoFichaActive.orden.id,
              slug: 'endometriosis',
              detail: itemsTosave,
              uniqueMeta: 1,
          }, function (response) {
              API.showSuccessAlert(response.msg);
          },
          function (response) {
              API.showErrorAlert(response.msg);
          }); 
  },
  saveDeepEndometriosis(){
    const self = this;
      let itemsTosave={};
      
      Object.keys(self.deep_endometriosis).map(function (value) {
        Object.keys(self.deep_endometriosis[value]).map(function (value2) {
            if (value2 !== 'levels'){
                itemsTosave[value+value2]=self.deep_endometriosis[value][value2];
            }
        })
      }); 
      API.send('POST', 'orders/metadata/save/', {
              orderId: self.GetMedicoFichaActive.orden.id,
              slug: 'deep_endometriosis',
              detail: itemsTosave,
              uniqueMeta: 1,
          }, function (response) {
              API.showSuccessAlert(response.msg);
          },
          function (response) {
              API.showErrorAlert(response.msg);
          }); 
  },
  selectGrade(item,level,slug){
    for (let lv of item.levels){
        if(lv !== level)
        lv.value = false;
    }
    item.currentLevel= level.name;
    if(slug=== 'endometriosis'){
        this.saveEndometriosis();
    }else if(slug === 'deep_endometriosis'){
        this.saveDeepEndometriosis();
    }
  }
 }
 }
</script>
 
 
<template>
   <box title="Estado de la paciente" icon="fas fa-notes-medical" :contraction='true'>
    <section class="row">
      <div v-for="item in estado_paciente" class="col-12 col-md-4">
                <div class="form-group mt-1">
                    <label>{{ item.label }}</label>
                    <textarea :type="item.type" class="form-control" :placeholder="item.placeholder" v-model="item.value" @change="saveGinec('estado_paciente')"></textarea>
                </div>
            </div>
  </section>
  </box>

  <box title="Estadización de Enzian" icon="fa-solid fa-book-medical" :contraction='true'>
    <section class="row py-2">
            <h5 class="col-12 text-primary mb-2">Endometrosis</h5>
            <div class="col-12 col-sm-4 mb-3 text-truncate" v-for="(item, key) in endometriosis" :key="'enzian_' + key">
                <label class="toggle text-truncate">
                    <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveEndometriosis"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">     
                        {{ `${item.label} / ${item.name}` }}
                    </span>
                </label>
                <h6 v-if="item.value" class="text-muted mt-2 mb-1">Grado</h6>
                <div v-if="item.value" class="form-group text-truncate" v-for="level in item.levels">
                    <label class="toggle text-truncate">
                    <input class="toggle-checkbox" type="checkbox" v-model="level.value" :checked="level.value" @change="selectGrade(item,level,'endometriosis')"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                        {{ `${level.name}  |  ${level.label}` }}
                    </span>
                </label>
                </div>
                <div class="form-group" v-if="item.value">
                    <label>Observacion adicional</label>
                    <textarea v-model="item.observations" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveEndometriosis"></textarea>
                </div>
            </div>
        </section>

        <section class="row py-2">
            <h5 class="col-12 text-primary mb-2">Endometrosis Profunda</h5>
            <div class="col-12 col-sm-3 mb-3 ext-truncate" v-for="(item, key) in deep_endometriosis" :key="'enzian_' + key">
                <label class="toggle  text-truncate">
                    <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveDeepEndometriosis"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">     
                        {{ `${item.label} / ${item.name}` }}
                    </span>
                </label>
                <h6 v-if="item.value" class="text-muted mt-2 mb-1">Grado</h6>
                <div v-if="item.value" class="form-group text-truncate" v-for="level in item.levels">
                    <label class="toggle text-truncate">
                    <input class="toggle-checkbox" type="checkbox" v-model="level.value" :checked="level.value" @change="selectGrade(item,level,'deep_endometriosis')"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                        {{ `${level.name}  |  ${level.label}` }}
                    </span>
                </label>
                </div>
                <div class="form-group" v-if="item.value">
                    <label>Observacion adicional</label>
                    <textarea v-model="item.observations" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveDeepEndometriosis"></textarea>
                </div>
            </div>
        </section>
  </box>

  <box title="Herramienta dibujo" icon="fa-solid fa-palette">
    <section class="row">
    
      <div class="col-12 pintura">
        <FreeDrawing :Category="'G'" :Images="Images"></FreeDrawing>
      </div>
    </section>
  </box>
</template>
 
 
<style scoped>
 
 .pintura{
  width: 100%;
  height: fit-content;

 }
</style>