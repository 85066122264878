<script>
 
export default { 
 data() {
        return {
        //data
     
 }},
 props:{
 editIndex: Number,
 procedimientos: Object,
 },
 mounted(){

 },
 }
</script>
 
 
<template>
  <section class="mb-1 d-flex flex-column">
      <div class="search-add">
        <div class="input-group my-1">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-default">Agregar medicamento</span>
        </div>
        <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
      </div>
      </div>
      <div v-if="Object.keys(procedimientos.content[editIndex].medicamentos).length" class="medic-content">
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="font-weight-bold">Id</th>
              <th scope="col" class="font-weight-bold">Código de barra</th>
              <th scope="col" class="font-weight-bold">Descripción</th>
              <th scope="col" class="font-weight-bold">Ingrediente activo</th>
              <th scope="col" class="font-weight-bold">Tamaño</th>
              <th scope="col" class="font-weight-bold">Concentración</th>
              <th scope="col" class="font-weight-bold">Presentación</th>
              <th scope="col" class="font-weight-bold">Estado</th>
            </tr>
          </thead>
          <tbody class="table-hover">
            <tr v-for="(item, key) in procedimientos.content[editIndex].medicamentos" :key="key">
              <td>{{ item.id }}</td>
              <td>{{ item.codigo }}</td>
              <td>{{ item.descripcion }}</td>
              <td>{{ item.ingrediente_activo }}</td>
              <td>{{ item.tamaño }}</td>
              <td>{{ item.concentracion }}</td>
              <td>{{ item.presentacion }}</td>
              <td v-if="item.estado==='Usado'" class="text-primary">Aplicado <i v-if="item.presentacion==='COMPRIMIDO'" class="fa-solid fa-tablets text-primary"></i><i v-if="item.presentacion==='INYECTABLE'" class="fa-solid fa-syringe text-primary"></i></td>
              <td v-else class="text-warning">No aplicado <i v-if="item.presentacion==='COMPRIMIDO'" class="fa-solid fa-tablets text-warning"></i><i v-if="item.presentacion==='INYECTABLE'" class="fa-solid fa-syringe text-warning"></i></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="d-flex justify-content-center mt-4">
          <p class=" text-muted">Este procedimiento aún no tiene medicamentos agregados</p>
      </div>
    </section>
</template>
 
 
<style scoped>

section{
  width: 100%;
  height: calc( 100% - 60px);
}
.search-add{
  width: 100%;
  height: 40px;
}

.medic-content{
  width: 100%;
  height: calc( 100% - 40px );
  overflow: hidden;
  overflow-y: auto;
}
</style>