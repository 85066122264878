<template>
    <forms-layout :active="35">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Crear código promocional</h4>
            </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-xl-6">
                        <label class="mr-sm-2">Descripción</label>
                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="desc" name="descripcion"/>
                    </div>
                    <div class="form-group col-xl-6">
                        <label class="mr-sm-2">Código promocional</label>
                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="shortCode" name="shortcode"/>
                        <small>Sin espacios ni caracteres especiales, únicamente guiones, ej. "PROMO-1"</small>
                    </div>
                    <div class="form-group col-xl-6">
                        <label class="mr-sm-2">No. usos</label>
                        <input type="number" class="form-control" placeholder="Escribe aquí" v-model="maxValidations" name="usos"/>
                    </div>
                    <div class="form-group col-xl-6">
                        <label class="mr-sm-2">Fecha de expiración</label>
                        <input type="date" class="form-control" placeholder="Escribe aquí" v-model="fechaExpira" name="usos"/>
                    </div>
                    <div class="form-group col-xl-6">
                        <label class="mr-sm-2">Porcentaje de descuento</label>
                        <input type="number" class="form-control" placeholder="Escribe aquí" v-model="discountPercent" name="descuento_porcentaje"/>
                    </div>
                    <div class="form-group col-xl-6">
                        <label class="mr-sm-2">Descuento fijo</label>
                        <input type="number" class="form-control" placeholder="Escribe aquí" v-model="discountAmount" name="descuento_fijo"/>
                        <small>Si coloca un descuento fijo, no se tomará en cuenta el porcentaje de descuento</small>
                    </div>
                    <div class="form-group col-xl-6">
                        <label class="toggle">
                            <input class="toggle-checkbox" type="checkbox" v-model="active"/>
                            <div class="toggle-switch"></div>
                            <span class="toggle-label">Habilitar código promocional</span>
                        </label>
                    </div>
                    <div class="form-group col-12">
                        <div>
                            <label class="mr-sm-2">Productos asociados</label>
                            <multiselect v-model="productosAsig"
                                         :options="productos"
                                         track-by="id" label="name"
                                         :close-on-select="false"
                                         :clear-on-select="false"
                                         :preserve-search="true"
                                         placeholder="Selecciona los productos aplicables"
                                         :multiple="true">
                            </multiselect>
                        </div>
                    </div>
                    <div class="form-group col-12 text-right">
                        <button class="btn btn-success pl-5 pr-5" @click="guardar">Guardar código promocional</button>
                    </div>
                </div>
            </div>
        </div>
    </forms-layout>
</template>

<script>
import formsLayout from "../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
        formsLayout
    },
    data() {
        return {
            id: 0,
            shortCode: '',
            desc: '',
            fechaExpira: '',
            maxValidations: 1,
            discountPercent: 0,
            discountAmount: 0,
            active: 0,
            productos: [],
            productosAsig: [],
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.loadProductos();
    },
    methods: {
        getData () {
            const self = this;
            if (self.id > 0) {
                API.send('GET', 'orders/promo-code/info/' + self.id, {}, function (response) {
                        self.shortCode = response.data.shortCode;
                        self.desc = response.data.desc;
                        self.fechaExpira = response.data.fechaExpira;
                        self.maxValidations = response.data.maxValidations;
                        self.discountPercent = response.data.discountPercent || 0;
                        self.discountAmount = response.data.discountAmount || 0;
                        self.active = !!(response.data.active);

                        self.productos.forEach(function(b) {
                            response.data.productos.forEach(function(a) {
                                if (parseInt(b.id) === parseInt(a.productoId)) {
                                    self.productosAsig.push({name: b.name, id: b.id});
                                }
                            })
                        })
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            }
        },
        guardar() {
            const self = this;
            API.send('POST', 'orders/promo-code/save', {
                    id: self.id,
                    shortCode: self.shortCode,
                    desc: self.desc,
                    fechaExpira: self.fechaExpira,
                    maxValidations: self.maxValidations,
                    discountPercent: self.discountPercent,
                    discountAmount: self.discountAmount,
                    active: self.active,
                    productosAsig: self.productosAsig,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    if (parseInt(self.$route.params.id) === 0) {
                        window.location.href = '/orders/cod-promo/' + response.data.id;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },
        loadProductos() {
            const self = this;
            self.productos = [];
            self.productosAsig = [];
            API.send('POST', 'orders/promo-code/product/list', {}, function (response) {
                    if (response.status) {

                        for (const [key, value] of Object.entries(response.data)) {
                            //console.log(key, value);
                            self.productos.push({name: value.nombre, id: value.id});
                        }

                        self.getData();
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
    },
};
</script>
