<template>
    <forms-layout :active="20">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Listado de documentos y formularios</h4>
                <img src="staticAssets/formBuilder/docsplus.png" style="max-width: 150px">
            </div>
            <div class="card-body">
                <div class="col-12 col-md-6">
                    <div class="input-group pb-sm-2">
                        <input type="text" placeholder="Buscar por nombre o token" v-model="filter" class="form-control">
                        <div class="input-group-append cursor-pointer"  @click="pageSelected = 1; loadItems(); ">
                            <span id="basic-addon2" class="input-group-text" >
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                        <div class="input-group-append cursor-pointer" v-if="filter !== ''"  @click="filter = ''; loadItems(); ">
                            <span id="basic-addon2" class="input-group-text" >
                                <i class="fa fa-times-circle"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <nav aria-label="navigation" class="p-2">
                        <ul class="pagination">
                            <li class="page-item" v-for="item in pagination" :key="'paginate_' + parseFloat(item)">
                                <a v-if="!isNaN(parseFloat(item))" @click="pageSelected = item; loadItems(); " :class="pageSelected == item?'page-link cursor-pointer page-link-active':'page-link cursor-pointer'">{{ item }}</a>
                                <div v-else class="page-link">{{ item }}</div>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="form">
                    <ul class="linked_account">
                        <li v-for="item in formularios" :key="item.id">
                            <div class="row">
                                <div class="col-12">
                                    <div class="media p-2 px-3">
                                        <span class="mr-3"><i class="fa fa-file-alt"></i></span>
                                        <div class="media-body">
                                            <h5 class="mt-0 mb-1">{{item.descripcion}}</h5>
                                            <div>
                                                Identificador de formulario (Token): {{item.token}}
                                            </div>
                                        </div>
                                        <div class="edit-option">
                                            <!--<a href="#"><i class="fa fa-eye"></i></a>-->
                                            <a @click="duplicateForm(item.id)" class="mr-2"><i class="fa fa-copy"></i></a>
                                            <a @click="$router.push('/form-builder/' + item.id)" class="mr-2"><i class="fa fa-pencil"></i></a>
                                            <a @click="deleteForm(item.id)"><i class="fa fa-trash"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="mt-5">
                        <router-link to="/form-builder" class="btn btn-primary px-4 mr-3">Agregar formulario</router-link>
                    </div>
                </div>
            </div>
        </div>
    </forms-layout>
</template>

<script>
import formsLayout from "../components/forms/formsLayout.vue";
import API from "src/core/Api";

export default {
    components: {
        formsLayout
    },
    data() {
        return {
            formularios: {},
            pagination: [],
            pageSelected: 1,
            limit: 40,
            filter: '',
        };
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            const self = this;
            API.send('POST', `formularios/all/${self.pageSelected}/${self.limit}`, {filter: self.filter},
                function (data) {
                    if (data.status) {
                        self.formularios = data.data.formularios;
                        self.pagination = Array.from(Array(data.data.totalPages).keys()).map(num => num + 1);
                        self.pageSelected = data.data.currentPage;
                    }
                },
                function (data) {
                    API.showErrorNotify(data.msg);
                });
        },
        deleteForm(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'formularios/remove/form/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },
        duplicateForm(id) {
            const self = this;

            API.showConfirm('¿Está seguro de duplicar el formulario?', 'Se obtendrá un diferente token para el formulario nuevo', function () {
                API.send('POST', 'formularios/duplicate/form/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },

    },
};
</script>
