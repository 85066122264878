<template xmlns="http://www.w3.org/1999/html">
    <div class="reservasAgendarI" v-on:click.self="hideSearch">
        <div class="reservasAgendarContainer">
            <div class="row">
                <div class="col-12 col-sm-12">
                    <div class="form-group row">
                        <label for="nombre" class="col-sm-3 col-form-label">Selecciona el establecimiento</label>
                        <div class="col-sm-9" >
                            <select
                                    v-if="disponibilidad.locaciones && Object.keys(disponibilidad.locaciones).length > 0"
                                    class="form-control"
                                    v-model="locacion"
                                    aria-describedby="alertField"
                            >
                                <option value="0" disabled>Sin establecimiento seleccionado</option>
                                <option
                                        v-for="item in disponibilidad.locaciones"
                                        :key="'loc_'+item.id"
                                        :value="item.id"
                                >{{ item.nombre }}</option>
                            </select>
                            <div
                            v-else
                            class="text-center text-small text-danger"
                            >
                            No hemos encontrado establecimientos disponibles
                        </div>
                        <small v-if="alertField && (locacion == 0)" id="alertField" class="form-text text-danger mt-2">*Es necesario seleccionar un establecimiento</small>
                        </div>
                        
                    </div>

                    <div
                            v-if="locacion && typeof disponibilidad.locaciones[locacion] !== 'undefined' && typeof disponibilidad.locaciones[locacion].personal !== 'undefined'"
                            class="form-group row"
                    >
                        <label for="nombre" class="col-sm-3 col-form-label">Deseo ser atendido por</label>
                        <div class="col-sm-9">
                            <select class="form-control" v-model="personal">
                                <option value="0" selected>Me es indiferente</option>
                                <option
                                        v-for="(item, id) in disponibilidad.locaciones[locacion].personal"
                                        :key="'locpersonal_'+id"
                                        :value="id"
                                >{{ item }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <!-- Hora -->
                        <div class="col-12 col-lg-6">
                            <div class="py-2">
                                <label class="toggle">
                                    <input class="toggle-checkbox" type="checkbox" v-model="citaEntrecita"/>
                                    <div class="toggle-switch"></div>
                                    <span class="toggle-label">Agendar entre citas</span>
                                </label>
                            </div>
                            <div v-if="!citaEntrecita">
                                <div class="form-group">
                                    <label class="mt-3 font-weight-bold">Selecciona el horario</label>
                                    <div class="timeSelector">
                                        <template
                                                v-if="typeof disponibilidad.horario[personalSelected] === 'undefined' || Object.keys(disponibilidad.horario[personalSelected]).length === 0"
                                        >
                                            <p class="mt-3 text-muted">Sin horarios disponibles</p>
                                        </template>
                                        <template v-else>
                                            <div
                                                    v-for="item in disponibilidad.horario[personalSelected]"
                                                    :key="'time+_'+item"
                                                    @click="horarioSelected = item"
                                                    :class="(horarioSelected === item) ? 'selected' : ''"
                                            >{{ item }}</div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="my-2">
                                <label class="pt-3">Ingresa la hora de cita
                                    <i
                                            class="fa fa-question-circle ml-2"
                                            v-tooltip="'Acá se configura el tiempo que durará una cita, el formato es hh:mm:ss'"
                                    ></i>
                                </label>
                                <div class="form-group">
                                    <input
                                            class="form-control"
                                            type="text"
                                            v-model="horarioSelected"
                                            v-maska
                                            data-maska="##:##:##"
                                            placeholder="00:00:00"
                                    />
                                    <div class="text-muted">Formato: Horas:Minutos:Segundos</div>
                                </div>
                            </div>
                        </div>
                        <!-- Color -->
                        <div class="form-group col-12 col-lg-6 px-2">
                            <label class="py-2">
                                Color personalizado para la reserva
                            </label>
                            <div class="col-12">
                                <div class="mt-2 row">
                                    <div class="col-3 p-2 shadow-custom">
                                    <div class="w-100 h-100" :style="{ backgroundColor: citaColor ? citaColor : '#0076B6'}">
                                        
                                    </div>
                                    </div>
                                    <div class="col-9">
                                        <Twitter v-model="hexColor"></Twitter>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label>Motivo de consulta o cita (*)</label>
                        <textarea
                                class="form-control"
                                placeholder="Escribe aquí el motivo de la cita"
                                v-model="motivoConsulta"
                                aria-describedby="alertFieldtxt"
                        ></textarea>
                        <small v-if="alertField && (motivoConsulta == '')" id="alertFieldtxt" class="form-text text-danger mt-2">*Es necesario indicar el motivo de consulta</small>
                    </div>
                </div>
                <div class=" col-12 reservasAgendarI" v-if="showResumen && !isMultiple">
                                <div class="reservasAgendarContainer">
                                    <h3><i class="fas fa-calendar mr-2"></i> Se agendará una cita</h3>
                                    <hr>
                                    <div>
                                        <h4>
                                            <span class="text-primary mr-2">Servicio:</span> {{producto.nombre}}
                                            <span v-if="producto.onSale">({{producto.precioOnSale}})</span>
                                            <span v-else>({{producto.precio}})</span>
                                        </h4>
                                    </div>
                                    <div>
                                        <h4 class="mt-3">Asistirá:</h4>
                                        <h5><span class="text-primary mr-2">DPI o Pasaporte:</span> {{dpi}}</h5>
                                        <h5><span class="text-primary mr-2">Nombre:</span> {{nombres}}</h5>
                                        <h5><span class="text-primary mr-2">Apellidos:</span> {{apellidos}}</h5>
                                    </div>
                                    <div>
                                        <h4 class="mt-3">Datos de cita:</h4>
                                        <h5><span class="text-primary mr-2">Ubicación:</span> {{locacion}}</h5>
                                        <h5><span class="text-primary mr-2">Hora y fecha:</span> {{horarioSelected}} el {{ dayjs(this.date).format('YYYY-MM-DD') }}</h5>
                                        <h5><span class="text-primary mr-2">Atendido por:</span> {{disponibilidad.locaciones[locacion].personal[personalSelected]}}</h5>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-6">
                                            <button type="button" @click="showResumen = false" class="btn btn-danger w-100">Cancelar</button>
                                        </div>
                                        <div class="col-6">
                                            <button type="button" class="btn btn-primary w-100" @click="finishSelection">Agendar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                <div class=" col-12 reservasAgendarI d-flex justify-content-center" v-if="isMultiple">
                    <div class="w-100 row mt-4">
                        <div class="col-6">
                            <button type="button" @click="cancelAdd" class="btn btn-danger w-100">Cancelar</button>
                        </div>
                        <div class="col-6">
                            <button type="button" class="btn btn-primary w-100" @click="AddClient">Agregar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.shadow-custom{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 5px;
}
</style>
<script>

import API from "src/core/Api";
import {mapActions, mapGetters} from "vuex";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import { Twitter } from '@ckpack/vue-color';

export default {
    components: {
        DatePicker,
        Twitter
    },

    props: ['producto', 'dateSelected', 'horaSelected','isMultiple'],
    data() {
        return {
            showResumen:false,
            hideDateSelector: false,
            showDataTimePicker: false,
            date: this.dateSelected? this.dateSelected: new Date(),
            time: this.horaSelected? this.horaSelected: '',
            datePickerParams: [
                {
                    dot: true,
                    key: 'today',
                    highlight: false,

                },
            ],
            locacion: 0,
            personal: 0,
            personalSelected: 0,
            locacionName: '',
            disponibilidad: {},
            horarioSelected: this.horaSelected? this.horaSelected: '',
            citaEntrecita: true,
            citaEntrecitaMotivo: '',
            
            // datos del paciente o asistente
            searchResults: {},
            clienteEsPaciente: true,
            clienteSelected: false,
            clienteSearched: false,
            clienteSearchResult: {},
            menorDeEdad: false,
            
            dpiSearch: '',
            nombreSearch: '',
            apellidoSearch: '',
            
            idCliente: '',
            dpi: '',
            genero: '',
            nombres: '',
            apellidos: '',
            fechaNacimiento: '',
            telefono: '',
            email: '',
            motivoConsulta: '',
            citaColor: '#FFFFFF',
            
            alertField:false,
        };
    },
    computed: {
        ...mapGetters({
            OrderGetClientSelected: 'OrderGetClientSelected',
            StoreInfo: 'StoreInfo',
        }),
        hexColor: {
        get() {
            return this.citaColor;
        },
        set(value) {
            // Convertir el valor a hexadecimal si es necesario
            if (typeof value === 'object' && value.hex) {
            this.citaColor = value.hex;
            } else {
            this.citaColor = value;
            }
      }
    }
    },
    watch: {
        showAgendamiento: function (value) {
            if (value) {
                this.resetSearch();
                this.menorDeEdad = false;
                this.clienteSearchResult = {};
                this.dpiSearch = '';
                this.clienteSearched = false;
                this.getDisponibilidad();
            }
        },
        motivoConsulta: function (value) {
            if (value) {  
                if(this.locacion==0){
                    this.showResumen=false;
                    this.alertField=true;
                }else{
                    this.showResumen=true;
                }
            }else{
                this.showResumen=false;
            }
        },
        date: function (value) {
            const self = this;
            if (value) {
                self.getDisponibilidad();
            }
        },
        locacion: function (value) {
            this.selectPersonal();
            if (value) {
                if(this.motivoConsulta==''){
                    this.showResumen=false;
                    this.alertField=true;
                }else{
                    this.showResumen=true;
                }
            }else{
                this.showResumen=false;
            }
        },
        personal: function () {
            this.selectPersonal();
        },
    },
    mounted() {
        //console.log(this.OrderGetClientSelected);
        this.resetSearch();
        this.getDisponibilidad();
        this.selectPersonal();
        this.utilizarMismoCliente()
    },
    methods: {
        dayjs,
        ...mapActions({
            AuthGetUserInfo: 'AuthGetUserInfo',
            OrderPosClientSelect: 'OrderPosClientSelect',
        }),
        hideSearch: function () {
            this.searchResults = {};
            this.searchTerm = '';
            this.operation = 'search';
        },
        getDisponibilidad() {
            const self = this;

            if (typeof this.producto.id === 'undefined') {
                API.showErrorNotify('Debe seleccionar un servicio');
                return false;
            }

            API.send('POST', 'products/public/disponibilidad/' + store.getters.AuthGetUserInfo.s + '/producto/' + self.producto.id + '/' + dayjs(self.date).format('YYYY-MM-DD'), {
                    idPais: self.pais,
                    idDepartamento: self.departamento,
                    idMunicipio: self.municipio,
                },
                function (response) {
                    if (response.status && typeof response.data !== 'undefined') {
                        self.disponibilidad = response.data;
                    }
                },
                function (response) {
                    self.horarios = {};
                    API.showErrorNotify(response.msg);
                });
        },
        finishSelection() {
            if (Tools.isEmptyString(this.nombres) || Tools.isEmptyString(this.motivoConsulta)) {
                API.showErrorNotify('Debe ingresar los datos del cliente que asistirá a la cita');
                return false;
            }

            if (this.horarioSelected && this.horarioSelected !== '') {
                
                this.$emit('selected',  {
                    productoKey: this.productoKey,
                    productoId: this.producto.id,
                    horario: this.horarioSelected,
                    entrecita: this.citaEntrecita,
                    entrecitaMotivo: this.citaEntrecitaMotivo,
                    citaColor: this.citaColor,
                    locacionId: this.locacion,
                    locacion: this.disponibilidad.locaciones[this.locacion].nombre,
                    fecha: dayjs(this.date).format('YYYY-MM-DD'),
                    personal: this.personalSelected,
                    personalNombre: this.disponibilidad.locaciones[this.locacion].personal[this.personalSelected],
                    sellBy: this.producto.locacionId,
                    clienteCita: {
                        id: this.idCliente,
                        dpi: this.dpi,
                        nombres: this.nombres,
                        apellidos: this.apellidos,
                        telefono: this.telefono,
                        email: this.email,
                        fechaNacimiento: this.fechaNacimiento,
                        genero: this.genero,
                        motivoCita: this.motivoConsulta,
                    }
                });
                this.$emit('createOrder')
            }
        },
        AddClient(){
            
            if(this.locacion=== 0 || this.motivoConsulta === ''){
                return this.alertField=true;
            }

            if (Tools.isEmptyString(this.nombres) || Tools.isEmptyString(this.motivoConsulta)) {
                API.showErrorNotify('Debe ingresar los datos del cliente que asistirá a la cita');
                return false;
            }

            if (this.horarioSelected && this.horarioSelected !== '') {
                
                this.$emit('AddClient', {
                    productoKey: this.productoKey,
                    productoId: this.producto.id,
                    horario: this.horarioSelected,
                    entrecita: this.citaEntrecita,
                    entrecitaMotivo: this.citaEntrecitaMotivo,
                    locacionId: this.locacion,
                    locacion: this.disponibilidad.locaciones[this.locacion].nombre,
                    fecha: dayjs(this.date).format('YYYY-MM-DD'),
                    personal: this.personalSelected,
                    personalNombre: this.disponibilidad.locaciones[this.locacion].personal[this.personalSelected],
                    sellBy: this.producto.locacionId,
                    clienteCita: {
                        id: this.idCliente,
                        dpi: this.dpi,
                        nombres: this.nombres,
                        apellidos: this.apellidos,
                        telefono: this.telefono,
                        email: this.email,
                        fechaNacimiento: this.fechaNacimiento,
                        genero: this.genero,
                        motivoCita: this.motivoConsulta,
                    }
                });
            }

        },
        cancelAdd(){
            this.$emit('cancelAdd');
        },
        selectPersonal() {
            if (parseInt(this.personal) === 0) {
                if (typeof this.disponibilidad.locaciones !== 'undefined' && typeof this.disponibilidad.locaciones[this.locacion] !== 'undefined' && typeof this.disponibilidad.locaciones[this.locacion].personal !== 'undefined') {
                    this.personalSelected = Object.keys(this.disponibilidad.locaciones[this.locacion].personal)[0];
                }
            }
            else {
                this.personalSelected = this.personal;
            }
        },
        resetSearch() {
            this.clienteSelected = false;
            this.idCliente = '';
            this.dpi = '';
            this.nombres = '';
            this.apellidos = '';
            this.telefono = '';
            this.email = '';
            this.fechaNacimiento = '';
            this.genero = '';
        },
        utilizarMismoCliente() {
            if (this.clienteEsPaciente) {
                this.clienteSelected = true;
                this.clienteSearched = true;

                if (typeof this.OrderGetClientSelected.nombres !== 'undefined') {
                    this.idCliente = (typeof this.OrderGetClientSelected.id !== 'undefined') ? this.OrderGetClientSelected.id : 0;
                    this.nombres = (typeof this.OrderGetClientSelected.nombres !== 'undefined') ? this.OrderGetClientSelected.nombres : '';
                    this.apellidos = (typeof this.OrderGetClientSelected.nombres !== 'undefined') ? this.OrderGetClientSelected.apellidos : '';
                    this.telefono = (typeof this.OrderGetClientSelected.telefono !== 'undefined') ? this.OrderGetClientSelected.telefono : '';
                    this.email = (typeof this.OrderGetClientSelected.email !== 'undefined') ? this.OrderGetClientSelected.email : '';
                    this.dpi = (typeof this.OrderGetClientSelected.cui_passport !== 'undefined') ? this.OrderGetClientSelected.cui_passport : '';
                    this.fechaNacimiento = (typeof this.OrderGetClientSelected.fechaNacimiento !== 'undefined') ? this.OrderGetClientSelected.fechaNacimiento : '';
                    this.genero = (typeof this.OrderGetClientSelected.genero !== 'undefined') ? this.OrderGetClientSelected.genero : '';
                }
            }
            else {
                this.clienteSelected = false;
                this.clienteSearched = true;

                if (typeof this.OrderGetClientSelected.nombres !== 'undefined') {
                    this.idCliente = 0;
                    this.nombres = '';
                    this.apellidos = '';
                    this.telefono = '';
                    this.email = '';
                    this.dpi = '';
                    this.fechaNacimiento = '';
                    this.genero = '';
                }
            }
        },
        crearCliente() {
            this.resetSearch();
            this.dpi = this.dpiSearch;
            this.clienteSearched = true;
            this.clienteSelected = true
        },
        selectClient(item) {
            this.idCliente = item.id;
            this.dpi = item.cui_passport;
            this.nombres = item.nombres;
            this.apellidos = item.apellidos;
            this.telefono = item.telefono;
            this.email = item.email;
            this.fechaNacimiento = item.fechaNacimiento;
            this.genero = item.genero;
            this.clienteSelected = true;
        },
        checkHideOption(option) {
            console.log(this.StoreInfo.cart);
            if (typeof this.StoreInfo === 'object' && typeof this.StoreInfo.cart !== 'undefined') {
                if (typeof this.StoreInfo.cart[option] !== 'undefined' && this.StoreInfo.cart[option]) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        },
        searchClienteCita() {
            const self = this;

            if (self.dpiSearch !== '' || self.nombreSearch !== '' || self.apellidoSearch !== '') {

                self.clienteSelected = false;
                self.resetSearch();

                API.send('POST', 'clients/general-search', {
                        'term': self.dpiSearch,
                        'nombres': self.nombreSearch,
                        'apellidos': self.apellidoSearch,
                    },
                    function (response) {
                        if (response.status) {
                            self.clienteSearched = true;
                            self.searchResults = response.data;

                            /*if (Object.keys(self.searchResults).length === 0) {
                                self.clienteSelected = true;
                                self.dpi = self.dpiSearch;
                            }*/
                        }
                        else {
                            API.showErrorNotify(response.msg);
                            self.clienteSearched = false;
                        }
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                        self.clienteSearched = false;
                    })
            }
            else {
                API.showErrorAlert('Ingresa los datos de búsqueda');
            }
        },
    },
};
</script>
