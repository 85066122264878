<template>
<layout>
    <div class="content-body">
            <div class="container h-100">
               <!-- MODALES -->
               <div>
                <!-- MODAL FACTURAS -->
                <div v-if="modalType===typesModals.FACTURAS" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div class="d-flex justify-content-between align-items-center w-100">
                                        
                                        <h5 class="modal-title" id="modal-cita-titulo">Facturas emitidas</h5>

                                        <button type="button" class="close" @click="handlerModal" data-dismiss="modal" aria-label="Cerrar">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>


                                </div>
                                <div class="modal-body">
                                    <div>
                                        <div class="row p-2">
                                            <table v-if="facturas.length > 0" class="table">
                                            <thead class="thead-light">
                                                <tr>
                                                <th scope="col">Codigo</th>
                                                <th scope="col">Fecha y Hora</th>
                                                <th scope="col">Estado</th>
                                                <th scope="col">Monto</th>
                                                <th scope="col">Opción</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in facturas" class="datosFila">
                                                <td>{{ item.codigo }}</td>
                                                <td>{{ item.fechaHora }}</td>
                                                <td>{{ item.estado }}</td>
                                                <td>{{ item.monto + ' ' + item.moneda }}</td>
                                                <td title="Ver factura">
                                                    <a :href="'/order/view/'+item.id" class="p-3"><i class="fa fa-eye fa-2xl text-dark"></i></a>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <div v-else class="row">
                                                <p class=" text-muted col-12 d-flex justify-content-center">Este cliente no tienes facturas emitida</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" @click="handlerModal" data-dismiss="modal">Cerrar</button>
                                </div>

                            </div>
                        </div>
                    </div>

                        <!-- MODAL HISTORIAL -->
                        <div v-if="modalType===typesModals.HISTORIAL" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div class="d-flex justify-content-between align-items-center w-100">
                                        <h5 class="modal-title" id="modal-cita-titulo">Historial</h5>

                                        <button type="button" class="close" @click="handlerModal" data-dismiss="modal" aria-label="Cerrar">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    

                                </div>
                                <div class="modal-body">
                                    <div>
                                    <h5 class="text-primary mt-3">Tratamientos</h5>
                                        
                                <template v-if="tratamientos">

                                    
                                    <div v-if="tratamientos.length > 0" class="row p-2">
                                    <div v-for="item in tratamientos" class="col-12 row border-top my-2">
                                        <div class="col-12 d-flex justify-content-between pt-1">
                                        <p class="font-weight-bold">{{ item.observacion }}</p>
                                        <p>{{ item.fecha }}</p>
                                        </div>
                                        <hr>
                                        <div class="col-12 d-flex  justify-content-center">
                                            <template v-if="item.recetas">
                                            <table v-if="item.recetas.length > 0" class="table">
                                            <thead class="thead-light">
                                                <tr>
                                                <th scope="col">Medicamento</th>
                                                <th scope="col">Administración</th>
                                                <th scope="col">Dosis y frecuencía</th>
                                                <th scope="col">Observaciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="receta in item.recetas" class="datosFila">
                                                <td>{{ receta.medicamento || 'Sin medicamento' }}</td>
                                                <td>{{ receta.administracion }}</td>
                                                <td>{{ receta.cantidad || '1' + ' ' + receta.cada + ' durante ' + receta.durante }}</td>
                                                <td>{{ receta.observaciones }}</td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <div v-else class="row">
                                                <p class=" text-muted col-12 d-flex justify-content-center">Este tratamiento no contiene recetas</p>
                                            </div>
                                        </template>
                                        <div v-else class="row">
                                                <p class=" text-muted col-12 d-flex justify-content-center">Este tratamiento no contiene recetas</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div v-else class="row p-2">
                                        <p class=" text-muted col-12 d-flex justify-content-center">Este paciente no tiene tratamientos</p>
                                    </div>
                                </template>
                                <div v-else class="row p-2">
                                        <p class=" text-muted col-12 d-flex justify-content-center">Este paciente no tiene tratamientos</p>
                                    </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" @click="handlerModal" data-dismiss="modal">Cerrar</button>
                                </div>

                            </div>
                        </div>
                    </div>

                        <!-- MODAL ADJUNTOS -->
                        <div v-if="modalType===typesModals.ADJUNTOS" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div class="d-flex justify-content-between align-items-center w-100">
                                        <h5 class="modal-title" id="modal-cita-titulo">Archivos adjuntos</h5>

                                        
                                        <button type="button" class="close" @click="handlerModal" data-dismiss="modal" aria-label="Cerrar">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>


                                </div>
                                <template v-if="!reloadFiles">
                                <div class="modal-body">
                                    <Box title="Adjuntos del cliente" icon="mdi mdi-account" :contraction="true">
                                        <template v-if="!uploadClientFile">
                                            <div v-if="clienteAdjuntos" class="w-100 px-2">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex flex-column">
                                                        <h4 class="font-weight-bold text-primary">{{formatName(client.nombres + ' ' + client.apellidos)}}</h4>
                                                
                                                    </div>
                                                    <div @click="handlerUploadClientFileSection()" class="btn btn-primary btn-sm" title="Click para cargar archivos a esta orden">
                                                        <i class="fa-solid fa-upload"></i> Cargar archivo
                                                    </div>
                                                </div>
                                                <div class="w-100 mb-2">
                                                    <h5 class="text-primary mt-3">Tabla de Ajuntos</h5>
                                                    <hr>
                                                    <div class="d-flex justify-content-center">
                                                        <table class="table">
                                                        <thead class="thead-light">
                                                        <tr>
                                                        <th scope="col">Nombre</th>
                                                        <th scope="col">Categoria</th>
                                                        <th scope="col">Fecha de carga</th>
                                                        <th scope="col">Opción</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="item in clienteAdjuntos" class="datosFila">
                                                        <td>{{ item.detail.name || ' Sin nombre' }}</td>
                                                        <td>{{ item.detail.cat === 'O' ? 'Otros'
                                                                : item.detail.cat === 'I' ? 'Imagen'
                                                                : item.detail.cat === 'H' ? 'Historial'
                                                                : item.detail.cat === 'L' ? 'Laboratorio'
                                                                : 'Desconocida'
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{ item.createdAt }}
                                                        </td>
                                                        <td>
                                                            <a title="Ver adjunto" :href="item.urlTMP.fileUrl" target="_blank" class="p-2">
                                                                <i class="fa fa-eye fa-2xl text-dark"></i>
                                                            </a>
                                                        </td>
                                                        </tr>
                                                        </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="row p-2">
                                            <div class="col-12 d-flex justify-content-between align-items-center">
                                                <h4 class="font-weight-bold text-primary">{{formatName(client.nombres + ' ' + client.apellidos)}}</h4>
                                                <div @click="handlerUploadClientFileSection()" class="btn btn-primary btn-sm" title="Click para cargar archivos a este cliente">
                                                    <i class="fa-solid fa-upload"></i> Cargar archivo
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <p class=" text-muted col-12 d-flex justify-content-center">Este paciente no tiene Archivos adjuntos personales</p>
                                            </div>
                                            </div>                                            
                                        </template>
                                        <template v-else>
                                        <div class="row px-2">
                                            <div class="col-12 d-flex justify-content-between align-items-center">
                                                <div class="d-flex flex-column">
                                                    <h4 class="font-weight-bold text-primary">{{formatName(client.nombres + ' ' + client.apellidos)}}</h4>
                                                </div> 
                                                <div @click="handlerUploadClientFileSection()" class="btn btn-danger btn-sm" title="Click para cancelar la carga">
                                                    Cancelar
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>Seleccione la categoria para el archivo adjunto</label>
                                                    
                                                    <select class="form-control" v-model="uploadClientFileExtradata.category">
                                                        <option v-for="item in categoryList" :value="item.value">{{ item.label }}</option>
                                                    </select>
                                                    <small v-if="uploadFileExtradata.category==='O'" class="form-text text-primary mt-1">*La categoria seleccionada por defecto es "Otros".</small>
                                                </div> 
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <FileUploader
                                                        url="orders/metadata-attachments-by-client/save"
                                                        :accepted-mime="[
                                                            'image/*',
                                                            'application/pdf',
                                                            'application/x-xls',
                                                            'application/vnd.ms-excel',
                                                            'application/msword',
                                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                            'application/dicom', // .dcm
                                                            'application/hdr',    // .hdr
                                                            'application/nii',    // .nii
                                                            'application/ktx',
                                                        ]"
                                                        remove-file-on-finish="0"
                                                        :extra-data-form="uploadClientFileExtradata"
                                                        @fileSuccess="fileSuccessUploadClientFile"
                                                        ></FileUploader>
                                                </div>
                                            </div>
                                        </div>
                                        </template>
                                    </Box>

                                    <Box title="Adjuntos por orden" icon="la la-file-invoice-dollar" :contraction="true">
                                    <template v-if="!uploadFile">
                                    <div v-if="adjuntos.length > 0" class="w-100 px-2 mb-2" v-for="item in adjuntos">
                                        <div class="d-flex justify-content-between align-items-center">
                                           <div class="d-flex flex-column">
                                            <h4 class="font-weight-bold text-primary">Consulta: {{ item.motivo }}</h4>
                                            <p class="font-weight-bold text-dark">
                                                Fecha de orden: {{  item.fecha }}
                                            </p>
                                           </div>
                                            <div @click="handlerUploadFileSection(item)" class="btn btn-primary btn-sm" title="Click para cargar archivos a esta orden">
                                                <i class="fa-solid fa-upload"></i> Cargar archivo
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="w-100 mb-2">

                                        <h5 class="text-primary mt-3">Historiales</h5>
                                        <hr>
                                    <div class="d-flex justify-content-center">

                                    <table v-if="item.historial.length > 0" class="table">
                                    <thead class="thead-light">
                                        <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Fecha de carga</th>
                                        <th scope="col">Opción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="h in item.historial" class="datosFila">
                                        <td>{{ h.nombre || ' Sin nombre' }}</td>
                                        <td>{{ h.fecha }}</td>
                                        <td>
                                            <a title="Ver adjunto" :href="h.url" target="_blank" class="p-2">
                                                <i class="fa fa-eye fa-2xl text-dark"></i>
                                            </a>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <div v-else class="row">
                                        <p class=" text-muted col-12 d-flex justify-content-center">No hay historiales adjuntos</p>
                                    </div>

                                    </div>
                                    <h5 class="text-primary mt-3">Laboratorios</h5>
                                        <hr>
                                    <div class="d-flex justify-content-center">

                                    <table v-if="item.laboratorios.length > 0" class="table">
                                    <thead class="thead-light">
                                        <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Fecha de carga</th>
                                        <th scope="col">Opción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="L in item.laboratorios" class="datosFila">
                                        <td>{{ L.nombre || ' Sin nombre' }}</td>
                                        <td>{{ L.fecha }}</td>
                                        <td>
                                            <a title="Ver adjunto" :href="L.url" target="_blank" class="p-2">
                                                <i class="fa fa-eye fa-2xl text-dark"></i>
                                            </a>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <div v-else class="row">
                                        <p class=" text-muted col-12 d-flex justify-content-center">No hay laboratorios adjuntos</p>
                                    </div>

                                    </div>
                                    <h5 class="text-primary mt-3">Imagenes</h5>
                                        <hr>
                                    <div class="d-flex justify-content-center">

                                    <table v-if="item.imagenes.length > 0" class="table">
                                    <thead class="thead-light">
                                        <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Fecha de carga</th>
                                        <th scope="col">Opción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="I in item.imagenes" class="datosFila">
                                        <td>{{ I.nombre || ' Sin nombre' }}</td>
                                        <td>{{ I.fecha }}</td>
                                        <td>
                                            <a title="Ver adjunto" :href="I.url" target="_blank" class="p-2">
                                                <i class="fa fa-eye fa-2xl text-dark"></i>
                                            </a>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <div v-else class="row">
                                        <p class=" text-muted col-12 d-flex justify-content-center">No hay imagenes adjuntas</p>
                                    </div>

                                    </div>
                                    <h5 class="text-primary mt-3">Otros</h5>
                                        <hr>
                                    <div class="d-flex justify-content-center">

                                    <table v-if="item.otros.length > 0" class="table">
                                    <thead class="thead-light">
                                        <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Fecha de carga</th>
                                        <th scope="col">Opción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="O in item.otros" class="datosFila">
                                        <td>{{ O.nombre || ' Sin nombre' }}</td>
                                        <td>{{ O.fecha }}</td>
                                        <td>
                                            <a title="Ver adjunto" :href="O.url" target="_blank" class="p-2">
                                                <i class="fa fa-eye fa-2xl text-dark"></i>
                                            </a>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <div v-else class="row">
                                        <p class=" text-muted col-12 d-flex justify-content-center">No hay otros adjuntas</p>
                                    </div>

                                    </div>

                                    </div>

                                    </div>
                                    <div v-else class="row p-2 mb-2">
                                        <p class=" text-muted col-12 d-flex justify-content-center">Este paciente no tiene Archivos adjuntos en sus ordenes</p>
                                    </div>
                                    </template>
                                    <template v-else>
                                    <div class="row">
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <div class="d-flex flex-column">
                                                <h6 class="font-weight-bold text-primary">Consulta: {{ uploadOrderInfo.motivo }}</h6>
                                                <p class="font-weight-bold text-dark">
                                                    Fecha: {{ uploadOrderInfo.fecha }}
                                                </p>
                                            </div> 
                                            <div @click="handlerUploadFileSection()" class="btn btn-danger btn-sm" title="Click para cancelar la carga">
                                                Cancelar
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Seleccione la categoria para el archivo adjunto</label>
                                                
                                                <select class="form-control" v-model="uploadFileExtradata.category">
                                                    <option v-for="item in categoryList" :value="item.value">{{ item.label }}</option>
                                                </select>
                                                <small v-if="uploadFileExtradata.category==='O'" class="form-text text-primary mt-1">*La categoria seleccionada por defecto es "Otros".</small>
                                            </div> 
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <FileUploader
                                                    url="orders/metadata-attachments/save"
                                                    :accepted-mime="[
                                                        'image/*',
                                                        'application/pdf',
                                                        'application/x-xls',
                                                        'application/vnd.ms-excel',
                                                        'application/msword',
                                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                        'application/dicom', // .dcm
                                                        'application/hdr',    // .hdr
                                                        'application/nii',    // .nii
                                                        'application/ktx',
                                                    ]"
                                                    remove-file-on-finish="0"
                                                    :extra-data-form="uploadFileExtradata"
                                                    @fileSuccess="fileSuccessUploadFile"
                                                    ></FileUploader>
                                            </div>
                                        </div>
                                    </div>
                                    </template>
                                    </Box>

                                </div>
                                </template>
                                <template v-else>
                                    <div class="modal-body">
                                        <div class="d-flex justify-content-center align-items-center p-5">
                                            <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" @click="handlerModal" data-dismiss="modal">Cerrar</button>
                                </div>

                            </div>
                        </div>
                    </div>

                        <!-- MODAL CITAS -->
                        <div v-if="modalType===typesModals.CITAS" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div class="d-flex justify-content-between align-items-center w-100">
                                        
                                        <h5 class="modal-title" id="modal-cita-titulo">Información</h5>
                                        
                                        <button type="button" class="close" @click="handlerModal" data-dismiss="modal" aria-label="Cerrar">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                </div>

                                <div class="modal-body">
                                    <div>
                                    <div class="row p-2">
                                        <table v-if="citas.length > 0" class="table">
                                        <thead class="thead-light">
                                            <tr>
                                            <th scope="col">Motivo</th>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Hora</th>
                                            <th scope="col">Opción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in citas" class="datosFila">
                                            <td>{{ item.motivo || 'No se agrego un motivo' }}</td>
                                            <td>{{ item.fecha }}</td>
                                            <td>{{ item.hora }}</td>
                                            <td title="Ver factura">
                                                <a :href="'/medic/ficha/'+item.id" class="p-3"><i class="fa fa-eye fa-2xl text-dark"></i></a>
                                            </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <div v-else class="row">
                                            <p class=" text-muted col-12 d-flex justify-content-center">Este cliente no tienes facturas emitida</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" @click="handlerModal" data-dismiss="modal">Cerrar</button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- MODAL IMG -->
                    <div v-if="modalType===typesModals.ADDIMG" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modal-cita-titulo">Subir imagen</h5>

                                </div>
                                <div class="modal-body">
                                    <div class="row">

                                        <div class="col-12 d-flex flex-column justify-content-center aling-items-center">

                                            <p class="text-primary text-center">Carga una foto para el expediente de un paciente, esta no debe exceder los 3mb</p>
                                            <FileUploader url="clients/img/upload"
                                                        accepted-mime="image/jpg, image/jpeg, image/png, image/gif, application/pdf"
                                                        :extra-data-form="imageUploadExtraDataForm"
                                                        @fileSuccess="fileSuccess"></FileUploader>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" @click="handlerModal" data-dismiss="modal">Cerrar</button>
                                </div>

                            </div>
                        </div>
                    </div>
            </div>
                <form id="formData" @submit="save" method="post">
                    <div class="main-box">
                        <div class="head-client">
                            <div class="d-flex justify-content-center align-items-center g-10">
                                <div @click="goBack"  class="anchordArrow cursor-pointer">
                                    <i class="back fa fa-arrow-circle-left"></i>
                                </div>
                                <h2 class="titulo m-0" v-if="!edit && !agg">
                                    Cliente
                                </h2>
                                <h2 v-if="edit" class="titulo m-0">
                                    Edición de cliente
                                </h2>
                                <h2 v-if="agg" class="titulo m-0">
                                    Nuevo cliente
                                </h2>
                            </div>
                            <div class="button-group">
                                <div v-if="!edit && !agg" @click="edit=!edit" class="btn btn-success">
                                    <i class="fa-solid fa-pen-to-square"></i> Editar
                                </div>
                                <button v-if="edit || agg" type="button" class="btn btn-success" @click="save">
                                    Guardar
                                </button>
                                <a class="btn btn-primary text-truncate text-nowrap" href="/client/0" v-if="client.nombres !== '' && !agg">
                                <i class="fa fa-plus"></i> Nuevo cliente
                                </a>
                            </div>
                        </div>
                                <div class="content-box">

                                    <div class="box-left">
                                        <div class="imgBox">
                                            <img
                                            :width="180"
                                            :height="180"
                                            :src="seleccionarImagen(client.extradata)"
                                            alt="Foto de usuario por defecto"
                                            class="user-default"
                                            >
                                            <div v-if="agg || edit && validarImagen(client.extradata)" class="addImg">
                                                <div title="Cambiar foto" class="p-3 text-primary cursor-pointer imgbtn" @click="handlerModal(typesModals.ADDIMG)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 19 19" version="1.1">
                                                    <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <g id="Rounded" transform="translate(-681.000000, -2682.000000)">
                                                            <g id="Image" transform="translate(100.000000, 2626.000000)">
                                                                <g id="-Round-/-Image-/-add_photo_alternate" transform="translate(578.000000, 54.000000)">
                                                                    <g>
                                                                        <polygon id="Path" points="0 0 24 0 24 24 0 24"/>
                                                                        <path d="M21.02,5 L19,5 L19,2.98 C19,2.44 18.56,2 18.02,2 L17.99,2 C17.44,2 17,2.44 17,2.98 L17,5 L14.99,5 C14.45,5 14.01,5.44 14,5.98 L14,6.01 C14,6.56 14.44,7 14.99,7 L17,7 L17,9.01 C17,9.55 17.44,10 17.99,9.99 L18.02,9.99 C18.56,9.99 19,9.55 19,9.01 L19,7 L21.02,7 C21.56,7 22,6.56 22,6.02 L22,5.98 C22,5.44 21.56,5 21.02,5 Z M16,9.01 L16,8 L14.99,8 C14.46,8 13.96,7.79 13.58,7.42 C13.21,7.04 13,6.54 13,5.98 C13,5.62 13.1,5.29 13.27,5 L5,5 C3.9,5 3,5.9 3,7 L3,19 C3,20.1 3.9,21 5,21 L17,21 C18.1,21 19,20.1 19,19 L19,10.72 C18.7,10.89 18.36,11 17.98,11 C16.89,10.99 16,10.1 16,9.01 Z M15.96,19 L6,19 C5.59,19 5.35,18.53 5.6,18.2 L7.58,15.57 C7.79,15.29 8.2,15.31 8.4,15.59 L10,18 L12.61,14.52 C12.81,14.26 13.2,14.25 13.4,14.51 L16.35,18.19 C16.61,18.52 16.38,19 15.96,19 Z" id="🔹-Icon-Color" fill="#0ca7e0"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div title="Eliminar foto" class="p-3 text-danger cursor-pointer imgbtn" @click="deleteClientImg">
                                                    <i class="fa-solid fa-trash-can"></i>
                                                </div>

                                            </div>
                                            <div v-if="agg || edit && !validarImagen(client.extradata)" class="addImg cursor-pointer" title="Agregar foto" @click="handlerModal(typesModals.ADDIMG)">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 19 19" version="1.1">
                                                <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="Rounded" transform="translate(-681.000000, -2682.000000)">
                                                        <g id="Image" transform="translate(100.000000, 2626.000000)">
                                                            <g id="-Round-/-Image-/-add_photo_alternate" transform="translate(578.000000, 54.000000)">
                                                                <g>
                                                                    <polygon id="Path" points="0 0 24 0 24 24 0 24"/>
                                                                    <path d="M21.02,5 L19,5 L19,2.98 C19,2.44 18.56,2 18.02,2 L17.99,2 C17.44,2 17,2.44 17,2.98 L17,5 L14.99,5 C14.45,5 14.01,5.44 14,5.98 L14,6.01 C14,6.56 14.44,7 14.99,7 L17,7 L17,9.01 C17,9.55 17.44,10 17.99,9.99 L18.02,9.99 C18.56,9.99 19,9.55 19,9.01 L19,7 L21.02,7 C21.56,7 22,6.56 22,6.02 L22,5.98 C22,5.44 21.56,5 21.02,5 Z M16,9.01 L16,8 L14.99,8 C14.46,8 13.96,7.79 13.58,7.42 C13.21,7.04 13,6.54 13,5.98 C13,5.62 13.1,5.29 13.27,5 L5,5 C3.9,5 3,5.9 3,7 L3,19 C3,20.1 3.9,21 5,21 L17,21 C18.1,21 19,20.1 19,19 L19,10.72 C18.7,10.89 18.36,11 17.98,11 C16.89,10.99 16,10.1 16,9.01 Z M15.96,19 L6,19 C5.59,19 5.35,18.53 5.6,18.2 L7.58,15.57 C7.79,15.29 8.2,15.31 8.4,15.59 L10,18 L12.61,14.52 C12.81,14.26 13.2,14.25 13.4,14.51 L16.35,18.19 C16.61,18.52 16.38,19 15.96,19 Z" id="🔹-Icon-Color" fill="#0ca7e0"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>


                                            <div>

                                            </div>
                                        </div>
                                        <hr>
                                        <h3
                                        v-if="!agg && !edit"
                                        class="w-100 text-center text-break mt-2">{{ formatName(client.nombres + ' ' + client.apellidos) }}</h3>
                                        <p
                                        v-if="!agg && !edit"
                                        class="w-100 text-center text-break">{{ client.email }}</p>

                                        <div v-if="agg || edit" class="row">
                                            <div class="form-group col-12">
                                                <label class=""><small v-if="agg || edit" class="text-danger">
                                                *</small>Nombres</label>
                                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.nombres"/>
                                            </div>
                                            <div class="form-group col-12">
                                                <label class=""><small v-if="agg || edit" class="text-danger">
                                                *</small>Apellidos</label>
                                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.apellidos"/>
                                            </div>
                                            <div class="form-group col-12">
                                                <label class="">Correo electrónico</label>
                                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.email"/>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="box-right scroll-area">
                                        <div class="row w-100">
                                            <div class="col-12 d-flex flex-column mt-2 mb-4">
                                                <h4 class="font-weight-bolder text-primary text-truncate text-nowrap">Datos personales </h4>
                                                <small v-if="agg || edit" class="text-danger">
                                                    Los campos con * son obligatorios
                                                </small>
                                            </div>
                                            <hr>
                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                <label>Fecha de nacimiento</label>
                                                <input type="date" class="form-control" placeholder="Escribe aquí" name="fechaNacimiento" :disabled="!edit && !agg" v-model="client.fechaNacimiento"/>
                                            </div>
                                            <div class="form-group col-12 col-md-4">
                                                <label class=""><small v-if="agg || edit" class="text-danger">
                                                *</small>Género</label>
                                                <select class="form-control" v-model="client.genero" :disabled="!edit && !agg">
                                                    <option value="M">Masculino</option>
                                                    <option value="F">Femenino</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                <label class="text-truncate text-nowrap">DPI o Pasaporte</label>
                                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.cui_passport" :disabled="!edit && !agg"/>
                                            </div>
                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                <label class="text-truncate text-nowrap">No. de expediente clinico fisico</label>
                                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.cui_passport" :disabled="!edit && !agg"/>
                                            </div>
                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                <label class="text-truncate text-nowrap">Teléfono de contacto</label>
                                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.telefono" :disabled="!edit && !agg"/>
                                            </div>

                                            <div class="form-group col-12 col-md-4">
                                                <label class="">NIT</label>
                                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.taxId" :disabled="!edit && !agg"/>
                                            </div>
                                            <div class="form-group col-12 col-md-4">
                                                <label class="">Grupo étnico</label>
                                                <select class="form-control" v-model="client.extradata.grupo_etnico" :disabled="!edit && !agg">
                                                    <option value="indigena">Indígena</option>
                                                    <option value="no_indigena">No indígena</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-12 col-md-4">
                                                <label class="">Lugar de residencia</label>
                                                <input type="text" class="form-control" placeholder="Nombre de la ciudad o pueblo donde vive..." v-model="client.extradata.residencia" :disabled="!edit && !agg"/>
                                            </div>
                                            <div class="form-group col-12 col-md-4">
                                                <label class="">Dirección</label>
                                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.direccion" :disabled="!edit && !agg"/>
                                            </div>
                                            <h4 v-if="!agg" class="col-12 font-weight-bolder text-primary mt-1 mb-3 text-truncate text-nowrap">Datos adicionales</h4>
                                            <hr>
                                            <div v-if="!agg" class="col-12 options-group">
                                                <div @click="handlerModal(typesModals.FACTURAS)" class="btn btn-primary w-25 opt text-truncate text-nowrap">
                                                    Facturas emitidas
                                                </div>
                                                <div @click="handlerModal(typesModals.HISTORIAL)" class="btn btn-primary w-25 opt text-truncate text-nowrap">
                                                    Historial
                                                </div>
                                                <div @click="handlerModal(typesModals.ADJUNTOS)" class="btn btn-primary w-25 opt text-truncate text-nowrap">
                                                    Archivos adjuntos
                                                </div>
                                                <div @click="handlerModal(typesModals.CITAS)" class="btn btn-primary w-25 opt text-truncate text-nowrap">
                                                    Citas anteriores
                                                </div>
                                            </div>

                                            <div class="row w-100 mt-2 p-3">
                                                <div class="form-group col-12">
                                                    <label class="">Notas</label>
                                                    <textarea class="form-control nota-texta" v-model="client.notas" :disabled="!edit && !agg"></textarea>
                                                </div>
                                            </div>

                                            <div v-if="(AuthGetUserInfo.layout === 'medico') && client.mandated" class="col-12 d-flex flex-column mt-2 mb-4">
                                                <h4 class="font-weight-bolder text-primary text-truncate text-nowrap">Datos del encargado responsable </h4>
                                            </div>

                                            <div v-if="(AuthGetUserInfo.layout === 'medico')" class="row w-100 p-3">
                                                <template v-if="client.mandated">
                                                    <div v-if="client.mandated.nombres" class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Nombres</label>
                                                        <input type="text" class="form-control" v-model="client.mandated.nombres" placeholder="Nombres del encargado" :disabled="!edit && !agg"/>
                                                    </div>
                                                    </div>
                                                <div v-if="client.mandated.apellidos" class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Apellidos</label>
                                                        <input type="text" class="form-control" v-model="client.mandated.apellidos" placeholder="Apellidos del encargado" :disabled="!edit && !agg"/>
                                                    </div>
                                                </div>
                                                <div v-if="client.mandated.documento" class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Documento de identificación</label>
                                                        <input type="text" class="form-control" v-model="client.mandated.documento" placeholder="Documento del encargado" :disabled="!edit && !agg"/>
                                                    </div>
                                                </div>
                                                <div v-if="client.mandated.email" class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Correo del encargado</label>
                                                        <input type="text" class="form-control" v-model="client.mandated.email" placeholder="Documento del encargado" :disabled="!edit && !agg"/>
                                                    </div>
                                                </div>
                                                <div v-if="client.mandated.genero" class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label class="mr-sm-2">Género</label>
                                                        <select class="form-control" v-model="client.mandated.genero" :disabled="!edit && !agg">
                                                            <option value="M">
                                                            Masculino
                                                            </option>
                                                            <option value="F">
                                                            Femenino
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div v-if="client.mandated.parentesco" class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Parentesco</label>
                                                        <input type="text" class="form-control" v-model="client.mandated.parentesco" placeholder="Parentesco con del encargado con el paciente" :disabled="!edit && !agg"/>
                                                    </div>
                                                </div>
                                                <div v-if="client.mandated.telefono" class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Número de teléfono</label>
                                                        <input type="text" class="form-control" v-model="client.mandated.telefono" placeholder="Telefono del encargado" :disabled="!edit && !agg"/>
                                                    </div>
                                                </div>
                                                <div v-if="client.mandated.ubicacion" class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Ubicación</label>
                                                        <input type="text" class="form-control" v-model="client.mandated.ubicacion" placeholder="Pais, ciudad y otros datos de ubicación" :disabled="!edit && !agg"/>
                                                    </div>
                                                </div>
                                                <div v-if="client.mandated.direccion" class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Dirección</label>
                                                        <input type="text" class="form-control" v-model="client.mandated.direccion" placeholder="Dirección del encargado" :disabled="!edit && !agg"/>
                                                </div>
                                                </div>
                                                </template>
                                                <template v-else>
                                                    <div v-if="edit" class="form-group col-12 row px-3">
                                                        <div v-if="!addEncargado" class="btn btn-primary col-12 col-md-6 mx-3" @click="addEncargado = true">
                                                            <i class="fa-solid fa-square-plus" ></i>
                                                            Agregar un encargado responsable
                                                        </div>
                                                        <div class="form-group col-12" v-if="addEncargado && !createMandated">
                                                            <label>Encargado</label>
                                                            <multiselect v-model="encargado"
                                                                        :options="encargadosList"
                                                                        track-by="id"
                                                                        label="nombres"
                                                                        :close-on-select="true"
                                                                        :clear-on-select="true"
                                                                        :preserve-search="true"
                                                                        placeholder="Búscar encargado existente por nombre, apellidos o documento."
                                                                        :multiple="false"
                                                                        :internal-search="false"
                                                                        @search-change="getMandated">
                                                            </multiselect>
                                                        </div>
                                                        <template v-if="addEncargado && createMandated">
                                                            <small class="text-danger col-12 my-2">
                                                            Los campos con * son obligatorios</small>
                                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                            <div class="form-group">
                                                                <label><small class="text-danger">*</small>Nombres</label>
                                                                <input type="text" class="form-control" v-model="encargado.nombres" placeholder="Nombres del encargado" :disabled="!edit && !agg"/>
                                                            </div>
                                                            </div>
                                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                                <div class="form-group">
                                                                    <label>Apellidos</label>
                                                                    <input type="text" class="form-control" v-model="encargado.apellidos" placeholder="Apellidos del encargado" :disabled="!edit && !agg"/>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                                <div class="form-group">
                                                                    <label><small class="text-danger">*</small>Documento de identificación</label>
                                                                    <input type="text" class="form-control" v-model="encargado.documento" placeholder="Documento del encargado" :disabled="!edit && !agg"/>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                                <div class="form-group">
                                                                    <label>Correo del encargado</label>
                                                                    <input type="text" class="form-control" v-model="encargado.email" placeholder="Documento del encargado" :disabled="!edit && !agg"/>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">Género</label>
                                                                    <select class="form-control" v-model="encargado.genero" :disabled="!edit && !agg">
                                                                        <option value="M">
                                                                        Masculino
                                                                        </option>
                                                                        <option value="F">
                                                                        Femenino
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                                <div class="form-group">
                                                                    <label><small class="text-danger">*</small>Parentesco</label>
                                                                    <input type="text" class="form-control" v-model="encargado.parentesco" placeholder="Parentesco con del encargado con el paciente" :disabled="!edit && !agg"/>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                                <div class="form-group">
                                                                    <label>Número de teléfono</label>
                                                                    <input type="text" class="form-control" v-model="encargado.telefono" placeholder="Telefono del encargado" :disabled="!edit && !agg"/>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                                <div class="form-group">
                                                                    <label>Ubicación</label>
                                                                    <input type="text" class="form-control" v-model="encargado.ubicacion" placeholder="Pais, ciudad y otros datos de ubicación" :disabled="!edit && !agg"/>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                                <div class="form-group">
                                                                    <label>Dirección</label>
                                                                    <input type="text" class="form-control" v-model="encargado.direccion" placeholder="Dirección del encargado" :disabled="!edit && !agg"/>
                                                            </div>
                                                            </div>
                                                        </template>
                                                        <div class="col-12 options-group jus">
                                                            <div v-if="addEncargado && !createMandated" class="btn btn-success  w-25"  @click="onSelectMandated">
                                                                <i class="fa-solid fa-check"></i>
                                                                Seleccionar
                                                            </div>
                                                            <div v-if="addEncargado && createMandated" class="btn btn-success  w-25"  @click="onCreateNewMandated">
                                                                <i class="fa-solid fa-check"></i>
                                                                Crear
                                                            </div>
                                                            <div v-if="addEncargado" class="btn btn-primary  w-25" @click="handleNewMandated">
                                                                <template v-if="!createMandated">
                                                                    <i class="fa-solid fa-square-plus" ></i>
                                                                    Crear nuevo
                                                                </template>
                                                                <template v-else>
                                                                    <i class="fa-solid fa-arrow-left"></i>
                                                                    Volver al selector
                                                                </template>
                                                            </div>
                                                            <div v-if="addEncargado" class="btn btn-danger  w-25"   @click="addEncargado = false">
                                                                    Cancelar
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                            </div>

                                            <div v-if="(AuthGetUserInfo.layout === 'medico')  && client.extradata.emergencia" class="col-12 d-flex flex-column mt-2 mb-4">
                                                <h4 class="font-weight-bolder text-primary text-truncate text-nowrap">Datos del contacto de emergencia</h4>
                                            </div>

                                            <div class="row w-100 p-3">
                                                <template v-if="(AuthGetUserInfo.layout === 'medico')  && client.extradata.emergencia">
                                                    <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Nombres</label>
                                                        <input type="text" class="form-control" v-model="client.extradata.emergencia.nombres" placeholder="Nombres del contacto de emergencia" :disabled="!edit && !agg"/>
                                                    </div>
                                                </div>
                                                <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Apellidos</label>
                                                        <input type="text" class="form-control" v-model="client.extradata.emergencia.apellidos" placeholder="Apellidos del contacto de emergencia" :disabled="!edit && !agg"/>
                                                    </div>
                                                </div>

                                                <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Parentesco</label>
                                                        <input type="text" class="form-control" v-model="client.extradata.emergencia.parentesco" placeholder="Parentesco con del contacto de emergencia" :disabled="!edit && !agg"/>
                                                    </div>
                                                </div>
                                                <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Número de teléfono</label>
                                                        <input type="text" class="form-control" v-model="client.extradata.emergencia.telefono" placeholder="Telefono del contacto de emergencia" :disabled="!edit && !agg"/>
                                                    </div>
                                                </div>
                                                <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Ubicación</label>
                                                        <input type="text" class="form-control" v-model="client.extradata.emergencia.ubicacion" placeholder="Pais, ciudad y otros datos de ubicación del contacto de emergencia" :disabled="!edit && !agg"/>
                                                    </div>
                                                </div>
                                                <div class="form-group col-12 col-md-4 text-truncate text-nowrap">
                                                    <div class="form-group">
                                                        <label>Dirección</label>
                                                        <input type="text" class="form-control" v-model="client.extradata.emergencia.direccion" placeholder="Dirección del contacto de emergencia" :disabled="!edit && !agg"/>
                                                </div>
                                                </div>
                                                </template>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                    </div>
                </form>
            </div>
    </div>
</layout>
</template>

<style>
.imgBox{
    position: relative;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    overflow: hidden;
}

.imgBox img{
    object-fit: cover;
}

.addImg{
    opacity: 0;
}
.imgbtn:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.imgBox:hover .addImg{
    font-size: 25px;
    opacity: 1;
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(26, 26, 26, 0.171);
    display: flex;
    justify-content: center;
    align-items: center;
    top:0px;
    left:0px;
}

.tile {
    background-color: #3498db; /* Color de fondo */
    color: #ffffff; /* Color del texto */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tile:hover {
    background-color: #2980b9; /* Color de fondo al pasar el ratón */
}

/* Estilo específico para el tema moderno de splitpanes */
.modern-theme {
    border: none;
    background-color: transparent;
}


</style>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import {DatePicker} from 'v-calendar';
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect';
import FileUploader from 'src/components/files/FileUploader';
import Box from '/src/modules/medico/views/fichas/Box.vue';

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import user from '/src/assets/Images/user.png'
import { updateDayOfYear } from "@quasar/quasar-ui-qcalendar";

export default {
    components: {
        Layout,
        DatePicker,
        Splitpanes,
        Pane,
        FileUploader,
        Multiselect,
        Box
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive'
        }),
    },
    data() {
        return {
            userDefault: user,
            id: 0,
            edit:false,
            agg: false,
            addEncargado:false,
            createMandated:false,
            horizontal: false,
            firstSplitter: false,
            reloadFiles:false,
            uploadFile:false,
            uploadOrderInfo:{},
            uploadFileExtradata:{
                orderId: 0,
                category: 'O',
            },
            categoryList:[
                {label:'Historial', value: 'H'},
                {label:'Laboratorio', value: 'L'},
                {label:'Imagen', value: 'I'},
                {label:'Otros', value: 'O'}
            ],
            modalType: 0,
            encargadosList:[{
                nombres:"Ingrese datos los un encargado..."
            }],
            encargado: {    
                nombres:"Ingrese datos los un encargado..."
            },

            client: {
                id: 0,
                taxId: '',
                email: '',
                nombres: '',
                apellidos: '',
                telefono: '',
                direccion: '',
                fechaNacimiento: '',
                cui_passport: '',
                genero: '',
                mandated: null,
                mandated_id: 0,
                extradata:{
                    foto: {
                        imgUrl: '',
                        nombre: '',
                        fileType:'',
                        hashName: '',
                    },
                    emergencia: {
                        nombres: '',
                        apellidos: '',
                        parentesco: '',
                        telefono: '',
                        ubicacion: '',
                        direccion:  '',
                    },
                    grupo_etnico: '',
                    residencia: ''
                }
            },
            typesModals: {
                    NONE: 0,
                    CLIENTE: 1,
                    FACTURAS: 2,
                    HISTORIAL: 3,
                    ADJUNTOS: 4,
                    CITAS: 5,
                    ADDIMG: 6
            },
            history:[],
            diagnosticos:[],
            tratamientos:[],
            adjuntos:[],
            clienteAdjuntos:[],
            uploadClientFile:false,
            uploadClientFileExtradata:{
                clienteId: 0,
                category: 'O',
            },
            facturas:[],
            citas:[],

            imageUploadExtraDataForm: {
                clientId: 0,
            },

        };
    },
    watch: {
        encargado: function(newValue) {
         
    },
    },
    mounted() {
        this.id = this.$route.params.id;
        this.imageUploadExtraDataForm.clientId = this.$route.params.id;
        this.getClient();
        if(this.$route.params.id == 0){
            this.agg=true;
        }
        this.getClientAttachFiles();
    },
    methods: {
        fileSuccessUploadFile(data){
            this.uploadFile=false; 
            this.uploadOrderInfo={};
            this.uploadFileExtradata.category='O';
            this.uploadFileExtradata.orderId=0;
            this.reloadFiles=true;
            this.getHistory();
        },
        fileSuccessUploadClientFile(data){
            this.uploadClientFile=false;
            this.uploadClientFileExtradata.category='O';
            this.uploadClientFileExtradata.clienteId=0;
            this.reloadFiles=true;
            this.getClientAttachFiles();
        },
        handlerUploadFileSection(item=false){
            if(!item){
                this.uploadFile=false; 
                this.uploadOrderInfo={};
                this.uploadFileExtradata.category='O';
                return;
            }
            this.uploadFile=true;
            this.uploadOrderInfo.motivo= item.motivo;
            this.uploadOrderInfo.fecha= item.fecha;
            this.uploadFileExtradata.orderId=item.ordenId;
        },
        handlerUploadClientFileSection(){
            this.uploadClientFile=!this.uploadClientFile;
            if(this.uploadClientFile){
                console.log( this.client.id );
                this.uploadClientFileExtradata.clienteId=this.client.id;
            }else{
                this.uploadClientFileExtradata.category='O';
            }
        },
        getMandated(query){
            const self = this;
            if (query.length > 0) {
                self.queryData = JSON.parse(JSON.stringify(query));

                API.send('GET', '/mandated/searchByData/' + query, {},
                    function (res) {
                        self.encargadosList = res.data;
                       
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);  
                    });
            }
        },
        handleNewMandated(){
            if(!this.createMandated){
                this.createMandated = true;
                this.encargado={
                    user_id: this.AuthGetUserInfo.id,
                    nombres: "",
                    apellidos: "",
                    documento: "",
                    parentesco: "",
                    genero: "",
                    telefono: "",
                    email: "",
                    ubicacion: "",
                    direccion: "",
                }
                }else{
                    this.createMandated = false;
                    this.encargado={    
                        nombres:"Ingrese datos los un encargado..."
                    }
                }
        },
        onCreateNewMandated(){
            const self = this;

            if(!self.createMandated && self.encargado.nombres === '' || self.encargado.apellidos === '' || self.encargado.documento === '') {
                return API.showErrorNotify('Ingrese los campos obligatorios');
            }
            self.encargado.cliente_id=self.client.id;

            API.send('POST', 'mandated/create', self.encargado, function (response) {
                if (typeof response.status !== 'undefined') {
                    API.showSuccessAlert(response.msg);
                    self.client.mandated=response.data;
                    self.client.mandated_id=response.data.id;
                    self.handleNewMandated();
                } else {
                    API.showErrorNotify('Error al guardar el encargado, por favor intente de nuevo');
                }
            },
            function (response) {
                API.showErrorNotify(response.msg);
            });
        },
        onSelectMandated(){
            if(this.encargado){
                if(this.encargado.nombres !== "Ingrese datos los un encargado..."){
                    this.client.mandated= this.encargado;
                    this.client.mandated_id = this.encargado.id;
                    this.encargado= {    
                        nombres:"Ingrese datos los un encargado..."
                    };
                    this.save();
                }else{
                    API.showErrorNotify("Seleccione un encargado valido o creé uno nuevo."); 
                }
            }else{
                API.showErrorNotify("Seleccione un encargado valido o creé uno nuevo.");   
            }
        },
        seleccionarImagen(extradata) {

            if (extradata && typeof extradata === 'object') {
            if (extradata.foto && typeof extradata.foto === 'object') {
                if (extradata.foto.imgUrl && extradata.foto.imgUrl !== '') {
                return extradata.foto.imgUrl;
                }
            }
            }
            return this.userDefault;
        },
        validarImagen(extradata) {
            if (extradata && typeof extradata === 'object') {
            if (extradata.foto && typeof extradata.foto === 'object') {
                if (extradata.foto.imgUrl && extradata.foto.imgUrl !== '') {
                return true;
                }
            }
            }
            return false;
        },
        fileSuccess(data){
            this.client.extradata.foto = {
                imgUrl: data.imgUrl,
                nombre: data.nombre,
                fileType:data.fileType,
                hashName: data.hashName,
            }
            this.handlerModal(this.typesModals.NONE);
        },
        goBack () {
          window.history.back();
        },
        formatName(nombre) {
            if(nombre && nombre != ''){
                const palabras = nombre.split(" ");
                const nombreFormateado = palabras.map((palabra) => palabra && palabra[0].toUpperCase() + palabra.slice(1).toLowerCase()).join(" ");
                return nombreFormateado;
            }else{
                return 'Sin Nombre'
            }

        },
        handlerModal(type){
            switch (type) {
                case this.typesModals.CLIENTE:
                    this.modalType=this.typesModals.CLIENTE;
                    break;
                case this.typesModals.FACTURAS:
                    this.modalType=this.typesModals.FACTURAS;
                    break;
                case this.typesModals.HISTORIAL:
                    this.modalType=this.typesModals.HISTORIAL;
                    break;
                case this.typesModals.ADJUNTOS:
                    this.modalType=this.typesModals.ADJUNTOS;
                    break;
                case this.typesModals.CITAS:
                    this.modalType=this.typesModals.CITAS;
                    break;
                case this.typesModals.ADDIMG:
                    this.modalType=this.typesModals.ADDIMG;
                    break;
                default:
                this.modalType=this.typesModals.NONE;
                this.handlerUploadFileSection();
                    break;
            }
        },
        getClient() {
            const self = this;
            if (parseInt(this.$route.params.id) > 0) {

                API.send('GET', 'clients/search/' + this.$route.params.id, {},
                    function (response) {

                        if (response.status) {
                            if(typeof response.data[0] !== 'undefined'){
                               self.client =response.data[0]
                               self.setDefaultExtraData(self.client.extradata);

                            }
                            self.getHistory();
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
        },
        setDefaultExtraData(extradata) {
            //TODO: HAY QUE BORRAR ENCARGADO DE LA EXTRUCTURA DE EXTRADATA PARA EVITAR CODIGO EN DESUSO
        let extradataObj;
        if (!extradata) {
            this.client.extradata = {
            foto: { imgUrl: '', nombre: '', fileType: '', hashName: '' },
            emergencia: { nombres: '', apellidos: '', parentesco: '', telefono: '', ubicacion: '', direccion: '' },
            grupo_etnico: '',
            residencia: ''
            };
        } else {
            extradataObj = JSON.parse(extradata);
            if (!extradataObj.foto) {
            extradataObj.foto = { imgUrl: '', nombre: '', fileType: '', hashName: '' };
            }
            if (!extradataObj.emergencia) {
            extradataObj.emergencia = { nombres: '', apellidos: '', parentesco: '', telefono: '', ubicacion: '', direccion: '' };
            }
            if (!extradataObj.grupo_etnico) {
            extradataObj.grupo_etnico = '';
            }
            if (!extradataObj.residencia) {
            extradataObj.residencia = '';
            }
            this.client.extradata = extradataObj;
        }
        },
        deleteClientImg() {
            const self = this;
            if (parseInt(this.$route.params.id) > 0) {

                API.send('DELETE', 'clients/img/delete/' + this.$route.params.id, {},
                    function (response) {

                        if (response.status) {
                            self.client.extradata.foto =  {
                                imgUrl:'',
                                nombre:'',
                                fileType:'',
                                hashName:'',
                            };
                            self.getClient();
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
        },
        save() {
            const self = this;

            if(self.client.nombres === '' || self.client.apellidos === '' || self.client.genero === '' || !self.client.nombres || !self.client.apellidos  || !self.client.genero) {
                 return API.showErrorNotify('Ingrese los campos obligatorios');
            }

            self.client.extradata && (self.client.extradata = JSON.stringify(self.client.extradata));

            API.send('POST', 'clients/edit/', self.client, function (response) {
                if (typeof response.status !== 'undefined') {
                    API.showSuccessAlert(response.msg);
                    if (parseInt(self.id) === 0) {
                        window.location.href = '/client/' + response.data.id;
                    }
                    self.setDefaultExtraData(self.client.extradata);
                    self.edit = false;
                    self.agg = false;

                } else {
                    API.showErrorNotify('Error al guardar cliente, por favor intente de nuevo');
                }
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })
        },
        getHistory(){
            const self = this;
            self.reloadFiles=true;
            if (typeof self.GetMedicoFichaActive.cliente !== 'undefined' || self.client.id !== 0) {
                API.send('POST', 'medico/getHistory', {
                        'clienteId': self.client.id,
                    }, function (response) {
                        self.history = response.data;
                        self.setModalData();
                        self.reloadFiles=false;
                    },
                    function (response) {
                        
                });
            }
        },
        getClientAttachFiles(){
            const self = this;
            if (parseInt(this.$route.params.id) > 0) {
                API.send('POST', 'orders/metadata-attachments-by-client/get', {
                        'clienteId': self.$route.params.id,
                    }, function (response) {
                        self.clienteAdjuntos = Object.values(response.data.metadata.attach).flatMap(category => Object.values(category));
                        self.reloadFiles=false;
                    },
                    function (response) {
                       
                });
            }
        },
        setModalData(){
            const self = this;

            //SI HAY HISTORIAL SETEAMOS INFO EN TABLAS
            if (self.history.length>0) {
                
                self.history.forEach(function (item) {


                    //SI HAY METADATA VERIFICAMOS Y FORMATEAMOS DIAGNOSTICOS Y TRATAMIENTOS
                    if(item.data.metadata){
                        let newDiagnostico= {
                        fecha: item.fechaReserva
                    }

                    // SI HAY DIAGNOSTICOS CIE10 FORMATEO Y GUARDO
                        if (item.data.metadata.impresion_clinica_cie10) {
                        newDiagnostico.impresion_clinica_cie10=[];
                        Object.keys(item.data.metadata.impresion_clinica_cie10).map(function (cie) {
                            newDiagnostico.impresion_clinica_cie10.push({
                                id: cie.id,
                                nombre: cie.nombre,
                            })
                        });
                    }

                    //SI HAY OTROS DIAGNOSTICOS LOS GUARDO
                    if (item.data.metadata.impresion_clinica) {
                        newDiagnostico.impresion_clinica=item.data.metadata.impresion_clinica.observaciones;
                    }

                    //SI HUBO DIAGNOSTICOS LOS GUARDO 1 A 1
                    if(item.data.metadata.impresion_clinica_cie10 || item.data.metadata.impresion_clinica){
                    self.diagnosticos.push(newDiagnostico);
                    }

                    let newTratamiento= {
                        fecha: item.fechaReserva
                    }

                    // SIY HAY TRATAMIENTOS LOS GUARDO
                    if(item.data.metadata.tratamiento){
                        newTratamiento.observacion=item.data.metadata.tratamiento.observaciones;
                    }

                    // SI HAY RECETAS LAS FORMATEO Y GUARDO
                    if(item.data.metadata.tratamiento_receta){
                        newTratamiento.recetas=[];
                        Object.keys(item.data.metadata.tratamiento_receta).map(function (index) {
                            let receta = item.data.metadata.tratamiento_receta[index]
                            if(receta.detail){   
                                let medTemp= 'Sin medicamento';   
                                if(receta.detail.medicamento){
                                    medTemp=receta.detail.medicamento;
                                }
                                newTratamiento.recetas.push(
                                    {
                                        medicamento: medTemp,
                                        cantidad: receta.detail.cantidad || null,
                                        cada: receta.detail.cada || null,
                                        cadaTiempo: receta.detail.cadaTiempo ||null,
                                        durante: receta.detail.durante || null,
                                        unidad: receta.detail.unidad || null,
                                        recomendaciones: receta.detail.recomendaciones || null,
                                        administracion: receta.detail.administracion || null,
                                        fechaInicio: receta.detail.fechaInicio || null,
                                        observaciones: receta.detail.observaciones || null,
    
                                    }
                                );
                            }
                        });
                    }

                    //SI HAY TRATAMIENTOS O RECETA GUARDO 1 A 1
                    if(item.data.metadata.tratamiento || item.data.metadata.tratamiento_receta){
                    self.tratamientos.push(newTratamiento);
                    }

                    if(item.data.metadata.attach){
                        let meta;
                        if(item.data.metadata.preclinica_motivo.motivo_principal_consulta)
                        meta= item.data.metadata.preclinica_motivo.motivo_principal_consulta;

                        let attach={
                            fecha: item.fechaReserva,
                            ordenId: item.data.orden.id,
                            motivo: meta
                        }

                        if (item.data.metadata.attach.H) {
                            attach.historial=[];
                            Object.keys(item.data.metadata.attach.H).forEach(function (i) {
                                let file = item.data.metadata.attach.H[i]
                                attach.historial.push({
                                    nombre: file.detail.name || null,
                                    url: file.urlTMP.fileUrl || null,
                                    fecha: file.createdAt || null
                                })
                            });
                        }
                        if (item.data.metadata.attach.L) {
                            attach.laboratorios=[];
                            Object.keys(item.data.metadata.attach.L).forEach(function (i) {
                                let file = item.data.metadata.attach.L[i]
                                attach.laboratorios.push({
                                    nombre: file.detail.name || null,
                                    url: file.urlTMP.fileUrl || null,
                                    fecha: file.createdAt || null
                                })
                            });
                        }
                        if (item.data.metadata.attach.I) {
                            attach.imagenes=[];
                            Object.keys(item.data.metadata.attach.I).forEach(function (i) {
                                let file = item.data.metadata.attach.I[i]
                                attach.imagenes.push({
                                    nombre: file.detail.name || null,
                                    url: file.urlTMP.fileUrl || null,
                                    fecha: file.createdAt || null
                                })
                            });
                        }
                        if (item.data.metadata.attach.D) {
                            attach.dermatologia=[];
                            Object.keys(item.data.metadata.attach.D).forEach(function (i) {
                                let file = item.data.metadata.attach.D[i]
                                attach.dermatologia.push({
                                    nombre: file.detail.name || null,
                                    url: file.urlTMP.fileUrl || null,
                                    fecha: file.createdAt || null
                                })
                            });
                        }
                        if (item.data.metadata.attach.O) {
                            attach.otros=[];
                            Object.keys(item.data.metadata.attach.O).forEach(function (i) {
                                let file = item.data.metadata.attach.O[i]
                                attach.otros.push({
                                    nombre: file.detail.name || null,
                                    url: file.urlTMP.fileUrl || null,
                                    fecha: file.createdAt || null
                                })
                            });
                        }
                    //Guardo los adjuntos formateados 1 a 1
                    self.adjuntos.push(attach);
                    }
                    }

                    // SI HAY ORDEN GUARDO LOS DATOS NECESARIOS PARA VER LA FACTURA
                    if(item.data.orden){
                        let orden = {
                            fechaHora: item.data.orden.createdAt,
                            id: item.data.orden.id,
                            codigo: item.data.orden.codigoOrden,
                            monto: item.data.orden.monto,
                            moneda: item.data.orden.monedaCode,
                            estado: item.data.orden.estado
                        }
                        self.facturas.push(orden);
                    }

                    //SI HAY CITA GUARDO LOS DATOS NECESARIOS NOMAS...
                    if(item.data.reserva){
                        let reserva = {
                            fecha: item.data.reserva.fechaReservada,
                            hora: item.data.reserva.horaInicio + ' ' + item.data.reserva.horaFin,
                            id: item.data.reserva.id,
                            motivo: item.data.reserva.entrecitaMotivo
                        }
                        self.citas.push(reserva);
                    }


                });
            }
        }
    },
};
</script>

<style scoped>
.user-default{
    width: 180px;
    height: 180px;
}
.main-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    width: 100%;
    height: calc( 100vh - 20px );
}

.back{
    font-size: 30px;
    padding: 10px;
}

.anchordArrow{
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-group{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
}
.head-client{
 background-color: #ffffff;
 display: flex; 
 justify-content: space-between;
 align-items: center;
 padding: 10px 15px;
 width: 100%;
 height: 70px;
 border-radius: 15px;
 border: 1px solid rgba(123, 123, 127, 0.1);
 box-shadow: rgba(149, 157, 165, 0.14) 0px 2px 15px
}

.content-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5%;
    width: 100%;
    height: calc( 100vh - 100px );
}

.box-left{
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    width: 25%;
    min-height: 85%;
    border-radius: 15px;
    border: 1px solid rgba(123, 123, 127, 0.1);
    box-shadow: rgba(149, 157, 165, 0.14) 0px 2px 15px
}

.box-right{
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    width: 75%;
    min-height: 85%;
    border-radius: 15px;
    border: 1px solid rgba(123, 123, 127, 0.1);
    box-shadow: rgba(149, 157, 165, 0.14) 0px 2px 15px
}

.show-data{
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid rgba(103, 103, 103, 0.637);
}

.nota-texta{
    resize: none;
    height: 60px;
}

.options-group{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
}

.datosFila{
    transition:0.2s
}
.datosFila:hover{
    transform: scale(1.01);
}
@media screen and (max-width: 900px) {
    .user-default{
    width: 100px;
    height: 100px;
    }
}

@media screen and (max-width: 768px) {

    .user-default{
    width: 70px;
    height: 70px;
    }
    .main-box{
    gap: 5%;
    height: fit-content;
    }

    .content-box{
    width: 100%;
    flex-direction: column;
    height: fit-content;
    }

    .box-left{
        width: 100%;
    }

    .box-right{
        width: 100%;
    }

}
@media screen and (max-width: 576px) {

    .head-client{
        padding: 5px 7px;
    }
    .back{
        font-size: 20px;
        padding: 5px;
    }

    .titulo{
        font-size: 20px;
    }

    .main-box{
        padding:0px;
    }
    .container{
        padding:0px;
    }
    .content-body{
        padding:0px;
    }
}

</style>
