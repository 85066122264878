<template>
    <layout>
        <div class="content-body orderDetails">
            <div class="mb-80">
                <div class="container h-100">
                    <form v-if="order.id" id="formData" @submit="save" method="post">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="card profile_card">
                                    <div :class="{'card-header bg-danger text-light py-2 px-4': !order.montoPagado, 'card-header bg-success text-light py-2 px-4': order.montoPagado}">
                                        <div class="card-title text-light mt-1">
                                            <router-link to="/orders">
                                                <i class="back fa fa-arrow-circle-left text-light"></i>
                                            </router-link>
                                            Código: <b class="text-light">{{ order.codigoOrden }}</b>
                                        </div>
                                        <div class="mt-1 font-weight-bold text-light text-right">
                                            <div v-if="order.montoPagado">
                                                Orden pagada
                                            </div>
                                            <div v-else>
                                                <span v-if="order.estado !== 'cancelada'">Pendiente de pago</span>
                                                <span v-else>Orden cancelada</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="media">
                                            <div class="media-body">
                                                <div class="font-weight-bold mb-3 text-center">
                                                    <span v-if="order.estado === 'creada'" class="text-warning">Orden creada en espera de cambio de estado</span>
                                                    <span v-if="order.estado === 'en_progreso'" class="text-info">Orden en progreso</span>
                                                    <span v-if="order.estado === 'finalizada'"  class="text-success">Orden finalizada</span>
                                                    <span v-if="order.estado === 'cancelada'"  class="text-danger">Orden cancelada</span>
                                                </div>
                                                <hr>
                                                <h4 class="mb-2" v-if="order.cliente && order.cliente !== '0'"><i class="fa fa-user-circle mr-3 text-primary"></i>{{ order.cliente.nombre }}</h4>
                                                <h4 class="mb-2" v-else><i class="fa fa-user-circle mr-3 text-primary"></i>{{ order.clientName }}</h4>
                                                <p class="mb-1"><span><i class="fa fa-phone mr-2"></i></span>
                                                    {{ (typeof order.telefonoContacto !== 'undefined' && order.telefonoContacto !== '0' && order.telefonoContacto !== '') ? order.telefonoContacto : 'Sin teléfono' }}
                                                </p>
                                                <p class="mb-1">
                                                    <span><i class="fa fa-address-card mr-2"></i></span>
                                                    {{ (typeof order.taxCode !== 'undefined' && order.taxCode !== '0') ? order.taxCode : 'Sin NIT' }}
                                                </p>
                                                <p class="mb-1">
                                                    <span><i class="fa fa-calendar mr-2"></i></span>
                                                    {{ dayjs(order.createdAt).subtract(6, 'hour').format('DD-MM-YYYY H:mm') }}
                                                </p>

                                                <p class="mb-1">
                                                    <span>Dirección de envío: </span>
                                                    <span class="font-italic text-dark">{{ (typeof order.direccionEnvio !== 'undefined' && order.direccionEnvio !== '0' && order.direccionEnvio) ? order.direccionEnvio : 'Sin dirección de envío' }}</span>
                                                </p>
                                                <p class="mb-1">
                                                    <span>Dirección de facturación: </span>
                                                    <span class="font-italic text-dark">{{ (typeof order.direccionFactura !== 'undefined' && order.direccionFactura !== '0' && order.direccionFactura) ? order.direccionFactura : 'Sin dirección de facturación' }}</span>
                                                </p>
                                                <p class="mb-1">
                                                    <span>Observaciones: </span>
                                                    <span class="font-italic text-dark">{{ (typeof order.observaciones !== 'undefined' && order.observaciones !== '0' && order.observaciones !== '' && order.observaciones) ? order.observaciones : 'Sin observaciones' }}</span>
                                                </p>
                                                <span class="text-success cursor-pointer hover-underline" @click="printInvoice('ordnPrnt')">
                                                    <i class="la la-file-pdf "></i>
                                                    Imprimir comprobante de orden
                                                </span>
                                                <div v-if="Access('orders-pos-fel')">
                                                    <hr>
                                                    <div class="text-left" v-if="!notEmptyObject(order.fcUID.link)">
                                                        <h5>Facturación</h5>
                                                        <div @click="previewInvoice = !previewInvoice" class="btn btn-outline-info btn-sm mt-3 mb-2">
                                                            <i :class="previewInvoice ? 'la la-arrow-circle-up mr-2': 'la la-file-invoice mr-2'" class=""></i>
                                                            <span v-text="previewInvoice ? 'Cancelar': 'Generar factura'"></span>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <h6>Factura</h6>
                                                        <div class="row mx-auto pb-3">
                                                            <div class="col-12 p-1">
                                                                <ul class="list-group">
                                                                    <li>Numero: {{ order.fcUID.numero || '' }}</li>
                                                                    <li>Serie: {{ order.fcUID.serie || '' }}</li>
                                                                    <li>Autorizacón: {{ order.fcUID.fcUID || '' }}</li>
                                                                    <li>Fecha autorización: {{ dayjs(order.fcDate).format('DD-MM-YYYY H:mm:ss') || '' }}</li>
                                                                    <li>
                                                                        <a target="_blank" :href="order.fcUID.link" class="text-primary">
                                                                            <i class="la la-external-link-alt"></i> Validar factura
                                                                        </a>
                                                                        <span class="text-success cursor-pointer hover-underline ml-3" @click="printInvoice('fcUID')">
                                                                            <i class="la la-file-pdf "></i>
                                                                            Imprimir
                                                                        </span>

                                                                        <span v-if="!notEmptyObject(order.acUID) && notEmptyObject(order.fcUID.link)" class="text-danger cursor-pointer hover-underline ml-3" @click="previewAnulacion = true;">
                                                                            <i class="la la-ban"></i> Anular
                                                                        </span>
                                                                        <span v-if="!notEmptyObject(order.ncUID) && notEmptyObject(order.fcUID.link)" class="text-danger cursor-pointer hover-underline ml-3" @click="previewNc = true;">
                                                                            <i class="la la-ban"></i> Emitir nota de crédito
                                                                        </span>
                                                                        <span v-if="!notEmptyObject(order.naUID) && notEmptyObject(order.fcUID.link)" class="text-danger cursor-pointer hover-underline ml-3" @click="previewNa = true;">
                                                                            <i class="la la-ban"></i> Emitir nota de abono
                                                                        </span>
                                                                        <span v-if="notEmptyObject(order.acUID)" class="text-warning cursor-pointer hover-underline ml-3" @click="viewAnulacion = !viewAnulacion;">
                                                                            <i :class="(viewAnulacion)?'la la-ban':'la la-search-plus'"></i>{{ (viewAnulacion) ? ' Cerrar' : ' Ver anulación' }}
                                                                        </span>
                                                                        <span v-if="notEmptyObject(order.ncUID)" class="text-warning cursor-pointer hover-underline ml-3" @click="viewNc = !viewNc;">
                                                                            <i :class="(viewNc)?'la la-ban':'la la-search-plus'"></i>{{ (viewNc) ? ' Cerrar' : ' Ver nota de crédito' }}
                                                                        </span>
                                                                        <span v-if="notEmptyObject(order.naUID)" class="text-warning cursor-pointer hover-underline ml-3" @click="viewNa = !viewNa;">
                                                                            <i :class="(viewNa)?'la la-ban':'la la-search-plus'"></i>{{ (viewNa) ? ' Cerrar' : ' Ver nota de abono' }}
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div v-if="viewAnulacion" class="row mx-auto pb-3">
                                                            <div class="col-12 p-0">
                                                                <h6>Anulación</h6>
                                                            </div>
                                                            <div class="col-12 p-1">
                                                                <ul class="list-group">
                                                                    <li>Numero: {{ order.acUID.numero || '' }}</li>
                                                                    <li>Serie: {{ order.acUID.serie || '' }}</li>
                                                                    <li>Autorizacón: {{ order.acUID.acUID || '' }}</li>
                                                                    <li>Fecha autorización: {{ order.acDate || '' }}</li>
                                                                </ul>

                                                            </div>
                                                        </div>
                                                        <div v-if="viewNa" class="row mx-auto pb-3">
                                                            <div class="col-12 p-0">
                                                                <h6>Nota de abono</h6>
                                                            </div>
                                                            <div class="col-12 p-1">
                                                                <ul class="list-group">
                                                                    <li>Numero: {{ order.naUID.numero || '' }}</li>
                                                                    <li>Serie: {{ order.naUID.serie || '' }}</li>
                                                                    <li>Autorizacón: {{ order.naUID.naUID || '' }}</li>
                                                                    <li>Fecha autorización: {{ order.naDate || '' }}</li>
                                                                </ul>

                                                            </div>
                                                        </div>
                                                        <div v-if="viewNc" class="row mx-auto pb-3">
                                                            <div class="col-12 p-0">
                                                                <h6>Nota de crédito</h6>
                                                            </div>
                                                            <div class="col-12 p-1">
                                                                <ul class="list-group">
                                                                    <li>Numero: {{ order.ncUID.numero || '' }}</li>
                                                                    <li>Serie: {{ order.ncUID.serie || '' }}</li>
                                                                    <li>Autorizacón: {{ order.ncUID.ncUID || '' }}</li>
                                                                    <li>Fecha autorización: {{ order.ncDate || '' }}</li>
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="row" v-if="previewInvoice">
                                                    <div class="col-lg-6 col-sm-12">
                                                        Nit
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend cursor-pointer" @click="order.taxCode = 'CF'">
                                                                <span class="input-group-text" id="inputGroup-sizing-default">C/F</span>
                                                            </div>
                                                            <input type="text" v-on:keyup.enter="consultaNit" class="form-control" placeholder="NIT" v-model="order.taxCode" aria-label="NIT" aria-describedby="inputGroup-sizing-default">
                                                            <div class="input-group-append cursor-pointer" @click="consultaNit()">
                                                                <span class="input-group-text" id="inputGroup-sizing-default"><i class="la la-search"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-12 d-flex justify-content-center align-items-center text-center mb-3">
                                                        <label class="toggle p-1">
                                                            <input class="toggle-checkbox" type="checkbox" v-model="isCambiaria"/>
                                                            <div class="toggle-switch"></div>
                                                            <span class="toggle-label">
                                                            Factura Cambiaria
                                                            <i class="fa fa-question-circle" v-tooltip="'Se emitirá una factura tributaria cambiaria correspondiente al monto de esta orden'"></i>
                                                        </span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 mb-3">
                                                        Nombre
                                                        <input class="form-control" v-model="order.clientName" placeholder="Nombre Cliente">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 mb-3">
                                                        Dirección
                                                        <input class="form-control" v-model="order.direccionFactura" placeholder="Dirección factura">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 mb-3">
                                                        Correo
                                                        <input class="form-control" v-model="order.correoContacto" placeholder="Email">
                                                    </div>

                                                    <div class="form-group col-lg-12 col-sm-12" v-if="Object.keys(locaciones).length > 0">
                                                        Seleccione la locación
                                                        <multiselect v-model="locacionId"
                                                                     :options="locaciones"
                                                                     track-by="id" label="nombre"
                                                                     :close-on-select="true"
                                                                     :clear-on-select="true"
                                                                     :preserve-search="true"
                                                                     placeholder="Selecciona una locación para facturar"
                                                                     :multiple="false"
                                                                     :internal-search="true"
                                                        >
                                                        </multiselect>

                                                    </div>

                                                    <div class="form-group col-lg-12 col-sm-12" v-if="fel.length > 0">
                                                        Seleccione emisor de factura
                                                        <multiselect v-model="felSelected"
                                                                    :options="fel"
                                                                    track-by="id" label="NombreComercial"
                                                                    :close-on-select="true"
                                                                    :clear-on-select="true"
                                                                    :preserve-search="true"
                                                                    placeholder="Selecciona un emisor"
                                                                    :multiple="false"
                                                                    :internal-search="true"
                                                        >
                                                        </multiselect>
                                                    </div>
                                                    <div class="form-group col-lg-6 col-sm-12">
                                                        Fecha de emisión
                                                        <DatePicker v-model="invoiceDate" mode="date" :attributes="facturacionCalendar" :max-date="lastDayOfMonth" :min-date="minDateInvoice">
                                                            <template v-slot="{ inputValue, inputEvents }">
                                                                <input
                                                                    class="form-control"
                                                                    :value="inputValue"
                                                                    v-on="inputEvents"
                                                                />
                                                            </template>
                                                        </DatePicker>
                                                    </div>
                                                    <div class="col-12" v-if="order.taxCode">
                                                        <div class="text-right">
                                                            <div @click="createInvoice()" class="btn btn-outline-success btn-sm mt-3">
                                                                <i class="la la-file-invoice mr-2"></i>
                                                                Emitir por {{ formatPrice(order.monto || 0) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="previewAnulacion">
                                                    <div class="row mx-auto pb-3">
                                                        <div class="col-12 p-1">
                                                            <div class="input-group mb-3">
                                                                <input placeholder="Motivo de anulación" type="text" class="form-control" v-model="motivo" aria-label="Motivo" aria-describedby="inputGroup-sizing-default">
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text" id="inputGroup-sizing-default"><i class="fa fa-comment"></i></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 p-1" v-if="order.taxCode">
                                                            <div class="text-right">
                                                                <div @click="previewAnulacion = false" class="btn btn-outline-danger btn-sm mt-3 mr-2">
                                                                    Cancelar
                                                                </div>
                                                                <div @click="anularInvoice()" class="btn btn-outline-success btn-sm mt-3">
                                                                    <i class="la la-file-invoice mr-2"></i>
                                                                    Anular por {{ formatPrice(order.monto || 0) }}
                                                                </div>
                                                                <div v-if="notEmptyObject(order.naUID)" @click="anularNotaAbono()" class="btn btn-outline-success btn-sm mt-3">
                                                                    <i class="la la-file-invoice mr-2"></i>
                                                                    Anular NOTA de abono {{ formatPrice(order.monto || 0) }}
                                                                </div>
                                                                <div v-if="notEmptyObject(order.ncUID)" @click="anularNotaCredito()" class="btn btn-outline-success btn-sm mt-3">
                                                                    <i class="la la-file-invoice mr-2"></i>
                                                                    Anular NOTA de crédito {{ formatPrice(order.monto || 0) }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="previewNc">
                                                    <div class="row mx-auto pb-3">
                                                        <div class="col-12 p-1" v-if="order.taxCode">
                                                            <div class="text-right">
                                                                <div @click="previewNc = false" class="btn btn-outline-danger btn-sm mt-3 mr-2">
                                                                    Cancelar
                                                                </div>
                                                                <div @click="ncInvoice()" class="btn btn-outline-success btn-sm mt-3">
                                                                    <i class="la la-file-invoice mr-2"></i>
                                                                    Nota de crédito por {{ formatPrice(order.monto || 0) }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="previewNa">
                                                    <div class="row mx-auto pb-3">
                                                        <div class="col-12 p-1" v-if="order.taxCode">
                                                            <div class="text-right">
                                                                <div @click="previewNa = false" class="btn btn-outline-danger btn-sm mt-3 mr-2">
                                                                    Cancelar
                                                                </div>
                                                                <div @click="naInvoice()" class="btn btn-outline-success btn-sm mt-3">
                                                                    <i class="la la-file-invoice mr-2"></i>
                                                                    Nota de abono por {{ formatPrice(order.monto || 0) }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="invoiceShow" class="orderInvoiceShow">
                                                    <div class="orderInvoiceShowContainer">
                                                        <div class="text-center">
                                                            <div class="btn-group">
                                                                <div class="btn btn-outline-light btn-outline-light btn-sm text-dark" @click="finalPrint">
                                                                    <i class="la la-print la-2x"></i>
                                                                </div>
                                                                <div class="btn btn-outline-light btn-outline-light btn-sm  text-success" @click="consultaPDF('fcUID')">
                                                                    <i class="la la-download la-2x"></i>
                                                                </div>
                                                                <div class="btn btn-outline-light btn-outline-light btn-sm text-danger" @click="invoiceShow = false;">
                                                                    <i class="fa fa-close fa-2x"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <vue-pdf-embed :source="invoiceBlob" ref="pdfRef"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label>
                                                Estado de orden
                                            </label>
                                            <div class="input-group">
                                                <select v-model="estatus" class="form-control">
                                                    <option value="creada">Creada</option>
                                                    <option value="en_progreso">En progreso</option>
                                                    <option value="finalizada">Finalizada</option>
                                                    <option value="cancelada">Cancelada</option>
                                                </select>
                                                <div class="input-group-append">
                                                    <button class="btn btn-dark btn-sm" type="button" @click="saveStatus">Actualizar</button>
                                                </div>
                                            </div>
                                            <div v-if="AuthGetUserInfo.configuration">
                                                <div v-if="typeof AuthGetUserInfo.configuration.order !== 'undefined' && (AuthGetUserInfo.configuration.order.verificarOrdenAlFinalizar && estatus === 'finalizada' && estatusGuardado !== 'finalizada')" class="mt-2">
                                                    <label>Ingrese el código de orden para confirmar</label>
                                                    <input type="text" class="form-control" v-model="confirmationOrdercode">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header border-0">
                                        <h4 class="card-title">Detalle</h4>
                                    </div>
                                    <div class="card-body pt-0">
                                        <div class="transaction-table">
                                            <div class="table-responsive">
                                                <table class="table mb-0">
                                                    <tbody v-if="order.detalle">
                                                    <tr v-for="item in order.detalle" :key="item.id">
                                                        <td class="pt-2">
                                                            <i class="fas fa-check-circle"></i>
                                                        </td>
                                                        <td v-if="item.producto">
                                                            <i class="cc"></i>
                                                            {{ item.producto.nombre }} {{ item.descripcion }}
                                                        </td>
                                                        <td v-else><i class="cc"></i> {{ item.descripcion }}</td>
                                                        <td class="text-primary">{{ item.cantidad }} x</td>
                                                        <td>
                                                            <span v-if="parseInt(item.precioOnSale) === 1">{{ Numbro((item.precioOnSale) ? item.precioOnSale : 0).formatCurrency() }}</span>
                                                            <span v-else>{{ Numbro((item.precio) ? item.precio : 0).formatCurrency() }}</span>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <h5 class="text-right pt-1">Total: {{ Numbro((order.monto) ? order.monto : 0).formatCurrency() }}</h5>
                                        <div class="text-right pt-1">
                                            <h5 class="text-warning">
                                                Descuento: {{ Numbro((order.descuento) ? order.descuento : 0).formatCurrency() }}
                                            </h5>
                                        </div>
                                        <div class="text-right pt-1">
                                            <h5 :class="{'text-success': order.montoPagado > 0, 'text-danger': parseFloat(order.montoPagado) === 0, 'text-danger': !order.montoPagado}">
                                                Pagado: {{ Numbro((order.montoPagado) ? order.montoPagado : 0).formatCurrency() }}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 fullHeight">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <div class="mb-3">
                                                <h6>Acciones</h6>
                                            </div>
                                            <div class="orderDetailsActions">
                                                <!--<div @click="showCard('entregas')" class="text-center" :class="{ 'active' : entregas }">
                                                    Entregas
                                                    <i class="mdi mdi-truck-delivery"></i>
                                                </div>-->
                                                <div @click="showCard('pagos')" class="text-center" :class="{ 'active' : pagos }">
                                                    Pagos
                                                    <i class="mdi mdi-cash"></i>
                                                </div>
                                                <div @click="showCard('formularios')" class="text-center" :class="{ 'active' : formularios }">
                                                    Formularios
                                                    <i class="mdi mdi-file"></i>
                                                </div>
                                                <!--<div @click="showCard('facturar')" class="text-center" :class="{ 'active' : facturar }">
                                                    Facturar
                                                    <i class="mdi mdi-receipt"></i>
                                                </div>-->
                                                <div @click="showCard('historial')" class="text-center" :class="{ 'active' : historial }">
                                                    Historial
                                                    <i class="mdi mdi-history"></i>
                                                </div>
                                                <div @click="showCard('virtual')" class="text-center" :class="{ 'active' : virtual }">
                                                    Citas
                                                    <i class="mdi mdi-calendar"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="actionContainer">
                                            <div v-if="pagos">
                                                <div v-if="notEmptyObject(order.pagos)">
                                                    <div>
                                                        <h6>Pagos efectuados a esta orden</h6>
                                                        <hr>
                                                        <div class="d-none d-sm-block">
                                                            <div class="row text-dark font-weight-bold">
                                                                <div class="col-12 col-sm-3">
                                                                    Tipo
                                                                </div>
                                                                <div class="col-12 col-sm-3">
                                                                    Autorización
                                                                </div>
                                                                <div class="col-12 col-sm-3">
                                                                    Fecha
                                                                </div>
                                                                <div class="col-12 col-sm-2">
                                                                    Monto
                                                                </div>
                                                                <div class="col-12 col-sm-1">
                                                                    <i class="fas fa-bars"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr class="d-none d-sm-block">
                                                        <div v-for="item in order.pagos" :key="item.id">
                                                            <div :class="{'row text-danger': (item.type === 'card' && (!item.autorizacion || item.autorizacion === '0')), 'row': (item.type !== 'card' || (item.type === 'card' && item.autorizacion))}">
                                                                <div class="col-12 col-sm-3">
                                                                    <div v-if="item.type === 'card'">Tarjeta
                                                                        <span class="d-inline-block d-sm-none font-weight-bold">Tipo:</span><span v-if="item.cuotas > 0">({{ item.cuotas }}) cuotas</span>
                                                                    </div>
                                                                    <div v-if="item.type === 'cash'">
                                                                        <span class="d-inline-block d-sm-none font-weight-bold">Tipo:</span>
                                                                        Efectivo
                                                                    </div>
                                                                    <div v-if="item.type === 'pos'">
                                                                        <span class="d-inline-block d-sm-none font-weight-bold">Tipo:</span>Tarjeta
                                                                    </div>
                                                                    <div v-if="item.type === 'paywallet_card_validation'">
                                                                        <span class="d-inline-block d-sm-none font-weight-bold">Tipo:</span>
                                                                        PayG-Wallet
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-3">
                                                                    <span class="d-inline-block d-sm-none font-weight-bold">Autorización:</span>
                                                                    {{ (item.autorizacion && item.autorizacion !== '0'&& item.autorizacion !== '') ? item.autorizacion : ((item.type === 'card') ? "Pago no autorizado": 'No aplica') }}
                                                                </div>
                                                                <div class="col-12 col-sm-3">
                                                                    <span class="d-inline-block d-sm-none font-weight-bold">Fecha:</span>
                                                                    {{ formatDate(item.createdAt) }}
                                                                </div>
                                                                <div class="col-12 col-sm-2">
                                                                    <span class="d-inline-block d-sm-none font-weight-bold">Monto:</span>
                                                                    {{ formatPrice(item.monto) }}
                                                                </div>
                                                                <div class="col-12 col-sm-1">
                                                                    <div @click="printPayment(item.id)" v-tooltip="'Permite imprimir el voucher de pago'" v-if="((item.type === 'card' && item.autorizacion && item.autorizacion !== '0') || item.type !== 'card') && !item.anuladoDate">
                                                                        <i class="fa fa-print text-primary cursor-pointer"></i>
                                                                    </div>
                                                                    <div @click="cancelPayment(item.id)" v-tooltip="'Anular pago'" v-if="((item.type === 'card' && item.autorizacion && item.autorizacion !== '0') || item.type !== 'card') && !item.anuladoDate">
                                                                        <i class="fa fa-ban text-danger cursor-pointer"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 text-dark mt-2" v-if="item.type === 'card'">
                                                                    <div>
                                                                        <b>Tarjeta:</b> **** **** **** {{item.cardLastDigits}}
                                                                    </div>
                                                                    <div v-if="!Tools.isEmptyString(item.errorLog)">
                                                                        <b>Errores:</b> {{item.errorLog}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="item.anuladoDate" class="row text-danger">
                                                                <div class="col-12 col-sm-3">
                                                                    Anulado
                                                                </div>
                                                                <div class="col-12 col-sm-3 text-uppercase">
                                                                    {{ item.anuladoReferencia }}
                                                                </div>
                                                                <div class="col-12 col-sm-3">
                                                                    {{ formatDate(item.anuladoDate) }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    No se encontraron pagos asociados a esta orden
                                                </div>
                                                <div v-if="(parseFloat(order.montoPagado) < parseFloat(order.monto) || !order.montoPagado)">
                                                    <div>
                                                        <hr>
                                                        <div class="row">
                                                            <div class="col-12 col-sm-7">
                                                                <h5>Pagar mediante enlace</h5>
                                                                <div class="mb-1" v-if="parseInt(order.pagado) === 0">
                                                                    {{ (typeof order.correoContacto !== 'undefined' && order.correoContacto !== '0') ? order.correoContacto : 'Sin correo electrónico' }}
                                                                    <div class="my-2">
                                                                        <div class="mb-3 mt-4 text-center">
                                                                            <a class="text-primary" :href="'https://' + order.tokenUrl" target="_blank">https://{{ order.tokenUrl }}</a>
                                                                        </div>
                                                                        <div class="input-group mb-4 mt-2">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"><i class="fa fa-envelope mr-2"></i></span>
                                                                            </div>
                                                                            <input type="text" class="form-control" placeholder="Correo electrónico" v-model="resendEmail">
                                                                        </div>
                                                                        <div class="mt-3 text-center text-sm-left">
                                                                            <button class="btn btn-outline-dark btn-sm" type="button" @click="resendOrderPaylink">Enviar enlace</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-5 text-center">
                                                                <div>
                                                                    <img :src="order.payLinkQR" style="max-width: 100%"/>
                                                                </div>
                                                                <div class="text-muted text-small">
                                                                    Escanea el código QR con tu teléfono para pagar de forma rápida y fácil
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="text-right">
                                                        <div @click="loadToPOS" class="btn btn-outline-success btn-sm mt-1">
                                                            <i class="la la-money-bill"></i>
                                                            Ver orden en punto de venta
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-5">
                                                    <h6>Códigos de descuento aplicados</h6>
                                                    <ul class="list-group">
                                                        <li class="list-group-item" v-for="item in order.codigos_promocionales" :key="'descuento'+item.id">
                                                            <b>Código:</b> {{item.shortCode}} - <b>Monto:</b> {{item.amount}}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div v-if="historial">
                                                <div class="row d-flex justify-content-center">
                                                    <div class="col-12">
                                                        <h5 class="card-title mb-3">Historial de orden</h5>
                                                        <div class="scroll-area">
                                                            <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                                                <div v-for="item in order.logs" class="vertical-timeline-item vertical-timeline-element" :key="item.id">
                                                                    <div>
                                                                        <span class="vertical-timeline-element-icon bounce-in"> <i class="badge badge-dot badge-dot-xl badge-success"></i> </span>
                                                                        <div class="vertical-timeline-element-content bounce-in">
                                                                            <span class="timeline-title">
                                                                                {{ item.nota }} <a v-if="!Tools.isEmptyString(item.nota_data)" @click="item.showLogDetails = true" class="text-success text-decoration-underline text-small ml-2">Ver Detalles</a>
                                                                            </span>
                                                                             <span class="vertical-timeline-element-date">{{ item.createdAt }}</span>
                                                                            <div v-if="typeof item.showLogDetails !== 'undefined' && item.showLogDetails">
                                                                                <div class="text-right">
                                                                                    <i class="fa fa-times-circle-o text-danger" @click="item.showLogDetails = false"></i>
                                                                                </div>
                                                                                <div>
                                                                                    {{ item.nota_data }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--<div class="form-group">
                                                            <label class="mr-sm-2">Comentario</label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend">
                                                                    <label class="input-group-text"><i class="fa fa-comment-o"></i></label>
                                                                </div>
                                                                <textarea type="text" class="form-control error" placeholder="Escribe tu comentario" name="comment" id="comment" aria-invalid="true"/>
                                                                <div class="input-group-append">
                                                                    <label class="input-group-text"><i class="fa fa-send"></i></label>
                                                                </div>
                                                            </div>
                                                            <label class="toggle">
                                                                <input class="toggle-checkbox" type="checkbox"/>
                                                                <div class="toggle-switch"></div>
                                                                <span class="toggle-label">notificar al cliente</span>
                                                            </label>
                                                        </div>-->
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="facturar">
                                                <div class="scroll-area">
                                                    <div class="container">
                                                        <div class="row d-flex justify-content-center">
                                                            <div class="col-12">
                                                                <div class="card">
                                                                    <div class="text-left logo p-2 px-5">
                                                                        <img src="https://i.imgur.com/2zDU056.png" width="50">
                                                                    </div>
                                                                    <div class="invoice p-5">
                                                                        <h5>Your order Confirmed!</h5>
                                                                        <span class="font-weight-bold d-block mt-4">Hello, Chris</span>
                                                                        <span>You order has been confirmed and will be shipped in next two days!</span>
                                                                        <div class="payment border-top mt-3 mb-3 border-bottom table-responsive">
                                                                            <table class="table table-borderless">
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="py-2">
                                                                                            <span class="d-block text-muted">Order Date</span>
                                                                                            <span>12 Jan,2022</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="py-2">
                                                                                            <span class="d-block text-muted">Order No</span>
                                                                                            <span>MT12332345</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="py-2">
                                                                                            <span class="d-block text-muted">Payment</span>
                                                                                            <span><img src="https://img.icons8.com/color/48/000000/mastercard.png" width="20"/></span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="py-2">
                                                                                            <span class="d-block text-muted">Shiping Address</span>
                                                                                            <span>414 Advert Avenue, NY,USA</span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div class="product border-bottom table-responsive">
                                                                            <table class="table table-borderless">
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td width="20%">
                                                                                        <img src="https://i.imgur.com/u11K1qd.jpg" width="90">
                                                                                    </td>
                                                                                    <td width="60%">
                                                                                        <span class="font-weight-bold">Men's Sports cap</span>
                                                                                        <div class="product-qty">
                                                                                            <span class="d-block">Quantity:1</span>
                                                                                            <span>Color:Dark</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td width="20%">
                                                                                        <div class="text-right">
                                                                                            <span class="font-weight-bold">$67.50</span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td width="20%">
                                                                                        <img src="https://i.imgur.com/SmBOua9.jpg" width="70">
                                                                                    </td>
                                                                                    <td width="60%">
                                                                                        <span class="font-weight-bold">Men's Collar T-shirt</span>
                                                                                        <div class="product-qty">
                                                                                            <span class="d-block">Quantity:1</span>
                                                                                            <span>Color:Orange</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td width="20%">
                                                                                        <div class="text-right">
                                                                                            <span class="font-weight-bold">$77.50</span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div class="row d-flex justify-content-end">
                                                                            <div class="col-md-5">
                                                                                <table class="table table-borderless">
                                                                                    <tbody class="totals">
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div class="text-left">
                                                                                                <span class="text-muted">Subtotal</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div class="text-right">
                                                                                                <span>$168.50</span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div class="text-left">
                                                                                                <span class="text-muted">Shipping Fee</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div class="text-right">
                                                                                                <span>$22</span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div class="text-left">
                                                                                                <span class="text-muted">Tax Fee</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div class="text-right">
                                                                                                <span>$7.65</span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div class="text-left">
                                                                                                <span class="text-muted">Discount</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div class="text-right">
                                                                                                <span class="text-success">$168.50</span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr class="border-top border-bottom">
                                                                                        <td>
                                                                                            <div class="text-left">
                                                                                                <span class="font-weight-bold">Subtotal</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div class="text-right">
                                                                                                <span class="font-weight-bold">$238.50</span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <p>We will be sending shipping confirmation email
                                                                            when the item shipped
                                                                            successfully!
                                                                        </p>
                                                                        <p class="font-weight-bold mb-0">Thanks for shopping
                                                                            with us!
                                                                        </p>
                                                                        <span>Nike Team</span>
                                                                    </div>
                                                                    <div class="d-flex justify-content-between footer p-3">
                                                                        <span>Need Help? visit our <a href="#"> help center</a></span>
                                                                        <span>12 June, 2020</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="entregas">
                                                <div v-if="notEmptyObject(order.pagos)">
                                                    <div class="scroll-area">
                                                        <span>Pagos</span>
                                                        <div class="d-flex justify-content-between my-auto" v-for="item in order.pagos" :key="item.id">
                                                            <div class="text-left">
                                                                <p class="mb-1">autorización&nbsp;</p>
                                                                <h5>{{
                                                                        item.autorizacion
                                                                    }}
                                                                </h5>
                                                            </div>
                                                            <div class="text-center">
                                                                <p class="mb-1"> fecha</p>
                                                                <h5>
                                                                    {{ formatDate(item.createdAt) }}
                                                                </h5>
                                                            </div>
                                                            <div class="text-right">
                                                                <p class="mb-1"> monto</p>
                                                                <h5>&nbsp;GTQ
                                                                    {{
                                                                        formatPrice(item.monto)
                                                                    }}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    No se encontraron pagos asociados a esta orden
                                                    <br>
                                                    <a :href="order.tokenUrl" class="btn btn-outline-info btn-sm"><i class="la la-money-bill"></i>
                                                        Pagar</a>
                                                </div>
                                            </div>
                                            <div v-if="formularios">
                                                <div class="row d-flex justify-content-center">
                                                    <div class="col-12">
                                                        <h4 class="card-title">Formularios asociados</h4>
                                                        <hr>
                                                        <div class="scroll-area">
                                                            <div v-for="form in forms" :key="'form_' + form.id">
                                                                <div class="row mb-4" v-if="((form.type === 'orderCreate' || form.type === 'orderPay') || (form.type === 'orderPost' && estatusGuardado === 'finalizada'))">
                                                                    <div class="col-12">
                                                                        <h6 class="timeline-title bm-2">
                                                                            {{ form.descripcion }}
                                                                        </h6>
                                                                        <div class="text-right">
                                                                            <a :href="form.url" class="d-inline-block btn btn-sm btn-outline-dark mx-1" target="_blank">Editar formulario</a>
                                                                            <div @click="downloadFormsTemplates(form.token)" class="d-inline-block btn btn-sm btn-outline-dark mx-1" target="_blank">Descargar</div>
                                                                            <div @click="sendFormsTemplates(form.token)" class="d-inline-block btn btn-sm btn-outline-dark mx-1" target="_blank">Enviar</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-for="form in formsNew" :key="'form_' + form.id">
                                                                <div class="row mb-4">
                                                                    <div class="col-12">
                                                                        <h6 class="timeline-title bm-2">
                                                                            {{ form.prod }}
                                                                        </h6>
                                                                        <div class="text-right">
                                                                            <div @click="printFormsTemplates(form)" class="d-inline-block btn btn-sm btn-outline-dark mx-1" target="_blank">Imprimir</div>
                                                                            <div @click="validarAsistencia(form)" class="d-inline-block btn btn-sm btn-outline-dark mx-1" target="_blank">Marcar asistencia</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="virtual">
                                                <div class="row d-flex justify-content-center">
                                                    <div class="col-12">
                                                        <h4 class="card-title">Citas o agendamiento</h4>
                                                        <hr>
                                                        <div class="scroll-area">
                                                            <div v-for="item in reservas" :key="'reserva_' + item.id">
                                                                <div class="row mb-4">
                                                                    <div class="col-12">
                                                                        <h6 class="timeline-title bm-2">
                                                                            {{ item.nombre }} ({{ item.estado }})
                                                                        </h6>
                                                                        <div>
                                                                            <a class="text-primary text-decoration-underline" @click="$router.push('/medic/ficha/' + item.id)"><i class="mr-2 fas fa-calendar"></i>Ver detalle de cita</a>
                                                                        </div>
                                                                        <div>
                                                                            {{ item.fechaReservada }}
                                                                        </div>
                                                                        <div>
                                                                            {{ item.horaInicio }} al {{ item.horaFin }} ({{ item.duracion }})
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-body">
                                        <h6>Envíos</h6>
                                        <div class="mt-3">
                                            <div class="row">
                                                <div class="col-5 font-weight-bold">
                                                    Detalle
                                                </div>
                                                <div class="col-3 font-weight-bold">
                                                    Precio
                                                </div>
                                                <div class="col-3 font-weight-bold">
                                                    <i class="fas fa-list"></i>
                                                </div>
                                            </div>
                                            <hr class="my-2">
                                            <div v-for="item in envios" :key="item.id">
                                                <div class="row">
                                                    <div class="col-5">
                                                        <div class="font-weight-bold text-primary">
                                                            <span v-if="item.proveedorEnvio === 'estandarShipping'">Envío Estándar</span>
                                                            <span v-if="item.proveedorEnvio === 'forzaDeliveryExpress'">Forza Delivery Express</span>
                                                        </div>
                                                        <div v-if="item.tracking">
                                                            Tracking: {{ item.tracking }}
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        {{ Numbro((item.precio) ? item.precio : 0).formatCurrency() }}
                                                    </div>
                                                    <div class="col-4">
                                                        <div v-if="!item.tracking">
                                                            <a class="text-primary" @click="generarGuia(item.id)">
                                                                <i class="fas fa-cloud-upload mr-2"></i> Generar guía
                                                            </a>
                                                        </div>
                                                        <div v-else>
                                                            <div>
                                                                <a class="text-primary" @click="cancelarGuia(item.id)">
                                                                    <i class="fas fa-ban mr-2"></i> Cancelar guía
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <a class="text-primary" @click="printLabel(item)">
                                                                    <i class="fas fa-print mr-2"></i> Imprimir etiqueta
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <a class="text-primary" @click="getTracking(item.id)">
                                                                    <i class="fas fa-shipping-fast mr-2"></i> Ver Tracking
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="text-muted mt-3">
                                                            Podrás ver el tracking del pedido cuando se procese el envío
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="typeof tracking[item.id] !== 'undefined'" class="mt-2">
                                                    <div>
                                                        <div>
                                                            <span class="font-weight-bold">Origen:</span> {{ tracking[item.id].origin }}
                                                        </div>
                                                        <div>
                                                            <span class="font-weight-bold">Destino:</span> {{ tracking[item.id].destiny }}
                                                        </div>
                                                    </div>
                                                    <div v-for="(item, index) in tracking[item.id].milestones" :key="'milestone' + item.id + '-' + index">
                                                        <div>
                                                            <i class="fas fa-check mr-2"></i> {{ item.date }} - {{ item.description }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div v-else class="text-center">
                        No se encontró ninguna orden
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapGetters} from "vuex";
import {DatePicker} from 'v-calendar';
import VuePdfEmbed from 'vue-pdf-embed'
import AndroidBridge from "src/core/AndroidBridge";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
        Layout,
        DatePicker,
        VuePdfEmbed
    },
    data() {
        return {
            locaciones:{},
            locacionId:{id:0},
            BridgeAndroid: null,
            Tools: Tools,
            userConfig: {},
            forms: {},
            order: {},
            reservas: {},
            envios: {},
            tracking: {},
            pagos: true,
            formularios: false,
            entregas: false,
            historial: false,
            facturar: false,
            virtual: false,
            previewInvoice: false,
            previewAnulacion: false,
            previewNc: false,
            previewNa: false,
            viewAnulacion: false,
            viewNc: false,
            viewNa: false,
            viewInvoice: false,
            estatus: 'creada',
            estatusGuardado: '',
            confirmationOrdercode: '',
            motivo: '',
            facturaDetails: {},
            customEmailSendFactura: '',
            invoiceDate: new Date(),
            lastDayOfMonth: '',
            minDateInvoice: '',
            isCambiaria: false,
            fel:[],
            felSelected:{id:0},
            impuestos: 0,
            iva: 0,
            detalleImpuesto: [],
            facturacionCalendar: [
                {
                    dot: true,
                    key: 'today',
                    highlight: false,
                    dates: new Date(),

                }
            ],
            // reenvío de orden
            resendingOrder: false,
            resendEmail: "",
            invoiceShow: false,
            invoiceData: {},
            invoiceBlob: '',

            // voucher
            impresionVoucherUrl: '',

            // formularios nuevos
            formsNew: {}
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            Access: 'Access',
        })
    },
    mounted() {
        const self = this;
        if(this.Access('orders-pos-fel')){
            this.getLocaciones();
        }
        this.BridgeAndroid = new AndroidBridge();
        this.loadItems();
        this.lastDayOfMonth = new Date(self.invoiceDate.getFullYear(), self.invoiceDate.getMonth() + 1, 0);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 5);

        this.minDateInvoice = minDate;
    },
    watch:{
        previewInvoice: function(){
            if(this.previewInvoice){
                this.getDataFel();
            }
        }
    },
    methods: {
        dayjs,
        Numbro,
        getLocaciones(){
            const self = this;
            API.send('GET', 'locaciones/all/', {},
                function (response) {
                    if (response.status) {
                        self.locaciones = response.data;
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                });
        },
        loadItems() {
            const self = this;
            if (parseInt(this.$route.params.id) > 0) {
                API.send('GET', 'orders/generadas/' + self.$route.params.id, {},
                    function (data) {
                        if (data.status) {
                            self.order = data.data;
                            self.reservas = data.data.reservasReadable;
                            self.estatus = data.data.estado;
                            self.estatusGuardado = data.data.estado;
                            self.envios = data.data.envios;

                            // get forms
                            API.send('GET', 'formularios/responses/' + self.$route.params.id, {},
                                function (data) {
                                    if (data.status) {
                                        self.forms = data.data;
                                    }
                                },
                                function (data) {
                                    API.showErrorNotify(data.msg);
                                });

                            // get forms news
                            API.send('POST', 'formularios/get-for-order', {
                                    orderId: self.order.id
                                },
                                function (data) {
                                    if (data.status) {
                                        self.formsNew = data.data;
                                    }
                                },
                                function (data) {
                                    API.showErrorNotify(data.msg);
                                });
                        }
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    });
            }
        },
        sendFormsTemplates(token) {
            API.send('GET', 'formularios/pdf-creator/send/' + token, {},
                function (response) {
                    if (response.status) {
                        API.showSuccessNotify(response.msg);
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                });
        },
        saveStatus() {
            const self = this;

            if (Tools.check.config('order', 'verificarOrdenAlFinalizar')) {
                if (self.estatus === 'finalizada') {
                    if (String(self.confirmationOrdercode.trim()) !== String(self.order.codigoOrden.trim())) {
                        API.showErrorNotify('El código no pertenece a la orden actual');
                        return false;
                    }
                }
            }

            const changeStatus = function (refreshOnFinish) {
                API.send('PUT', 'orders/changeStatus', {
                        orderId: self.order.id,
                        status: self.estatus,
                    },
                    function (response) {
                        if (response.status) {
                            self.estatusGuardado = self.estatus;
                            self.order.estado = self.estatus;
                            API.showSuccessAlert(response.msg);
                            if (refreshOnFinish) {
                                self.loadItems();
                            }
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorAlert("Error al cancelar orden", response.msg);
                    });
            }

            if (self.estatus === 'cancelada' && self.notEmptyObject(self.order.pagos)) {
                API.showConfirm('Pagos detectados', 'Si la orden es cancelada, se anularán los pagos que se encuentren activos. Esta acción no es reversible, ¿desea continuar?', function () {
                    changeStatus(true);
                }, false, 'Cancelar orden', 'Regresar');
            }
            else {
                changeStatus(false);
            }
        },
        downloadFormsTemplates(token) {
            API.send('GET', 'formularios/pdf-creator/download/' + token, {},
                function (response) {
                    if (response.status) {
                        window.open(response.data.url);
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                });
        },
        printFormsTemplates(form) {
            const self = this;
            API.send('POST', 'formularios/ticket-printer/print-credential', {
                    token: self.order.token,
                    orderDetailId: form.id,
                    noEntrada: form.num,
                    formToken: form.ft,
                    fields: [],
                },
                function (response) {
                    if (response.status) {
                        window.open(response.data);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        validarAsistencia(form) {
            const self = this;
            API.showConfirm('Se marcará la asitencia, esta acción no se puede deshacer', '¿desea continuar?',function (){
                API.send('POST', 'formularios/verifyAssistance/verify', {
                        token: self.order.token,
                        od: form.id,
                        dn: form.num,
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessAlert(response.msg);
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            })
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            let strTime = hours + ':' + minutes + ' ' + ampm;
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + strTime;
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2)
            return val.toString()
        },
        notEmptyObject(someObject) {
            if (someObject) {
                return Object.keys(someObject).length
            }
            else {
                return false;
            }
        },
        showCard(stringToShow) {
            this.pagos = (stringToShow == 'pagos') ? true : false;
            this.entregas = (stringToShow == 'entregas') ? true : false;
            this.historial = (stringToShow == 'historial') ? true : false;
            this.facturar = (stringToShow == 'facturar') ? true : false;
            this.formularios = (stringToShow == 'formularios') ? true : false;
            this.virtual = (stringToShow == 'virtual') ? true : false;
        },
        loadToPOS() {

            this.$store.dispatch('OrderClearProducts');
            this.$store.dispatch('OrderPosClientClear');

            this.$store.dispatch('OrderPosClientSelect', {
                id: this.order.clienteId,
                email: this.order.correoContacto,
                taxId: this.order.taxCode,
                nombres: this.order.clientName,
                apellidos: '',
                telefono: this.order.telefonoContacto,
            });

            Object.entries(this.order.detalle).map(([key, val]) => {

                if (val.producto === null) {

                    const montoDirectoProduct = {
                        id: '_DIRECT_AMOUNT_',
                        nombre: 'Pago por monto directo',
                        precio: val.precio,
                        clearTotal: true,
                    };

                    this.$store.dispatch('OrderAddProductToPos', montoDirectoProduct);
                }
                else {
                    this.$store.dispatch('OrderAddProductToPos', val.producto);
                }
            })

            this.$router.push({path: '/order/edit/' + this.order.id});

        },
        checkSession(reservaId) {
            const self = this;
            // obtengo lsa categorías
            API.send('POST', 'virtualmanager/reservas/checkroom', {reservaId: reservaId},
                function (response) {
                    if (response.status) {
                        if (response.data.token) {
                            window.open('/client/profile/virtualcall/' + response.data.token);
                        }
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        getDataFel() {
        const self = this;
            API.send('GET', 'payments/fel-by-user', {},
            function (response) {
                if (response.status) {
                    const felData = response.data.map(item => {
                        const payload = JSON.parse(item.payload); // Parseo el campo payload
                        return {
                            id: item.id,
                            NITEmisor: payload.NITEmisor,
                            NombreComercial: payload.NombreComercial
                        };
                    });

                    self.fel = felData;
                } else {
                    API.showErrorNotify(response.msg);
                }
            },
            function (response) {
                API.showErrorNotify(response.msg);
            });
         },
        createInvoice() {
            const self = this;
            const dataSend = {
                id: self.$route.params.id,
                taxCode: self.order.taxCode,
                clientName: self.order.clientName,
                direccionFactura: self.order.direccionFactura,
                correoContacto: self.order.correoContacto,
                fcDate: self.invoiceDate,
                isCambiaria: self.isCambiaria,
                sucursalId: self.locacionId.id,
                felId: self.felSelected.id

            }
            // obtengo lsa categorías
            API.send('POST', 'orders/factura/crear', dataSend,
                function (response) {
                    if (response.status) {
                        self.order.fcUID = response.data;
                        self.previewInvoice = false;
                        self.viewInvoice = true;
                        API.showSuccessNotify(response.msg);
                    }
                },
                function (response) {
                    const { msg, data } = response;

                    if (!msg) {
                        API.showErrorNotify("Ha ocurrido algun error, intente nuevamente")
                        return;
                    }

                    let errorMsg = msg;

                    if (data && data.data && data.data.listado_errores && data.data.listado_errores.error) {

                        if(data.data.listado_errores.error.length > 0){ 
                        const errorMessages = data.data.listado_errores.error.map(item => 
                            `\n ->${item.desc_error} ERROR: [${item.cod_error}] `
                        );
                        errorMsg += errorMessages.join('');
                     }else if(data.data.listado_errores.error.desc_error){
                        errorMsg += `\n ->${data.data.listado_errores.error.desc_error} ERROR: [${data.data.listado_errores.error.cod_error}]`;
                     }

                    }
                    API.showErrorNotify(errorMsg);
                })
        },
        anularInvoice() {
            const self = this;
            const dataSend = {
                id: self.$route.params.id,
                motivo: self.motivo
            }

            API.showConfirm('¿Está seguro de anular el documento?', 'Esta acción no se puede revertir', function () {
                // obtengo lsa categorías
                API.send('POST', 'orders/factura/anular', dataSend,
                    function (response) {
                        if (response.status) {
                            self.order.acUID = response.data;
                            self.previewAnulacion = false;
                            self.viewAnulacion = true;
                            API.showSuccessNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }, false, 'Anular documento', 'Regresar')
        },
        anularNotaAbono() {
            const self = this;
            const dataSend = {
                id: self.$route.params.id,
                motivo: self.motivo,
                tipoDocumento: 'NABN'
            }

            API.showConfirm('¿Está seguro de anular el documento?', 'Esta acción no se puede revertir', function () {
                // obtengo lsa categorías
                API.send('POST', 'orders/factura/anular', dataSend,
                    function (response) {
                        if (response.status) {
                            self.order.acUID = response.data;
                            self.previewAnulacion = false;
                            self.viewAnulacion = true;
                            API.showSuccessNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }, false, 'Anular documento', 'Regresar')
        },
        anularNotaCredito() {
            const self = this;
            const dataSend = {
                id: self.$route.params.id,
                motivo: self.motivo,
                tipoDocumento: 'NCRE'
            }

            API.showConfirm('¿Está seguro de anular el documento?', 'Esta acción no se puede revertir', function () {
                // obtengo lsa categorías
                API.send('POST', 'orders/factura/anular', dataSend,
                    function (response) {
                        if (response.status) {
                            self.order.acUID = response.data;
                            self.previewAnulacion = false;
                            self.viewAnulacion = true;
                            API.showSuccessNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }, false, 'Anular documento', 'Regresar')
        },
        ncInvoice() {
            const self = this;
            API.showConfirm('¿Está seguro de emitir este documento?', 'Esta acción no se puede revertir', function () {

                const dataSend = {
                    id: self.$route.params.id,
                    taxCode: self.order.taxCode,
                    clientName: self.order.clientName,
                    direccionFactura: self.order.direccionFactura,
                    correoContacto: self.order.correoContacto,
                    fcDate: self.invoiceDate,
                    isCambiaria: self.isCambiaria,
                    tipo: 'NCRE'

                }
                // obtengo lsa categorías
                API.send('POST', 'orders/factura/crear', dataSend,
                    function (response) {
                        if (response.status) {
                            self.order.ncUID = response.data;
                            self.previewInvoice = false;
                            self.viewInvoice = true;
                            API.showSuccessNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }, false, 'Emitir nota de crédito', 'Regresar')
        },
        naInvoice() {
            const self = this;
            API.showConfirm('¿Está seguro de anular el documento?', 'Esta acción no se puede revertir', function () {

                const dataSend = {
                    id: self.$route.params.id,
                    taxCode: self.order.taxCode,
                    clientName: self.order.clientName,
                    direccionFactura: self.order.direccionFactura,
                    correoContacto: self.order.correoContacto,
                    fcDate: self.invoiceDate,
                    isCambiaria: self.isCambiaria,
                    tipo: 'NABN'

                }
                // obtengo lsa categorías
                API.send('POST', 'orders/factura/crear', dataSend,
                    function (response) {
                        if (response.status) {
                            self.order.naUID = response.data;
                            self.previewInvoice = false;
                            self.viewInvoice = true;
                            API.showSuccessNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }, false, 'Emitir nota de crédito', 'Regresar')
        },
        consultaNit() {
            const self = this;
            // obtengo la info del NIT
            if (self.order.taxCode !== 'CF' && self.order.taxCode !== '') {
                API.send('GET', 'orders/factura/consulta/' + self.order.taxCode, {},
                    function (response) {
                        if (response.status) {
                            if (response.data.empty === false) {
                                self.order.clientName = response.data.nombres;
                                self.order.direccionFactura = response.data.direccion;
                            }
                            else {
                                self.loadItems();

                            }
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }

        },
        consultaPDF(tipo) {
            const self = this;
            // para al final es el mismo pdf solo consulto este servicio si no tengo el blob del pdf
            if (self.invoiceBlob.length === 0) {
                API.send('GET', 'orders/factura/pdf/' + self.order.id + '/' + tipo, {},
                    function (response) {
                        if (response.status) {
                            self.downloadPDF(response.data, tipo);
                            /*
                            if (response.data.empty === false) {
                                self.order.clientName = response.data.nombres;
                                self.order.direccionFactura = response.data.direccion;
                            }
                            else {
                                self.loadItems();

                            }*/
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
            else {
                self.downloadPDF(self.invoiceBlob, tipo);

            }

        },
        downloadPDF(file, tipo) {
            const linkSource = `${file}`;
            const downloadLink = document.createElement("a");
            const fileName = this.order.codigoOrden + tipo + ".pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        resendOrderPaylink() {

            const self = this;
            if (self.resendEmail !== '') {
                API.send('POST', 'orders/resend', {
                        "id": self.order.id,
                        "email": self.resendEmail,
                        "type": "email"
                    },
                    function (response) {
                        if (response.status) {
                            self.resendingOrder = false;
                            API.showSuccessAlert(response.msg);
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
            else {
                API.showErrorNotify('Ingrese un correo electrónico válido');
            }
        },
        printInvoice(tipo) {
            const self = this;
            if (self.invoiceBlob.length === 0) {
                API.send('GET', 'orders/factura/datos/' + self.order.id + '/' + tipo, {},
                    function (response) {
                        if (response.status) {
                            self.invoiceData = response.data;
                            if (self.BridgeAndroid.isOnTopWise()) {
                                self.BridgeAndroid.PrintFactura(self.invoiceData);
                            }
                            else {
                                self.invoiceShow = true;
                                self.invoiceBlob = self.invoiceData.pdf;
                            }
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
            }
            else {
                if (self.BridgeAndroid.isOnTopWise()) {
                    self.BridgeAndroid.PrintFactura(self.invoiceData);
                }
                else {
                    self.invoiceShow = true;
                    self.invoiceBlob = self.invoiceData.pdf;
                }

            }
        },
        finalPrint() {
            const self = this;
            this.$refs.pdfRef.print(670, self.order.codigoOrden + "-fcUID.pdf");
        },

        // Envíos
        generarGuia(idEnvio) {
            const self = this;

            API.showConfirm('¿Está seguro?', 'Se generará una la guía de envío, no podrá cancelara luego de que esta sea aceptada por el proveedor de envíos', function () {
                // get forms
                API.send('POST', 'delivery/create/guide', {
                        'envioId': idEnvio
                    },
                    function (data) {
                        if (typeof data.status !== 'undefined') {
                            if (data.status) {
                                API.showSuccessAlert(data.msg);
                                self.loadItems();
                            }
                            else {
                                API.showErrorAlert(data.msg);
                            }
                        }
                        else {
                            API.showErrorAlert('Error al crear guía, por favor intente de nuevo');
                        }
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    });
            });
        },
        cancelarGuia(idEnvio) {
            const self = this;
            API.showConfirm('¿Está seguro?', 'Se cancelará la guía de envío, esta accion no se puede revertir', function () {
                // get forms
                API.send('POST', 'delivery/cancel/guide', {
                        'envioId': idEnvio
                    },
                    function (data) {
                        if (typeof data.status !== 'undefined') {
                            if (data.status) {
                                API.showSuccessAlert(data.msg);
                                self.loadItems();
                            }
                            else {
                                API.showErrorAlert(data.msg);
                            }
                        }
                        else {
                            API.showErrorAlert('Error al crear guía, por favor intente de nuevo');
                        }
                    },
                    function (data) {
                        console.log(data);
                        API.showErrorNotify(data.msg);
                    });
            })
        },
        getTracking(idEnvio) {
            const self = this;
            API.send('POST', 'delivery/tracking/guide', {
                    'envioId': idEnvio
                },
                function (response) {
                    if (typeof response.status !== 'undefined') {
                        if (response.status) {
                            API.showSuccessAlert(response.msg);
                            self.tracking[idEnvio] = response.data;
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    }
                    else {
                        API.showErrorAlert('Error al crear guía, por favor intente de nuevo');
                    }
                },
                function (data) {
                    console.log(data);
                    API.showErrorNotify(data.msg);
                });
        },
        printLabel(item) {
            // get forms
            if (!Tools.isEmptyString(item.labelPrint)) {
                window.open(item.labelPrint);
            }
            else {
                API.showErrorAlert('Error al imprimir etiqueta');
            }
        },
        cancelPayment(idPayment) {
            const self = this;
            API.showConfirm('¿Está seguro de revertir el pago?', 'Esta acción no se puede deshacer', function () {

                API.send('POST', 'payments/revert-pay', {
                        pagoId: idPayment
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessNotify(response.msg);
                            self.loadItems();

                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
            })
        },
        printPayment(idPayment) {
            const self = this;

            self.impresionVoucherUrl = '';

            if (self.impresionVoucherUrl === '') {
                API.send('POST', 'payments/print-voucher', {
                        pagoId: idPayment
                    },
                    function (response) {
                        if (response.status) {
                            if (typeof response.data.url !== 'undefined') {
                                self.impresionVoucherUrl = response.data.url;
                                window.open(self.impresionVoucherUrl);
                            }
                            else {
                                API.showErrorNotify(response.msg);
                            }
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
            }
            else {
                window.open(self.impresionVoucherUrl);
            }
        }
    },
};
</script>
