<template>
    <setting-layout :active="30">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Lotes de inventario</h4>
            </div>
            <div class="card-body">
                <div class="text-right mb-3">
                    <router-link to="/products/category/add" class="btn btn-primary px-4 mr-2">
                        <i class="fa fa-plus-circle mr-2"></i> Cargar lote
                    </router-link>
                    <router-link to="/products/category/add" class="btn btn-primary px-4">
                        <i class="fa fa-list mr-2"></i> Unidades de medida
                    </router-link>
                </div>
                <div class="form">
                    <ul class="linked_account">
                        <li v-for="item in categories" :key="item.id">
                            <div class="row">
                                <div class="col-12">
                                    <div class="media">
                                        <span class="mr-3"><i class="fa fa-list-alt"></i></span>
                                        <div class="media-body">
                                            <h5 class="mt-0 mb-1">{{ item.nombre }}</h5>
                                            <div>
                                                Identificador o Slug: <b>{{ item.slug }}</b>
                                            </div>
                                            <p v-if="item.descripcion !== ''">{{ item.descripcion }}</p>
                                        </div>
                                        <div class="edit-option">
                                            <router-link :to="'/products/category/edit/'+item.id">
                                                <i class="fa fa-pencil"></i>
                                            </router-link>
                                            <a @click="deleteItem(item.id)"><i class="fa fa-trash"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";

export default {
    components: {
        settingLayout,
    },
    data() {
        return {
            categories: {},
        };
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            const self = this;
            API.send('GET', '/products/allCategoria/1', {},
                function (data) {
                    if (data.status) {
                        self.categories = data.data;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },
        copyLink(copyvalue) {
            Tools.copyToClipboard(copyvalue, 'Enlace de pago copiado al portapapeles');
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'products/removeCategoria/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },
    },
};
</script>
