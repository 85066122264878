<template>
    <layout :active="30" :title="true">
        <div class="content-body">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4">
                        <div class="card settings_menu">
                            <div class="card-header">
                                <h4 class="card-title">{{ menu[tipo].titulo }}</h4>
                            </div>
                            <div class="card-body">
                                <ul>

                                    <li class="nav-item" v-if="Access('products-productos-listado')">
                                        <router-link to="/products" class="nav-link" :class="active === 31 && 'active'">
                                            <i class="la la-box"></i>
                                            <span>{{ menu[tipo].productos }}</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('products-productos-cat')">
                                        <router-link to="/products/category" class="nav-link" :class="active === 30 && 'active'">
                                            <i class="la la-list-alt"></i>
                                            <span>{{ menu[tipo].categorias }}</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('products-productos-lotes')">
                                        <router-link to="/products/inventory/lot" class="nav-link" :class="active === 34 && 'active'">
                                            <i class="la la-boxes"></i>
                                            <span>{{ menu[tipo].lotes }}</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('products-productos-marcas')">
                                        <router-link to="/products/brand" class="nav-link" :class="active === 33 && 'active'">
                                            <i class="la la-file-image"></i>
                                            <span>{{ menu[tipo].marcas }}</span>
                                        </router-link>
                                    </li>
                                    <!--<li class="nav-item">
                                        <router-link to="/products/digital-certs" class="nav-link" :class="active === 32 && 'active'">
                                            <i class="la la-file-signature"></i>
                                            <span>Formularios y certificados</span>
                                        </router-link>
                                    </li>-->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <slot/>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import {mapGetters} from "vuex";
import {store} from "/src/store";


export default {
    name: "SettingLayout",
    components: {
        Layout,
    },
    props: {
        active: Number,
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
            Access: 'Access',
        })
    },
    data() {
        return {
            tipo: (typeof (store.getters.whiteLabelInfo.tpl) !== 'undefined' && store.getters.whiteLabelInfo.tpl === 'medicina') ? 'medicina' : 'default',
            menu: {
                default: {
                    titulo: 'Productos',
                    productos: 'Listado de productos',
                    categorias: 'Editar categorías',
                    marcas: 'Editar marcas',
                    lotes: 'Lotes y existencias',
                },
                medicina: {
                    titulo: 'Consultas y servicios',
                    productos: 'Editar servicios y productos',
                    categorias: 'Editar categorías',
                    marcas: 'Editar especialidades',
                    lotes: 'Lotes y existencias',
                }
            },
        }
    }
};
</script>
