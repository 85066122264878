<template>
    <layout>
        <div v-if="reloadFicha" class="content-body fichaMedicaForm">
            <div class="loading-ficha my-5 p-4 text-danger text-center">
                <div class="spinner-box">
                    <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="content-body fichaMedicaForm">
            <ficha-nutricion v-if="tipoFicha === 'nutricion'" :tabDefault="tabDefault"></ficha-nutricion>
            <ficha-ginecologia v-if="tipoFicha === 'ginecologia'" :tabDefault="tabDefault"></ficha-ginecologia>
            <ficha-medicina-general v-if="tipoFicha === 'medicina_general'" :tabDefault="tabDefault"></ficha-medicina-general>
            <ficha-pediatria v-if="tipoFicha === 'pediatria'" :tabDefault="tabDefault"></ficha-pediatria>
            <ficha-urgencia v-if="tipoFicha === 'urgencia'" :tabDefault="tabDefault"></ficha-urgencia>
            <ficha-odontologia v-if="tipoFicha === 'odontologia'" :tabDefault="tabDefault"></ficha-odontologia>
            <ficha-veterinaria v-if="tipoFicha === 'veterinaria'" :tabDefault="tabDefault"></ficha-veterinaria>
            <ficha-podologia v-if="tipoFicha === 'podologia'" :tabDefault="tabDefault"></ficha-podologia>
            <ficha-traumatologia v-if="tipoFicha === 'traumatologia'" :tabDefault="tabDefault"></ficha-traumatologia>
            <ficha-urologia v-if="tipoFicha === 'urologia'" :tabDefault="tabDefault"></ficha-urologia>
            <ficha-fisioterapia v-if="tipoFicha === 'fisioterapia'" :tabDefault="tabDefault"></ficha-fisioterapia>
            <ficha-dermatologia v-if="tipoFicha === 'dermatologia'" :tabDefault="tabDefault"></ficha-dermatologia>
            <ficha-psicologia v-if="tipoFicha === 'psicologia'" :tabDefault="tabDefault"></ficha-psicologia>
            <ficha-geriatria v-if="tipoFicha === 'geriatria'" :tabDefault="tabDefault"></ficha-geriatria>
            <ficha-tanatologia v-if="tipoFicha === 'tanatologia'" :tabDefault="tabDefault"></ficha-tanatologia>
            <ficha-prenatal v-if="tipoFicha === 'prenatal'" :tabDefault="tabDefault"></ficha-prenatal>

            <div v-if="tipoFicha === '' || !tipoFicha" class="loading-ficha my-5 p-4 text-danger text-center">
                <div class="spinner-box">
                    <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";

// FICHAS MÉDICAS DISPONIBLES
import FichaGinecologia from './fichas/medico/Ginecologia/Ginecologia.vue';
import FichaNutricion from './fichas/medico/Nutricion/Nutricion.vue';
import FichaMedicinaGeneral from './fichas/medico/MedicinaGeneral/MedicinaGeneral.vue';
import FichaPediatria from './fichas/medico/Pediatria/Pediatria.vue';
import FichaUrgencia from './fichas/medico/Urgencia/Urgencia.vue';
import FichaOdontologia from './fichas/medico/Odontologia/Odontologia.vue';
import FichaPodologia from './fichas/medico/Podologia/Podologia.vue';
import FichaVeterinaria from './fichas/medico/Veterinaria/Veterinaria.vue';
import FichaTraumatologia from './fichas/medico/Traumatologia/Traumatologia.vue';
import FichaUrologia from './fichas/medico/Urologia/Urologia.vue';
import FichaFisioterapia from "./fichas/medico/Fisioterapia/Fisioterapia.vue";
import FichaDermatologia from "./fichas/medico/Dermatologia/Dermatologia.vue";
import FichaPsicologia from "./fichas/medico/Psicologia/Psicologia.vue";
import FichaGeriatria from "./fichas/medico/Geriatria/Geriatria.vue";
import FichaTanatologia from "./fichas/medico/Tanatologia/Tanatologia.vue";
import FichaPrenatal from "./fichas/medico/Prenatal/Prenatal.vue";

export default {
    components: {
        Layout,

        // fichas médicas
        FichaUrologia,
        FichaGinecologia,
        FichaNutricion,
        FichaMedicinaGeneral,
        FichaPediatria,
        FichaUrgencia,
        FichaOdontologia,
        FichaVeterinaria,
        FichaTraumatologia,
        FichaPodologia,
        FichaFisioterapia,
        FichaDermatologia,
        FichaPsicologia,
        FichaGeriatria,
        FichaTanatologia,
        FichaPrenatal
    },
    data() {
        return {
            // Ficha médica
            tabDefault: 'preclinica',
            ws:null,
            idReserva: 0,
            tipoFicha: '',
            reloadFicha: false,
            ws_url: process.env.ANY_WS
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            AuthGetToken: 'AuthGetToken',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {

    },
    mounted() {
        const self = this;

        let cacheTab = self.getWithExpiry('cacheTab');
        if (cacheTab != null) {
            self.tabDefault = cacheTab;
        }

        this.$store.dispatch('SetMedicoFichaActive', {}); // limpio todo antes de iniciar
        this.idReserva = this.$route.params.reservaId;
        this.loadReserva();

        // Actualizar clientes
        this.emitter.on("medico-ficha-refresh", function () {
            self.loadReserva();
            self.enviarActualizacion();
        });

        // Guardar tab en cache
        this.emitter.on("save-default-tab", function (tab) {
            self.tabDefault = tab;
        });

        // Establezco la conexión WebSocket al montar el componente
        this.conectarWebSocket();

    },
    methods: {
        ...mapActions({
            SetMedicoFichaActive: 'SetMedicoFichaActive',
        }),
        dayjs,
        Numbro,

        conectarWebSocket() {
        let token = this.AuthGetToken;
        let user_id = this.AuthGetUserInfo.id;
        // Conectar al WebSocket con la URL adecuada y el userId como parámetro
        this.ws = new WebSocket(`${this.ws_url}ficha_update`);

        // Manejar el evento de apertura de conexión
        this.ws.onopen = () => {
            console.log("%cConexión WebSocket establecida 👌","background: green; color: white; padding: 4px;");

            // Enviar mensaje de autenticación al abrir la conexión
            const authMessage = {
            action: "authenticate",
            token,
            user_id

            };
            this.ws.send(JSON.stringify(authMessage));
        };

        // Manejar mensajes recibidos del servidor
        this.ws.onmessage = (event) => {
            const self = this;
            const data = JSON.parse(event.data);

            if (data.status === "authenticated") {
            console.log("%cCliente autenticado correctamente ✅", "background: green; color: white; padding: 4px;");
            } else if (data.status === "success" && data.action === "update") {
                console.log( "%cFicha actualizada!", "background: green; color: white; padding: 4px;" );
                self.saveCacheTab();
                self.loadReserva();
            } else if (data.status === "error") {
                console.error("%c" + data.message, "background: red; color: white; padding: 4px;");

            }
        };

        // Manejar el evento de cierre de conexión
        this.ws.onclose = (event) => {
            
            if(event.code == 1000){
                console.log("%cConexión WebSocket cerrada totalmente", "background: red; color: white; padding: 4px;");
            }else{
                console.log("%cConexión WebSocket cerrada por exceso de tiempo de espera.", "background: orange; color: white; padding: 4px;");
                console.log("%cIntentando reconectar... 🔌", "background: orange; color: white; padding: 4px;");
                setTimeout(this.conectarWebSocket(), 5000);
            }
        };

        // Manejar errores de WebSocket
        this.ws.onerror = (error) => {
            console.error("%cError en el WebSocket: " + error, "background: red; color: white; padding: 4px;");

        };
        },

        enviarActualizacion() {
            let user_id = this.AuthGetUserInfo.id;
            // Preparar los datos de la ficha médica actualizada
            const fichaActualizada = {
                action: "update",
                user_id
            };
        // Enviar la actualización al servidor WebSocket
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(fichaActualizada));
        } else {
            console.error("%cConexión WebSocket no está abierta", "background: orange; color: white; padding: 4px;");
        }
        },
        loadReserva() {
            const self = this;
            self.personal = [];
            self.asignaciones = [];
            self.reloadFicha=true;
            API.send('GET', 'orders/metadata/by-reserva/' + self.idReserva, {}, function (response) {
                self.tipoFicha = response.data.producto.fichaMedica;
                self.emitter.emit("medico-ficha-load");
                self.$store.dispatch('SetMedicoFichaActive', response.data);
                self.reloadFicha=false;
            },
            function (response) {
                    API.showErrorNotify(response.msg);
                });
            },
        setWithExpiry(key, value, ttl) {
            const now = new Date();
            const item = {
                value: value,
                expiry: now.getTime() + ttl,
            }
            sessionStorage.setItem(key, JSON.stringify(item))
            },
        getWithExpiry(key) {
            const itemStr = sessionStorage.getItem(key)
            if (!itemStr) {
                return null
            }
            const item = JSON.parse(itemStr)
            const now = new Date()
            if (now.getTime() > item.expiry) {
                sessionStorage.removeItem(key)
                return null
            }
            return item.value
        },
        saveCacheTab(){
            this.setWithExpiry('cacheTab',this.tabDefault,1 * 60 * 1000);
        }
    },
    beforeUnmount() {
    // Cerrar la conexión WebSocket antes de que se desmonte el componente
    if (this.ws && this.ws.close && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close(1000);
    }
    },
};

</script>

<style scoped>

.loading-ficha{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
</style>