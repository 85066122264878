import ShortlinkList from "src/modules/shortLink/views/ShortlinkList.vue";
import ShortlinkCreate from "src/modules/shortLink/views/ShortlinkCreate.vue";

export default [
    {
        path: "/shortlink-list",
        component: ShortlinkList,
    },
    {
        path: "/shortlink-create",
        component: ShortlinkCreate,
    },
    /*{
        path: "/form-builder",
        component: FormBuilder,
    },
    {
        path: "/form-builder/:id",
        component: FormBuilder,
    },
    {
        path: "/docs-plus-ocr",
        component: OCRList,
    },
    {
        path: "/docs-plus-ocr/:id",
        component: OCRForm,
    },*/
];
