<script>


//ficha's components
 import FichaLayout from '../components/FichaLayout.vue';
 import PreClinica from '../components/PreClinica.vue';
 import Antecedentes from '../components/Antecedentes.vue';
 import ExamenFisico from '../components/ExploracionFisica.vue';
 import ImpresionClinica from '../components/ImpresionClinica.vue';
 import Tratamiento from '../components/Tratamiento.vue';
 import Historial from '../components/HistorialPr.vue';
 import Resumen from '../components/Resumen.vue';

//  Ficha Urologia
import Consulta from './components/Consulta.vue';


export default { 
 data() {
        return {
        //data
        tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                antecedentes: {
                    icon: 'fas fa-history',
                    name: 'Antecedentes',
                },
                examen_fisico: {
                    icon: 'fas fa-search-plus',
                    name: 'Examen fisico',
                },
                consulta: {
                    icon: 'fa-solid fa-brain',
                    name: 'Tanatología',//Consulta se refiere a el componente que contiene los datos del tipo de consulta actual de la ficha.
                },
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica',
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento',
                },
                historial: {
                    icon: 'fas fa-history',
                    name: 'Historial', // agregar estado de cuenta
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen',
                },
              }
 }},
  mounted(){

  },
 components:{
  FichaLayout,
  PreClinica,
  Antecedentes,
  ExamenFisico,
  Consulta,
  ImpresionClinica,
  Tratamiento,
  Historial,
  Resumen
 }
 }
</script>
 
 
<template>
  <div>
    <ficha-layout title="Tanatología" color="#BCA99F" :tabs="tabs" tab-default="preclinica" enable-timeline="true">
            <template #preclinica>
                <pre-clinica></pre-clinica>
            </template>
            <template #antecedentes>
                <antecedentes></antecedentes>
            </template>
            <template #examen_fisico>
                <examen-fisico></examen-fisico>
            </template>
            <template #consulta>
                <consulta></consulta>
            </template>
            <template #impresion_clinica>
                <impresion-clinica></impresion-clinica>
            </template>
            <template #tratamiento>
                <tratamiento></tratamiento>
            </template>
            <template #historial>
                <historial></historial>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
    </ficha-layout>
  </div>
</template>
 
 
<style scoped>
 
</style>