<script>
 
export default { 
 data() {
        return {
        //data
        DefaultUrlApp: 'https://dicom.isi.clinic/',
        UrlDicomApp: ''
 }},
 props:{
 CustomUrlApp: String, // Domain to iFrame -> https://www.example.com/
 FileUrl: String, // Optional File to Dicom App -> https://www.example.dicom/
 ScreeType: String,// select windows or fullscreen 
 },
 mounted(){
   this.setUrlDicomApp();

   const fichaContentTab = document.querySelector(".fichaContentTab");
   fichaContentTab.style.overflow = "hidden";
},
methods:{
    dicomScreenContainer(){
        if (this.ScreeType && this.ScreeType.toLowerCase() === "fullscreen") {
            return  "fullscreen";
        }else if(this.ScreeType && this.ScreeType.toLowerCase() === "windows"){
            return "windows";
        }
        return "fullscreen"
    },
    buttonCloseType(){
        if (this.ScreeType && this.ScreeType.toLowerCase() === "fullscreen") {
            return  "close-fullscreen";
        }else if(this.ScreeType && this.ScreeType.toLowerCase() === "windows"){
            return "close-windows";
        }
        return "close-fullscreen";
    },
    setUrlDicomApp(){


        if (this.CustomUrlApp) {
        if (this.FileUrl) {
            if (this.CustomUrlApp.endsWith('/')) {
                return this.UrlDicomApp= this.CustomUrlApp+this.FileUrl;
            }
           return this.UrlDicomApp= this.CustomUrlApp+'/'+this.FileUrl;
        }
        return this.UrlDicomApp= this.CustomUrlApp;
        }

        if (this.FileUrl) {
            return this.UrlDicomApp= this.DefaultUrlApp+this.FileUrl;
        }
        return this.UrlDicomApp= this.DefaultUrlApp;
    },
    handlerDicomViewer(){
        this.$emit('handlerDicomViewer', {url:'', action:false});
        //set FileURL '' empy and close DicomViewer
    }

}
 }
</script>
 
 
<template>
  <div class="dicom-container">
    <button type="button" @click="handlerDicomViewer()" class="btn btn-danger px-3" :class="buttonCloseType()"><i class="fa-solid fa-xmark"></i></button>

    <div :class="dicomScreenContainer()">
        <iframe v-if="UrlDicomApp" ref="dicom-viewer" id="dicom-viewer" :src="UrlDicomApp" frameborder="0" class="iframe-dicom-viewer"></iframe>
        
        <div class="loading">
            <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
  </div>
</template>
 
 
<style scoped>

.dicom-container{
  z-index: 9999;
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(3px);
}
.windows{
  width: 90%;
  height: 95%;
  /* background-color: rgb(255, 255, 255); */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.fullscreen{
  width: 100%;
  height: 100vh;
  /* background-color: rgb(255, 255, 255); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.iframe-dicom-viewer{
  width: 100%;
  height: 100%;
}

.close-windows{
  position: fixed;
  top: 20px;
  right: 10px;
  font-size: 20px;
  transition: 0.3s;
}

.close-fullscreen{
  position: fixed;
  z-index: 999;
  bottom: 20px;
  right: 10px;
  font-size: 20px;
  transition: 0.3s;
}

.loading{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>