<template>
    <forms-layout :active="35">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Listado de códigos promocionales</h4>
                <button class="btn btn-primary" @click="$router.push('/orders/cod-promo/0')"><i class="fa fa-plus mr-2"></i>Crear código</button>
            </div>
            <div class="card-body">
                <div class="form">
                    <div v-if="Object.keys(listData).length < 1" class="text-center text-muted">
                        No existen códigos promocionales
                    </div>
                    <div v-else>
                        <ul>
                            <li v-for="item in listData" :key="item.id" class="h-50">
                                <div class="listItem">
                                    <div class="row">
                                        <div class="col-12 col-md-10">
                                            <div>
                                                <h5 class="mt-0 mb-1">{{item.desc}} <span class="text-muted ml-2">(usado {{ item.validationsCount }} veces)</span></h5>
                                                <div class="text-primary"><b>Código:</b> {{ item.shortCode }}</div>
                                                <div class="row">
                                                     <div class="col-12 col-sm-4">
                                                         <div><b>Límite de usos:</b> {{ item.maxValidations }}</div>
                                                     </div>
                                                     <div class="col-12 col-sm-4">
                                                         <div><b>% Descuento:</b> {{ item.discountPercent }}</div>
                                                     </div>
                                                     <div class="col-12 col-sm-4">
                                                         <div><b>Descuento fijo:</b> {{ item.discountAmount }}</div>
                                                     </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2 text-right">
                                            <div class="edit-option">
                                                <router-link :to="'/orders/cod-promo/'+item.id">
                                                    <i class="fa fa-pencil"></i>
                                                </router-link>
                                                <a @click="deleteItem(item.id)"><i class="fa fa-trash"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </forms-layout>
</template>

<script>
import formsLayout from "../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";

export default {
    components: {
        formsLayout
    },
    data() {
        return {
            listData: {},
        };
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            const self = this;
            API.send('POST', 'orders/promo-code/list', {},
                function (data) {
                    if (data.status) {
                        self.listData = data.data;
                    }
                },
                function (data) {
                    API.showErrorNotify(data.msg);
                })
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'orders/promo-code/delete/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessNotify(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    })
            })
        },
    },
};
</script>
