<script>
import API from "src/core/Api";
import {mapActions, mapGetters} from "vuex";
import Box from '/src/modules/medico/views/fichas/Box.vue';
import ModalDetail from "./ModalDetail.vue";
import BodyPartsSelector from "./BodyPartsSelector.vue";

import VueMuscleGroupSelector from "vue-muscle-group-selector";
import "vue-muscle-group-selector/dist/style.css";

export default { 
 data() {
        return {
        //data
        fisioterapia_tratamientos:{
          ejercicios_activos:{
            name: 'Ejercicios Activos',
            value: false,
            observations: '',
          },
          ejercicios_pasivos:{
            name: 'Ejercicios Pasivos',
            value: false,
            observations: '',
          },
          ejercicios_de_amplitud_articular:{
            name: 'Ejercicios de amplitud articular',
            value: false,
            observations: '',
          },
          ejercicios_de_fortalecimiento:{
            name: 'Ejercicios de fortalecimiento',
            value: false,
            observations: '',
          },
          ejercicios_isometricos:{
            name: 'Ejecicios isométricos',
            value: false,
            observations: '',
          },
          electro_estimulo:{
            name: 'Electro estímulo',
            value: false,
            observations: '',
          },
          empaques_calientes:{
            name: 'Empaques calientes',
            value: false,
            observations: '',
          },
          empaques_frios:{
            name: 'Empaques fríos',
            value: false,
            observations: '',
          },
          hidroterapia:{
            name: 'Hidroterapia',
            value: false,
            observations: '',
          },
          laser:{
            name: 'Láser',
            value: false,
            observations: '',
          },
          masaje:{
            name: 'Masaje',
            value: false,
            observations: '',
          },
          propiocepcion:{
            name: 'Propiocepción',
            value: false,
            observations: '',
          },
          reeducacion:{
            name: 'Reeducación',
            value: false,
            observations: '',
          },
          ultrasonido:{
            name: 'Ultrasonido',
            value: false,
            observations: '',
          },
          otro:{
            name: 'Otro',
            value: false,
            observations: '',
          },
        },
        observaciones:{
          general:'',
          fuerza:''
        },
        indicaciones_amplitud_articular:{
          hombro:{
            name: 'Hombro',
            adduction: '',
            adduction_grades: 'Grado ideal - 40°',
            abduction: '',
            abduction_grades: 'Grado ideal - 180°',
            flexion: '',
            flexion_grades: 'Grado ideal - 180°',
            extension: '',
            extension_grades: 'Grado ideal - 45°',
            internal_rotation:'',
            internal_rotation_grades:'Grado ideal - 90°',
            external_rotation: '',
            external_rotation_grades:'Grado ideal - 90°',
          },
          codo_antebrazo:{
            name: 'Codo/Antebrazo',
            flexion: '',
            flexion_grades: 'Grado ideal - 145°',
            pronation: '',
            pronation_grades: 'Grado ideal - 80°',
            supination:'',
            supination_grades: 'Grado ideal - 80°',
          },
          muñeca:{
            name: 'Muñeca',
            ulnar_flexion: '',
            ulnar_flexion_grades: 'Grado ideal - 20°',
            extension: '',
            extension_grades: 'Grado ideal - 70°',
            radial_flexion:'',
            radial_flexion_grades:'Grado ideal - 45°',
            flexion: '',
            flexion_grades: 'Grado ideal - 80°',
          },
          cadera:{
            name: 'Cadera',
            adduction: '',
            adduction_grades: 'Grado ideal - 10°',
            abduction: '',
            abduction_grades: 'Grado ideal - 45°',
            internal_rotation:'',
            internal_rotation_grades:'Grado ideal - 125°',
            external_rotation: '',
            external_rotation_grades:'Grado ideal - 45°',
            extension: '',
            extension_grades: 'Grado ideal - 30°',
            flexion: '',
            flexion_grades: 'Grado ideal - 45°',
          },
          rodilla:{
            name: 'Rodilla',
            extension: '',
            extension_grades: 'Grado ideal - 0°',
            flexion: '',
            flexion_grades: 'Grado ideal - 140°',
          },
          tobillo:{
            name: 'Tobillo',
            inversion:'',
            inversion_grades:'Grado ideal - 40°',
            eversion: '',
            eversion_grades: 'Grado ideal - 20°',
            plantar_flexion: '',
            plantar_flexion_grades: 'Grado ideal - 45°',
            dorsiflexion: '',
            dorsiflexion_grades: 'Grado ideal - 20°',
          },
        },
        detailData: {},
        detailModal: false,
        muscularGroupTranslations:{
          arms: {
            arms: "Arms",
            bicpes: "Biceps",
            deltoids: "Deltoids",
            forearms: "Forearms",
            triceps: "Triceps",
          },
          back: {
            back: "Back",
            trapezius: "Trapezius",
            lats: "Lats",
          },
          core: {
            core: "Core",
            abs: "Abs",
            obliques: "Obliques",
            pectorals: "Pectorals",
          },
          legs: {
            legs: "Legs",
            adductors: "Adductors",
            calves: "Calves",
            hamstrings: "Hamstrings",
            glutes: "Glutes",
            quads: "Quads",
          }
        },
        musculosSeleccionados:[],
        bodyLoading:false,
        showTable:true
 }},
 components:{
  Box,
  ModalDetail,
  BodySelector: VueMuscleGroupSelector,
  BodyPartsSelector
 },
 watch:{
  // musculosSeleccionados: function (value) {
  //   this.bodyLoading=true;
  //   setTimeout(() => {
  //     this.bodyLoading=false;
  //   }, 1000);
  // }
 },
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
 },
 mounted() {
        const self = this;


        //Cargo musculos
        if (self.GetMedicoFichaActive.metadata.musculos_seleccionados){
          self.bodyLoading=true;
          Object.keys(self.GetMedicoFichaActive.metadata.musculos_seleccionados).map(function (value) {
            self.musculosSeleccionados.push(self.GetMedicoFichaActive.metadata.musculos_seleccionados[value]);
          });
          self.bodyLoading=false;
        };


        // Cargo tratamientos
        Object.keys(self.fisioterapia_tratamientos).map(function (value) {

            if (self.GetMedicoFichaActive.metadata.fisioterapia_tratamientos) {
                if (self.GetMedicoFichaActive.metadata.fisioterapia_tratamientos[value]) self.fisioterapia_tratamientos[value].value = self.GetMedicoFichaActive.metadata.fisioterapia_tratamientos[value];
                if (self.GetMedicoFichaActive.metadata.fisioterapia_tratamientos[value + '_name']) self.fisioterapia_tratamientos[value].name = self.GetMedicoFichaActive.metadata.fisioterapia_tratamientos[value + '_name'];
                if (self.GetMedicoFichaActive.metadata.fisioterapia_tratamientos[value + '_observations']) self.fisioterapia_tratamientos[value].observations = self.GetMedicoFichaActive.metadata.fisioterapia_tratamientos[value + '_observations'];
            }
        });

         //Cargo observaciones
         if (self.GetMedicoFichaActive.metadata.observaciones_fisioterapeuticas){
          self.observaciones = self.GetMedicoFichaActive.metadata.observaciones_fisioterapeuticas
        };

        // Cargo indicaciones de amplitud
        Object.keys(self.indicaciones_amplitud_articular).map(function (value) {

        if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular) {
            // name- 
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_name']) self.indicaciones_amplitud_articular[value].name = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_name'];
            // adduction-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_adduction']) self.indicaciones_amplitud_articular[value].adduction = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_adduction'];
            // abduction
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_abduction']) self.indicaciones_amplitud_articular[value].abduction = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_abduction'];
            // flexion-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_flexion']) self.indicaciones_amplitud_articular[value].flexion = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_flexion'];
            // extension-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_extension']) self.indicaciones_amplitud_articular[value].extension = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_extension'];
            // internal_rotation-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_internal_rotation']) self.indicaciones_amplitud_articular[value].internal_rotation = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_internal_rotation'];
            // external_rotation-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_external_rotation']) self.indicaciones_amplitud_articular[value].external_rotation = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_external_rotation'];
            // pronation-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_pronation']) self.indicaciones_amplitud_articular[value].pronation = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_pronation'];
            // supination-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_supination']) self.indicaciones_amplitud_articular[value].supination = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_supination'];
            // ulnar_flexion-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_ulnar_flexion']) self.indicaciones_amplitud_articular[value].ulnar_flexion = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_ulnar_flexion'];
            // radial_flexion-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_radial_flexion']) self.indicaciones_amplitud_articular[value].radial_flexion = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_radial_flexion'];
            // inversion-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_inversion']) self.indicaciones_amplitud_articular[value].inversion = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_inversion'];
            // eversion-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_eversion']) self.indicaciones_amplitud_articular[value].eversion = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_eversion'];
            // plantar_flexion-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_plantar_flexion']) self.indicaciones_amplitud_articular[value].plantar_flexion = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_plantar_flexion'];
            // dorsiflexion-
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_dorsiflexion']) self.indicaciones_amplitud_articular[value].dorsiflexion = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular[value + '_dorsiflexion'];
        }
        });


    },
 methods:{
  ...mapActions({
}),
  saveTreatments(){
    const self = this;

    // transformo los keys a un array normal
    const arrToSave = {};
            Object.keys(self.fisioterapia_tratamientos).map(function (value) {
                arrToSave[value] = self.fisioterapia_tratamientos[value].value;
                arrToSave[value + '_name'] = self.fisioterapia_tratamientos[value].name;
                arrToSave[value + '_observations'] = self.fisioterapia_tratamientos[value].observations;
            });
    // Guardo y luego emito el evento
    API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'fisioterapia_tratamientos',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh");
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
              },
    saveIndications(){
    const self = this;

    // transformo los keys a un array normal
    const arrToSave = {};
            Object.keys(self.indicaciones_amplitud_articular).map(function (value) {
                // name-
                arrToSave[value + '_name'] = self.indicaciones_amplitud_articular[value].name;
                // adduction-
                ("adduction" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_adduction'] = self.indicaciones_amplitud_articular[value].adduction);
                // abduction
                ("abduction" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_abduction'] = self.indicaciones_amplitud_articular[value].abduction);
                // flexion-
                ("flexion" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_flexion'] = self.indicaciones_amplitud_articular[value].flexion);
                // extension-
                ("extension" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_extension'] = self.indicaciones_amplitud_articular[value].extension);
                // internal_rotation-
                ("internal_rotation" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_internal_rotation'] = self.indicaciones_amplitud_articular[value].internal_rotation);
                // external_rotation-
                ("external_rotation" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_external_rotation'] = self.indicaciones_amplitud_articular[value].external_rotation);
                // pronation-
                ("pronation" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_pronation'] = self.indicaciones_amplitud_articular[value].pronation);
                // supination-
                ("supination" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_supination'] = self.indicaciones_amplitud_articular[value].supination);
                // ulnar_flexion-
                ("ulnar_flexion" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_ulnar_flexion'] = self.indicaciones_amplitud_articular[value].ulnar_flexion);
                // radial_flexion-
                ("radial_flexion" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_radial_flexion'] = self.indicaciones_amplitud_articular[value].radial_flexion);
                // inversion-
                ("inversion" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_inversion'] = self.indicaciones_amplitud_articular[value].inversion);
                // eversion-
                ("eversion" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_eversion'] = self.indicaciones_amplitud_articular[value].eversion);
                // plantar_flexion-
                ("plantar_flexion" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_plantar_flexion'] = self.indicaciones_amplitud_articular[value].plantar_flexion);
                // dorsiflexion-
                ("dorsiflexion" in self.indicaciones_amplitud_articular[value]) && (arrToSave[value + '_dorsiflexion'] = self.indicaciones_amplitud_articular[value].dorsiflexion);
            });
    // Guardo y luego emito el evento
    API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'indicaciones_amplitud_articular',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh");
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
              },
    saveObservaciones(){
      const self = this;

      API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'observaciones_fisioterapeuticas',
                    detail: self.observaciones,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh");
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
    },
              openDetailModal(item){
                this.detailData=item;
                this.detailModal=true;
              },
              closeDetailModal(){
                this.detailData={};
                this.detailModal=false;
              },
              saveDataFromModal(item){
                const self = this;


                if (item && item.observations) {
                  
                const arrToSave = {};
                Object.keys(self.fisioterapia_tratamientos).map(function (value) {
                arrToSave[value] = self.fisioterapia_tratamientos[value].value;
                arrToSave[value + '_name'] = self.fisioterapia_tratamientos[value].name;
                if (item.name === self.fisioterapia_tratamientos[value].name) {
                arrToSave[value + '_observations'] = self.detailData.observations;
                }else{
                arrToSave[value + '_observations'] = self.fisioterapia_tratamientos[value].observations;
                }
            });

            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'fisioterapia_tratamientos',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh");
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
                }else{
                  API.showErrorAlert('No hay cambios para guardar')
                }
                this.closeDetailModal();
              },
              handleOnSelectMuscularGroup(selection ){
                this.musculosSeleccionados=selection;
                if (this.musculosSeleccionados.length===0) {
                  this.deleteMetada();
                }else{
                  this.saveMuscle();
                }
              },
              saveMuscle(){
                const self = this;
                if (self.GetMedicoFichaActive.metadata.musculos_seleccionados) {
                  self.deleteMetada();
                }
                
                  const arrToSave = {};
                        self.musculosSeleccionados.map(function (value) {
                            arrToSave['muscle_'+value]=value;
                        });
               
                setTimeout(() => {
                  API.send('POST', 'orders/metadata/save/', {
                                orderId: self.GetMedicoFichaActive.orden.id,
                                slug: 'musculos_seleccionados',
                                detail: arrToSave,
                                uniqueMeta: 1,
                            }, function (response) {
                                API.showSuccessAlert(response.msg);
                                console.log('Grupo muscular guardado');
                                self.emitter.emit("medico-ficha-refresh");
                            },
                            function (response) {
                                API.showErrorAlert(response.msg);
                            });
                }, 500);
                
           
              },
              deleteMetada(){
                    const self = this;
                    API.send('POST', 'orders/metadata/deleteBySlug', {
                        orderId: self.GetMedicoFichaActive.orden.id,
                        slug: 'musculos_seleccionados',
                    }, function (response) {
                       console.log('Grupo muscular eliminado');
                       self.emitter.emit("medico-ficha-refresh");
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });

  },

 }

 }
</script>
 
 
<template>
  <Box title="Zona de afectación" icon="fa-solid fa-person-circle-exclamation">
    <div v-if="GetMedicoFichaActive.metadata.musculos_seleccionados && !musculosSeleccionados.length" class="w-100 d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
   </div>
   <div v-else>
    <small v-if="!musculosSeleccionados.length" class="form-text text-danger">Seleccione las zonas musculares en las que el paciente presenta afectación.</small>
    <small v-else class="form-text text-primary">Puedes seleccionar multiples zonas de afectación</small>
    <!-- <BodySelector  :translations="muscularGroupTranslations"
      @on-select="handleOnSelectMuscularGroup" :allowMultiple="true" :primaryColor="'#0CA7E0'" :initialValues="musculosSeleccionados"></BodySelector> -->
      <BodyPartsSelector />
   </div>
  </Box>
  <Box title="Tratamientos" icon="fa-regular fa-calendar-days">
    <div class="row">
      <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in fisioterapia_tratamientos" :key="key">
                <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveTreatments"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                        {{ item.name }}
                    </span>
                </label>
            </div>
    </div>
  </Box>
  <Box title="Informacion de tratamiento actual" icon="fa-solid fa-circle-info">
    <ModalDetail v-if="detailModal" :detail="detailData" @closeModal="closeDetailModal" @saveData="saveDataFromModal"/>
    <div class="w-100">
      <div class="changeShowTable cursor-pointer" @click="showTable=!showTable">
      <p v-if="showTable" class="table-title"><i class="fa-solid fa-eye-slash"></i>  Ocultar tabla de observaciones detalladas</p>
      <p v-else> <i class="fa-solid fa-eye"></i>  Ver tabla de observaciones detalladas</p>
    </div>
    </div>
    <div v-if="showTable">
      <table class="table">
        <thead class="thead-light">
          <tr>
            <th scope="col">Tratamiento</th>
            <th scope="col">Opción</th>
          </tr>
        </thead>
        <tbody>
          <tr class="item-table cursor-pointer" @click="openDetailModal(item)" v-show="item.value" v-for="(item, key) in fisioterapia_tratamientos" :key="key">
            <td>{{ item.name }}</td>
            <td><i title="Ver o Editar" class="fa-solid fa-eye text-primary"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="form-group col-12">
                    <label>Observación general</label>
                    <textarea v-model="observaciones.general" class="form-control" placeholder="Escribe aquí..." @change="saveObservaciones"></textarea>
        </div>
        <div class="form-group col-12">
                    <label>Fuerza muscular</label>
                    <textarea v-model="observaciones.fuerza" class="form-control" placeholder="Escribe aquí..." @change="saveObservaciones"></textarea>
        </div>
    </div>
  </Box>
  <Box v-if="fisioterapia_tratamientos.ejercicios_de_amplitud_articular.value" title="Indicaciones de amplitud articular" icon="fa-solid fa-person-chalkboard">
    <div class="cards-container singleborderbox row mx-1">
      <div v-for="(item, key) in indicaciones_amplitud_articular" class="col-12 col-md-4" style="width: 15rem;" :key="key">
        <div class="card px-1">
          <div class="card-body">
          <h5 class="card-title mb-2">{{ item.name }}</h5>
          <div v-show="item.adduction_grades" class="form-group">
              <label>Adducción | {{ item.adduction_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.adduction" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.abduction_grades" class="form-group">
              <label>Abducción | {{item.abduction_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.abduction" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.flexion_grades" class="form-group">
              <label>Flexión | {{item.flexion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.flexion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.extension_grades" class="form-group">
              <label>Extensión | {{item.extension_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.extension" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.internal_rotation_grades" class="form-group">
              <label>Rotación interna | {{item.internal_rotation_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.internal_rotation" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.external_rotation_grades" class="form-group">
              <label>Rotación externa | {{item.external_rotation_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.external_rotation" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.pronation_grades" class="form-group">
              <label>Pronación | {{item.pronation_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.pronation" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.supination_grades" class="form-group">
              <label>Supinación | {{item.supination_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.supination" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.ulnar_flexion_grades" class="form-group">
              <label>Flexión cubital | {{item.ulnar_flexion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.ulnar_flexion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.radial_flexion_grades" class="form-group">
              <label>Flexión radial | {{item.radial_flexion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.radial_flexion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.inversion_grades" class="form-group">
              <label>Iversión | {{item.inversion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.inversion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.eversion_grades" class="form-group">
              <label>Eversion | {{item.eversion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.eversion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.plantar_flexion_grades" class="form-group">
              <label>Flexión plantar | {{item.plantar_flexion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.plantar_flexion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.dorsiflexion_grades" class="form-group">
              <label>Flexión dorsal | {{item.dorsiflexion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.dorsiflexion" @change="saveIndications"/>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Box>
</template>
 
 
<style scoped>

.item-table{
  transition: 0.3s;
}
 .item-table:hover{
  color:#0ca7e0;
  transform: scale(1.01);
 }

.changeShowTable{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px 2px 10px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.144);
  height: 40px;
  padding:0 10px;
}

.changeShowTable:hover{
  background-color: rgba(145, 144, 157, 0.097);
}


.table-title{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
}

.changeShowTable p{
  margin: 0;
}
 
</style>