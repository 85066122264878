<template>
    <div>
        <timeline v-if="enableTimeline" :show="showTimeline" @close="showTimeline = false"></timeline>
        <div class="fichaHeader">
            <div>
                <div class="header-title">
                    <div class="infobox">

                        <h3 :style="'color: ' + color" class="ficha-type-name">
                            {{title}}:
                        </h3>
                        <div class="d-flex align-items-center namebox">

                            <h3 class="ficha-client-name text-nowrap text-truncate">
                                <span class="text-dark small" v-if="typeof GetMedicoFichaActive.cliente !== 'undefined'">
                                {{formatName(GetMedicoFichaActive.cliente.nombre_mostrar)}}
                                </span>
                            </h3>
                            <span class="d-flex align-items-center justify-content-center p-2"  v-if="typeof GetMedicoFichaActive.cliente !== 'undefined'">
                                <router-link :to="'/client/' + GetMedicoFichaActive.cliente.id" class="text-muted d-flex align-items-center justify-content-center">
                                <i class="fa-solid fa-pen-to-square edit-client-from-ficha"></i>
                                </router-link>
                            </span>
                            
                        </div>

                        <div class="infoAS">
                
                            <div class="tipoSangreAlert">
                                <div v-if="typeof GetMedicoFichaActive.metadata !== 'undefined' && typeof GetMedicoFichaActive.metadata.datos_paciente !== 'undefined' && typeof GetMedicoFichaActive.metadata.datos_paciente.tipo_sangre !== 'undefined' && GetMedicoFichaActive.metadata.datos_paciente.tipo_sangre">
                                    <span><i class="fas fa-tint mr-1"></i> Sangre:</span> {{GetMedicoFichaActive.metadata.datos_paciente.tipo_sangre}}
                                </div>
                                <div v-else>
                                    <span class="text-muted"><i class="fas fa-tint-slash mr-1"></i>Sin tipo sangre</span>
                                </div>
                            </div>
                            <div class="alergiasAlert">
                                <div v-if="typeof GetMedicoFichaActive.metadata !== 'undefined' && typeof GetMedicoFichaActive.metadata.datos_paciente !== 'undefined' && typeof GetMedicoFichaActive.metadata.datos_paciente.alergias_observaciones !== 'undefined' && GetMedicoFichaActive.metadata.datos_paciente.alergias_observaciones">
                                    <span><i class="fas fa-allergies mr-1"></i> Alergias:</span> {{GetMedicoFichaActive.metadata.datos_paciente.alergias_observaciones}}
                                </div>
                                <div v-else>
                                    <span class="text-muted"><i class="fas fa-allergies mr-1"></i> Sin alergias</span>
                                </div>
                            </div>
    
                        </div>
                    </div>    

                    <div v-if="enableTimeline" class="showTimeline" @click="showTimeline = true">
                    <i class="fa fa-calendar-day"></i>
                    </div>

                    <div @click="hanlderFoptions" title="Opciones de ficha" class="optionsFicha btn btn-primary">
                        <i v-if="!fOptions" class="fa-solid fa-filter"></i>
                        <i  v-if="fOptions" class="fa-solid fa-filter-circle-xmark"></i>

                        <div v-if="fOptions" class="options-container">
                            <div @click="(showTimeline = true)" class="historial-option btn btn-success">
                                <i class="fa fa-calendar-day"></i> Historial
                            </div>
                            <h5 class="tabs-title text-primary">
                                Secciones
                            </h5>
                            <div v-for="(tab, slug) in tabs" :key="'tab_' + slug" @click="changeTab(slug)" class="ficha-tab-option" :class="(tabActive === slug) ? 'active-option' :'' " :style="(tabActive === slug) ? 'color: ' + color : 'color: #aeaed4'">
                                <span>
                                    <i :class="tab.icon" :style="(tabActive === slug) ? 'color: ' + color : 'color: #aeaed4'"></i> {{tab.name}}
                                </span>
                            </div>

                        </div>

                    </div>
                   
                </div>

            </div>
            <div class="fichaTabBar m-0">
                <div v-for="(tab, slug) in tabs" :key="'tab_' + slug" @click="changeTab(slug)" class="fichaTab" :style="(tabActive === slug) ? 'color: ' + color : ''">
                    <i :class="tab.icon"></i> {{tab.name}}
                </div>
            </div>
            
        </div>
        <div class="fichaContentTab">
            <div v-for="(tab, slug) in tabs" :key="'tabcontent_' + slug" v-show="tabActive === slug">
                <slot :name="slug"></slot>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';


// componentes
import Timeline from './Timeline.vue';

export default {
    components: {
        Timeline
    },
    props: [
        'title',
        'color',
        'tabs',
        'tabDefault',
        'enableTimeline',
    ],
    data() {
        return {
            tabActive: '',
            showTimeline: false,
            fOptions: false,
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    mounted() {
        this.tabActive = this.tabDefault;
    },
    methods: {
        ...mapActions({
        }),
        changeTab(slug) {
            this.tabActive = slug
            this.emitter.emit("save-default-tab", slug);
            this.emitter.emit("medico-ficha-changed-tab", slug);
        },
        formatName(nombre) {
            const palabras = nombre.split(" ");
            const nombreFormateado = palabras.map((palabra) => palabra && palabra[0].toUpperCase() + palabra.slice(1).toLowerCase()).join(" ");
            return nombreFormateado;
        },
        hanlderFoptions(){
            this.fOptions=!this.fOptions;
        }
    },
};

</script>
