<template>
    <forms-layout :active="20">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Crear URL corta</h4>
                <img src="staticAssets/formBuilder/shortlink.png" style="max-width: 130px">
            </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-xl-6">
                        <label class="mr-sm-2">URL o link para acortar</label>
                        <input type="text" class="form-control" placeholder="https://ejemplo.com" v-model="url" name="link"/>
                    </div>
                    <div class="form-group col-xl-6">
                        <label class="mr-sm-2">Días de expiración</label>
                        <input type="number" class="form-control" placeholder="Escribe aquí" v-model="diasExpiracion" name="dias_expiracion"/>
                        <small>Máximo 120 días de vigencia</small>
                    </div>
                    <div class="col-12" v-if="urlCorta !== ''">
                        <div class="my-2">
                            <label class="mr-sm-2">URL corta</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" readonly :value="urlCorta">
                                <div class="input-group-append">
                                    <button @click="copyLink" class="btn btn-outline-secondary" type="button"><i class="fas fa-copy mr-1"></i>Copiar</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-group col-12 text-right">
                        <button class="btn btn-success pl-5 pr-5" @click="createUrl">Generar URL</button>
                    </div>
                </div>
            </div>
        </div>
    </forms-layout>
</template>

<script>
import formsLayout from "../components/shortlinkLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";

export default {
    components: {
        formsLayout
    },
    data() {
        return {
            url: '',
            diasExpiracion: 90,
            urlCorta: '',
        };
    },
    mounted() {
    },
    methods: {
        createUrl() {
            const self = this;

            API.showConfirm('¿Está seguro de crear la URL?', '', function () {
                API.send('POST', 'short-link/create', {
                        url: self.url,
                        diasExpiracion: self.diasExpiracion,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.urlCorta = data.data.shortUrl;
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },
        copyLink() {
            Tools.copyToClipboard(this.urlCorta, 'Enlace copiado al portapapeles');
        }
    },
};
</script>
