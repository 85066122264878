<script>
   import Box from "src/modules/medico/views/fichas/Box.vue";
   import Modal from './Modal.vue';
   import {mapActions,mapGetters} from "vuex";
   import API from "src/core/Api";
   import {IfrEvents} from "src/core/IfrEvents";
   import {store} from "src/store";
   import dayjs from "dayjs";
   import { VueperSlides, VueperSlide } from 'vueperslides'
   import {DatePicker} from 'v-calendar';
   import 'vueperslides/dist/vueperslides.css'
   import DicomViewer from 'src/modules/medico/dicom-viewer/DicomViewer.vue';

   export default { 
 data() {
        return {
        //data
        urlViwer: 'https://dicom.isi.clinic',
        urlDicomFile:"/",
        checkDeleteProcess: false,
        delProcess:false,
        keyProcessDelete:0,
        modalAction: '',
        timeline: true,
        ShowImg:true,
        modal:false,
        showMri:false,
        editIndex:0,
        datos_principales_urgencia:{
          nombre:'',
          fecha_de_nacimimento:'',
          numero_afiliacion: '',
          numero_boleta: '',
          tipo_evento: '',
          problema: '',
          medico_asignado: '',
        },
        procedimientos:{
          data_index: 0,
          content:{}
        },
        imagenes:[],
        medicamentos:[],
        rayosx:[],
        diagnosticos:[],
        notas:[]
 }},
 components:{
  Box,
  Modal,
  VueperSlides,
  VueperSlide,
  DatePicker,
  DicomViewer
 },
 mounted(){

  const self = this;

  // Cargo datos principales de Urgencia
  Object.keys(self.datos_principales_urgencia).map(function (value) {
          if (self.GetMedicoFichaActive.metadata.datos_principales_urgencia) {
                if (self.GetMedicoFichaActive.metadata.datos_principales_urgencia["datos_principales_urgencia_"+value]) self.datos_principales_urgencia[value] = self.GetMedicoFichaActive.metadata.datos_principales_urgencia["datos_principales_urgencia_"+value];
          }
        });
        if (self.GetMedicoFichaActive.metadata.procedimientos) {  
          this.setProcessData();
          this.setAllTables();
        }



 },
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
 },
 methods:{
    ...mapActions({
  }),
  dayjs,
  deleteMetada(){
    const self = this;
    API.send('POST', 'orders/metadata/deleteBySlug', {
                        orderId: self.GetMedicoFichaActive.orden.id,
                        slug: 'procedimientos',
                    }, function (response) {
                        self.saveProcessData();
                       
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });

  },
  setAllTables(){
      const self = this;

      self.imagenes=[]
      self.medicamentos=[]
      self.rayosx=[]
      self.diagnosticos=[]
      self.notas=[]

      if(Object.keys(self.procedimientos.content).length){
        
        Object.keys(self.procedimientos.content).map(function(key){
          if (Object.keys(self.procedimientos.content[key].imagenes).length) {
            Object.keys(self.procedimientos.content[key].imagenes).map(function(img) {
              self.imagenes.push(self.procedimientos.content[key].imagenes[img]);
              self.imagenes[self.imagenes.length-1].procesoKey=key;
            });
          }
          if (Object.keys(self.procedimientos.content[key].medicamentos).length) {
            Object.keys(self.procedimientos.content[key].medicamentos).map(function(med) {
              self.medicamentos.push(self.procedimientos.content[key].medicamentos[med]);
              self.medicamentos[self.medicamentos.length-1].procesoKey=key;
            });
          }
          if (Object.keys(self.procedimientos.content[key].rayosx).length) {
            Object.keys(self.procedimientos.content[key].rayosx).map(function(ray) {
              self.rayosx.push(self.procedimientos.content[key].rayosx[ray]);
              self.rayosx[self.rayosx.length-1].procesoKey=key;
            });
          }
          if (Object.keys(self.procedimientos.content[key].diagnosticos).length) {
            Object.keys(self.procedimientos.content[key].diagnosticos).map(function(diag) {
              self.diagnosticos.push(self.procedimientos.content[key].diagnosticos[diag]);
              self.diagnosticos[self.diagnosticos.length-1].procesoKey=key;
            });
          }
          if (Object.keys(self.procedimientos.content[key].nota_observacion).length) {
            Object.keys(self.procedimientos.content[key].nota_observacion).map(function(nota) {
              self.notas.push(self.procedimientos.content[key].nota_observacion[nota]);
              self.notas[self.notas.length-1].procesoKey=key;
            });
          }

        });

      }
  },
  handlerDicomViewer({url,action}){

  if (action) {
    this.showMri=true;
    this.urlDicomFile=url || '';
    this.$nextTick(() => {;
  const eventHandler = IfrEvents.getInstance();

  eventHandler.sendDataToChild('token_Message', {
    iframe_id: 'dicom-viewer', // id del iframe hijo
    data: {
        token: store.getters.AuthGetToken || '',
        version: store.getters.WlCache + '-' + store.getters.StCache || '',
        domain: 'https://app.isi.clinic/' || '',
        wlInfo:getters.whiteLabelInfo || ''
    },
    // target_domain: '*'
    target_domain: this.urlViwer+this.urlDicomFile, // dominio del iframe hijo, no se recomienda *
    success: function () {
        console.log('se envió data con éxito');
    },
    error: function () {
        console.log('falló el envío de data');
    }
});

  });


  }else {
    this.showMri=false;
  }
},
  handleModal(action, key){

    if (action) {
        this.modalAction=action;
    }else{
      this.modalAction='';
    }

    if (this.modalAction=='edit'){
      key && (this.editIndex=Number(key));
    }else if (this.modalAction==='cancel') {
      this.cancelAddProcess();
      this.editIndex=0;
    }else if (this.modalAction==='add'){
      this.addProcess();
      this.editIndex=Number(this.procedimientos.data_index);
    }else if(this.modalAction==='save' || this.modalAction==='save_noclose'){
      if(this.procedimientos.content[this.editIndex].primary_data.nombre == '' || this.procedimientos.content[this.editIndex].primary_data.fecha_hora == '' || this.procedimientos.content[this.editIndex].primary_data.descripcion == ''){
        API.showErrorAlert('Es necesario completar los datos primarios');
        this.setProcessData();
      }else{
        this.saveProcessData();
        if (this.modalAction==='save') {
        this.modalAction='';
        }
      }
      
    }


    if (this.modalAction!=='save_noclose') {
      this.modal=!this.modal;
    }
    
    
  },
  changeTimeline(){
    this.timeline = !this.timeline;
  },
  changeShowImg(){
    this.ShowImg = !this.ShowImg;
  },
  savePricipalData(){
    const self = this;

    const arrToSave = {};
            Object.keys(self.datos_principales_urgencia).map(function (value) {
                      arrToSave["datos_principales_urgencia_"+value] = self.datos_principales_urgencia[value];
                  });

      API.send('POST', 'orders/metadata/save/', {
          orderId: self.GetMedicoFichaActive.orden.id,
          slug: 'datos_principales_urgencia',
          detail: arrToSave,
          uniqueMeta: 1,
      }, function (response) {
          API.showSuccessAlert(response.msg);
      },
      function (response) {
          API.showErrorAlert(response.msg);
      });
  },
  calcularEdad(fechaNacimiento) {

  if (!fechaNacimiento) {
    return "Ingrese fecha nacimiento"
  }
  
  const fechaNacimientoObjeto = new Date(fechaNacimiento);

  const fechaActual = new Date();

  let edad = fechaActual.getFullYear() - fechaNacimientoObjeto.getFullYear();
  //Si ya cumplio o no años
  if (
    fechaActual.getMonth() < fechaNacimientoObjeto.getMonth() ||
    (fechaActual.getMonth() === fechaNacimientoObjeto.getMonth() &&
      fechaActual.getDate() < fechaNacimientoObjeto.getDate())
  ) {
    edad--;
  }
  if (edad >= 0) {
    return edad;
  }else{
    return "Fecha de nacimiento invalida"
  }
},
addProcess(){
  const self = this;

  self.procedimientos.data_index=Number(self.procedimientos.data_index)+1;

  self.procedimientos.content[self.procedimientos.data_index]={
            primary_data:{
              nombre: '',
              fecha_hora: '',
              orden: '',
              descripcion: '',
            },
            imagenes:{
              // 1:{
              //   nombre: '',
              //   descripcion: '',
              //   url:'',
              // }
            },
            medicamentos:{
            //  1:{
            //   id: 0,
            //   codigo: 0,
            //   descripcion: '',
            //   ingrediente_activo: '',
            //   tamaño: '',
            //   concentracion: '',
            //   presentacion: '',
            //   estado: ''
            //  }
            },
            rayosx:{
              // 1:{
              //   codigo: '',
              //   descripcion: '',
              //   usuario: '',
              //   url: '',
              // }
            },
            diagnosticos:{
              // 1:{
              //   codigo: '',
              //   descripcion: '',
              // }
            },
            nota_observacion:{
              // 1:{
              //   titulo:'',
              //   descripcion: '',
              // }
            }
  }
 
},
cancelAddProcess(){
  const self = this;
  delete self.procedimientos.content[self.procedimientos.data_index]
  self.procedimientos.data_index=Number(self.procedimientos.data_index)-1;  
},
saveProcessData(){
  const self = this;
    const arrToSave = {};

            arrToSave['procedimientos_data_index']=self.procedimientos.data_index;

            Object.keys(self.procedimientos.content).map(function (value) {
              const item =  self.procedimientos.content[value];
              //Formateo data primaria
                      arrToSave['procedimientos_content'+value+'_primary_data_nombre'] = item.primary_data.nombre;
                      arrToSave['procedimientos_content'+value+'_primary_data_fecha_hora'] = item.primary_data.fecha_hora;
                      arrToSave['procedimientos_content'+value+'_primary_data_orden'] = item.primary_data.orden;
                      arrToSave['procedimientos_content'+value+'_primary_data_descripcion'] = item.primary_data.descripcion;
              //Formateo imagenes
              Object.keys(item.imagenes).map(function (valueImg) {
                const imgData = item.imagenes[valueImg];
                arrToSave['procedimientos_content'+value+'_imagenes'+valueImg+'_nombre'] = imgData.nombre;
                arrToSave['procedimientos_content'+value+'_imagenes'+valueImg+'_descripcion'] = imgData.descripcion;
                arrToSave['procedimientos_content'+value+'_imagenes'+valueImg+'_url'] = imgData.url;
              });
              //Formateo medicamentos
              Object.keys(item.medicamentos).map(function (valueMedic) {
                const medicData = item.medicamentos[valueMedic];
                arrToSave['procedimientos_content'+value+'_medicamentos'+valueMedic+'_id'] = medicData.id;
                arrToSave['procedimientos_content'+value+'_medicamentos'+valueMedic+'_codigo'] = medicData.codigo;
                arrToSave['procedimientos_content'+value+'_medicamentos'+valueMedic+'_descripcion'] = medicData.descripcion;
                arrToSave['procedimientos_content'+value+'_medicamentos'+valueMedic+'_ingrediente_activo'] = medicData.ingrediente_activo;
                arrToSave['procedimientos_content'+value+'_medicamentos'+valueMedic+'_tamaño'] = medicData.tamaño;
                arrToSave['procedimientos_content'+value+'_medicamentos'+valueMedic+'_concentracion'] = medicData.concentracion;
                arrToSave['procedimientos_content'+value+'_medicamentos'+valueMedic+'_presentacion'] = medicData.presentacion;
                arrToSave['procedimientos_content'+value+'_medicamentos'+valueMedic+'_estado'] = medicData.estado;
              });
              //Formateo RayosX
              Object.keys(item.rayosx).map(function (valueRay) {
                const rayData = item.rayosx[valueRay];
                arrToSave['procedimientos_content'+value+'_rayosx'+valueRay+'_codigo'] = rayData.codigo;
                arrToSave['procedimientos_content'+value+'_rayosx'+valueRay+'_descripcion'] = rayData.descripcion;
                arrToSave['procedimientos_content'+value+'_rayosx'+valueRay+'_usuario'] = rayData.usuario;
                arrToSave['procedimientos_content'+value+'_rayosx'+valueRay+'_url'] = rayData.url;
              });
              //Formateo diagnosticos
              Object.keys(item.diagnosticos).map(function (valueDiag) {
                const diagData = item.diagnosticos[valueDiag];
                arrToSave['procedimientos_content'+value+'_diagnosticos'+valueDiag+'_codigo'] = diagData.codigo;
                arrToSave['procedimientos_content'+value+'_diagnosticos'+valueDiag+'_descripcion'] = diagData.descripcion;
              });
              //Formateo notas u observaciones
              Object.keys(item.nota_observacion).map(function (valueNotas) {
                const notasData = item.nota_observacion[valueNotas];
                arrToSave['procedimientos_content'+value+'_nota_observacion'+valueNotas+'_titulo'] = notasData.titulo;
                arrToSave['procedimientos_content'+value+'_nota_observacion'+valueNotas+'_descripcion'] = notasData.descripcion;
              });
                  });

      API.send('POST', 'orders/metadata/save/', {
          orderId: self.GetMedicoFichaActive.orden.id,
          slug: 'procedimientos',
          detail: arrToSave,
          uniqueMeta: 1,
      }, function (response) {
          API.showSuccessAlert(response.msg);
          self.setAllTables()
      },
      function (response) {
          API.showErrorAlert(response.msg);
      });
},
setProcessData(){
    const self = this;

    self.procedimientos.data_index=self.GetMedicoFichaActive.metadata.procedimientos.procedimientos_data_index;

    Object.keys(self.GetMedicoFichaActive.metadata.procedimientos).forEach(function (key) {
      if (key.startsWith('procedimientos_content')) {
        const index = key.match(/\d+/)[0];
        if (index) {
        if (!self.procedimientos.content[index]) {
          self.procedimientos.content[index] = {};
        }

        // Asignar valores a primary_data
        self.procedimientos.content[index].primary_data = {
          nombre: self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_primary_data_nombre'],
          fecha_hora: self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_primary_data_fecha_hora'],
          orden: self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_primary_data_orden'],
          descripcion: self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_primary_data_descripcion'],
        };

        // Inicializar la propiedad imagenes si aún no existe
        if (!self.procedimientos.content[index].imagenes) {
          self.procedimientos.content[index].imagenes={};
        }

        // Asignar valores a imagenes
        Object.keys(self.GetMedicoFichaActive.metadata.procedimientos).forEach(function (imgKey) {
          if (imgKey.startsWith('procedimientos_content' + index + '_imagenes')) {
            const imgIndex = imgKey.match(/\d+/g)[1];
            if (imgIndex) {
            if (!self.procedimientos.content[index].imagenes[imgIndex]) {
              self.procedimientos.content[index].imagenes[imgIndex]={};
            }

            // Asignar valores específicos a imagenes
            self.procedimientos.content[index].imagenes[imgIndex].nombre = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_imagenes'+imgIndex+'_nombre'];
            self.procedimientos.content[index].imagenes[imgIndex].descripcion = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_imagenes'+imgIndex+'_descripcion'];
            self.procedimientos.content[index].imagenes[imgIndex].url = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_imagenes'+imgIndex+'_url'];
          }
          }
        });

        // Inicializar la propiedad medicamentos si aún no existe
        if (!self.procedimientos.content[index].medicamentos) {
          self.procedimientos.content[index].medicamentos={};
        }

        Object.keys(self.GetMedicoFichaActive.metadata.procedimientos).forEach(function (rayKey) {
          if (rayKey.startsWith('procedimientos_content' + index + '_medicamentos')) {
            const medicIndex = rayKey.match(/\d+/g)[1];
            if (medicIndex) {
            if (!self.procedimientos.content[index].medicamentos[medicIndex]) {
              self.procedimientos.content[index].medicamentos[medicIndex]={};
            }

            // Asignar valores específicos a medicamentos
            self.procedimientos.content[index].medicamentos[medicIndex].id = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_medicamentos'+medicIndex+'_id'];
            self.procedimientos.content[index].medicamentos[medicIndex].codigo = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_medicamentos'+medicIndex+'_codigo'];
            self.procedimientos.content[index].medicamentos[medicIndex].descripcion = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_medicamentos'+medicIndex+'_descripcion'];
            self.procedimientos.content[index].medicamentos[medicIndex].ingrediente_activo = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_medicamentos'+medicIndex+'_ingrediente_activo'];
            self.procedimientos.content[index].medicamentos[medicIndex].tamaño = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_medicamentos'+medicIndex+'_tamaño'];
            self.procedimientos.content[index].medicamentos[medicIndex].concentracion = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_medicamentos'+medicIndex+'_concentracion'];
            self.procedimientos.content[index].medicamentos[medicIndex].presentacion = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_medicamentos'+medicIndex+'_presentacion'];
            self.procedimientos.content[index].medicamentos[medicIndex].estado = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_medicamentos'+medicIndex+'_estado'];
       
          }
          }
        });

        // Inicializar la propiedad rayosx si aún no existe
        if (!self.procedimientos.content[index].rayosx) {
          self.procedimientos.content[index].rayosx={};
        }

        Object.keys(self.GetMedicoFichaActive.metadata.procedimientos).forEach(function (rayKey) {
          if (rayKey.startsWith('procedimientos_content' + index + '_rayosx')) {
            const rayIndex = rayKey.match(/\d+/g)[1];
            if (rayIndex) {
            if (!self.procedimientos.content[index].rayosx[rayIndex]) {
              self.procedimientos.content[index].rayosx[rayIndex]={};
            }

            // Asignar valores específicos a rayosx
            self.procedimientos.content[index].rayosx[rayIndex].codigo = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_rayosx'+rayIndex+'_codigo'];
            self.procedimientos.content[index].rayosx[rayIndex].descripcion = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_rayosx'+rayIndex+'_descripcion'];
            self.procedimientos.content[index].rayosx[rayIndex].usuario = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_rayosx'+rayIndex+'_usuario'];
            self.procedimientos.content[index].rayosx[rayIndex].url = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_rayosx'+rayIndex+'_url'];
          }
          }
        });


        // Inicializar la propiedad diagnosticos si aún no existe
        if (!self.procedimientos.content[index].diagnosticos) {
          self.procedimientos.content[index].diagnosticos={};
        }

        Object.keys(self.GetMedicoFichaActive.metadata.procedimientos).forEach(function (diagKey) {
          if (diagKey.startsWith('procedimientos_content' + index + '_diagnosticos')) {
            const diagIndex = diagKey.match(/\d+/g)[1];
            if (diagIndex) {
            if (!self.procedimientos.content[index].diagnosticos[diagIndex]) {
              self.procedimientos.content[index].diagnosticos[diagIndex]={};
            }

            // Asignar valores específicos a diagnosticos
            self.procedimientos.content[index].diagnosticos[diagIndex].codigo = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_diagnosticos'+diagIndex+'_codigo'];
            self.procedimientos.content[index].diagnosticos[diagIndex].descripcion = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_diagnosticos'+diagIndex+'_descripcion'];
          }
          }
        });

        // Inicializar la propiedad notas u observaciones si aún no existe
        if (!self.procedimientos.content[index].nota_observacion) {
          self.procedimientos.content[index].nota_observacion={};
        }

        Object.keys(self.GetMedicoFichaActive.metadata.procedimientos).forEach(function (notasKey) {
          if (notasKey.startsWith('procedimientos_content' + index + '_nota_observacion')) {
            const notasIndex = notasKey.match(/\d+/g)[1];
            if (notasIndex) {
            if (!self.procedimientos.content[index].nota_observacion[notasIndex]) {
              self.procedimientos.content[index].nota_observacion[notasIndex]={};
            }

            // Asignar valores específicos a nota_observacion
            self.procedimientos.content[index].nota_observacion[notasIndex].titulo = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_nota_observacion'+notasIndex+'_titulo'];
            self.procedimientos.content[index].nota_observacion[notasIndex].descripcion = self.GetMedicoFichaActive.metadata.procedimientos['procedimientos_content'+index+'_nota_observacion'+notasIndex+'_descripcion'];
          }
          }
        });

      }
      } 
    });

  },
  capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  },
  askDeleteProcess(key){
    this.checkDeleteProcess=true;
    this.keyProcessDelete=key;
  },
  deleteProcess(action){
    if (action) {
      delete this.procedimientos.content[this.keyProcessDelete]
      this.deleteMetada();
      this.checkDeleteProcess=false;
      this.keyProcessDelete=0
    }else{
      this.checkDeleteProcess=false;
      this.keyProcessDelete=0
    }

  }

},
      
}

</script>
 
 
<template>
  <box  title="Urgencia" icon="fa-solid fa-truck-medical">
    <section class="row">
      <div class="form-group col-12 col-md-4">
                    <label>Nombre</label>
                    <input type="text" class="form-control" v-model="datos_principales_urgencia.nombre" @change="savePricipalData"/>
      </div>
      <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>Fecha nacimiento</label>
                    <date-picker v-model="datos_principales_urgencia.fecha_de_nacimimento" mode="date" @dayclick="savePricipalData"  :timezone="'UTC'" >
                        <template #default="{ inputValue, inputEvents }">
                            <input :value="inputValue" v-on="inputEvents" class="form-control" @change="savePricipalData"/>
                        </template>
                    </date-picker>
                </div>
            </div>
      <div class="form-group col-12 col-md-4">
                    <label>Edad</label>
                    <input type="text" class="form-control" :value="calcularEdad(datos_principales_urgencia.fecha_de_nacimimento)" disabled />
      </div>
      <div class="form-group col-12 col-md-4">
                    <label>Numero de afiliación</label>
                    <input type="text" class="form-control" v-model="datos_principales_urgencia.numero_afiliacion" @change="savePricipalData"/>
      </div>
      <div class="form-group col-12 col-md-4">
                    <label>Numero de boleta</label>
                    <input type="text" class="form-control" v-model="datos_principales_urgencia.numero_boleta" @change="savePricipalData"/>
      </div>
      <div class="form-group col-12 col-md-4">
                    <label>Tipo de evento</label>
                    <input type="text" class="form-control" v-model="datos_principales_urgencia.tipo_evento" @change="savePricipalData"/>
      </div>
      <div class="form-group col-12 col-md-4">
                    <label>Problema</label>
                    <input type="text" class="form-control" v-model="datos_principales_urgencia.problema" @change="savePricipalData"/>
      </div>
      <div class="form-group col-12 col-md-4">
                    <label>Medico asignado</label>
                    <input type="text" class="form-control" v-model="datos_principales_urgencia.medico_asignado" @change="savePricipalData"/>
      </div>
    </section>
  </box>
  <box  title="Precedimientos" icon="fa-solid fa-pager">
    <modal @deleteMetada="deleteMetada" @saveAnychages="saveProcessData" v-if="modal" :editIndex="editIndex" :procedimientos="procedimientos" :modalAction="modalAction" @handleModal="handleModal"></modal>
    <section v-if="Object.keys(procedimientos.content).length" class="row tl">

    <div class="changetimeline" @click="changeTimeline">
      <p v-if="timeline" class="timeline-title">Ocultar linea de tiempo  <i class="fa-solid fa-heart-pulse"></i></p>
      <p v-else> Ver linea de tiempo  <i class="fa-solid fa-heart-pulse"></i></p>
      <i v-if="timeline" class="fa-solid fa-eye-slash"></i>
      <i v-else class="fa-solid fa-eye"></i>
    </div>

    <ul v-if="timeline" class="col-12">
        <li v-for="(item, key) in procedimientos.content" class="tlbox" style="--accent-color:#0d6efd">
            <div class="timeheader">{{ capitalizeFirstLetter(item.primary_data.nombre) }}</div>
            <div class="row">
              <div class="col-9">
                  <div class="timeDate">{{ dayjs(item.primary_data.fecha_hora).format('DD-MMMM-YYYY H:mm:ss', 'es') || '' }}</div>
                  <div class="timeDescrip">{{ item.primary_data.descripcion }}</div>
              </div>
              <div class="col-3 d-flex flex-column justify-content-start align-items-end flex-wrap mt-2">
                <div class="d-flex justify-content-center m-2 flex-wrap text-success">
                  <i v-if="Object.keys(item.imagenes).length" title="Contiene Imagenes" class="fa-solid fa-image m-2"></i>
                  <i v-if="Object.keys(item.medicamentos).length" title="Contiene medicamentos" class="fa-solid fa-tablets m-2"></i>
                  <i v-if="Object.keys(item.rayosx).length" title="Contiene Rayos X" class="fa-solid fa-prescription m-2"></i>
                  <i v-if="Object.keys(item.diagnosticos).length" title="Contiene Diagnosticos" class="fa-solid fa-hand-holding-medical m-2"></i>
                  <i v-if="Object.keys(item.nota_observacion).length" title="Contiene Notas u observaciones" class="fa-solid fa-comment-medical m-2"></i>
                </div>
                <button @click="handleModal('edit',key)" type="button" class="btn btn-primary mx-3 my-2 px-2" data-toggle="modal" data-target="#detail">Ver detalles  <i class="fa-solid fa-circle-info"></i></button>
              </div>
            </div>
        </li>
    </ul>
  
    </section>
    <section v-else class="d-flex justify-content-center">
      <p class=" text-muted">Este paciente aún no tiene procedimientos agregados</p>
    </section>
    <section class="row">
      <div v-if="Object.keys(procedimientos.content).length" class="col-12 d-flex flex-column align-items-center">
      <div v-if="checkDeleteProcess" class="card askDelete" style="width: 18rem;">
      <div class="card-body d-flex flex-column justify-content-center">
        <h5 class="card-title mb-2 text-center">¿Desea eliminar este procedimiento?</h5>
        <div class="d-flex justify-content-center m-1">
          <button @click="deleteProcess(true)" type="button" class="btn btn-primary px-3 m-1">Si</button>
          <button @click="deleteProcess(false)" type="button" class="btn btn-danger px-3 m-1">No</button>
        </div>
      </div>
      </div>
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col" class=" font-weight-bold">Nombre de procedimiento</th>
              <th scope="col" class=" font-weight-bold">Orden</th>
              <th scope="col" class=" font-weight-bold">Opción</th>
            </tr>
          </thead>
          <tbody class="table-hover">
            <tr v-for="(item, key) in procedimientos.content" :key="key">
              <td>{{ item.primary_data.nombre }}</td>
              <td>{{ item.primary_data.orden }}</td>
              <td>
                <div class="options">
                   <i @click="handleModal('edit',key)" class="fa-solid fa-pen-to-square text-success cursor-pointer"></i>
                   <i @click="askDeleteProcess(key)" class="fa-solid fa-trash-can text-danger"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="button-place">
        <button @click="handleModal('add')" class="btn btn-primary">Agregar procedimiento <i class="fa-solid fa-pager"></i></button>
      </div>
    </section>
  </box>

  <box  title="Imagenes" icon="fa-solid fa-image">
    <div v-if="imagenes.length" class="changetimeline" @click="changeShowImg">
      <p v-if="ShowImg" class="timeline-title">Ocultar imagenes <i class="fa-solid fa-image"></i></p>
      <p v-else> Ver imagenes  <i class="fa-solid fa-image"></i></p>
      <i v-if="ShowImg" class="fa-solid fa-eye-slash"></i>
      <i v-else class="fa-solid fa-eye"></i>
    </div>
    <section v-if="ShowImg" class="Mri-viewer">
      
              <table v-if="imagenes.length" class="table">
                <thead class="thead-light">
                  <tr>
                    <th scope="col" class=" font-weight-bold">Nombre</th>
                    <th scope="col" class=" font-weight-bold">Descripción</th>
                    <th scope="col" class=" font-weight-bold">Opción</th>
                  </tr>
                </thead>
                <tbody class="table-hover">
                  <tr v-for="(item, key) in imagenes" :key="key">
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.descripcion }}</td>
                    <td>
                      <div class="options">
                         <i @click="handleModal('edit',key)" class="fa-solid fa-pen-to-square text-success cursor-pointer"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class=" d-flex justify-content-center">
                <p class="text-muted">No hay imagenes agregadas aún</p>
              </div>
      <div class="button-mri">

        <button type="button" @click="handlerDicomViewer({url:'',action:true})" class="btn btn-primary openMri">Abrir Mri-Viewer</button>
      </div>
      <!-- <div v-if="showMri" class="Mri-app">s
      <button type="button" @click="handlerMri({url:'',action:false})" class="btn btn-danger close-mri px-3"><i class="fa-solid fa-xmark"></i></button>

      <div class="Mri-content">
        <iframe ref="mriIframe" id="mriIframe" :src="urlViwer+urlDicomFile" frameborder="0" class="app-iframe"></iframe>
      </div>
      </div> -->
      <DicomViewer v-if="showMri" :FileUrl="urlDicomFile" @handlerDicomViewer="handlerDicomViewer"/>
    </section>
  </box>

  <box  title="Medicamentos" icon="fa-solid fa-tablets">
    <section class="row">
      <div class="col-12 d-flex justify-content-center">
        <table v-if="medicamentos.length" class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="font-weight-bold">Id</th>
              <th scope="col" class="font-weight-bold">Código de barra</th>
              <th scope="col" class="font-weight-bold">Descripción</th>
              <th scope="col" class="font-weight-bold">Ingrediente activo</th>
              <th scope="col" class="font-weight-bold">Tamaño</th>
              <th scope="col" class="font-weight-bold">Concentración</th>
              <th scope="col" class="font-weight-bold">Presentación</th>
            </tr>
          </thead>
          <tbody class="table-hover">
            <tr v-for="item in medicamentos">
              <td>{{ item.id }}</td>
              <td>{{ item.codigo }}</td>
              <td>{{ item.descripcion }}</td>
              <td>{{ item.ingrediente_activo }}</td>
              <td>{{ item.tamaño }}</td>
              <td>{{ item.concentracion }}</td>
              <td>{{ item.presentacion }}</td>
              <td>
                <td v-if="item.estado==='Usado'" class="text-primary">Aplicado <i v-if="item.presentacion==='COMPRIMIDO'" class="fa-solid fa-tablets text-primary"></i><i v-if="item.presentacion==='INYECTABLE'" class="fa-solid fa-syringe text-primary"></i></td>
              <td v-else class="text-warning">No aplicado <i v-if="item.presentacion==='COMPRIMIDO'" class="fa-solid fa-tablets text-warning"></i><i v-if="item.presentacion==='INYECTABLE'" class="fa-solid fa-syringe text-warning"></i></td>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class=" d-flex justify-content-center">
          <p class="text-muted">No hay medicamentos agregados aún</p>
        </div>
      </div>
    </section>
  </box>
  <box  title="Rayos X" icon="fa-solid fa-prescription">
    <section class="row">
      <div class="col-12 d-flex justify-content-center">
        <table v-if="rayosx.length" class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="font-weight-bold">Código</th>
              <th scope="col" class="font-weight-bold">Descripción</th>
              <th scope="col" class="font-weight-bold">Usuario</th>
            </tr>
          </thead>
          <tbody class="table-hover">
            <tr v-for="item in rayosx">
              <td>{{ item.codigo }}</td>
              <td>{{ item.descripcion }}</td>
              <td>{{ item.usuario }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else class=" d-flex justify-content-center">
          <p class="text-muted">No hay rayos x agregados aún</p>
        </div>
      </div>
    </section>
  </box>
  <box  title="Diagnosticos" icon="fa-solid fa-hand-holding-medical">
    <section class="row">
      <div class="col-12 d-flex justify-content-center">
        <table v-if="diagnosticos.length"  class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="font-weight-bold">Código</th>
              <th scope="col" class="font-weight-bold">Descripción</th>
              <th scope="col" class="font-weight-bold">Procedimiento</th>
            </tr>
          </thead>
          <tbody class="table-hover">
            <tr v-for="item in diagnosticos">
              <td>{{ item.codigo }}</td>
              <td>{{ item.descripcion }}</td>
              <td>{{ procedimientos.content[item.procesoKey].primary_data.nombre }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else class=" d-flex justify-content-center">
          <p class="text-muted">No hay diagnosticos agregados aún</p>
        </div>
      </div>
    </section>
  </box>
  <box  title="Nota u Observaciones" icon="fa-solid fa-comment-medical">
    <section v-if="notas.length" class="row m-2 gap-10">
      <div v-for="nota in notas" class="card col-4 m-2" :key="nota.index">
          <div class="card-body">
            <h5 class="card-title mb-2">{{ nota.titulo }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{  nota.Process }}</h6>
            <p class="card-text">{{  nota.descripcion }}</p>
          </div>
      </div>
    </section>
    <div v-else class=" d-flex justify-content-center">
          <p class="text-muted">No hay nota u observaciones agregadas aún</p>
    </div>
  </box>
</template>
 
 
<style scoped>
.button-mri{
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: start;
  padding: 10px 0 0 15px;
}
.button-place{
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: start;
  padding: 10px 0 0 30px;
}
.Mri-viewer{
  position: relative;
}
.close-mri{
  position: fixed;
  top: 20px;
  right: 10px;
  font-size: 20px;
  transition: 0.3s;
}

.close-mri:hover{
  transform: scale(1.2);
}

.Mri-app{
  z-index: 9999;
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(3px);
}

.Mri-content{
  width: 90%;
  height: 95%;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.app-iframe{
  width: 100%;
  height: 100%;
}
.card{
  min-width: 10rem;
  width: 17em;
  max-width: 20rem;
 }

.tl,
.tl::before,
.tl::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.tl {
  display: grid;
  align-content: center;
  gap: 2rem;
}

.tl h2 {
  text-align: center;
}

.tl ul {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: min(60rem, 90%);
  margin-inline: auto;
}

/* line */
.tl ul::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
.tl ul li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
.tl ul li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

/* timeheader */
.tl ul li .timeheader {
  --dateH: 1.5rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);

  text-align: center;
  background-color: var(--accent-color);

  color: white;
  font-size: 1rem;
  font-weight: 500;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* timeheader flap */
.tl ul li .timeheader::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;

  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

/* circle */
.tl ul li .timeheader::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid var(--accent-color);
  border-radius: 50%;
  top: 50%;

  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* timeDate timeDescrip */
.tl ul li .timeDate,
.tl ul li .timeDescrip {
  background: var(--bgColor);
  position: relative;
  padding-inline: 1.5rem;
}
.tl ul li .timeDate {
  overflow: hidden;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
  font-weight: 500;
}
.tl ul li .timeDescrip {
  padding-block-end: 1.5rem;
  font-weight: 300;
}

/* shadows */
.tl ul li .timeDate::before,
.tl ul li .timeDescrip::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}
.tl ul li .timeDate::before {
  bottom: calc(100% + 0.125rem);
}

.tl ul li .timeDescrip::before {
  z-index: -1;
  bottom: 0.25rem;
}

@media (min-width: 2040px) {
  .tl ul {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }
  .tl ul::before {
    grid-column: 2;
  }
  .tl ul li:nth-child(odd) {
    grid-column: 1;
  }
  .tl ul li:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  .tl ul li:nth-child(2) {
    grid-row: 2/4;
  }

  .tl ul li:nth-child(odd) .timeheader::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  .tl ul li:nth-child(odd) .timeheader::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  .tl ul li:nth-child(odd) .timeheader {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}

.changetimeline{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px 2px 10px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.144);
  height: 40px;
  padding:0 10px;
}

.changetimeline:hover{
  background-color: rgba(145, 144, 157, 0.097);
}

.timeline-title{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
}

.changetimeline p{
  margin: 0;
}

.options{
  display:flex;
  align-items: center;
  gap: 10px;
}

.options i{
  transition: 0.3s;
  font-size: 1.5rem;
}
.options i:hover{
  transform: scale(1.2);
}

.tlbox button{
  height: 40px;
}

.askDelete{
  position: absolute;
  z-index: 9999;
  left: calc( 50% - 144px );
  top: 20%;
}

</style>