<template>
    <div class="sidebar sidebarpc">
        <div class="d-flex align-items-center mb-3">
            <router-link class="brand-logo text-center" to="/dashboard">
                <img :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre" style="margin: auto" class=""/>
            </router-link>
        </div>
        <div class="menu">
            <div class="usernameMenu text-success">
                <div class="text-truncate">
                    <i class="fa fa-location-pin mr-2"></i> {{AuthGetUserInfo.s}}
                </div>
            </div>
            <ul>
                <li :class="active === 1 && 'active'" v-if="Access('reportes-dashboard')">
                    <router-link to="/dashboard" :class="active === 1 && 'active'">
                        <span><i class="mdi mdi-view-dashboard"></i></span>
                        <span class="nav-text">Inicio</span>
                    </router-link>
                </li>
                <li :class="active === 2 && 'active'" v-if="Access('orders-pos')">
                    <router-link to="/order/creation" :class="active === 2 && 'active'">
                        <span><i class="la la-cash-register"></i></span>
                        <span class="nav-text">Punto de venta</span>
                        <!--<span><i class="la la-lock ml-2 text-danger"></i></span>-->
                    </router-link>
                </li>
                <li :class="active === 31 && 'active'" v-if="Access('orders-listado')">
                    <router-link to="/orders" :class="active === 31 && 'active'">
                        <span><i class="la la-file-invoice-dollar"></i></span>
                        <span class="nav-text">Ordenes</span>
                    </router-link>
                </li>
                <li :class="active === 40 && 'active'" v-if="Access('clients-administrar-crear_editar')">
                    <router-link to="/clients" :class="active === 90 && 'active'">
                        <span><i class="mdi mdi-account"></i></span>
                        <span class="nav-text">Clientes</span>
                    </router-link>
                </li>
                <li :class="active === 30 && 'active'" v-if="Access('products-productos-listado')">
                    <router-link to="/products" :class="active === 30 && 'active'">
                        <span><i class="mdi mdi-archive"></i></span>
                        <span class="nav-text">Productos y servicios</span>
                    </router-link>
                </li>
                <li :class="active === 70 && 'active'" v-if="Access('reportes-reportes')">
                    <router-link to="/reports" :class="active === 70 && 'active'">
                        <span><i class="mdi mdi-chart-bar"></i></span>
                        <span class="nav-text">Reportes</span>
                    </router-link>
                </li>
                <li :class="active === 50 && 'active'" v-if="Access('users-config-pagina_web')">
                    <router-link to="/my-website" :class="active === 50 && 'active'">
                        <span><i class="mdi mdi-laptop-mac"></i></span>
                        <span class="nav-text">Página web</span>
                    </router-link>
                </li>
                <li v-if="AuthGetUserInfo.wlAdmin && Access('stores')" :class="active === 60 && 'active'">
                    <router-link to="/sub-commerce/control-list" :class="active === 60 && 'active'">
                        <span><i class="mdi mdi-chart-bar"></i></span>
                        <span class="nav-text">Control de comercios</span>
                    </router-link>
                </li>
                <li :class="active === 20 && 'active'" v-if="Access('users-config')">
                    <router-link to="/account" :class="active === 20 && 'active'">
                        <span><i class="mdi mdi-cogs"></i></span>
                        <span class="nav-text">Configuración</span>
                    </router-link>
                </li>
                <li v-if="typeof whiteLabelInfo.enlaces !== 'undefined'">
                    <a :href="whiteLabelInfo.enlaces.envio" target="_blank">
                        <span><i class="mdi mdi-help-circle"></i></span>
                        <span class="nav-text">Ayuda</span>
                    </a>
                </li>
                <li>
                    <a @click="logout()" class="text-danger">
                        <span><i class="mdi mdi-logout"></i></span>
                        <span class="nav-text">Cerrar sesión</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="sidebar sidebarmovil" :class="showBar ? 'sidebar-animation' :  'sidebar-none' ">
        <div class="d-flex align-items-center mb-3">
            <div @click="handlerSidebarMovil" class="menu-icon mr-2 cursor-pointer">
                <i class="fa-solid fa-bars"></i>
            </div>
            <router-link class="brand-logo text-center" to="/dashboard">
                <img :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre" style="margin: auto" class=""/>
            </router-link>
        </div>
        <div class="menu">
            <div class="usernameMenu">
                <i class="fa fa-location-pin mr-2"></i> {{AuthGetUserInfo.s}}
            </div>
            <ul>
                <li :class="active === 1 && 'active'" v-if="Access('reportes-dashboard')">
                    <router-link to="/dashboard" :class="active === 1 && 'active'">
                        <span><i class="mdi mdi-view-dashboard"></i></span>
                        <span class="nav-text">Inicio</span>
                    </router-link>
                </li>
                <li :class="active === 2 && 'active'" v-if="Access('orders-pos')">
                    <router-link to="/order/creation" :class="active === 2 && 'active'">
                        <span><i class="la la-cash-register"></i></span>
                        <span class="nav-text">Punto de venta</span>
                        <!--<span><i class="la la-lock ml-2 text-danger"></i></span>-->
                    </router-link>
                </li>
                <li :class="active === 31 && 'active'" v-if="Access('orders-listado')">
                    <router-link to="/orders" :class="active === 31 && 'active'">
                        <span><i class="la la-file-invoice-dollar"></i></span>
                        <span class="nav-text">Ordenes</span>
                    </router-link>
                </li>
                <li :class="active === 40 && 'active'" v-if="Access('clients-administrar-crear_editar')">
                    <router-link to="/clients" :class="active === 90 && 'active'">
                        <span><i class="mdi mdi-account"></i></span>
                        <span class="nav-text">Clientes</span>
                    </router-link>
                </li>
                <li :class="active === 30 && 'active'" v-if="Access('products-productos-listado')">
                    <router-link to="/products" :class="active === 30 && 'active'">
                        <span><i class="mdi mdi-archive"></i></span>
                        <span class="nav-text">Productos y servicios</span>
                    </router-link>
                </li>
                <li :class="active === 70 && 'active'" v-if="Access('reportes-reportes')">
                    <router-link to="/reports" :class="active === 70 && 'active'">
                        <span><i class="mdi mdi-chart-bar"></i></span>
                        <span class="nav-text">Reportes</span>
                    </router-link>
                </li>
                <li :class="active === 50 && 'active'" v-if="Access('users-config-pagina_web')">
                    <router-link to="/my-website" :class="active === 50 && 'active'">
                        <span><i class="mdi mdi-laptop-mac"></i></span>
                        <span class="nav-text">Página web</span>
                    </router-link>
                </li>
                <li v-if="AuthGetUserInfo.wlAdmin && Access('stores')" :class="active === 60 && 'active'">
                    <router-link to="/sub-commerce/control-list" :class="active === 60 && 'active'">
                        <span><i class="mdi mdi-chart-bar"></i></span>
                        <span class="nav-text">Control de comercios</span>
                    </router-link>
                </li>
                <li :class="active === 20 && 'active'" v-if="Access('users-config')">
                    <router-link to="/account" :class="active === 20 && 'active'">
                        <span><i class="mdi mdi-cogs"></i></span>
                        <span class="nav-text">Configuración</span>
                    </router-link>
                </li>
                <li v-if="typeof whiteLabelInfo.enlaces !== 'undefined'">
                    <a :href="whiteLabelInfo.enlaces.envio" target="_blank">
                        <span><i class="mdi mdi-help-circle"></i></span>
                        <span class="nav-text">Ayuda</span>
                    </a>
                </li>
                <li v-if="typeof whiteLabelInfo.enlaces !== 'undefined'">
                    <router-link to="/encuesta/satisfaccion" :class="active === 60 && 'active'">
                        <span><i class="mdi mdi-chart-bar"></i></span>
                        <span class="nav-text">Encuesta de servicios</span>
                    </router-link>
                </li>
                <li>
                    <a @click="logout()" class="text-danger">
                        <span><i class="mdi mdi-logout"></i></span>
                        <span class="nav-text">Cerrar sesión</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import API from "/src/core/Api";
import {mapGetters} from "vuex";

export default {
    emits: ['handlerSidebarMovil'],
    data() {
        return {
          
        };
    },
    name: "Sidebar",
    props: {
        active: Number,
        showBar: Boolean
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
            Access: 'Access',
        })
    },
    mounted() {
    },
    methods: {
        logout() {
            const self = this;
            API.showConfirm('¿Desea cerrar sesión?', '', function () {
                self.$store.dispatch('AuthLogout');
                window.location.href = '/signin';
            }, false, 'Cerrar sesión', 'Seguir conectado');
        },
        handlerSidebarMovil(){
            this.$emit('handlerSidebarMovil');
        }
    }
};
</script>
