<template>
      <box title="Exploración general" icon="fas fa-magnifying-glass-plus" :contraction='true'>
        <div class="row">
            <div class="col-12 mt-3">
                <div class="form-group">
                    <label>Exploración generalizada del paciente</label>
                    <textarea v-model="observacion_g_fisica" class="form-control" placeholder="Escribe aquí cualquier observación adicional detallada..." @change="saveObservacionG" style="min-height: 250px"></textarea>
                </div>
            </div>
        </div>
    </box>
    <box title="Exploración Física" icon="fa fa-search" :contraction='true'>
        <div class="row">
            <div class="col-12 col-sm-4" v-for="(item, key) in exploracion_fisica" :key="key">
                <div class="form-group mb-4">
                    <div>
                        <h6>{{item.name}}</h6>
                        <div class="radio-group">
                            <input type="radio" :name="key" value="normal" v-model="item.estado" :id="key + 'normal'"  @change="saveMetaIndividual" /><label :for="key + 'normal'">Normal</label>
                            <input type="radio" :name="key" value="anormal" v-model="item.estado" :id="key + 'anormal'"  @change="saveMetaIndividual" /><label :for="key + 'anormal'">Anormal</label>
                            <input type="radio" :name="key" value="diferido" v-model="item.estado" :id="key + 'diferido'"  @change="saveMetaIndividual" /><label :for="key + 'diferido'">Diferido</label>
                        </div>
                    </div>
                    <div v-if="item.estado === 'diferido'">
                        <label>Diferido por</label>
                        <div class="radio-group">
                            <input type="radio" :name="key + '_diferido'" value="negativa_paciente" v-model="item.diferido" :id="key + 'negativa_paciente'"  @change="saveMetaIndividual" /><label :for="key + 'negativa_paciente'">Negativa de paciente</label>
                            <input type="radio" :name="key + '_diferido'" value="otra_causa" v-model="item.diferido" :id="key + 'otra_causa'" @change="saveMetaIndividual"/><label :for="key + 'otra_causa'">Otra causa</label>
                        </div>
                    </div>
                    <div>
                        <label>Observaciones</label>
                        <input type="text" class="form-control" v-model="item.observaciones" @change="saveMetaIndividual">
                    </div>
                </div>
            </div>
        </div>
    </box>
</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';

import Box from "src/modules/medico/views/fichas/Box.vue";

// Componentes

export default {
    components: {
        Box
    },
    props: [
        'type',
    ],
    data() {
        return {
            exploracion_fisica: {
                habitus_exterior: {
                    name: 'Habitus Exterior',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                cabeza: {
                    name: 'Cabeza',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                faringe: {
                    name: 'Faringe',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                cuello: {
                    name: 'Cuello',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                torax: {
                    name: 'Tórax',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                region_precordial: {
                    name: 'Región Precordial',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                glandulas_mamarias_y_regiones_auxiliares: {
                    name: 'Glándulas Mamarias y Regiones Axilares',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                abdomen: {
                    name: 'Abdomen',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                genitales_externos: {
                    name: 'Genitales externos',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                tacto_vaginal: {
                    name: 'Tacto Vaginal',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                tacto_rectal: {
                    name: 'Tacto Rectal',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                extremidades: {
                    name: 'Extremidades',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                columna_vertebral: {
                    name: 'Columna Vertebral',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                exploracion_neurologica: {
                    name: 'Exploración Neurológica',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
                exploracion_pielyfaneras: {
                    name: 'Piel y faneras',
                    estado: 'normal',
                    diferido: '',
                    observaciones: '',
                },
            },
            observacion_g_fisica:""
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    mounted() {
        const self = this;
        // Cargo familiares
        Object.keys(self.exploracion_fisica).map(function (value) {

            if (self.GetMedicoFichaActive.metadata.exploracion_fisica) {
                if (self.GetMedicoFichaActive.metadata.exploracion_fisica[value]) self.exploracion_fisica[value].estado = self.GetMedicoFichaActive.metadata.exploracion_fisica[value];
                if (self.GetMedicoFichaActive.metadata.exploracion_fisica[value + '_diferido']) self.exploracion_fisica[value].diferido = self.GetMedicoFichaActive.metadata.exploracion_fisica[value + '_diferido'];
                if (self.GetMedicoFichaActive.metadata.exploracion_fisica[value + '_observaciones']) self.exploracion_fisica[value].observaciones = self.GetMedicoFichaActive.metadata.exploracion_fisica[value + '_observaciones'];
            }
        });

        this.observacion_g_fisica = (this.GetMedicoFichaActive.metadata.observacion_g_fisica) ? this.GetMedicoFichaActive.metadata.observacion_g_fisica.observaciones : '';
    },
    methods: {
        ...mapActions({

        }),
        dayjs,
        Numbro,
        // Motivo de consulta
        saveMetaIndividual() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.exploracion_fisica).map(function (value) {
                arrToSave[value] = self.exploracion_fisica[value].estado;
                arrToSave[value + '_diferido'] = self.exploracion_fisica[value].diferido;
                arrToSave[value + '_observaciones'] = self.exploracion_fisica[value].observaciones;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'exploracion_fisica',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh");
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveObservacionG(){
            const self = this;

            const arrToSave = {};
            arrToSave['observaciones'] = self.observacion_g_fisica;
                // Guardo y luego emito el evento
                API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'observacion_g_fisica',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh");
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

        }
    },
};

</script>
