<template xmlns="http://www.w3.org/1999/html">
    <div class="reservasAgendar" v-if="showAgendamiento" v-on:click.self="hideSearch">
        <div class="reservasAgendarContainer">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="mb-4">
                    <h3>
                        <span v-if="date">
                            <i class="fas fa-calendar mr-1"></i>
                            {{ dayjs(date).format('DD/MM/YYYY') }}
                        </span>
                        <span v-else>
                            Seleccione fecha
                        </span>
                        <span v-if="time !== ''">
                            <i class="fas fa-clock-o mr-1"></i>
                            {{ time }}
                        </span>
                        <span class="ml-4">
                            <i class="fas fa-arrow-right mr-2"></i> {{ producto.nombre }}
                        </span>
                    </h3>
                    </div>
                    <div>
                        <div v-show="hideDateSelector">
                            <button class="btn btn-primary btn-block btn-sm" @click="hideDateSelector = false">Cambiar fecha</button>
                        </div>
                        <div v-show="!hideDateSelector">
                            <date-picker v-model="date" mode="date" :min-date='new Date()' :attributes="datePickerParams"></date-picker>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <div>
                        <div>
                            <div class="mt-3 mb-1 font-weight-bold">
                                Selecciona el establecimiento
                            </div>
                            <select v-if="disponibilidad.locaciones && Object.keys(disponibilidad.locaciones).length > 0" class="form-control" v-model="locacion">
                                <option value="0" disabled>Sin establecimiento seleccionado</option>
                                <option v-for="item in disponibilidad.locaciones" :key="'loc_'+item.id" :value="item.id">{{ item.nombre }}</option>
                            </select>
                            <div v-else class="text-center text-small text-danger">
                                No hemos encontrado establecimientos disponibles
                            </div>
                        </div>
                        <div v-if="locacion && typeof disponibilidad.locaciones[locacion] !== 'undefined' && typeof disponibilidad.locaciones[locacion].personal !== 'undefined'">
                            <div class="mt-3 mb-1 font-weight-bold">
                                Deseo ser atendido por
                            </div>
                            <select class="form-control" v-model="personal">
                                <option value="0" selected>Me es indiferente</option>
                                <option v-for="(item, id) in disponibilidad.locaciones[locacion].personal" :key="'locpersonal_'+id" :value="id">{{ item }}</option>
                            </select>
                        </div>
                        <div>
                            <div v-if="!locacion" class="mt-3 text-muted text-center text-small mb-4">Selecciona establecimiento para mostrar los horarios disponibles</div>
                            <div v-else class="row">
                                <div class="col-11">
                                    <div class="py-2">
                                        <label class="toggle">
                                            <input class="toggle-checkbox" type="checkbox"
                                                v-model="citaEntrecita"/>
                                            <div class="toggle-switch"></div>
                                            <span class="toggle-label">Agendar entre citas</span>
                                        </label>
                                    </div>
                                    <div v-if="!citaEntrecita">
                                        <div class="mt-3 font-weight-bold">
                                            Selecciona el horario
                                        </div>
                                        <div class="timeSelector">
                                            <template v-if="typeof disponibilidad.horario[personalSelected] === 'undefined' || Object.keys(disponibilidad.horario[personalSelected]).length === 0">
                                                <p class="mt-3 text-muted">Sin horarios disponibles</p>
                                            </template>
                                            <template v-else>
                                                <div v-for="item in disponibilidad.horario[personalSelected]" :key="'time+_'+item" @click="horarioSelected = item" :class="(horarioSelected === item) ? 'selected' : ''">{{ item }}</div>
                                            </template>
                                        </div>
                                    </div>
                                    <div v-else class="my-2">
                                        <label class="pt-3">
                                            Ingresa la hora de cita
                                            <i class="fa fa-question-circle ml-2" v-tooltip="'Acá se configura el tiempo que durará una cita, el formato es hh:mm:ss'"></i>
                                        </label>
                                        <div class="form-group">
                                            <input class="form-control" type="text" v-model="horarioSelected"  v-maska data-maska="##:##:##" placeholder="00:00:00"/>
                                            <div class="text-muted">Formato: Horas:Minutos:Segundos</div>
                                        </div>
                                        <label>
                                            Ingresa el motivo de entre cita
                                        </label>
                                        <div class="form-group">
                                            <input class="form-control" type="text" v-model="citaEntrecitaMotivo" placeholder="Escribe aquí"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-1 justify-content-center align-self-center pl-0">
                                    <i class="fas fa-arrows-alt-v fa-lg"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="clientFastCreator">
                    <hr>
                    <div class="text-left">
                        <h6 class="font-weight-bold mb-4">
                            Datos del paciente o persona que asistirá a la cita
                        </h6>
                        <div class="my-3 text-center">
                            <div class="form-group">
                                <label class="toggle">
                                    <input class="toggle-checkbox" type="checkbox" v-model="clienteEsPaciente" @change="utilizarMismoCliente"/>
                                    <div class="toggle-switch"></div>
                                    <span class="toggle-label">
                                        <i class="fa fa-user"></i> Utilizar cliente ingresado para cobro y facturación
                                    </span>
                                </label>
                            </div>
                            <div v-if="clienteEsPaciente" class="text-danger">
                                Atención, si edita datos en esta sección, se editarán en la ficha del cliente de facturación.
                            </div>
                        </div>
                        <div class="text-right mb-3" v-if="clienteSelected && !clienteEsPaciente">
                            <a class="text-primary cursor-pointer" @click="resetSearch"><i class="fa fa-search"></i> Volver a listado de búsqueda</a>
                        </div>
                    </div>
                    <div class="row" v-if="!clienteSelected">
                        <div class="col-xs-12 col-sm-12">
                            <div class="form-group mb-1">
                                <div class="input-group cfField">
                                    <input type="text" class="form-control" placeholder="Búsqueda por nombres, apellidos, NIT o DPI" name="taxId" v-model="dpiSearch"/>
                                    <div class="input-group-append">
                                        <button class="btn btn-primary btn-sm mr-3" type="button" @click="searchClienteCita">
                                            <i class="fa fa-search"></i> Buscar
                                        </button>
                                    </div>
                                </div>
                                <div class="text-left text-muted mb-3">
                                    <small>DPI o pasaporte sin espacios ni guiones*</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="clienteSearched && !clienteSelected">
                        <h6>Resultados</h6>
                        <table class="table" v-if="Object.keys(searchResults).length > 0">
                            <thead>
                            <tr>
                                <th class="font-weight-bold" scope="col">DPI o Pasaporte</th>
                                <th class="font-weight-bold" scope="col">Nombres</th>
                                <th class="font-weight-bold" scope="col">Apellidos</th>
                                <th class="font-weight-bold" scope="col">Fecha Nacimiento</th>
                                <th class="font-weight-bold" scope="col">Edad</th>
                                <th class="font-weight-bold" scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in searchResults" :key="item.id">
                                <th scope="row">{{ item.cui_passport }}</th>
                                <td>{{ item.nombres }}</td>
                                <td>{{ item.apellidos }}</td>
                                <td>{{ item.fechaNacimiento }}</td>
                                <td>{{ item.edad }}</td>
                                <td>
                                    <button class="btn btn-sm btn-primary" @click="selectClient(item)">
                                        <i class="fa fa-check"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="col-12 text-center text-success mb-2" v-else>
                            No se han encontrado clientes, por favor llene los datos para crearlo automáticamente al agendar
                        </div>
                    </div>
                    <div class="row" v-if="clienteSearched && clienteSelected">
                        <div class="col-xs-12 col-sm-4">
                            <div class="form-group" v-if="!checkHideOption('hideDPI')">
                                <label>
                                    <span v-if="menorDeEdad">DPI o pasaporte del encargado </span>
                                    <span v-else>DPI o pasaporte</span>
                                    (*)
                                </label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="nombres" v-model="dpi"/>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="form-group">
                                <label>Nombres (*)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="nombres" v-model="nombres"/>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="form-group">
                                <label>Apellidos (*)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="apellidos" v-model="apellidos"/>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="form-group">
                                <label>Fecha nacimiento</label>
                                <DatePicker v-model="fechaNacimiento">
                                    <template #default="{ inputValue, inputEvents }">
                                        <input type="text" class="form-control" placeholder="Seleccione la fecha" :value="inputValue" v-on="inputEvents"/>
                                    </template>
                                </DatePicker>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="form-group">
                                <label>Género</label>
                                <select class="form-control" v-model="genero">
                                    <option value="M">
                                        <template v-if="producto.fichaMedica !== 'veterinaria'">Masculino</template>
                                        <template v-else>Macho</template>
                                    </option>
                                    <option value="F">
                                        <template v-if="producto.fichaMedica !== 'veterinaria'">Femenino</template>
                                        <template v-else>Hembra</template>
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="form-group">
                                <label>Teléfono de contacto</label>
                                <input type="number" class="form-control" placeholder="Escribe aquí" v-model="telefono"/>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="form-group">
                                <label>Correo electrónico</label>
                                <input type="email" class="form-control" placeholder="Escribe aquí" v-model="email"/>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Motivo de consulta o cita (*)</label>
                                <textarea class="form-control" placeholder="Escribe aquí el motivo de la cita" v-model="motivoConsulta"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 text-right" v-if="clienteSearched && !clienteSelected">
                        <button class="btn btn-sm btn-success" @click="crearCliente">
                            <i class="fa fa-plus mr-2"></i>Crear cliente
                        </button>
                    </div>
                    <!--<div class="text-right">
                        <button class="btn btn-success btn-sm mr-3" type="button" @click="registerClient"><i class="fa fa-check"></i></button>
                        <button class="btn btn-outline-danger btn-sm" type="button" @click="operation = 'search'"><i class="fa fa-chevron-up"></i></button>
                    </div>-->
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-6">
                    <button @click="hidePicker" class="btn btn-danger w-100">Cancelar</button>
                </div>
                <div class="col-6">
                    <button v-if="this.horarioSelected !== '' && date && clienteSelected" @click="finishSelection" class="btn btn-primary w-100">Continuar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import API from "src/core/Api";
import {mapActions, mapGetters} from "vuex";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";

export default {
    components: {
        DatePicker
    },

    props: ['showAgendamiento', 'producto'],
    data() {
        return {
            hideDateSelector: false,
            showDataTimePicker: false,
            date: new Date(),
            time: '',
            datePickerParams: [
                {
                    dot: true,
                    key: 'today',
                    highlight: false,

                },
            ],
            locacion: 0,
            personal: 0,
            personalSelected: 0,
            locacionName: '',
            disponibilidad: {},
            horarioSelected: '',
            citaEntrecita: false,
            citaEntrecitaMotivo: '',

            // datos del paciente o asistente
            searchResults: {},
            clienteEsPaciente: 0,
            clienteSelected: false,
            clienteSearched: false,
            clienteSearchResult: {},
            menorDeEdad: false,

            dpiSearch: '',
            nombreSearch: '',
            apellidoSearch: '',

            idCliente: '',
            dpi: '',
            genero: '',
            nombres: '',
            apellidos: '',
            fechaNacimiento: '',
            telefono: '',
            email: '',
            motivoConsulta: '',
        };
    },
    computed: {
        ...mapGetters({
            OrderGetClientSelected: 'OrderGetClientSelected',
            StoreInfo: 'StoreInfo',
        }),
    },
    watch: {
        showAgendamiento: function (value) {
            if (value) {
                this.resetSearch();
                this.menorDeEdad = false;
                this.clienteSearchResult = {};
                this.dpiSearch = '';
                this.clienteSearched = false;
                this.getDisponibilidad();
            }
        },
        date: function (value) {
            const self = this;
            if (value) {
                self.getDisponibilidad();
            }
        },
        locacion: function () {
            this.horarioSelected = '';
            this.selectPersonal();
        },
        personal: function () {
            this.horarioSelected = '';
            this.selectPersonal();
        },
    },
    mounted() {
        //console.log(this.OrderGetClientSelected);
        this.resetSearch();
    },
    methods: {
        dayjs,
        ...mapActions({
            AuthGetUserInfo: 'AuthGetUserInfo',
            OrderPosClientSelect: 'OrderPosClientSelect',
        }),
        hideSearch: function () {
            this.searchResults = {};
            this.$emit('update:showAgendamiento', false);
            this.searchTerm = '';
            this.operation = 'search';
        },
        getDisponibilidad() {
            const self = this;

            if (typeof this.producto.id === 'undefined') {
                API.showErrorNotify('Debe seleccionar un servicio');
                return false;
            }

            API.send('POST', 'products/public/disponibilidad/' + store.getters.AuthGetUserInfo.s + '/producto/' + self.producto.id + '/' + dayjs(self.date).format('YYYY-MM-DD'), {
                    idPais: self.pais,
                    idDepartamento: self.departamento,
                    idMunicipio: self.municipio,
                },
                function (response) {
                    if (response.status && typeof response.data !== 'undefined') {
                        self.disponibilidad = response.data;
                        console.log(self.disponibilidad);
                    }
                },
                function (response) {
                    self.horarios = {};
                    API.showErrorNotify(response.msg);
                });
        },
        finishSelection() {

            if (Tools.isEmptyString(this.nombres) || Tools.isEmptyString(this.apellidos) || Tools.isEmptyString(this.motivoConsulta)) {
                API.showErrorNotify('Debe ingresar los datos del cliente que asistirá a la cita');
                return false;
            }

            if (this.horarioSelected && this.horarioSelected !== '') {
                this.$emit('selected', {
                    productoKey: this.productoKey,
                    productoId: this.producto.id,
                    horario: this.horarioSelected,
                    entrecita: this.citaEntrecita,
                    entrecitaMotivo: this.citaEntrecitaMotivo,
                    locacionId: this.locacion,
                    locacion: this.disponibilidad.locaciones[this.locacion].nombre,
                    fecha: dayjs(this.date).format('YYYY-MM-DD'),
                    personal: this.personalSelected,
                    personalNombre: this.disponibilidad.locaciones[this.locacion].personal[this.personalSelected],
                    sellBy: this.producto.locacionId,
                    clienteCita: {
                        id: this.idCliente,
                        dpi: this.dpi,
                        nombres: this.nombres,
                        apellidos: this.apellidos,
                        telefono: this.telefono,
                        email: this.email,
                        fechaNacimiento: this.fechaNacimiento,
                        genero: this.genero,
                        motivoCita: this.motivoConsulta,
                    }
                });
            }
        },
        selectPersonal() {
            if (parseInt(this.personal) === 0) {
                if (typeof this.disponibilidad.locaciones !== 'undefined' && typeof this.disponibilidad.locaciones[this.locacion] !== 'undefined' && typeof this.disponibilidad.locaciones[this.locacion].personal !== 'undefined') {
                    this.personalSelected = Object.keys(this.disponibilidad.locaciones[this.locacion].personal)[0];
                }
            }
            else {
                this.personalSelected = this.personal;
            }
        },
        hidePicker() {
            this.$emit('update:showAgendamiento', false);
        },
        resetSearch() {
            this.clienteSelected = false;
            this.idCliente = '';
            this.dpi = '';
            this.nombres = '';
            this.apellidos = '';
            this.telefono = '';
            this.email = '';
            this.fechaNacimiento = '';
            this.genero = '';
        },
        utilizarMismoCliente() {
            if (this.clienteEsPaciente) {
                this.clienteSelected = true;
                this.clienteSearched = true;

                if (typeof this.OrderGetClientSelected.nombres !== 'undefined') {
                    this.idCliente = (typeof this.OrderGetClientSelected.id !== 'undefined') ? this.OrderGetClientSelected.id : 0;
                    this.nombres = (typeof this.OrderGetClientSelected.nombres !== 'undefined') ? this.OrderGetClientSelected.nombres : '';
                    this.apellidos = (typeof this.OrderGetClientSelected.nombres !== 'undefined') ? this.OrderGetClientSelected.apellidos : '';
                    this.telefono = (typeof this.OrderGetClientSelected.telefono !== 'undefined') ? this.OrderGetClientSelected.telefono : '';
                    this.email = (typeof this.OrderGetClientSelected.email !== 'undefined') ? this.OrderGetClientSelected.email : '';
                    this.dpi = (typeof this.OrderGetClientSelected.cui_passport !== 'undefined') ? this.OrderGetClientSelected.cui_passport : '';
                    this.fechaNacimiento = (typeof this.OrderGetClientSelected.fechaNacimiento !== 'undefined') ? this.OrderGetClientSelected.fechaNacimiento : '';
                    this.genero = (typeof this.OrderGetClientSelected.genero !== 'undefined') ? this.OrderGetClientSelected.genero : '';
                }
            }
            else {
                this.clienteSelected = false;
                this.clienteSearched = true;

                if (typeof this.OrderGetClientSelected.nombres !== 'undefined') {
                    this.idCliente = 0;
                    this.nombres = '';
                    this.apellidos = '';
                    this.telefono = '';
                    this.email = '';
                    this.dpi = '';
                    this.fechaNacimiento = '';
                    this.genero = '';
                }
            }
        },
        crearCliente() {
            this.resetSearch();
            this.dpi = this.dpiSearch;
            this.clienteSearched = true;
            this.clienteSelected = true
        },
        selectClient(item) {
            this.idCliente = item.id;
            this.dpi = item.cui_passport;
            this.nombres = item.nombres;
            this.apellidos = item.apellidos;
            this.telefono = item.telefono;
            this.email = item.email;
            this.fechaNacimiento = item.fechaNacimiento;
            this.genero = item.genero;
            this.clienteSelected = true;
        },
        checkHideOption(option) {
            console.log(this.StoreInfo.cart);
            if (typeof this.StoreInfo === 'object' && typeof this.StoreInfo.cart !== 'undefined') {
                if (typeof this.StoreInfo.cart[option] !== 'undefined' && this.StoreInfo.cart[option]) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        },
        searchClienteCita() {
            const self = this;

            if (self.dpiSearch !== '' || self.nombreSearch !== '' || self.apellidoSearch !== '') {

                self.clienteSelected = false;
                self.resetSearch();

                API.send('POST', 'clients/general-search', {
                        'term': self.dpiSearch,
                        'nombres': self.nombreSearch,
                        'apellidos': self.apellidoSearch,
                    },
                    function (response) {
                        if (response.status) {
                            self.clienteSearched = true;
                            self.searchResults = response.data;

                            /*if (Object.keys(self.searchResults).length === 0) {
                                self.clienteSelected = true;
                                self.dpi = self.dpiSearch;
                            }*/
                        }
                        else {
                            API.showErrorNotify(response.msg);
                            self.clienteSearched = false;
                        }
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                        self.clienteSearched = false;
                    })
            }
            else {
                API.showErrorAlert('Ingresa los datos de búsqueda');
            }
        },
    },
};
</script>
