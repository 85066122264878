<template>
    <setting-layout :active="33">
        <div class="row justify-content-center h-100 align-items-center">
            <div class="col-xl-12 col-md-12">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">
                                    Validador de asistencia
                                    <i class="fa fa-question-circle ml-3" v-tooltip="'Permite validar la asistencia de participantes a eventos'"></i>
                                </h4>
                            </div>
                            <div class="card-body">
                                <div class="text-justify">
                                    Valida la asistencia de tus eventos mediante el escaneo de invitaciones que incluyan código QR generado por el sistema.
                                </div>
                                <div class="text-center mt-3">
                                    <button @click="readerStart" class="btn btn-primary"><i class="fa-solid fa-qrcode mr-2"></i> Iniciar validador</button>
                                </div>
                                <div class="text-muted mt-3 text-justify">
                                    Recuerda, para utilizar el validador de asistencia debes brindar accesos al navegador para utilizar la cámara de tu preferencia.
                                </div>
                                <div id="fullScreenContainer">
                                    <QrReader v-if="validatorStarted" @qrScanned="qrScanned"  @readerStop="readerStop" @readerReset="resetReader">
                                        <div v-if="formDetails.t" class="text-left mb-3">
                                            <h5>{{formDetails.p}}</h5>
                                            <div>
                                                <b class="mr-2">Código QR:</b> <span class="text-success">{{formDetails.c}}-{{formDetails.dn}}</span>
                                            </div>
                                            <div>
                                                <b class="mr-2">Orden:</b> {{formDetails.c}}
                                            </div>
                                            <div>
                                                <b class="mr-2">Adquirido por:</b> {{formDetails.cn}}
                                            </div>
                                            <div>
                                                <b class="mr-2">Fecha compra:</b> {{formDetails.fc}}
                                            </div>
                                            <div class="text-center text-muted mt-3" v-if="typeof formDetails.d !== 'undefined' && parseInt(formDetails.d) > 0">
                                                <div>La asistencia ya ha sido marcada <b class="text-danger">{{formDetails.d}}</b> veces</div>
                                                <div>
                                                    Fecha de último marcaje: {{formDetails.fv}}
                                                </div>
                                            </div>
                                            <div class="mt-3" v-if="!asistenciaMarcada">
                                                <button @click="marcarAsistencia(formDetails)" class="btn btn-primary w-100 mb-2"><i class="fa-solid fa-check-square-o"></i> Marcar asistencia</button>
                                            </div>
                                        </div>
                                        <div v-else>
                                            Error, código QR inválido
                                        </div>
                                    </QrReader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>>
</template>

<script>
import API from "src/core/Api";
//import Tools from "src/core/Tools";
import QrReader from 'src/components/QrReader';
import {mapGetters} from "vuex";
import settingLayout from "../../order/components/settings/settingLayout.vue";
//import {store} from 'src/store'
//import DataValidation from "src/core/DataValidation";

export default {
    components: {
        settingLayout,
        QrReader,
    },
    data() {
        return {
            validatorStarted: false,
            formDetails: {},
            qrScannedResult: '',
            token: '',
            asistenciaMarcada: false,
        };
    },
    watch: {
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo'
        })
    },
    mounted() {

    },
    methods: {

        qrScanned(data) {
            this.qrScannedResult = data;
            let token = /[^/]*$/.exec(this.qrScannedResult);
            this.token = (typeof token[0] !== 'undefined') ? token[0] : false;
            this.getFormInfo();
        },
        readerStart() {
            this.validatorStarted = true;
        },
        readerStop() {
            this.asistenciaMarcada = false;
            this.validatorStarted = false;
        },
        resetReader() {
            this.formDetails = {};
            this.qrScannedResult = {};
            this.token = '';
            this.asistenciaMarcada = false;
        },
        getFormInfo() {
            const self = this;
            if (self.token !== '') {
                API.send('POST', 'formularios/verifyAssistance', {
                        token: self.token
                    },
                    function (response) {
                        if (response.status) {
                            self.formDetails = response.data;
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
        },
        marcarAsistencia(item) {
            const self = this;
            this.asistenciaMarcada = true;
            API.send('POST', 'formularios/verifyAssistance/verify', {
                token: item.t,
                od: item.od,
                dn: item.dn,
            },
            function (response) {
                if (response.status) {
                    self.getFormInfo();
                }
                else {
                    API.showErrorNotify(response.msg);
                }
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })
        }
    },
};
</script>
