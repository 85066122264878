<template>
    <layout :active="60" :title="true">
        <div class="content-body">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4">
                        <div class="card settings_menu">
                            <div class="card-body">
                                <ul>
                                    <li class="nav-item" v-if="Access('stores-comercios-listado')">
                                        <router-link to="/sub-commerce/control-list" class="nav-link" :class="active === 60 && 'active'">
                                            <i class="mdi mdi-store"></i>
                                            <span>Listado de comercios</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('stores-concilias')">
                                        <router-link to="/sub-commerce/concilia" class="nav-link" :class="active === 61 && 'active'">
                                            <i class="mdi mdi-store"></i>
                                            <span>Concilias</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('stores-concilias')">
                                        <router-link to="/sub-commerce/concilia" class="nav-link" :class="active === 61 && 'active'">
                                            <i class="mdi mdi-store"></i>
                                            <span>Cambiar Información de suscripciones</span>
                                        </router-link>
                                    </li>
                                    <!--<li class="nav-item">
                                        <router-link to="/account" class="nav-link" :class="active === 20 && 'active'">
                                            <i class="mdi mdi-account"></i>
                                            <span>Ganancias</span>
                                        </router-link>
                                    </li>-->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <slot/>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import {mapGetters} from "vuex";

export default {
    name: "SettingLayout",
    components: {
        Layout,
    },
    props: {
        active: Number,
    },
    computed: {
        ...mapGetters({
            Access: 'Access',
        })
    },
};
</script>
