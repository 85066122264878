<script>
 
export default { 
 data() {
        return {
        //data
        View: true,
 
      NewRay:{
        Code: "",
        Description: "",
        User: "",
      },
      
 }},
 props:{
 editIndex: Number,
 procedimientos: Object,
 },
 mounted(){
  console.log(this.editIndex);
 },
 methods:{
  addRay(){

    if (this.NewRay.Code == "" || this.NewRay.Description == "" || this.NewRay.Description == "") {
      return alert("Completa datos de los Rayos X")
    }
    this.Rayosx.unshift(this.NewRay)
    this.NewRay={
        Code: "",
        Description: "",
        User: "",
      }
    this.View=!this.View;
  }
 }
 }
</script>
 
 
<template>
   <section v-if="View" class="d-flex flex-column justify-content-between mb-1">
      <div v-if="Object.keys(procedimientos.content[editIndex].rayosx).length" class="ray-content">
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="font-weight-bold">Código</th>
              <th scope="col" class="font-weight-bold">Descripción</th>
              <th scope="col" class="font-weight-bold">Usuario</th>
            </tr>
          </thead>
          <tbody class="table-hover">
            <tr v-for="(item, key) in procedimientos.content[editIndex].rayosx" :key="key">
              <td>{{ item.codigo }}</td>
              <td>{{ item.descripcion }}</td>
              <td>{{ item.usuario }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="d-flex justify-content-center mt-4">
          <p class=" text-muted">Este procedimiento aún no tiene rayos x agregados</p>
      </div>
      <div class="button-div d-flex justify-content-start">
        <button type="button" @click="View=!View" class="btn btn-primary">Agregar Rayos X  <i class="fa-solid fa-square-plus"></i></button>
      </div>
    </section>
    <section v-else class=" d-flex flex-column justify-content-between">
      <div class="row">
          <div class="form-group col-12">
                          <label>Codigo</label>
                          <input type="number" class="form-control" v-model="NewRay.Code"/>
          </div>
          <div class="form-group col-12">
                          <label>Usuario</label>
                          <input type="text" class="form-control" v-model="NewRay.User"/>
          </div>
          <div class="form-group col-12">
                          <label>Descripción</label>
                          <textarea  class="form-control" placeholder="Escribe aquí" v-model="NewRay.Description"></textarea>
          </div>
      </div>
      <div class="button-div mt-2 d-flex justify-content-start">
        <button @click="View=!View" class="btn btn-secondary px-3">Cancelar</button>
        <button @click="addRay()" class="btn btn-primary px-3 ml-3">Guardar</button>
      </div>
    </section>
</template>
 
 
<style scoped>


section{
  width: 100%;
  height: calc( 100% - 60px );
}

 textarea{
  min-height: 100px;
  height: 160px;
  max-height: 200px;
 }

 .button-div{
  width: 100%;
  height: 40px;
 }

 .ray-content{
  height: calc( 100% - 40px );
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
 }

 .ray-content table{
  width: 100%;
 }
</style>