<script>

export default { 
 data() {
        return {
        //data
        imgName: "",
        quality: 0.8
 }},
 methods:{
    handlerSaveModal(){
        this.$emit('handlerSaveModal');
    },
    guardarImagen(action){
        if (this.imgName) {
                this.$emit('guardarImagen', {action,imgName: this.imgName, quality: this.quality})
        }
    },
    disabledSave(){
        if (!this.imgName) {
                return 'disabled'
        }
    }
 },
 components:{
  
 },
 }
</script>
 
 
<template>
  <div class="ModalMain scale-up-tr">
    <div class="ModalContainer">
        <i class="fa-solid fa-xmark close" active @click="handlerSaveModal"></i>
    <div class="mainbox d-flex flex-column justify-content-center align-items-center">

        <div class="groupbox">

        <div class="leftbox">
        <button type="button" class=" w-100 btn btn-success" :class="disabledSave()" @click="guardarImagen('')">
            Guardar y seguir editando
        </button>
        <button type="button" class="w-100 btn btn-primary" :class="disabledSave()" @click="guardarImagen('limpiar')">
            Guardar y limpiar lienzo
        </button>
        <button type="button" class="w-100 btn btn-danger" @click="handlerSaveModal">
            Cancelar
        </button>
      </div>

      <div class="rigthbox">
        
        <div class="form-group w-100">
                <label>Nombre:</label>
                <input type="text" class="form-control" placeholder="Ingrese el nombre de la imagen..." v-model="imgName" >
        </div>
            
        <div class="form-group w-100">
        <label for="formControlRange">Calidad de la imagen {{ quality*100 }}</label>
        <input type="range" min="0" max="1" step="0.1" class="form-control-range" v-model="quality">
        </div>
  
        </div>

        </div>
        <small v-if="!imgName" class="form-text text-danger">Ingresa un nombre de imagen para poder guardar.</small>
    </div>
  </div>
  </div>
</template>
 
 
<style scoped>
 .mainbox{
  width: 100%;
  height: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }

 .groupbox{
        width: 100%;
        height: 100%;
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: center;
 }

 .leftbox{
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 45%;
 }
 .rigthbox{
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 55%;
 }
 .imgbox{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #9c9c9c43;
  padding:20px;
 }
 .cardImg{
  width: 170px;
  height: 120px;
  overflow: hidden;
  transition: 0.2s;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 }

 .cardImg:hover{
  transform: scale(1.1);
 }
 .cardImg img{
  width: 100%;
  overflow: hidden;
 }


 .plantillas{
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  width: 100%;
  height: 46%;
 }
 .guardadas{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  gap: 10px;
  width: 100%;
  height: 46%;
 }

 .ModalMain{
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 50px;
    height: fit-content;
    display: flex;
    justify-content: center;
    overflow: hidden;
 }

 .ModalContainer{
    padding: 10px;
    z-index: 1;
    position: relative;
    width: 400px;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 }

 .close{
    position: absolute;
    top: 10px;
    right: 10px;
    transition: 0.3s;
 }

 .close:hover{
    transform: rotate(180deg);
    color: #f33a3a;
 }
 .scale-up-tr {
	-webkit-animation: scale-up-tr 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-tr 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-13 17:47:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-tr
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

</style>