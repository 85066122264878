<template>
    <div class="fichaMedicaTimeline" v-show="showTimeline">
        <div class="closeTimeline" @click="close">
            <i class="fa fa-times-circle"></i>
        </div>
        <div class="row">
            <div class="col-12">
                <h2 class="card-title pt-3 pb-2"> Historial del paciente</h2>
            </div>
            <div class="col-12 p-0">
                <div id="content">
                    <div v-if="showResumeModal" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div class="d-flex justify-content-between align-items-center w-100">
                                        <h3>Resumen de consulta</h3>

                                        <div @click.stop="handlerResumeModal" class="p-1 d-flex justify-content-center align-items-center">
                                            <i class="fa-solid fa-xmark fa-lg"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <box title="Paciente" icon="fa fa-question-circle">
                                        <div class="p-2 row">
                                            <div class="col-3">
                                                <h6 class="text-primary">Nombre: </h6>
                                                <p class="text-dark">{{ `${dataResumeModal.data.cliente.nombres} ${dataResumeModal.data.cliente.apellidos}` }}</p>
                                            </div>
                                            <div class="col-3">
                                                <h6 class="text-primary">Género: </h6>
                                                <p class="text-dark">{{ dataResumeModal.data.cliente.genero }}</p>
                                            </div>
                                            <div class="col-3">
                                                <h6 class="text-primary">Edad: </h6>
                                                <p class="text-dark">{{ dataResumeModal.data.cliente.edad }}</p>
                                            </div>
                                            <div class="col-3">
                                                <h6 class="text-primary">Fecha de atención: </h6>
                                                <p class="text-dark">{{ dataResumeModal.fechaReserva }}</p>
                                            </div>
                                        </div>

                                        <hr>
                                    </box>
                                    <box title="Motivo de consulta" icon="fa fa-question-circle" :contraction='true'>
                                        <div v-if="dataResumeModal.data.metadata.preclinica_motivo.motivo_principal_consulta" class="p-3 w-100">
                                            <p>{{dataResumeModal.data.metadata.preclinica_motivo.motivo_principal_consulta}}</p>
                                        </div>
                                        <div v-else class="p-3 w-100">
                                            <div class="text-center text-danger">No se especificó un motivo de consulta</div>
                                        </div>
                                    </box>
                                    <box title="Signos vitales" icon="fa fa-heartbeat" :contraction='true'>
                                        <div class="p-3" v-if="typeof dataResumeModal.data.metadata.preclinica_signos_v === 'undefined'">
                                            <div class="text-center text-danger">No tiene signos vitales ingresados</div>
                                        </div>
                                        <div class="p-3" v-else>
                                            <table class="table align-middle m-0">
                                                <thead class="small flex-sm-nowrap font-weight-bold">
                                                <tr class="font-weight-bold small">
                                                    <th class="font-weight-bold">Fecha y hora</th>
                                                    <th class="font-weight-bold">Presión arterial</th>
                                                    <th class="font-weight-bold">Frecuencia cardiaca</th>
                                                    <th class="font-weight-bold">Frecuencia respiratoria</th>
                                                    <th class="font-weight-bold">Saturación de oxígeno</th>
                                                    <th class="font-weight-bold">Temperatura</th>
                                                    <th class="font-weight-bold">Peso</th>
                                                    <th class="font-weight-bold">Talla</th>
                                                    <th class="font-weight-bold">IMC</th>
                                                    <th class="font-weight-bold">Glicemia</th>
                                                    <th class="font-weight-bold">Estado de conciencia</th>
                                                    <th class="font-weight-bold">Dolor</th>
                                                    <th class="font-weight-bold"></th>
                                                </tr>
                                                </thead>
                                                <tbody class="small">
                                                <tr v-for="item in dataResumeModal.data.metadata.preclinica_signos_v" :key="item.id">
                                                    <template v-if="typeof item.detail !== 'undefined'">
                                                        <td>{{ item.detail.fechaHora }}</td>
                                                        <td>{{ item.detail.presionArterial }}</td>
                                                        <td>{{ item.detail.frecuenciaCardiaca }}</td>
                                                        <td>{{ item.detail.frecuenciaRespiratoria }}</td>
                                                        <td>{{ item.detail.saturacionDeOxigeno }}</td>
                                                        <td>{{ item.detail.temperatura }}</td>
                                                        <td>{{ item.detail.peso }}</td>
                                                        <td>{{ item.detail.talla }}</td>
                                                        <td>{{ item.detail.IMC }}</td>
                                                        <td>{{ item.detail.glicemia }}</td>
                                                        <td>{{ item.detail.estadoDeConciencia }}</td>
                                                        <td>{{ item.detail.dolor }}/10</td>
                                                        <td>
                                                            <i @click="editSignoVital(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                                                            <i @click="deletesignoVital(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                                                        </td>
                                                    </template>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </box>
                                    <box title="Impresión clínica" icon="fas fa-laptop-medical" :contraction='true'>
                                        <div class="p-3" v-if="typeof dataResumeModal.data.metadata.impresion_clinica_cie10 === 'undefined' && typeof dataResumeModal.data.metadata.impresion_clinica === 'undefined'">
                                            <div class="text-center text-danger">No tiene diagnostico ingresados</div>
                                        </div>
                                        <div  v-else class="w-100 p-3">
                                            <div class="row px-4 py-1">
                                                <div class="form-group text-wrap overflow-hidden">
                                                <label class="font-weight-bold">Diagnóstico: </label>
                                        
                                                <span v-for="item in dataResumeModal.data.metadata.impresion_clinica_cie10">
                                                    <div class="text-wrap">{{ item }} </div>
                                                </span>
                                                <span v-for="item in dataResumeModal.data.metadata.impresion_clinica">
                                                    <div class="text-wrap" v-html="nl2br(item)"></div>
                                                </span>

                                                </div>
                                            </div>
                                            <div v-if="dataResumeModal.data.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi" class="col-12 col-sm-6">
                                            <div class="form-group text-wrap">
                                                <label class="font-weight-bold">Historial de la enfermedad actual (HPI): </label> {{dataResumeModal.data.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi}}
                                            </div>
                                            </div>
                                        </div>
                                    </box>
                                    <box title="Tratamiento" icon="fa fa-comment-medical" :contraction='true'>
                                        <div class="p-3" v-if="(typeof dataResumeModal.data.metadata.tratamiento !== 'undefined')">
                                            <table v-if="(dataResumeModal.data.metadata.tratamiento.observaciones !== null)" class="table align-middle m-0">
                                                <thead class="small flex-sm-nowrap font-weight-bold">
                                                <tr class="font-weight-bold small">
                                                    <th class="font-weight-bold">Observaciones</th>
                                                </tr>
                                                </thead>
                                                <tbody class="small">
                                                <tr v-for="(item, index) in dataResumeModal.data.metadata.tratamiento" :key="index">
                                                    <template v-if="(typeof item !== 'undefined')">
                                                        <td>{{item}}</td>
                                                    </template>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div v-else class="text-center text-danger">No tiene datos en el tratamiento</div>
                                        </div>           
                                        <div class="p-3" v-else >
                                            <div class="text-center text-danger">No tiene datos en el tratamiento</div>
                                        </div>
                                        <div v-if="(typeof dataResumeModal.data.metadata.tratamiento !== 'undefined')" class="mt-3 text-right p-3">
                                            <div v-if="(dataResumeModal.data.metadata.tratamiento.observaciones !== null)" class="btn btn-primary" @click="printTratamiento"><i class="fas fa-print"></i> Imprimir tratamiento</div>
                                        </div>
                                    </box>
                                    <box title="Receta" icon="fas fa-receipt" :contraction='true'>
                                        <div class="p-3" v-if="(typeof dataResumeModal.data.metadata.tratamiento_receta === 'undefined')">
                                            <div class="text-center text-danger">No tiene datos en receta</div>
                                        </div>
                                        <div v-else>
                                            <table class="table align-middle m-0">
                                                <thead class="small flex-sm-nowrap font-weight-bold">
                                                <tr class="font-weight-bold small">
                                                    <th class="font-weight-bold">Medicamento</th>
                                                    <th class="font-weight-bold">Administración</th>
                                                    <th class="font-weight-bold">Dosis y frecuencia</th>
                                                    <th class="font-weight-bold">Observaciones</th>
                                                </tr>
                                                </thead>
                                                <tbody class="small">
                                                <tr v-for="item in dataResumeModal.data.metadata.tratamiento_receta" :key="item.id">
                                                    <template v-if="(typeof item.detail !== 'undefined')">
                                                        <td>{{ item.detail.medicamento }}</td>
                                                        <td>{{ item.detail.administracion }}</td>
                                                        <td>{{ item.detail.dosis }} cada {{ item.detail.cada }}, durante {{item.detail.durante}} {{item.detail.duranteTiempo}}</td>
                                                        <td>{{ item.detail.observaciones }}</td>
                                                    </template>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-if="(typeof dataResumeModal.data.metadata.tratamiento_receta !== 'undefined')"  class="mt-3 text-right p-3">
                                            <div class="btn btn-primary" @click="printReceta"><i class="fas fa-print"></i> Imprimir receta</div>
                                        </div>
                                    </box>
                                    <div class="modal-footer">
                                    <div @click.stop="handlerResumeModal" class="btn btn-danger">
                                        Cerrar
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul class="timeline-1">
                        <template  v-for="item in history">

                            <li v-if="(typeof item.data.status === 'undefined')" class="event cursor-pointer" :key="item.data.cita" @click="handlerResumeModal(item)">

                                <span class="badge badge-pill badge-primary badge-date">{{item.fechaReserva}}</span>
                                        <span class="badge badge-pill badge-light badge-option">
                                            <div class="d-flex">
                                            <div title="Ir a consulta" class="p-1 text-primary cursor-pointer" @click.stop="goToPage(`/medic/ficha/${item.data.reserva.id}`)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-file-earmark-fill" viewBox="0 0 16 16">
                                                    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z"/>
                                                </svg>
                                            </div>
                                            <div class="p-1 text-success cursor-pointer" title="Ver resumen" @click.stop="handlerResumeModal(item)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                                </svg>
                                            </div>
                                        </div>
                                </span>
                                <h6 v-if="(typeof item.data !== 'undefined' && typeof item.data.producto !== 'undefined' && typeof item.data.producto.nombre !== 'undefined')" class="mt-1 py-1 text-primary font-weight-bolder">{{item.data.producto.nombre}}</h6>
                                <p v-if="(typeof item.data.metadata !== 'undefined' && typeof item.data.metadata.preclinica_motivo !== 'undefined' && typeof item.data.metadata.preclinica_motivo.motivo_principal_consulta !== 'undefined')" class="pb-2 small">{{item.data.metadata.preclinica_motivo.motivo_principal_consulta}}</p>  
                            </li>

                        </template>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.event{
    position: relative
}

.badge-date{
    position: absolute;
    top: -4px;
    left: -7px;
    padding: 5px 10px;
    transition: 0.3s;
}
.event:hover .badge-date{
    left: -10px;
}

.badge-option{
    position: absolute;
    top: -4px;
    right: -7px;
    transition: 0.3s;
}

.event:hover .badge-option{
    padding:7px;
    top: -6px;
    right: -10px;
}
</style>

<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import Box from '/src/modules/medico/views/fichas/Box.vue';

// Componentes

export default {
    components: {
        DatePicker,
        Box
    },
    props: [
        'show',
    ],
    data() {
        return {
            showTimeline: false,
            showResumeModal:false,
            dataResumeModal: {},
            history: {},
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {
        show: function(value) {
            this.showTimeline = value;
            this.loadItems();
        }
    },
    mounted() {

    },
    methods: {
        ...mapActions({
        }),
        dayjs,
        Numbro,
        goToPage(url) {
            window.location.href = url;
        },
        handlerResumeModal(item){
            console.log( item );
            this.showResumeModal= !this.showResumeModal;
            if(this.showResumeModal){
                this.dataResumeModal=item;
            }else{
                this.dataResumeModal={};
            }
        },
        nl2br(str) {
            if(str){
                return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
            return str;
        },
        loadItems() {
            const self = this;
            API.send('POST', 'medico/getHistory', {
                    'clienteId': self.GetMedicoFichaActive.cliente.id,
                }, function (response) {
                    self.history = response.data;
                    console.log( self.history );
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        close() {
            this.$emit('close');
        }
    },
};

</script>
