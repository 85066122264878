<script>
import { left } from "@popperjs/core";
import ClientesInLine from "./clientesInLine.vue";
import ReservasAgendarInLine from "./ReservasAgendarInLine.vue";
export default { 
 data() {
        return {
        //data
        agendarCliente:{},
        showMore:false,
        loadDelete:false
        
 }},
 emits:['createOrderMultiple', 'addClient', 'deleteOrdenMulti'],
 props: ['producto', 'dateSelected', 'horaSelected','ordersMultiples'],
 components: { 
        ClientesInLine,
        ReservasAgendarInLine
},
methods:{
    deleteOrdenMulti(orden){
        this.loadDelete=true;
           this.$emit('deleteOrdenMulti',orden);
        this.loadDelete=false;
    },
    selectClient(data){
        this.agendarCliente = data;
        console.log(this.producto.nombre);
        console.log(this.agendarCliente.nombres);
    },
    addClient(){
        this
    },
    createOrderMultiple(){
        this.$emit('createOrderMultiple');
    },
    cancelAdd(){
        this.agendarCliente={}
    },
    AddClient(data){
        data.clienteCita.taxId=this.agendarCliente.taxId;
        data.clienteCita.direccion= this.agendarCliente.direccion;
        this.$emit('AddClient',data);
        this.agendarCliente={}
    }
}
 }
</script>
 
 
<template>
    <div class="form-group row">
        <label for="nombre" class="col-sm-3 col-form-label">Agendando a:</label>
        <div class="col-sm-9">
            <clientes-in-line :show-client-search="true" :inline="true" @clientSelected="selectClient"></clientes-in-line>
            <div class="mt-2">
                <span class="alert alert-info" v-if="agendarCliente.nombres" ><b>Cliente seleccionado: </b>{{agendarCliente.nombres}} {{agendarCliente.apellidos}}</span>
            </div>
        </div>
    </div>
    <reservas-agendar-in-line v-if="producto.nombre && agendarCliente.nombres" :producto="producto" :date-selected="dateSelected" :hora-selected="horaSelected" :isMultiple="true" @cancelAdd="cancelAdd" @AddClient="AddClient"></reservas-agendar-in-line>
    <div v-if="ordersMultiples.length>0" class="mt-1 col-12">
        <div class="alert alert-success d-flex flex-column justify-content-center align-items-center p-2" role="alert">
            <div class="w-100 d-flex justify-content-between align-items-center">
                <h4 class="p-0 m-0 text-primary">Agendados {{ ordersMultiples.length }}</h4>
                <div v-if="!showMore" @click="showMore = !showMore" class="cursor-pointer text-primary">Ver mas <i class="fa-solid fa-eye"></i></div>
                <div v-else @click="showMore = !showMore"  class="cursor-pointer text-muted">Ocultar <i class="fa-solid fa-eye-slash"></i></div> 
            </div>
            
            <div  v-if="(ordersMultiples.length>0) && showMore && !loadDelete" class="resumenMultiple mt-2 pt-2 w-100 d-flex flex-column">
                <div v-for="(order,key) in ordersMultiples" class="card border-success mb-3 w-100" style="max-width: 100%;">
                    <div class="card-header d-flex justify-content-between align-items-center"><div>{{ order.clienteCita.nombres+ ' '+order.clienteCita.nombres }}
                    </div>
                    <div class="px-2" @click="deleteOrdenMulti(key)">
                        <i class="fa-solid fa-trash-can text-danger"></i>
                    </div>
                    </div>
                    <div class="card-body">
                        <p class="card-title font-weight-bold">Detalle:</p>
                        <p v-if="producto.nombre" class="card-text">Producto: {{ producto.nombre }}</p>
                        <p v-if="producto.onSale" class="card-text">Precio: {{producto.precioOnSale}}</p>
                        <p v-else class="card-text">Precio: {{ producto.precio }}</p> 
                        <p class="card-text">Cliente DPI:{{ order.clienteCita.dpi }}</p>
                        <p class="card-text">Fecha y hora {{ order.fecha+ ' '+order.horario }}</p>
                        <p class="card-text">Lugar: {{ order.locacion }}</p>
                        <p class="card-text">Atendido por: {{ order.personalNombre }}</p>
                    </div>
                </div>
            </div>
            <div v-if="loadDelete" class="d-flex justify-content-center aling-items-center">
                <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
                </div>
            </div>
          
           
        </div>
    </div>
    <div v-if="ordersMultiples.length>0" class="col-12">
         <button class="btn btn-success w-100" type="button" @click="createOrderMultiple">Finalizar</button>
    </div>
</template>
 
 
<style scoped>
 .resumenMultiple{
    border-top: 1px solid rgba(161, 161, 161, 0.312);
 }
</style>