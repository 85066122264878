import Category from "./views/Category/Category";
import CategoryForm from "./views/Category/CategoryForm";

import Brand from "./views/Brand/Brands";
import BrandsForm from "./views/Brand/BrandsForm";

import Certificates from "./views/Certificates/Certificates";
import CertificatesForm from "./views/Certificates/CertificateForm";

import Product from "./views/Product/Product";
import ProductForm from "./views/Product/ProductForm";
import ProductFormMassive from "./views/Product/ProductFormMassive";
import ProductImageMassive from "./views/Product/ProductImageMassive";

import Lotes from "./views/Lote/Lotes.vue";
import LoteForm from "./views/Lote/LoteForm.vue";

export default [
    {
        path: "/products/",
        component: Product,
    },
    {
        path: "/products/edit/:id",
        component: ProductForm,
    },
    {
        path: "/products/add/",
        component: ProductForm,
    },
    {
        path: "/products/add/massive",
        component: ProductFormMassive,
    },
    {
        path: "/products/add-images/massive",
        component: ProductImageMassive,
    },
    {
        path: "/products/category",
        component: Category,
    },
    {
        path: "/products/category/add",
        component: CategoryForm,
    },
    {
        path: "/products/category/edit/:id",
        component: CategoryForm,
    },
    {
        path: "/products/brand",
        component: Brand,
    },
    {
        path: "/products/brand/add",
        component: BrandsForm,
    },
    {
        path: "/products/brand/edit/:id",
        component: BrandsForm,
    },
    {
        path: "/products/digital-certs",
        component: Certificates,
    },
    {
        path: "/products/digital-certs/edit/:id",
        component: CertificatesForm,
    },

    // lotes
    {
        path: "/products/inventory/lot",
        component: Lotes,
    },
    {
        path: "/products/inventory/lot/edit/:id",
        component: LoteForm,
    },
];
