// Estilos
import "bootstrap/dist/css/bootstrap.css";
import "vue-multiselect/dist/vue-multiselect.css";
import 'v-calendar/dist/style.css';
import 'v-tooltip/dist/v-tooltip.css'
import "core-js/stable";
import "regenerator-runtime/runtime";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'src/assets/Global/globals.css';

// Scripts
import { createApp, h } from "vue";
import App from './App.vue'
import router from "./router";
import { store } from './store';
import VTooltipPlugin from 'v-tooltip'
import { vMaska } from "maska"
import VueGtag from "vue-gtag";
import VueSignaturePad from 'vue-signature-pad';


// Eventos globales
import mitt from 'mitt'
const emitter = mitt()

const app  = createApp({
    render: ()=>h(App)
});
app.use(VueGtag, {
    bootstrap: false,
});
app.use(store);
app.use(router);
app.use(VueSignaturePad);
app.use(VTooltipPlugin);

// para mascaras
app.directive("maska", vMaska);

// eventos globales
app.config.globalProperties.emitter = emitter;

app.config.productionTip = false;
app.mount("#app");
