<template>
      <div class="tooltip-wrapper" @click.stop>
        <slot/>
        <div :class="direction === 'right' ? 'custom-tooltip-right' : 'custom-tooltip-left'" :style="{ fontSize: fontSz+'px' }" @click.stop>
          {{text}}
          <div :class="direction === 'right' ? 'tooltip-arrow-right' : 'tooltip-arrow-left'" @click.stop></div>
        </div>
      </div>
  </template>
  
  <script>
  export default {
    name: 'CustomTooltip',
    data() {
      return {
        showTooltip: true,
      };
    },
    props:{
        text: {
          type:String,
          defaul: 'Sin descripción'
        },
        fontSz: {
          type:Number,
          defaul: 12
        },
        direction:{
          type:String,
          defaul: 'left'
        }
    },
    methods: {
     toggleTooltip(show) {
      this.showTooltip = show;
    },
    },
  };
  </script>
  
  <style scoped>
  .tooltip-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .custom-tooltip-left {
    background-color: rgba(32, 147, 218, 0.799);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -155px; /* Ajustar según sea necesario */
    transform: translateY(-50%);
    transition: opacity 0.3s;
    width: 150px;
    height: fit-content;
    opacity: 1;
  }
  .custom-tooltip-right {
    background-color:rgba(32, 147, 218, 0.799);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 110%; /* Ajustar según sea necesario */
    transform: translateY(-50%);
    transition: opacity 0.3s;
    width: 150px;
    height: fit-content;
    opacity: 1;
  }
  
  .tooltip-arrow-left{
    content: '';
    position: absolute;
    top: 50%;
    right: -10px; /* Ajustar según sea necesario */
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(32, 147, 218, 0.799);
  }

  .tooltip-arrow-right{
    content: '';
    position: absolute;
    top: 50%;
    left: -10px; /* Ajustar según sea necesario */
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(32, 147, 218, 0.799) transparent transparent ;
  }
  
  /* .tooltip-wrapper:hover .custom-tooltip {
    visibility: visible;
    opacity: 1;
  } */
  </style>