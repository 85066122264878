<script>
import {DatePicker} from 'v-calendar';
 
export default { 
 data() {
        return {
        //data
 }},
 methods:{
  
 },
 components:{
  DatePicker,
 },
 props:{
 editIndex: Number,
 procedimientos: Object,
 },
 mounted(){
 },
 }
</script>
 
 
<template>
    <div class="d-flex flex-column">
    <div class="row">
    <div class="form-group col-12 col-md-4">
                    <label>Procedimiento</label>
                    <input type="text" class="form-control" v-model="procedimientos.content[editIndex].primary_data.nombre" />
    </div>
    <div class="col-12 col-md-4">
        <div class="form-group">
            <label>Fecha/Hora</label>
            <date-picker v-model="procedimientos.content[editIndex].primary_data.fecha_hora"  mode="dateTime">
                <template #default="{ inputValue, inputEvents }">
                    <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                </template>
            </date-picker>
        </div>
    </div>
    <div class="form-group col-12 col-md-4">
                    <label>Codigo</label>
                    <input type="text" class="form-control" v-model="procedimientos.content[editIndex].primary_data.orden"/>
    </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
                    <label>Descripción</label>
                    <textarea  class="form-control" placeholder="Escribe aquí"  v-model="procedimientos.content[editIndex].primary_data.descripcion"></textarea>
      </div>
    </div>
  </div>
</template>
 
 
<style scoped>
 
 textarea{
  min-height: 100px;
  height: 160px;
  max-height: 200px;
 }
</style>