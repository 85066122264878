<template>
    <setting-layout :active="32">
        <div class="card ordenesListado">
            <div class="card-header">

                <div class="col-md-4">
                    <h4 class="card-title">Listado de suscripciones</h4>
                </div>
                <div class="col-md-4">
                    <div class="input-group pb-sm-2">

                        <order-product-search v-model:show-product-search-box="showProductSearchBox" :tipo-orden="tipoOrden" ref="searchComponent" :redirect-link="'/orders/subscriptions/new/'"></order-product-search>
                        <button type="button" class="btn btn-primary btn-block" @click="showProductSearchBoxWindow">
                            <i class="fa fa-plus"></i> Nueva subscripción
                        </button>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group pb-sm-2">
                        <input type="text" placeholder="Filtrar por: Afiliación" v-model="searchTerm"
                               @change="searchSubscription" class="form-control">
                        <div class="input-group-append" @click="searchSubscription">
                            <span id="basic-addon2" class="input-group-text">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="card-body">
                <div class="orderItem" v-for="item in displayedItems" :key="item.codigo">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="order-status">
                                    <i class="fa-solid fa-infinity"></i>
                                    <div class="order-info">
                                        <div>
                                            <i class="mdi mdi-check-circle mr-2"></i>
                                            <span>No. {{ item.codigo }}</span>
                                        </div>
                                        <div>
                                            <i class="mdi mdi-account-circle mr-2"></i>
                                            <span>{{ item.nombre || 'Cliente sin nombre' }}</span>
                                            <span class="ml-2">{{ item.tax || 'CF' }}</span>
                                        </div>
                                        <div>
                                            <i class="mdi mdi-email-variant mr-2"></i>
                                            <span>{{ item.email || 'Sin correo' }}</span>
                                        </div>
                                        <div>
                                            <i class="mdi mdi-phone mr-2"></i>
                                            <span>{{ item.telefono || 'Sin teléfono' }}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-sm-6 text-left">
                            <div class="order-actions">
                                <div>
                                    <div>
                                        Creada el <span>{{ dayjs(item.fecha).format('DD-MM-YYYY H:mm') }}</span>
                                    </div>
                                    <div>
                                        Último pago: <span>{{ dayjs(item.ultimaFechaPago).format('DD-MM-YYYY') }}</span>
                                    </div>
                                    <div class="text-primary">
                                        <span>Siguiente pago: </span>
                                        <span>{{ dayjs(item.siguienteFechaPago).format('DD-MM-YYYY') }}</span>
                                    </div>
                                </div>
                                <div>
                                    <h4>Monto {{ Numbro(item.monto || 0).formatCurrency() }}</h4>
                                </div>
                                <div v-if="item.estado === 'activa'" class="text-primary text-danger">
                                            <span @click="cancelSuscription(item.codigo)" class="cursor-pointer" v-if="!item.fcUID">
                                                Cancelar <i class="fa-solid fa-sign-out" style="font-size: 1em"></i>
                                            </span>
                                    <h5 class="text-success">
                                        Estado: Activa
                                    </h5>
                                    <span @click="createInvoice(item);"
                                          class="cursor-pointer btn btn-outline-info" v-if="!item.fcUID">
                                                Facturar <i class="fa-solid fa-file" style="font-size: 1em"></i>
                                            </span>
                                </div>
                                <div v-else>
                                    <h5 class="text-danger">Estado: Cancelada</h5>
                                </div>
                                <div v-if="itemFacturar.codigo === item.codigo">
                                    <div>
                                        <vue-pdf-embed :source="invoiceBlob" ref="pdfRef"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav>
                    <ul class="pagination">
                        <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
                            <a class="page-link" @click="currentPage = 1">Primera</a>
                        </li>
                        <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
                            <a class="page-link" @click="currentPage -= 1">Anterior</a>
                        </li>
                        <li class="page-item" v-for="page in totalPages" :key="page"
                            :class="{ 'active': currentPage === page }">
                            <a class="page-link" @click="currentPage = page">{{ page }}</a>
                        </li>
                        <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
                            <a class="page-link" @click="currentPage += 1">Siguiente</a>
                        </li>
                        <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
                            <a class="page-link" @click="currentPage = totalPages">Última</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import OrderProductSearch from "../../components/OrderProductSearch";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import VuePdfEmbed from "vue-pdf-embed";

export default {
    components: {
        VuePdfEmbed,
        settingLayout,
        OrderProductSearch
    },
    data() {
        return {
            suscriptionList: {},
            searchTerm: '',
            itemFacturar: '',
            voucherPago: '',
            invoiceBlob: '',
            currentPage: 1, // Página actual
            itemsPerPage: 15, // Cantidad de elementos por página
            filteredItems: [],
            showProductSearchBox: false,
            tipoOrden: false,
        };
    },
    mounted() {
        // Establece itemsPerPage antes de cargar los items
        this.itemsPerPage = 15; // Puedes ajustar el valor según tu necesidad
        this.loadItems();
    },
    computed: {
        itemIds() {
            return Object.keys(this.suscriptionList);
        },
        totalPages() {
            return Math.ceil(this.itemIds.length / this.itemsPerPage);
        },
        displayedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.itemIds.slice(startIndex, endIndex).map(id => this.suscriptionList[id]);
        }
    },
    methods: {
        Numbro,
        dayjs,
        loadItems() {
            const self = this;
            API.send('GET', 'orders/subscriptions/all', {},
                function (data) {
                    if (data.status) {
                        self.suscriptionList = data.data;
                        self.itemFacturar = '';
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },

        showProductSearchBoxWindow() {
            this.showProductSearchBox = true;
            this.$refs.searchComponent.search();
        },
        consultaPDF() {
            const self = this;
            // para al final es el mismo pdf solo consulto este servicio si no tengo el blob del pdf
            if (self.invoiceBlob.length === 0) {

                API.send('GET', 'orders/factura/pdf/' + self.itemFacturar.orden + '/fcUID', {},
                    function (response) {
                        if (response.status) {
                            self.downloadPDF(response.data, 'fcUID');
                            self.loadItems();
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
            else {
                self.downloadPDF(self.invoiceBlob, 'fcUID');
                self.loadItems();

            }

        },
        downloadPDF(file, tipo) {
            const linkSource = `${file}`;
            const downloadLink = document.createElement("a");
            const fileName = this.itemFacturar.codigo + tipo + ".pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        createInvoice(item) {
            const self = this;
            self.itemFacturar = item;
            const dataSend = {
                id: item.orden,
                taxCode: (item.tax) ? item.tax : 'CF',
                clientName: (item.nombre) ? item.nombre : 'Consumidor Final',
                correoContacto: item.email,
                voucher: 'pos-subscripciones'

            }
            // obtengo lsa categorías
            API.send('POST', 'orders/factura/crear', dataSend,
                function (response) {
                    if (response.status) {
                        API.showSuccessNotify(response.msg);
                        self.consultaPDF()
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        searchSubscription() {
            if(this.searchTerm !==''){
                const filteredList = Object.keys(this.suscriptionList)
                    .filter(id => {
                        const item = this.suscriptionList[id];
                        return (
                            item.codigo.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                            item.telefono.toLowerCase().includes(this.searchTerm.toLowerCase())
                        );
                    })
                    .map(id => this.suscriptionList[id]);

                // Asignar los resultados filtrados nuevamente a suscriptionList
                this.suscriptionList = Object.fromEntries(filteredList.map(item => [item.codigo, item]));
                this.currentPage = 1;
            }
            else{
                this.loadItems()
            }


        },

        cancelSuscription(suscripcionId) {

            const self = this;
            console.log(suscripcionId);

            API.showConfirm('Antes de continuar', 'La cancelación de una suscripción es definitiva, si la cancela, no podrá reactivarla.', function () {
                API.send('POST', 'orders/subscription/cancel', {
                        "suscripcionId": suscripcionId,
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessNotify(response.msg);
                            self.loadItems();
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            })
        },
    },
};
</script>
