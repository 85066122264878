<template>
    <forms-layout :active="20">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Listado de documentos y formularios</h4>
                <img src="staticAssets/formBuilder/shortlink.png" style="max-width: 130px">
            </div>
            <div class="card-body">
                <div class="form">
                    <div v-if="Object.keys(listUrl).length < 1" class="text-center text-muted">
                        No existen URL generadas
                    </div>
                    <div v-else style="max-width: 100%; overflow: auto">
                        <table class="table table-striped text-center">
                            <thead>
                            <tr>
                                <th class="font-weight-bold">Fecha creación</th>
                                <th class="font-weight-bold">Expira en</th>
                                <th class="font-weight-bold">No. clics</th>
                                <th class="font-weight-bold">URL corta</th>
                                <th class="font-weight-bold">URL Original</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in listUrl" :key="item.url" style="white-space: nowrap;">
                                <td>{{ item.d }}</td>
                                <td>{{ item.e }}</td>
                                <td>{{ item.c }}</td>
                                <td>{{ item.url }}</td>
                                <td>
                                    <input type="text" class="form-control" readonly :value="item.long"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="text-right">
                            <small class="text-muted">
                                Limitado a las últimas 500 URL
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </forms-layout>
</template>

<script>
import formsLayout from "../components/shortlinkLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";

export default {
    components: {
        formsLayout
    },
    data() {
        return {
            listUrl: {},
        };
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            const self = this;
            API.send('POST', 'short-link/list', {},
                function (data) {
                    if (data.status) {
                        self.listUrl = data.data;
                    }
                },
                function (data) {
                    API.showErrorNotify(data.msg);
                })
        },
        copyLink(item) {
            Tools.copyToClipboard(item, 'Copiado al portapapeles');
        }
    },
};
</script>
