<template>
    <div>
        <ficha-layout title="Traumatologia" color="#e89b00" :tabs="tabs" :tab-default=tabDefault enable-timeline="true">
            <template #preclinica>
                <pre-clinica></pre-clinica>
            </template>
            <template #antecedentes>
                <antecedentes></antecedentes>
            </template>
            <template #exploracion_fisica>
                <exploracion-fisica></exploracion-fisica>
            </template>
            <template #impresion_clinica>
                <impresion-clinica></impresion-clinica>
            </template>
            <template #tratamiento>
                <tratamiento></tratamiento>
              </template>
            <template #historial>
                <historial></historial>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
            <template #adjuntos>
                <adjuntos></adjuntos>
            </template>
            <template #consulta>
                <box title="Rayos X" icon="fas fa-x-ray">
                    <div class="row">
                        <div class="col-12 col-sm-3 mb-3" v-for="item in traumatologia_radiologia" :key="item.name">
                            <Field :data="item" v-model="item.value" @change="saveRayosX"></Field>
                        </div>
                    </div>
                </box>
                <box title="RMN" icon="fas fa-radiation">
                  <div class="row">
                    <div class="col-12 col-sm-3 mb-3" v-for="item in traumatologia_rmn" :key="item.name">
                      <Field :data="item" v-model="item.value" @change="saveRMN"></Field>
                    </div>
                  </div>
                </box>
                <box title="Densitometria" icon="fas fa-bone">
                  <div class="row">
                    <div class="col-12 mb-3" v-for="item in traumatologia_densitometria" :key="item.name">
                      <Field :data="item" v-model="item.value" @change="saveDensitometria"></Field>
                    </div>
                  </div>
                </box>
              <box title="Tomografía" icon="fas fa-skull">
                <div class="row">
                  <div class="col-12 mb-3" v-for="item in traumatologia_tomografia" :key="item.name">
                    <Field :data="item" v-model="item.value" @change="saveTomografia"></Field>
                  </div>
                </div>
              </box>
            </template>
        </ficha-layout>
    </div>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import {Chart} from 'highcharts-vue';


import FichaLayout from '../components/FichaLayout.vue';
import Box from '/src/modules/medico/views/fichas/Box.vue';
import Field from '/src/modules/medico/views/fichas/Field.vue';

// componentes medicos
import Antecedentes from '../components/Antecedentes.vue';
import ExploracionFisica from '../components/ExploracionFisica.vue';
import Tratamiento from '../components/Tratamiento.vue';
import LaboratorioRadiologia from '../components/LaboratorioRadiologia.vue';
import PreClinica from '../components/PreClinica.vue';
import ImpresionClinica from '../components/ImpresionClinica.vue';
import Historial from '../components/HistorialPr.vue';
import Resumen from '../components/Resumen.vue';
import Adjuntos from '../components/Adjuntos.vue';

export default {
    components: {
        Box,
        Field,
        FichaLayout,
        Antecedentes,
        ExploracionFisica,
        Tratamiento,
        PreClinica,
        ImpresionClinica,
        Historial,
        Resumen,
        Adjuntos,

        // graficos
        highcharts: Chart
    },
    data() {
        return {
            tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                antecedentes: {
                    icon: 'fas fa-history',
                    name: 'Antecedentes',
                },
                exploracion_fisica: {
                    icon: 'fas fa-search-plus',
                    name: 'Examen físico',
                },
                consulta: {
                    icon: 'fas fa-x-ray',
                    name: 'Traumatologia',
                },
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica', // CIE10
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento', // cie10 mover para acá y laboratorios y procedimientos
                },
                adjuntos: {
                    icon: 'fas fa-paperclip',
                    name: 'Adjuntos', // agregar estado de cuenta
                },
                historial: {
                    icon: 'fas fa-history',
                    name: 'Historial', // agregar estado de cuenta
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', // agregar estado de cuenta
                },
            },
          traumatologia_radiologia: {
                columna_completa: {
                    name: 'Columna Completa AP-Parado',
                    type: 'textarea',
                    value: '',
                },
                pierna: {
                    name: 'Pierna',
                    type: 'textarea',
                    value: '',
                },
                rodilla: {
                  name: 'Rodilla',
                  type: 'textarea',
                  value: '',
                },
                femur: {
                  name: 'Fémur',
                  type: 'textarea',
                  value: '',
                },
                cadera: {
                  name: 'Cadera',
                  type: 'textarea',
                  value: '',
                },
                pelvis: {
                  name: 'Pelvis',
                  type: 'textarea',
                  value: '',
                },
            mano: {
              name: 'Mano',
              type: 'textarea',
              value: '',
            },
            antebrazo: {
              name: 'Antebrazo',
              type: 'textarea',
              value: '',
            },
              codo: {
                name: 'Codo',
                type: 'textarea',
                value: '',
              },
              brazo: {
                name: 'brazo',
                type: 'textarea',
                value: '',
              },
              hombro_izquierdo: {
                name: 'Hombro Izquierdo',
                type: 'textarea',
                value: '',
              },
              hombro_derecho: {
                name: 'Hombro Derecho',
                type: 'textarea',
                value: '',
              },
              torax: {
                name: 'Tórax',
                type: 'textarea',
                value: '',
              },
              columna_lumbosacra: {
                name: 'Columna Lumbosacra',
                type: 'textarea',
                value: '',
              },
              columna_toraxica: {
                name: 'Columna Toráxica',
                type: 'textarea',
                value: '',
              },
              columna_cervical: {
                name: 'Columna Cervical',
                type: 'textarea',
                value: '',
              },
              pie: {
                name: 'Pie',
                type: 'textarea',
                value: '',
              },
              tobillo: {
                name: 'Tobillo',
                type: 'textarea',
                value: '',
              },
            },
          traumatologia_rmn: {
            columna_cervical: {
              name: 'Columna Cervical',
              type: 'textarea',
              value: '',
            },
            miembro_superior: {
              name: 'Miembro Superior',
              type: 'textarea',
              value: '',
            },
            columna_lumbosacra: {
              name: 'Columna Lumbosacra',
              type: 'textarea',
              value: '',
            },
            miembro_inferior: {
              name: 'Miembro Inferior',
              type: 'textarea',
              value: '',
            },
            columna_dorsal: {
              name: 'Columna Dorsal',
              type: 'textarea',
              value: '',
            },
          },
          traumatologia_densitometria: {
            comentarios: {
              name: 'Comentarios',
              type: 'textarea',
              value: '',
            },
          },
          traumatologia_tomografia: {
            comentarios: {
              name: 'Comentarios',
              type: 'textarea',
              value: '',
            },
          }

        }},
    props:{
        tabDefault: {
            default: 'preclinica',
            type: String
        }
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {
    },
    mounted() {
        const self = this;

        // Cargo la info de prenatales
        Object.keys(self.traumatologia_radiologia).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.traumatologia_radiologia) {
                if (self.GetMedicoFichaActive.metadata.traumatologia_radiologia[value]) {
                    self.traumatologia_radiologia[value].value = self.GetMedicoFichaActive.metadata.traumatologia_radiologia[value];
                }
            }
        });
    },
    methods: {
        ...mapActions({}),
        dayjs,
        Numbro,
        saveRayosX() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.traumatologia_radiologia).map(function (value) {
                if (self.traumatologia_radiologia[value].value !== '') arrToSave[value] = self.traumatologia_radiologia[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'traumatologia_radiologia',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveRMN(){
          const self = this;

          // transformo los keys a un array normal
          const arrToSave = {};
          Object.keys(self.traumatologia_rmn).map(function (value) {
            if (self.traumatologia_rmn[value].value !== '') arrToSave[value] = self.traumatologia_rmn[value].value;
          });

          // Guardo y luego emito el evento
          API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'traumatologia_rmn',
                detail: arrToSave,
                uniqueMeta: 1,
              }, function (response) {
                API.showSuccessAlert(response.msg);
              },
              function (response) {
                API.showErrorAlert(response.msg);
              });
        },
        saveDensitometria(){
          const self = this;

          // transformo los keys a un array normal
          const arrToSave = {};
          Object.keys(self.traumatologia_densitometria).map(function (value) {
            if (self.traumatologia_densitometria[value].value !== '') arrToSave[value] = self.traumatologia_densitometria[value].value;
          });

          // Guardo y luego emito el evento
          API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'traumatologia_densitometria',
                detail: arrToSave,
                uniqueMeta: 1,
              }, function (response) {
                API.showSuccessAlert(response.msg);
              },
              function (response) {
                API.showErrorAlert(response.msg);
              });
        },
        saveTomografia(){
          const self = this;

          // transformo los keys a un array normal
          const arrToSave = {};
          Object.keys(self.traumatologia_tomografia).map(function (value) {
            if (self.traumatologia_tomografia[value].value !== '') arrToSave[value] = self.traumatologia_tomografia[value].value;
          });

          // Guardo y luego emito el evento
          API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'traumatologia_tomografia',
                detail: arrToSave,
                uniqueMeta: 1,
              }, function (response) {
                API.showSuccessAlert(response.msg);
              },
              function (response) {
                API.showErrorAlert(response.msg);
              });
        }
    },
};

</script>
