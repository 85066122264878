<script>
 import Procedimiento from './Procedimiento.vue';
 import Imagenes from './Imagenes.vue';
 import Medicamentos from './Medicamentos.vue';
 import RayosX from './RayosX.vue';
 import Diagnosticos from './Diagnosticos.vue';
 import Notas from './Notas.vue';

export default { 
 data() {
        return {
        //data
        tabSelected:"Procedimiento",
        tabs:[
          "Procedimiento",
          "Imagenes",
          "Medicamentos",
          "Rayos X",
          "Diagnosticos",
          "Nota u Observación"
        ]

 }},
 components:{
  Procedimiento,
  Imagenes,
  Medicamentos,
  RayosX,
  Diagnosticos,
  Notas
 },
 props:{
 modalAction: String,
 editIndex: Number,
 procedimientos: Object,
 },
 mounted(){
 },
 methods:{
  handleStyleSelected(tab){
    if (tab === this.tabSelected) {
      return 'selected'
    }
  },
  handleOptionTag(tag){
      this.tabSelected=tag;
  },
  handleClose(action){

    if (this.modalAction==='add' && action === 'cancel') {
      this.$emit("handleModal", 'cancel');
    }else if (this.modalAction==='add' && action === 'save_noclose') {
      this.$emit("handleModal", 'save_noclose');
    }else if (this.modalAction==='add' && action === 'save') {
      this.$emit("handleModal", 'save');
    }else if(this.modalAction==='edit' && action === 'cancel') {
      this.$emit("handleModal");
    }else if(this.modalAction==='edit' && action === 'save_noclose') {
      this.$emit("handleModal", 'save_noclose');
    }else if(this.modalAction==='edit' && action === 'save') {
      this.$emit("handleModal", 'save');
    }else if(this.modalAction==='save_noclose' && action === 'save'){
      this.$emit("handleModal", 'save');
    }else if(this.modalAction==='save_noclose' && action === 'save_noclose'){
      this.$emit("handleModal", 'save_noclose');
    }
  },
  saveAnychages(){
    this.$emit("saveAnychages")
  },
  deleteMetada(){
    this.$emit("deleteMetada")
  }
 }
 }
</script>
 
 
<template>
  <div class="Modal">
    <div class="Modal-box">
      <div class="Modal-content row">
        <div class="col-12 title">
          {{ procedimientos.content[editIndex].primary_data.nombre }}
          <i @click="handleClose('cancel')" class="close fa-solid fa-xmark"></i>
        </div>
        <div class="col-12 tabs">
          <div v-for="tab in tabs" class="tab" :key="tab.index" :class="handleStyleSelected(tab)" @click="handleOptionTag(tab)">{{ tab }}</div>
        </div>
        <div class="col-12 detailMode d-flex flex-column justify-content-between">
            <Procedimiento :editIndex="editIndex" :procedimientos="procedimientos" v-if="tabSelected==='Procedimiento'" @handleClose="handleClose('cancel')"/>
            <Imagenes :editIndex="editIndex" :procedimientos="procedimientos" v-if="tabSelected==='Imagenes'"/>
            <Medicamentos :editIndex="editIndex" :procedimientos="procedimientos" v-if="tabSelected==='Medicamentos'"/>
            <RayosX :editIndex="editIndex" :procedimientos="procedimientos" v-if="tabSelected==='Rayos X'"/>
            <Diagnosticos @deleteMetada="deleteMetada" @saveAnychages="saveAnychages" :editIndex="editIndex" :procedimientos="procedimientos" v-if="tabSelected==='Diagnosticos'"/>
            <Notas @saveAnychages="saveAnychages" :editIndex="editIndex" :procedimientos="procedimientos" v-if="tabSelected==='Nota u Observación'"/>
            <div class="mb-3 d-flex justify-content-end close_or_save_buttons">
                <button @click="handleClose('cancel')" class="btn btn-danger ml-2" type="button">Cancelar</button>
                <button @click="handleClose('save_noclose')" class="btn btn-success ml-2" type="button">Guardar</button>
                <button @click="handleClose('save')" class="btn btn-primary ml-2" type="button">Guardar y Salir</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
 
<style scoped>

.Modal{
  position: relative;
  z-index: 9999;
}

.Modal-box{
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(3px);
}

.Modal-content{
  width: 90%;
  height: 90vh;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.title{
  position: relative;
  padding:10px 20px ;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(95, 95, 95, 0.208);
}

.close{
  position: absolute;
  top: 10px;
  right: 15px;
  transition: 0.3s;
}

.close:hover{
  transform: scale(1.2);
}
.tabs{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  gap: 10px;
  flex-wrap: wrap;
}

.tab{
  padding: 5px 10px;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
}

.tab:hover{
  box-shadow: rgba(0, 0, 0, 0.111) 0px -50px 36px -28px inset;
}

.selected{
  box-shadow: rgba(0, 0, 0, 0.111) 0px -50px 36px -28px inset;
}

.detailMode{
  height: calc( 100% - 110px);
  width: 100%;
}

.close_or_save_buttons{
 width: 100%;
 height: 35px;
 padding: 0;
 box-sizing: border-box;
}

</style>