<script>
//Images
import man from '/src/assets/Images/man.jpg';
import woman from '/src/assets/Images/woman.jpg';
import faceman from '/src/assets/Images/face-man.jpg';
import facewoman from '/src/assets/Images/face-woman.jpg';

import FreeDrawing from "/src/modules/medico/fabric/FreeDrawing.vue";
import API from "src/core/Api";
import {mapActions, mapGetters} from "vuex";
import Box from '/src/modules/medico/views/fichas/Box.vue';

export default { 
 data() {
        return {
        //data
        useDefaultUI: true,
            options: { // for tui-image-editor component's "options" prop
                cssMaxWidth: 700,
                cssMaxHeight: 500
            },

        Images: [man,woman,faceman,facewoman], 
            
        morfologia_primaria_boleean_label:{
          macula:"Mácula",
          mancha:"Mancha",
          placa:"Placa",
          papula:"Papúla",
          nodulo:"Nódulo",
          vesicula:"Vesícula",
          pustula:"Pústula",
          bula:"Bula",
        },
        morfologia_primaria_boleean:{
          macula:false,
          mancha:false,
          placa:false,
          papula:false,
          nodulo:false,
          vesicula:false,
          pustula:false,
          bula:false,
        },
        morfologia_primaria_text:{
          observaciones:"",
          tamaño:"",
          demarcacion:"No demarcado",
          observaciones_demarcacion:"",
          color:"Sin color",
          observaciones_color:"",
        },
        morfologia_secundaria_label:{
          suero:"Suero (corteza seca)",
          fisura:"Fisura",
          liquenificacion:"Liquenificación",
          erosion:"Erosión",
          ulceracion:"Ulceración",
          escalado:"Escalado", 
        },
        morfologia_secundaria_boolean:{
          suero:false,
          fisura:false,
          liquenificacion:false,
          erosion:false,
          ulceracion:false,
          escalado:false, 
        },
        morfologia_secundaria_text:{
          observaciones:"",
          distribucion:"",
          observaciones_distribucion:"",
        }
 }},
 mounted(){
  const self = this;

  if (self.GetMedicoFichaActive.metadata.morfologia_primaria_boleean) {
    Object.keys(self.morfologia_primaria_boleean).map(function (value) {
          if (self.GetMedicoFichaActive.metadata.morfologia_primaria_boleean!= undefined){
            if (self.GetMedicoFichaActive.metadata.morfologia_primaria_boleean[value]!= undefined) self.morfologia_primaria_boleean[value] = self.GetMedicoFichaActive.metadata.morfologia_primaria_boleean[value];
          }
        });
  }

  if (self.GetMedicoFichaActive.metadata.morfologia_primaria_text) {
    Object.keys(self.morfologia_primaria_text).map(function (value) {
          if (self.GetMedicoFichaActive.metadata.morfologia_primaria_text!= undefined){
            if (self.GetMedicoFichaActive.metadata.morfologia_primaria_text[value]!= undefined) self.morfologia_primaria_text[value] = self.GetMedicoFichaActive.metadata.morfologia_primaria_text[value];
          }
        });
  }

  
  if (self.GetMedicoFichaActive.metadata.morfologia_secundaria_boolean) {
    Object.keys(self.morfologia_secundaria_boolean).map(function (value) {
          if (self.GetMedicoFichaActive.metadata.morfologia_secundaria_boolean!= undefined){
            if (self.GetMedicoFichaActive.metadata.morfologia_secundaria_boolean[value]!= undefined) self.morfologia_secundaria_boolean[value] = self.GetMedicoFichaActive.metadata.morfologia_secundaria_boolean[value];
          }
        });
  }

  if (self.GetMedicoFichaActive.metadata.morfologia_secundaria_text) {
    Object.keys(self.morfologia_secundaria_text).map(function (value) {
          if (self.GetMedicoFichaActive.metadata.morfologia_secundaria_text!= undefined){
            if (self.GetMedicoFichaActive.metadata.morfologia_secundaria_text[value]!= undefined) self.morfologia_secundaria_text[value] = self.GetMedicoFichaActive.metadata.morfologia_secundaria_text[value];
          }
        });
  }

},
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
 components:{
  Box,
  FreeDrawing
 },
 methods:{
  ...mapActions({
            OrderSaveMetadata: 'OrderSaveMetadata',
        }),
  saveMorfologiaPBoolean(){
    const self = this;

    
    API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'morfologia_primaria_boleean',
                    detail: self.morfologia_primaria_boleean,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

  },
  saveMorfologiaPText(){
    const self = this;

    
    API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'morfologia_primaria_text',
                    detail: self.morfologia_primaria_text,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

  },
  saveMorfologiaSBoolean(){
    const self = this;

    
    API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'morfologia_secundaria_boolean',
                    detail: self.morfologia_secundaria_boolean,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

  },
  saveMorfologiaSText(){
    const self = this;

    
    API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'morfologia_secundaria_text',
                    detail: self.morfologia_secundaria_text,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

  }
 }
 }
</script>
 
 
<template>
  <box title="Morfología primaría" icon="fa-solid fa-virus-covid">
    <section class="row">
      <div class="col-12 col-sm-3 mb-3" v-for="(item, key) in morfologia_primaria_boleean_label" :key="key">
          <label class="toggle">
              <input class="toggle-checkbox" type="checkbox" v-model="morfologia_primaria_boleean[key]" :checked="morfologia_primaria_boleean[key]" @change="saveMorfologiaPBoolean"/>
              <div class="toggle-switch"></div>
              <span class="toggle-label">
                  {{ item }}
              </span>
          </label>
      </div>
      <div class="form-group col-12">
                    <label>Observaciónes</label>
                    <textarea v-model="morfologia_primaria_text.observaciones" class="form-control" placeholder="Escribe aquí..." @change="saveMorfologiaPText"></textarea>
        </div>
        <div class="form-group col-12 mt-3">
                    <label>Tamaño</label>
                    <input type="text" v-model="morfologia_primaria_text.tamaño" class="form-control" placeholder="Escribe aquí..." @change="saveMorfologiaPText">
        </div>
        <div class="col-12 mt-4">
            <h6>Demarcación</h6>
            <div class="radio-group mt-3 ">
                <input type="radio" name="No demarcado" value="No demarcado" v-model="morfologia_primaria_text.demarcacion" :id="'no_demarcado'"  @change="saveMorfologiaPText" /><label :for="'no_demarcado'">No demarcado</label>
                <input type="radio" name="Levemente demarcado" value="Levemente demarcado" v-model="morfologia_primaria_text.demarcacion" :id="'levemente_demarcado'"  @change="saveMorfologiaPText" /><label :for="'levemente_demarcado'">Levemente demarcado</label>
                <input type="radio" name="Altamente demarcado" value="Altamente demarcado" v-model="morfologia_primaria_text.demarcacion" :id="'altamente_demarcado'"  @change="saveMorfologiaPText" /><label :for="'altamente_demarcado'">Altamente demarcado</label>
                <input type="radio" name="Gravemente demarcado" value="Gravemente demarcado" v-model="morfologia_primaria_text.demarcacion" :id="'gravemente_demarcado'"  @change="saveMorfologiaPText" /><label :for="'gravemente_demarcado'">Gravemente demarcado</label>

            </div>
        </div>
        <div class="form-group col-12 mt-4 ">
                    <label>Observaciónes</label>
                    <textarea v-model="morfologia_primaria_text.observaciones_demarcacion" class="form-control" placeholder="Escribe aquí..." @change="saveMorfologiaPText"></textarea>
        </div>
        <div class="col-12 mt-4">
            <h6>Color</h6>
            <div class="radio-group mt-3">
                <input type="radio" name="Color" value="Blanco" v-model="morfologia_primaria_text.color" :id="'Blanco'"  @change="saveMorfologiaPText" /><label :for="'Blanco'">Blanco</label>
                <input type="radio" name="Color" value="Amarillo" v-model="morfologia_primaria_text.color" :id="'Amarillo'"  @change="saveMorfologiaPText" /><label :for="'Amarillo'">Amarillo</label>
                <input type="radio" name="Color" value="Rojo" v-model="morfologia_primaria_text.color" :id="'Rojo'"  @change="saveMorfologiaPText" /><label :for="'Rojo'">Rojo</label>
                <input type="radio" name="Color" value="Negro" v-model="morfologia_primaria_text.color" :id="'Negro'"  @change="saveMorfologiaPText" /><label :for="'Negro'">Negro</label>
                <input type="radio" name="Color" value="Púrpura" v-model="morfologia_primaria_text.color" :id="'Púrpura'"  @change="saveMorfologiaPText" /><label :for="'Púrpura'">Púrpura</label>
                <input type="radio" name="Color" value="Azul" v-model="morfologia_primaria_text.color" :id="'Azul'"  @change="saveMorfologiaPText" /><label :for="'Azul'">Azul</label>
                <input type="radio" name="Color" value="Marrón" v-model="morfologia_primaria_text.color" :id="'Marrón'"  @change="saveMorfologiaPText" /><label :for="'Marrón'">Marrón</label>
            </div>
        </div>
        <div class="form-group col-12 mt-4 ">
                    <label>Observaciónes</label>
                    <textarea v-model="morfologia_primaria_text.observaciones_color" class="form-control" placeholder="Escribe aquí..." @change="saveMorfologiaPText"></textarea>
        </div>
    </section>
  </box>
  <box title="Morfología primaría" icon="fa-solid fa-viruses">
    <section class="row">
      <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in morfologia_secundaria_label" :key="key">
          <label class="toggle">
              <input class="toggle-checkbox" type="checkbox" v-model="morfologia_secundaria_boolean[key]" :checked="morfologia_secundaria_boolean[key]" @change="saveMorfologiaSBoolean"/>
              <div class="toggle-switch"></div>
              <span class="toggle-label">
                  {{ item }}
              </span>
          </label>
      </div>
      <div class="form-group col-12 mt-4 ">
                    <label>Observaciónes</label>
                    <textarea v-model="morfologia_secundaria_text.observaciones" class="form-control" placeholder="Escribe aquí..." @change="saveMorfologiaPText"></textarea>
        </div>
        <div class="col-12 mt-4">
            <h6>Distribución</h6>
            <div class="radio-group mt-3">
                <input type="radio" name="Distribución" value="Generalizada" v-model="morfologia_secundaria_text.distribucion" :id="'Generalizada'"  @change="saveMorfologiaSText" /><label :for="'Generalizada'">Generalizada</label>
                <input type="radio" name="Distribución" value="Localizada" v-model="morfologia_secundaria_text.distribucion" :id="'Localizada'"  @change="saveMorfologiaSText" /><label :for="'Localizada'">Localizada</label>
                <input type="radio" name="Distribución" value="Fotodistribuido" v-model="morfologia_secundaria_text.distribucion" :id="'Fotodistribuido'"  @change="saveMorfologiaSText" /><label :for="'Fotodistribuido'">Fotodistribuido</label>
                <input type="radio" name="Distribución" value="Simétrica" v-model="morfologia_secundaria_text.distribucion" :id="'Simétrica'"  @change="saveMorfologiaSText" /><label :for="'Simétrica'">Simétrica</label>
                <input type="radio" name="Distribución" value="Dermatomal" v-model="morfologia_secundaria_text.distribucion" :id="'Dermatomal'"  @change="saveMorfologiaSText" /><label :for="'Dermatomal'">Dermatomal</label>
                <input type="radio" name="Distribución" value="Fotoinducida" v-model="morfologia_secundaria_text.distribucion" :id="'Fotoinducida'"  @change="saveMorfologiaSText" /><label :for="'Fotoinducida'">Fotoinducida</label>
                <input type="radio" name="Distribución" value="Acral" v-model="morfologia_secundaria_text.distribucion" :id="'Acral'"  @change="saveMorfologiaSText" /><label :for="'Acral'">Acral</label>
                <input type="radio" name="Distribución" value="Extensora" v-model="morfologia_secundaria_text.distribucion" :id="'Extensora'"  @change="saveMorfologiaSText" /><label :for="'Extensora'">Extensora</label>
                <input type="radio" name="Distribución" value="Flexora" v-model="morfologia_secundaria_text.distribucion" :id="'Flexora'"  @change="saveMorfologiaSText" /><label :for="'Flexora'">Flexora</label>
            </div>  
        </div>
        <div class="form-group col-12 mt-4 ">
                    <label>Observaciónes</label>
                    <textarea v-model="morfologia_secundaria_text.observaciones_distribucion" class="form-control" placeholder="Escribe aquí..." @change="saveMorfologiaSText"></textarea>
        </div>
    </section>
  </box>
  <box title="Herramienta dibujo" icon="fa-solid fa-palette">
    <section class="row">
      <div class="col-12 pintura">
        <FreeDrawing :Category="'D'" :Images="Images"></FreeDrawing>
      </div>
    </section>
  </box>

  
</template>
 
 
<style scoped>
 

 .pintura{
  width: 100%;
  height: fit-content;

 }
</style>