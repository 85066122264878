<template>
    <box title="Odontograma" icon="fas fa-tooth">
        <odontograma/>
    </box>

</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import Odontograma from 'src/components/odontogramvue/components/Odontogram.vue';
import Slider from '@vueform/slider';

import Box from "src/modules/medico/views/fichas/Box.vue";

// Componentes

export default {
    components: {
        DatePicker,
        Box,
        Slider,
        Odontograma
    },
    props: [
        'type',
    ],
    data() {
        return {
            // datos paciente
            tipoFicha: '',
            pesoKg: '',
            pesoOz: '',
            BMI: '',
            datos_paciente: {},

            // motivo de consulta
            motivo_consulta: {},

            // signos vitales
            showEdition: false,
            signoVital: {},

            // cliente
            fechaNacimiento: '',
            cuiPassport: '',
            genero: '',
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {
        'datos_paciente.peso': function (value) {
            this.calcularLbToKg();
        },
    },
    mounted() {

        this.tipoFicha = this.GetMedicoFichaActive.producto.fichaMedica;
        this.fechaNacimiento = this.GetMedicoFichaActive.cliente.fechaNacimiento;
        this.cuiPassport = this.GetMedicoFichaActive.cliente.cui_passport;
        this.genero = this.GetMedicoFichaActive.cliente.genero;

        this.datos_paciente = {
            fecha_nacimiento: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.fecha_nacimiento : '',
            tipo_sangre: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.tipo_sangre : '',
            peso: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.peso : '',
            talla: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.talla : '',
            circEncef: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.circEncef : '',
            alergias_observaciones: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.alergias_observaciones : '',
        };

        this.motivo_consulta = {
            motivo_principal_consulta: (this.GetMedicoFichaActive.metadata.preclinica_motivo) ? this.GetMedicoFichaActive.metadata.preclinica_motivo.motivo_principal_consulta : '',
            historial_de_la_enfermedad_actual_hpi: (this.GetMedicoFichaActive.metadata.preclinica_motivo) ? this.GetMedicoFichaActive.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi : '',
            fecha_inicio_sintomas: (this.GetMedicoFichaActive.metadata.preclinica_motivo) ? this.GetMedicoFichaActive.metadata.preclinica_motivo.fecha_inicio_sintomas : '',
        };
    },
    methods: {
        ...mapActions({
            OrderSaveMetadata: 'OrderSaveMetadata',
        }),
        dayjs,
        Numbro,

        // Motivo de consulta
        saveMetaDatosPaciente() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.motivo_consulta.id,
                    slug: 'datos_paciente',
                    detail: self.datos_paciente,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh");
                  
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.calcularBMI();
            this.cancelEdit();
        },
        saveMetaIndividual() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.motivo_consulta.id,
                    slug: 'preclinica_motivo',
                    detail: self.motivo_consulta,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh");
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEdit();
        },

        // Signos vitales
        resetSignoVital() {
            this.signoVital = {
                id: 0,
                detail: {
                    fechaHora: dayjs().format('DD-MM-YYYY HH:mm'),
                    presionArterial: '',
                    frecuenciaCardiaca: '',
                    frecuenciaRespiratoria: '',
                    saturacionDeOxigeno: '',
                    temperatura: '',
                    peso: '',
                    talla: '',
                    IMC: '',
                    glicemia: '',
                    estadoDeConciencia: '',
                    dolor: 0,
                }
            };
        },
        cancelEdit() {
            this.showEdition = false;
            this.resetSignoVital();
        },
        saveSignos() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.signoVital.id,
                    slug: 'preclinica_signos_v',
                    detail: self.signoVital.detail,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEdit();
        },
        addNewSignoVital() {
            this.resetSignoVital();
            this.showEdition = true;
        },
        editSignoVital(item) {
            this.resetSignoVital();
            if (!item.detail.dolor) item.detail.dolor = 0; // arreglo el dolor por si viene null
            this.signoVital = item;
            this.showEdition = true;
        },
        deletesignoVital(item, key) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },
        editClient() {
            const self = this;

            API.showConfirm('Antes de continuar', 'Esta acción editará la información del cliente en toda la plataforma, ¿desea continuar?', function (){
                const tmpClient = self.GetMedicoFichaActive.cliente;
                if (self.fechaNacimiento !== '') tmpClient.fechaNacimiento = self.fechaNacimiento;
                if (self.cuiPassport !== '') tmpClient.cui_passport = self.cuiPassport;
                if (self.genero !== '') tmpClient.genero = self.genero;

                API.send('POST', 'clients/edit/', tmpClient, function (response) {
                        if (typeof response.status !== 'undefined') {
                            API.showSuccessAlert(response.msg);
                            self.emitter.emit("medico-ficha-refresh");

                        } else {
                            API.showErrorNotify('Error al guardar cliente, por favor intente de nuevo');
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }, function () {
                self.fechaNacimiento = self.GetMedicoFichaActive.cliente.fechaNacimiento;
                self.cuiPassport = self.GetMedicoFichaActive.cliente.cui_passport;
                self.genero = self.GetMedicoFichaActive.cliente.genero;
            })
        },


        // calcular peso
        lbToKg(value) {
            return parseFloat(parseFloat(value) * 0.45359237).toFixed(2);
        },
        calcularLbToKg() {
            this.pesoKg = this.lbToKg(this.datos_paciente.peso);
            this.pesoOz = parseFloat(this.datos_paciente.peso) * 16;
            this.calcularBMI();
        },
        calcularKgToLb() {
            this.datos_paciente.peso = parseFloat(parseFloat(this.pesoKg) / 0.45359237).toFixed(2);
            this.saveMetaDatosPaciente();
        },
        calcularLbToOz() {
            this.datos_paciente.peso = parseFloat(parseFloat(this.pesoOz) / 16).toFixed(2);
            this.calcularLbToKg();
            this.saveMetaDatosPaciente();
        },
        calcularBMI() {
            const tallaPulgadas = this.datos_paciente.talla / 2.54;
            const BMI = (703 * this.datos_paciente.peso) / (tallaPulgadas ** 2);
            this.BMI = parseFloat(BMI).toFixed(1);
        }
    },
};

</script>
