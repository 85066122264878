<script>
 
export default { 
 data() {
        return {
        //data
        View: true,
        newIndex:0,
        checkDelete:false,
        del:false,
        keyDelete:0,
      
 }},
 props:{
 editIndex: Number,
 procedimientos: Object,
 },
 mounted(){

 },
 methods:{
  deleteDiag(action){
    
    if (action) {
      delete this.procedimientos.content[this.editIndex].diagnosticos[this.keyDelete];
      this.$emit("deleteMetada")
      this.checkDelete=false;
      this.keyDelete=0
    }else{
      this.checkDelete=false;
      this.keyDelete=0
    }

  },
  askDelete(key){
    this.checkDelete=true;
    this.keyDelete=key;
  },
  handlerView(){
    this.View=!this.View;

    if(!this.View){
      if(Object.keys(this.procedimientos.content[this.editIndex].diagnosticos).length){
        this.newIndex = Number(Object.keys(this.procedimientos.content[this.editIndex].diagnosticos).length+1);

        while (this.procedimientos.content[this.editIndex].diagnosticos[this.newIndex]) {
          this.newIndex++;
        }

        this.procedimientos.content[this.editIndex].diagnosticos[this.newIndex]={
          codigo: '',
          descripcion: '',
        }
      }else{
        this.newIndex=1;
        this.procedimientos.content[this.editIndex].diagnosticos = {
          1: {
            codigo: '',
            descripcion: '',
          },
        };
      }

    }else{

      delete this.procedimientos.content[this.editIndex].diagnosticos[this.newIndex];
      this.newIndex=0;

    }
  },
  cancelAdd(){

  },
  addDiag(){

    if (this.procedimientos.content[this.editIndex].diagnosticos[this.newIndex].codigo == "" || this.procedimientos.content[this.editIndex].diagnosticos[this.newIndex].descripcion == "") {
      return alert("Completa datos del diagnostico")
    }
    this.$emit("saveAnychages")
    this.newIndex=0;
    this.View=!this.View;
  }
 }
 }
</script>
 
 
<template>
   <section v-if="View" class="d-flex flex-column justify-content-between mb-1">
      <div v-if="Object.keys(procedimientos.content[editIndex].diagnosticos).length" class="row diag-content">
        <div v-if="checkDelete" class="card askDelete" style="width: 18rem;">
        <div class="card-body d-flex flex-column justify-content-center">
          <h5 class="card-title mb-2 text-center">¿Desea eliminar este diagnostico?</h5>
          
          <div class="d-flex justify-content-center m-1">
            <button @click="deleteDiag(true)" type="button" class="btn btn-primary px-3 m-1">Si</button>
            <button @click="deleteDiag(false)" type="button" class="btn btn-danger px-3 m-1">No</button>
          </div>
        </div>
      </div>
        <table class="table col-12">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="font-weight-bold">Código</th>
              <th scope="col" class="font-weight-bold">Descripción</th>
              <th scope="col" class="font-weight-bold">Opción</th>
            </tr>
          </thead>
          <tbody class="table-hover">
            <tr v-for="(item, key) in procedimientos.content[editIndex].diagnosticos" :key="key">
              <td>{{ item.codigo }}</td>
              <td>{{ item.descripcion }}</td>
              <td>
                <div class="options">
                   <i @click="" class="fa-solid fa-pen-to-square text-success cursor-pointer"></i>
                   <i  @click="askDelete(key)" class="fa-solid fa-trash-can text-danger cursor-pointer"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="d-flex justify-content-center mt-4">
          <p class=" text-muted">Este procedimiento aún no tiene diagnosticos agregados</p>
      </div>
      <div class="button-div d-flex justify-content-start">
        <button type="button" @click="handlerView" class="btn btn-primary">Agregar diagnostico  <i class="fa-solid fa-square-plus"></i></button>
      </div>
    </section>
    <section v-else class="d-flex flex-column justify-content-between mb-1">
      <div v-if="procedimientos.content[editIndex].diagnosticos[newIndex]" class="row">
          <div class="form-group col-12">
                          <label>Codigo</label>
                          <input type="number" class="form-control" v-model="procedimientos.content[editIndex].diagnosticos[newIndex].codigo"/>
          </div>
          <div class="form-group col-12">
                          <label>Descripción</label>
                          <textarea  class="form-control" placeholder="Escribe aquí" v-model="procedimientos.content[editIndex].diagnosticos[newIndex].descripcion"></textarea>
          </div>
      </div>
      <div v-else class="d-flex justify-content-center mt-2">

        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        
      </div>
      <div class=" button-div d-flex justify-content-start mt-2">
        <button @click="handlerView" class="btn btn-secondary px-3">Cancelar</button>
        <button @click="addDiag()" class="btn btn-primary px-3 ml-3">Guardar</button>
      </div>
    </section>
</template>
 
 
<style scoped>

section{
  position: relative;
  height: calc( 100% - 60px );
}

.askDelete{
  position: absolute;
  z-index: 9999;
  left: calc( 50% - 144px );
  top: 20%;
}

textarea{
  min-height: 100px;
  height: 160px;
  max-height: 200px;
 }
.button-div{
  height: 40px;
}

.diag-content{
  height: fit-content;
  max-height: calc( 100% - 40px );
  overflow: hidden;
  overflow-y: auto;
}
.options{
  display:flex;
  align-items: center;
  gap: 10px;
}

.options i{
  transition: 0.3s;
  font-size: 1.5rem;
}
.options i:hover{
  transform: scale(1.2);
}
</style>