<template>
    <div>
        <ficha-layout title="Veterinaria" color="#1e9a7c" :tabs="tabs" :tab-default=tabDefault enable-timeline="true">
            <template #preclinica>
                <pre-clinica></pre-clinica>
            </template>
            <template #impresion_clinica>
                <impresion-clinica></impresion-clinica>
            </template>
            <template #tratamiento>
                <tratamiento></tratamiento>
            </template>
            <template #grooming>
                <grooming></grooming>
            </template>
            <template #historial>
                <historial></historial>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
            <template #checklist>
                <box title="Checklist del procedimiento" icon="fa-solid fa-check-double">
                    <div class="d-flex justify-content-center w-100">
                        <table class="table">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col" class="font-weight-bold">
                                    Preceso
                                </th>
                                <th scope="col" class="font-weight-bold">
                                    <div class="center-option">
                                        Cancelado
                                    </div>
                                </th>
                                <th scope="col" class="font-weight-bold">
                                    <div class="center-option">
                                        Listo
                                    </div>
                                </th>
                                <th scope="col" class="font-weight-bold">
                                    <div class="center-option">
                                        Observación
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="table-hover">
                            <tr v-for="item in veterinaria_checklist" class="inputGroup bgtr" :class="handleCheckStyle(item)">
                                <td>{{ item.name }}
                                    <div v-if="item.detail" class="form-group" title="Nombre del proceso">
                                        <textarea v-model="item.detailValue" class="form-control text-detail" placeholder="Ingese aqui observaciones adicionales..." @change="saveCheckProcess"></textarea>
                                    </div>
                                </td>
                                <td>
                                    <div class="center-option">
                                        <label :for="`cancel${item.name}`" title="Cancelar proceso">

                                            <input :id="`cancel${item.name}`" class="cancel" name="cancel" type="checkbox" v-model="item.cancel" @change="handleCheckSelected(item, 'cancel')"/>
                                            <svg v-if="item.cancel" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#F05228;" d="M213.333,0C95.514,0,0,95.514,0,213.333s95.514,213.333,213.333,213.333  s213.333-95.514,213.333-213.333S331.153,0,213.333,0z M330.995,276.689l-54.302,54.306l-63.36-63.356l-63.36,63.36l-54.302-54.31  l63.356-63.356l-63.356-63.36l54.302-54.302l63.36,63.356l63.36-63.356l54.302,54.302l-63.356,63.36L330.995,276.689z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>


                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="center-option">
                                        <label :for="`check${item.name}`" title="Marcar proceso como listo">

                                            <input :id="`check${item.name}`" class="check" name="check" type="checkbox" v-model="item.check" @change="handleCheckSelected(item, 'check')"/>
                                            <svg  v-if="item.check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
                                        <path style="fill:#6AC259;" d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333  c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622  l140.894-140.898l31.309,31.309L174.199,322.918z"/>
                                        </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
                                                <defs/>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                                                    <circle id="Circle" stroke="#6B6C6E" stroke-width="2" sketch:type="MSShapeGroup" cx="32" cy="32" r="31"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="center-option p-1" @click="onChangeDetail(item)" title="Agregar observaciones adicionales">
                                        <i v-if="!item.detail" class="fa-solid fa-chevron-down"></i>
                                        <i v-if="item.detail" class="fa-solid fa-chevron-up"></i>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </box>
            </template>
            <template #adjuntos>
                <adjuntos></adjuntos>
            </template>
            <template #consulta>
                <box title="Seleccion de procedimiento" icon="fas fa-clipboard-check">
                    <div class="row">
                            <div class="input-group col-12 col-md-6">
                                    <select class="form-control" v-model="select_procedimiento" @change="saveVeterinaria(1,'select_procedimiento')">
                                        <option value="sistema_respiratorio">Sistema Respiratorio</option>
                                        <option value="sistema_digestivo">Sistema Digestivo</option>
                                        <option value="ojos">Ojos</option>
                                        <option value="cabeza">Cabeza, cuello, oídos-orejas, nariz y cavidad bucal</option>
                                        <option value="piel">Piel</option>
                                        <option value="sistema_urinario">Sistema Urinario</option>
                                        <option value="sistema_genital">Sistema Genital</option>
                                        <option value="sistema_musculoesqueletico">Sistema Musculoesquelético</option>
                                        <option value="sistema_nervioso">Sistema Nervioso</option>
                                        <option value="aumentos_masas">Aumentos de masas</option>
                                    </select>
                            </div>
                    </div>
                </box>
                <box v-if="select_procedimiento === 'sistema_respiratorio'" title="Sistema Respiratorio" icon="fas fa-lungs">
                    <div class="row w-100">
                        <div v-for="item in sistema_respiratorio" class="form-group col-12 col-md-6 col-2xl-3">
                            <label>{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'sistema_respiratorio')"/>
                        </div>
                    </div>
                </box>
                <box v-if="select_procedimiento === 'sistema_digestivo'" title="Antecedentes de la dieta" icon="fas fa-bowl-food">
                    <div class="row w-100">
                        <div v-for="item in antecedentes_dieta" class="form-group col-12 col-md-6 col-2xl-3">
                            <label>{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'antecedentes_dieta')"/>
                        </div>
                    </div>
                </box>
                <box v-if="select_procedimiento === 'sistema_digestivo'" title="Queja principal" icon="fas fa-hospital-user">
                    <div class="row w-100">
                        <div v-for="item in queja_principal" class="form-group col-12 col-md-6 col-2xl-3">
                            <label>{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'queja_principal')"/>
                        </div>
                    </div>
                </box>
                <box v-if="select_procedimiento === 'ojos'" title="Ojos" icon="fas fa-eye">
                    <div class="row w-100">
                        <div v-for="item in ojos" class="form-group col-12 col-md-6 col-2xl-3">
                            <label>{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'ojos')"/>
                        </div>
                    </div>
                </box>
                <box v-if="select_procedimiento === 'cabeza'" title="Cabeza, cuello, oídos-orejas, nariz y cavidad bucal" icon="fas fa-shield-dog">
                    <div class="row w-100">
                        <div v-for="item in cabeza" class="form-group col-12 col-md-6 col-2xl-3">
                            <label>{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'cabeza')"/>
                        </div>
                    </div>
                </box>
                <box v-if="select_procedimiento === 'piel'" title="Piel" icon="fas fa-dog">
                    <div class="row w-100">
                        <div v-for="item in piel" class="form-group col-12 col-md-6 col-2xl-3">
                            <label>{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'piel')"/>
                        </div>
                    </div>
                </box>
                <box v-if="select_procedimiento === 'sistema_urinario'" title="Sistema Urinario" icon="fas fa-dog">
                    <div class="row w-100">
                        <div v-for="item in sistema_urinario" class="form-group col-12 col-md-6 col-2xl-3">
                            <label>{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'sistema_urinario')"/>
                        </div>
                    </div>
                </box>
                <box v-if="select_procedimiento === 'sistema_genital'" title="Sistema Genital" icon="fas fa-dog">
                    <div class="row w-100">
                        <div v-for="item in sistema_genital" class="form-group col-12 col-md-6 col-2xl-3">
                            <label>{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'sistema_genital')"/>
                        </div>
                    </div>
                </box>
                <box v-if="select_procedimiento === 'sistema_musculoesqueletico'" title="Sistema Musculoesquelético" icon="fas fa-hospital-user">
                    <div class="row w-100">
                        <div v-for="item in sistema_musculoesqueletico" class="form-group col-12 col-md-6 col-2xl-3">
                            <label>{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'sistema_musculoesqueletico')"/>
                        </div>
                    </div>
                </box>
                <box v-if="select_procedimiento === 'sistema_nervioso'" title="Sistema Nervioso" icon="fas fa-dog">
                    <div class="row w-100">
                        <div v-for="item in sistema_nervioso" class="form-group col-12 col-md-6 col-2xl-3">
                            <label>{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'sistema_nervioso')"/>
                        </div>
                    </div>
                </box>
                <box v-if="select_procedimiento === 'aumentos_masas'" title="Aumentos de masas" icon="fas fa-dog">
                    <div class="row w-100">
                        <div v-for="item in aumentos_masas" class="form-group col-12 col-md-6 col-2xl-3">
                            <label>{{ item.label }}</label>
                            <input type="text" class="form-control" v-model="item.value" :placeholder="item.placeholder" @change="saveVeterinaria(2,'aumentos_masas')"/>
                        </div>
                    </div>
                </box>
                <box title="Otros" icon="fas fa-file-alt">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-3" v-for="item in veterinaria_otros" :key="item.name">
                            <Field :data="item" v-model="item.value" @change="saveOtros"></Field>
                        </div>
                    </div>
                </box>
            </template>
            <template #vacunas>
                <box title="Lista de Vacunación" icon="fas fa-syringe">
                    <table class="table">
                        <tr>
                            <td class="font-weight-bold">
                                Vacuna
                            </td>
                            <td class="font-weight-bold">Fecha #1</td>
                            <td class="font-weight-bold">Fecha #2</td>
                            <td class="font-weight-bold">Fecha #3</td>
                            <td class="font-weight-bold">Fecha #4</td>
                            <td class="font-weight-bold">Fecha #5</td>
                        </tr>
                        <tr v-for="item in veterinaria_vacunas.vacunas" :key="item" class="mb-3">
                            <td>
                                <div v-if="!item.editable">
                                    {{item.nombre}}
                                </div>
                                <div v-else>
                                    <input type="text" class="form-control" v-model="item.nombre" @change="saveVacunas"/>
                                </div>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f1" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f2" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f3" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f4" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f5" @change="saveVacunas"/>
                            </td>
                        </tr>
                    </table>
                    <div class="mt-3">
                        <div class="form-group">
                            <label>Observaciones sobre vacunas</label>
                            <textarea class="form-control w-100" v-model="veterinaria_vacunas.observaciones"  @change="saveVacunas"></textarea>
                        </div>
                    </div>
                </box>
            </template>
        </ficha-layout>
    </div>
</template>
<style scoped>
.bgtr{
    background-color: var(--bodyBackground);
    transition: 0.2s ease-in;
}

.bgtr td{
    background-color: transparent;
}
.center-option{
   display: flex; 
   flex-direction: row;
   justify-content: center;
}

.check-style{
    color: white;
    background-color: #007bff;
    .center-option label svg{
        border-radius: 50%;
        background-color: aliceblue;
        opacity: 1;
    }
}
.cancel-style{
    background-color: #6c6c6c27;
    opacity: 0.6;
    text-decoration: line-through;
    .center-option label svg{
        border-radius: 50%;
        background-color: aliceblue;
        opacity: 1;
    }
}


.cancel,
.check {
    outline: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    order: 1;
    z-index: 1;
    cursor: pointer;
    visibility: hidden;
}

.center-option label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 30px;
  height: 30px;
}


.center-option label svg{
    position: absolute;
    width: 25px;
    height: 25px;
}

.inputGroup {
    /* background-color: #fff;
    display: block;
    margin: 10px 0; */
    position: relative;
   
  }

  .text-detail{
    resize: none;
    max-height: 150px;
    text-decoration: none;
  }


</style>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';


import FichaLayout from '../components/FichaLayout.vue';
import Box from '/src/modules/medico/views/fichas/Box.vue';
import Field from '/src/modules/medico/views/fichas/Field.vue';

// componentes medicos
import Tratamiento from '../components/Tratamiento.vue';
import PreClinica from '../components/PreClinica.vue';
import ImpresionClinica from '../components/ImpresionClinica.vue';
import Grooming from '../components/Grooming.vue';
import Historial from '../components/HistorialPr.vue';
import Resumen from '../components/Resumen.vue';
import Adjuntos from '../components/Adjuntos.vue';
import { event } from "vue-gtag";

export default {
    components: {
        Box,
        Field,
        FichaLayout,
        Tratamiento,
        PreClinica,
        Grooming,
        ImpresionClinica,
        Historial,
        Resumen,
        Adjuntos,
    },
    props:{
    tabDefault: {
        default: 'preclinica',
        type: String
    }
    },
    data() {
        return {
            tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                consulta: {
                    icon: 'fas fa-paw',
                    name: 'Veterinaria',
                },
                vacunas: {
                    icon: 'fas fa-syringe',
                    name: 'Vacunas',
                },
                grooming: {
                    icon: 'fas fa-hands-wash',
                    name: 'Grooming',
                },
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica', // CIE10
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento', // cie10 mover para acá y laboratorios y procedimientos
                },
                adjuntos: {
                    icon: 'fas fa-paperclip',
                    name: 'Adjuntos',
                },
                historial: {
                    icon: 'fas fa-history',
                    name: 'Historial', // agregar estado de cuenta
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', // agregar estado de cuenta
                },
                checklist: {
                    icon: 'fa-solid fa-list-check',
                    name: 'Checklist',
                },
            },

            // checklist
            veterinaria_checklist:{
                recepcion: {
                    name: 'Recepcion y toma de datos',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                decision: {
                    name: 'Decisión del estilo de corte',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                revision: {
                    name: 'Revisión de heridas, lesiones o problemas de piel con toma de fotos para constancia',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                corte_limado: {
                    name: 'Corte uñas y limado',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                limpieza_orejas: {
                    name: 'Limpieza de orejas',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                corte_pelo: {
                    name: 'Corte de pelo o fulminado según caso',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                b_medicado: {
                    name: 'Baño medicado (2 aplicaciones)',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                vaciado_glandula: {
                    name: 'Vaciado de glándula anal',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                secado: {
                    name: 'Secado (toalla y secadora)',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                secado_oidos: {
                    name: 'Secado de oídos',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                retoque: {
                    name: 'Retoque final del corte',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                llamada: {
                    name: 'Llamada al cliente para indicar que ya pueden recogerlo',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                perfume: {
                    name: 'Perfume',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
                accesorios: {
                    name: 'Accesorios',
                    cancel: false,
                    check:false,
                    detail:false,
                    detailValue: '',
                },
            },

            veterinaria_observacion: {
                dias_enfermo: {
                    name: 'Días de enfermedad',
                    type: 'text',
                    value: '',
                },
                dificultad_respiratoria: {
                    name: 'Dificultad respiratoria',
                    type: 'text',
                    value: '',
                },
                congestion: {
                    name: 'Congestión',
                    type: 'text',
                    value: '',
                },
                tos: {
                    name: 'Tos',
                    type: 'text',
                    value: '',
                },
                secreciones: {
                    name: 'Secreciones',
                    type: 'text',
                    value: '',
                },
                estornudos: {
                    name: 'Estornudos o agita la cabeza',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                se_alimenta: {
                    name: 'Se alimenta',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                        'Muy poco',
                    ]
                },
                vomitos: {
                    name: 'Vómitos o náusea',
                    type: 'text',
                    value: '',
                },
                gases: {
                    name: 'Gases o inflamación abdominal',
                    type: 'text',
                    value: '',
                },
                diarrea: {
                    name: 'Diarrea',
                    type: 'text',
                    value: '',
                },
                deshidratado: {
                    name: 'Deshidratación',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                temblores: {
                    name: 'Temblores musculares',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                incoordinacion: {
                    name: 'Incoordinación para caminar',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                ceguera: {
                    name: 'Ceguera o problemas oculares',
                    type: 'text',
                    value: '',
                },
                salivacion: {
                    name: 'Salivación',
                    type: 'text',
                    value: '',
                },
                afonico: {
                    name: 'Afónico',
                    type: 'text',
                    value: '',
                },
                envenenamiento: {
                    name: 'Sospecha de envenenamiento',
                    type: 'text',
                    value: '',
                },
                dolor: {
                    name: 'Dolor',
                    type: 'text',
                    value: '',
                },
                articulaciones: {
                    name: 'Problema articular',
                    type: 'text',
                    value: '',
                },
                movilidad: {
                    name: 'Movilidad',
                    type: 'text',
                    value: '',
                },
            },
            veterinaria_vacunas: {
                "vacunas": {
                    "primeraVacunaPerro": {
                        "nombre": "Primera vacuna - polivalente (Perros)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "SegundaVacunaPerro": {
                        "nombre": "Segunda vacuna - polivalente (Perros)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "terceraVacunaPerro": {
                        "nombre": "Tercera vacuna - polivalente (Perros)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "cuartaVacuna": {
                        "nombre": "Cuarta vacuna - Tos de la perrera (Perros)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "quintaVacuna": {
                        "nombre": "Quinta vacuna - Antirrábica (Perros)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "primeraVacunaGato": {
                        "nombre": "Primera vacuna - Polivalente (Gatos)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "segundaVacunaGato": {
                        "nombre": "Segunda vacuna - Polivalente (Gatos)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "terceraVacunaGatos": {
                        "nombre": "Tercer vacuna - Polivalente (Gatos)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "cuartaVacunaGatos": {
                        "nombre": "Cuarta vacuna - Leucemia (Gatos)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "defensorRabia": {
                        "nombre": "Quinta vacuna - antirrábica (Gatos)",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "otraUno": {
                        "nombre": "",
                        "editable": true,
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "otraDos": {
                        "nombre": "",
                        "editable": true,
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    }
                },
                "observaciones": ""
            },

            //tab veterinaria

            select_procedimiento:'sistema_respiratorio',

            sistema_respiratorio:{  
            dias_enfermo:{
                label:"Cuantos días lleva enfermo",
                value:"",
                placeholder:"Escriba un valor aproximado...",
            },
            dificultad:{
                label:"Tiene dificultad respiratoria o congestión",
                value:"",
                placeholder:"Describa si ha notado alguno de estos sintomas...",
            },
            tos:{
                label:"Tipo de tos",
                value:"",
                placeholder:"Productiva, no productiva, húmeda, seca, aspera o ruidosa",
            },
            contracciones:{
                label:"Contracciones abdominales antes de la producción de fluido",
                value:"",
                placeholder:"Escriba aqui la descripción...",
            },
            secrecion_nasal:{
                label:"Tipo de secrecion nasal o en mucosas",
                value:"",
                placeholder:"Transparente, mucoide, mucopurulenta o hemorrágica",
            },
            sintomas_alergicos:{
                label:"Síntomas alérgicos",
                value:"",
                placeholder:"Estornudos, agitación de cabeza, etc",
            },
            inflamacion_oidos:{
                label:"Inflamación e irritación en oídos",
                value:"",
                placeholder:"Escriba aqui la descripción...",
            },
            apetito:{
                label:"Apetito",
                value:"",
                placeholder:"Escriba aqui la descripción...",
            },
            vomitos:{
                label:"Vomitos y náusea",
                value:"",
                placeholder:"Escriba aqui la descripción...",
            },
            deshidratacion:{
                label:"Deshidratación",
                value:"",
                placeholder:"Escriba aqui la descripción...",
            },
            temblor_muscular:{
                label:"Temblores musculares o coordinación para caminar, calambres",
                value:"",
                placeholder:"Escriba aqui la descripción...",
            },
            vision:{
                label:"Problemas de visión",
                value:"",
                placeholder:"Escriba aqui la descripción...",
            },
            agitacion:{
                label:"Agitación, jadeo, salivación, cambio de voz",
                value:"",
                placeholder:"Escriba aqui la descripción...",
            },
            no_ejercicio:{
                label:"Intolerancia al ejercicio, debilidad o desmayos",
                value:"",
                placeholder:"Escriba aqui la descripción...",
            },

            }, 
            
            antecedentes_dieta: {
            tiene_apetito_perdida_ganancia_peso: {
                label: "Apetito y peso",
                value: "",
                placeholder: "Describa los cambios en el apetito o peso del animal."
            },
            tipo_dieta_nombre_comercial_premios: {
                label: "Tipo de dieta",
                value: "",
                placeholder: "¿Qué tipo de comida consume? (seca, húmeda, etc.) Nombre comercial, premios y bocadillos."
            },
            metodo_alimentacion_cantidad_alimento: {
                label: "Método de alimentación",
                value: "",
                placeholder: "¿Cómo se le da de comer? (libre o tiempos individuales) Cantidad de alimento."
            },
            acceso_basura_toxinas_consumo_objetos: {
                label: "Acceso a toxinas",
                value: "",
                placeholder: "¿Tiene acceso a basura, toxinas o plantas? ¿Ha ingerido objetos?"
            },
            sospecha_obstrucción: {
                label: "Se sospecha alguna obstrucción ",
                value: "",
                placeholder: "¿Cree que tenga alguna obstrucción?"
            }
            },

            queja_principal: {

            dias_enfermo: {
                label: "Cuantos días lleva enfermo",
                value: "",
                placeholder: "¿Cuántos días lleva el animal presentando estos síntomas?"
            },
            vomitos_nausea: {
                label: "Vómitos o náuseas",
                value: "",
                placeholder: "Describa el tipo de vómito (comida digerida, no digerida, fluidos, etc.)"
            },
            frecuencia_vomito: {
                label: "Frecuencia del vómito",
                value: "",
                placeholder: "¿Con qué frecuencia presenta el animal vómitos?"
            },
            contracciones_abdominales_vomito: {
                label: "Contracciones abdominales al vomitar",
                value: "",
                placeholder: "¿Ha observado contracciones abdominales en el animal al vomitar?"
            },
            diarrea_estreñimiento: {
                label: "¿Diarrea o estreñimiento?",
                value: "",
                placeholder: "¿Ha observado diarrea o estreñimiento en el animal?"
            },
            color_heces: {
                label: "Color de las heces",
                value: "",
                placeholder: "¿De qué color son las heces del animal? (blanco, verde, amarillo, marrón, rojo)"
            },
            consistencia_heces: {
                label: "Consistencia de las heces",
                value: "",
                placeholder: "¿Qué consistencia tienen las heces del animal? (sólida, muy dura, pastosa, líquida, con ligas o mucosidad, hemorragia)"
            },
            volumen_heces: {
                label: "Volumen de las heces",
                value: "",
                placeholder: "¿Ha notado algún cambio en el volumen de las heces del animal?"
            },
            frecuencia_defecacion: {
                label: "Frecuencia de defecación",
                value: "",
                placeholder: "¿Con qué frecuencia defeca el animal? (2, 3, 5 veces al día) (foto idealmente)"
            },
            dificultad_defecar: {
                label: "¿Puja, se esfuerza o se queja de dolor al defecar?",
                value: "",
                placeholder: "¿Ha observado si el animal presenta dificultad para defecar?"
            },
            coprofagia: {
                label: "¿Consume sus propias heces?",
                value: "",
                placeholder: "¿Ha observado si el animal consume sus propias heces?"
            },
            apetito: {
                label: "Apetito",
                value: "",
                placeholder: "¿Ha notado algún cambio en el apetito del animal? (come, no come, muy poco)"
            },
            perdida_peso: {
                label: "Pérdida de peso",
                value: "",
                placeholder: "¿Ha notado alguna pérdida de peso en el animal?"
            },
            deshidratacion: {
                label: "Deshidratación",
                value: "",
                placeholder: "¿Ha observado signos de deshidratación en el animal? (sequedad en boca, encías pegajosas, piel seca, ojos hundidos, etc.)"
            },
            gases_inflamacion_dolor_abdominal: {
                label: "¿Gases, inflamación o dolor abdominal?",
                value: "",
                placeholder: "¿Ha observado si el animal presenta gases, inflamación o dolor abdominal?"
            },
            sospecha_consumo_inadecuado: {
                label: "Sospecha de consumo inadecuado",
                value: "",
                placeholder: "¿Sospecha que el animal haya consumido algo inadecuado? (basura, carne en descomposición, insecticida, veneno, grasa)"
            }
            },

            ojos: {
            secrecion_ocular: {
                label: "Secreciones oculares",
                value: "",
                placeholder: "¿Ha notado secreciones en los ojos del animal? (serosa, mucoide, mucopurelenta)"
            },
            unilateral_bilateral: {
                label: "¿Unilateral o bilateral?",
                value: "",
                placeholder: "¿Afecta uno o ambos ojos?"
            },
            dolor_ocular_incomodidad: {
                label: "Dolor ocular o incomodidad",
                value: "",
                placeholder: "¿Ha observado si el animal presenta dolor o molestia en los ojos?"
            },
            rascado_ojos: {
                label: "¿Se rasca los ojos o frota la cara contra objetos?",
                value: "",
                placeholder: "¿Ha observado si el animal se rasca los ojos o frota la cara contra objetos?"
            },
            fotofobia: {
                label: "Fotofobia",
                value: "",
                placeholder: "¿Parece que el animal le molesta la luz?"
            },
            enrojecimiento_inflamacion_asimetria: {
                label: "Enrojecimiento, inflamación o asimetría",
                value: "",
                placeholder: "¿Ha notado enrojecimiento, inflamación o asimetría en los ojos del animal?"
            },
            cambio_color: {
                label: "Cambio de color",
                value: "",
                placeholder: "¿Ha notado algún cambio en el color de los ojos del animal?"
            },
            alteraciones_vista: {
                label: "Alteraciones de la vista",
                value: "",
                placeholder: "¿Ha notado que el animal tiene problemas para ubicarse o atender a su llamado?"
            },
            ceguera: {
                label: "Ceguera",
                value: "",
                placeholder: "¿Sospecha que el animal ha perdido la visión?"
            }
            },

            cabeza: {
            cambio_volumen_asimetria: {
                label: "Cambio de volumen o asimetría",
                value: "",
                placeholder: "¿Ha notado algún cambio en el tamaño o forma de la cabeza o el cuello del animal?"
            },
            sacude_cabeza_rasca_orejas: {
                label: "¿Sacude la cabeza o se rasca las orejas?",
                value: "",
                placeholder: "¿Ha observado si el animal se rasca las orejas o sacude la cabeza con frecuencia?"
            },
            secrecion_mal_olor_otico: {
                label: "Secreción o mal olor ótico",
                value: "",
                placeholder: "¿Ha notado secreción o mal olor en los oídos del animal?"
            },
            perdida_audicion: {
                label: "Pérdida de audición",
                value: "",
                placeholder: "¿Ha notado que el animal parece tener problemas para escuchar?"
            },
            secrecion_nasal: {
                label: "Secreciones nasales",
                value: "",
                placeholder: "¿Ha notado secreciones nasales en el animal? (serosa, mucoide, mucopurulenta, hemorragia)"
            },
            unilateral_bilateral_nasal: {
                label: "¿Unilateral o bilateral (nariz)?",
                value: "",
                placeholder: "¿Las secreciones nasales afectan una o ambas fosas nasales?"
            },
            estornudos_frota_nariz: {
                label: "Estornudos o frota la nariz",
                value: "",
                placeholder: "¿Ha observado si el animal estornuda o se frota la nariz con frecuencia?"
            },
            mal_aliento: {
                label: "Mal aliento",
                value: "",
                placeholder: "¿Ha notado mal aliento en el animal?"
            },
            dificultad_comer_deglutir: {
                label: "Dificultad para comer o deglutir",
                value: "",
                placeholder: "¿Ha observado si el animal tiene dificultad para comer o tragar?"
            },
            estado_encias_lengua: {
                label: "Estado de las encías y lengua",
                value: "",
                placeholder: "¿Ha observado el estado de las encías y la lengua del animal? (color, inflamación, sangrado, etc.)"
            },
            cambio_pigmentacion_gingival: {
                label: "Cambio de pigmentación gingival",
                value: "",
                placeholder: "¿Ha notado algún cambio en el color de las encías del animal?"
            },
            cambio_ladrido_maullido: {
                label: "Cambio de ladrido o maullido",
                value: "",
                placeholder: "¿Ha notado algún cambio en el ladrido o maullido del animal?"
            }
            },

            piel: {
            perdida_pelo: {
                label: "Pérdida de pelo",
                value: "",
                placeholder: "¿Ha notado pérdida de pelo en el animal? Describa la zona afectada (capa superior o inferior del pelaje)."
            },
            prurito: {
                label: "Prurito",
                value: "",
                placeholder: "¿Ha observado que el animal se rasca, se muerde o se lame? Describa la intensidad (leve, moderado, intenso) y si es continuo o estacional."
            },
            pulgas_tratamiento: {
                label: "Pulgas y tratamiento",
                value: "",
                placeholder: "¿Se observan pulgas en el animal? ¿Qué tratamiento antipulgas usa regularmente?"
            },
            tipo_cama: {
                label: "Tipo de cama",
                value: "",
                placeholder: "¿Qué tipo de cama usa la mascota? (plumas, algodón, lana)"
            },
            dieta_premios_juguetes: {
                label: "Dieta, premios y juguetes",
                value: "",
                placeholder: "¿Qué tipo de comida, premios y juguetes utiliza el animal?"
            },
            plantas_interiores: {
                label: "Plantas interiores",
                value: "",
                placeholder: "¿Hay plantas interiores o en el entorno donde se mantiene la mascota?"
            },
            reaccion_farmacos: {
                label: "Reacción a fármacos",
                value: "",
                placeholder: "¿Ha tenido el animal alguna reacción a medicamentos en el pasado?"
            },
            olor_corporal: {
                label: "Cambio de olor o mal olor",
                value: "",
                placeholder: "¿Ha notado algún cambio en el olor corporal del animal? ¿Mal olor?"
            },
            pigmentacion_piel_pelo: {
                label: "Cambio de pigmentación",
                value: "",
                placeholder: "¿Ha notado algún cambio en la pigmentación de la piel o el pelo del animal?"
            },
            textura_piel_pelo: {
                label: "Cambios en la textura",
                value: "",
                placeholder: "¿Ha notado algún cambio en la textura de la piel o el pelo del animal?"
            },
            caspa: {
                label: "Caspa",
                value: "",
                placeholder: "¿Ha notado caspa en el animal? ¿En qué áreas está afectada?"
            },
            higiene: {
                label: "Higiene",
                value: "",
                placeholder: "¿Con qué frecuencia baña, corta el pelo y cepilla al animal? ¿Qué productos utiliza?"
            },
            mascotas_afectadas: {
                label: "Mascotas en casa",
                value: "",
                placeholder: "¿Hay más mascotas en la casa? ¿Están también afectadas por problemas de piel?"
            },
            problemas_piel_humanos: {
                label: "Problemas de piel en humanos",
                value: "",
                placeholder: "¿Algún miembro de la casa tiene problemas de piel que puedan estar relacionados con la mascota?"
            },
            tratamiento_previo: {
                label: "Tratamiento previo",
                value: "",
                placeholder: "¿Ha recibido el animal algún tratamiento previo para problemas de piel? Describa el tratamiento (medicamento, dosis, vía de administración, frecuencia, respuesta observada)."
            }
            },

            sistema_urinario: {
            orina_exceso_frecuencia: {
                label: "Orina en exceso o cambios de frecuencia",
                value: "",
                placeholder: "¿Ha notado que el animal orina con más frecuencia de lo habitual o en cantidades excesivas?"
            },
            consumo_agua: {
                label: "Cambios en el consumo de agua",
                value: "",
                placeholder: "¿Ha notado cambios en la cantidad de agua que consume la mascota?"
            },
            otros_animales_toman_agua: {
                label: "Otros animales y el agua",
                value: "",
                placeholder: "¿Otros animales toman de la misma fuente de agua que la mascota afectada?"
            },
            orina_dentro_fuera_casa: {
                label: "Orina dentro o fuera de la casa",
                value: "",
                placeholder: "¿El animal orina dentro o fuera de la casa? ¿En qué lugares específicos?"
            },
            problema_despierto_dormido: {
                label: "Problema despierto o dormido",
                value: "",
                placeholder: "¿El problema de orinar ocurre cuando el animal está despierto o dormido?"
            },
            goteo_orina: {
                label: "Goteo de orina",
                value: "",
                placeholder: "¿Ha observado que el animal gotea orina sin darse cuenta?"
            },
            cantidad_orina: {
                label: "Cantidad de orina",
                value: "",
                placeholder: "¿Ha notado si la cantidad de orina producida por el animal ha cambiado?"
            },
            dificultad_orinar: {
                label: "Dificultad para orinar",
                value: "",
                placeholder: "¿Ha observado si el animal puja o muestra dificultad para orinar?"
            },
            sangrado_orina: {
                label: "Sangrado en la orina",
                value: "",
                placeholder: "¿Ha notado sangre en la orina del animal, antes o después de orinar?"
            },
            cambio_color_orina: {
                label: "Cambio de coloración de la orina",
                value: "",
                placeholder: "¿Ha notado algún cambio en el color de la orina del animal? (muy oscura, con sangre)"
            }
            },

            sistema_genital: {
            ultimo_celo: {
                label: "Último celo (hembras)",
                value: "",
                placeholder: "¿Cuándo fue el último celo de la mascota? (fecha aproximada)"
            },
            monta_accidental: {
                label: "Monta accidental (hembras)",
                value: "",
                placeholder: "¿Ha sido cubierta la mascota o existe la posibilidad de una monta accidental?"
            },
            ciclo_estral_normal: {
                label: "Ciclo estral normal (hembras)",
                value: "",
                placeholder: "¿La duración del ciclo estral fue normal respecto al intervalo esperado entre el ciclo anterior?"
            },
            reproducido_anterioridad: {
                label: "¿Se ha reproducido la mascota con anterioridad?",
                value: "",
                placeholder: "¿La mascota ha tenido crías o ha participado en la reproducción anteriormente?"
            },
            exito_cruzamiento: {
                label: "Éxito en el cruzamiento",
                value: "",
                placeholder: "¿Si se ha reproducido antes, tuvo éxito en el cruzamiento?"
            },
            dificultad_parto: {
                label: "Dificultad en el parto",
                value: "",
                placeholder: "¿Ha tenido la mascota alguna dificultad en el parto en el pasado?"
            },
            secreciones_vulvares: {
                label: "Secreciones vulvares",
                value: "",
                placeholder: "¿Ha observado secreciones vulvares en la mascota? Describa la cantidad, consistencia, color y olor."
            }
            },

            sistema_musculoesqueletico: {
            dolor: {
                label: "¿Hay dolor?",
                value: "",
                placeholder: "¿Ha observado que el animal presenta dolor? Describa la intensidad y localización del dolor."
            },
            apoyo_peso: {
                label: "¿Apoya peso?",
                value: "",
                placeholder: "¿El animal apoya peso en la extremidad afectada? ¿En qué medida?"
            },
            traumatismo_previo: {
                label: "Trauma previo",
                value: "",
                placeholder: "¿Ha habido algún traumatismo reciente que pueda estar relacionado con el dolor? ¿El dueño conoce algún incidente?"
            },
            perdida_masa_muscular: {
                label: "Pérdida de masa muscular",
                value: "",
                placeholder: "¿Ha notado pérdida de masa muscular en la zona afectada?"
            },
            asimetria_inflamacion: {
                label: "Asimetría o inflamación",
                value: "",
                placeholder: "¿Ha observado asimetría en las extremidades o inflamación en las articulaciones?"
            },
            dificultad_movimiento: {
                label: "Dificultad para moverse",
                value: "",
                placeholder: "¿Ha notado que el animal tiene dificultad para levantarse, subir o bajar escaleras?"
            },
            evolucion_dolor: {
                label: "Evolución del dolor",
                value: "",
                placeholder: "¿El dolor mejora o empeora con el tiempo?"
            },
            garrapatas_lyme: {
                label: "Garrapatas y enfermedad de Lyme",
                value: "",
                placeholder: "¿Se han observado garrapatas en el animal? (especialmente en zonas endémicas de la enfermedad de Lyme)"
            }
            },

            sistema_nervioso: {
            cambios_conducta: {
                label: "Cambios de conducta",
                value: "",
                placeholder: "¿Ha notado cambios en la conducta del animal, como agresión o demencia?"
            },
            convulsiones: {
                label: "Convulsiones",
                value: "",
                placeholder: "¿Ha observado convulsiones en el animal? Describa la duración, intervalo entre ellas y la apariencia de las convulsiones."
            },
            anormalidad_postura: {
                label: "Anormalidad en la postura",
                value: "",
                placeholder: "¿Ha notado alguna anormalidad en la postura del animal?"
            },
            incoordinacion_marcha: {
                label: "Incoordinación para caminar",
                value: "",
                placeholder: "¿Ha observado que el animal camina de manera incoordinada o tambaleándose?"
            },
            caidas: {
                label: "¿Se cae hacia algún lado?",
                value: "",
                placeholder: "¿Ha notado que el animal se cae con frecuencia hacia un lado en particular?"
            },
            vueltas_circulos: {
                label: "¿Da vueltas en círculos?",
                value: "",
                placeholder: "¿Ha observado que el animal da vueltas en círculos sin razón aparente?"
            },
            arrastrar_extremidad: {
                label: "¿Arrastra alguna extremidad?",
                value: "",
                placeholder: "¿Ha notado que el animal arrastra alguna extremidad o presenta debilidad en una de ellas?"
            }
            },

            aumentos_masas: {
            volumen_anormal: {
                label: "¿Se observan aumentos de volumen anormal?",
                value: "",
                placeholder: "¿Ha notado la presencia de masas o protuberancias inusuales en el cuerpo del animal?"
            },
            localizacion: {
                label: "Localización",
                value: "",
                placeholder: "¿En qué parte del cuerpo se encuentran estas masas o protuberancias?"
            },
            tiempo_aparicion: {
                label: "Tiempo de aparición",
                value: "",
                placeholder: "¿Hace cuánto tiempo ha notado la presencia de estas masas o protuberancias? ¿Ha observado un aumento de volumen en ese tiempo?"
            },
            cambio_apariencia_tamano: {
                label: "Cambio de apariencia o tamaño",
                value: "",
                placeholder: "¿Ha notado algún cambio en la apariencia o el tamaño de estas masas o protuberancias?"
            },
            consistencia: {
                label: "Consistencia",
                value: "",
                placeholder: "¿Cómo describiría la consistencia de estas masas o protuberancias? (blanda o sólida, firme o fluctuante, móvil o adherida)"
            },
            piel_afectada: {
                label: "Piel afectada",
                value: "",
                placeholder: "¿La piel sobre estas masas o protuberancias está cicatrizada o presenta alguna herida abierta?"
            }
            },

            veterinaria_otros: {
                habitos: {
                    name: 'Hábitos o comportamientos',
                    type: 'textarea',
                    value: '',
                },
                observaciones: {
                    name: 'Observaciones',
                    type: 'textarea',
                    value: '',
                },
            },

            // graficos
            weightForAge: false,
            weightForLengthHeight: false,
            lengthHeightForAge: false,
            BMIForAge: false,
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {
        'GetMedicoFichaActive.datos_paciente.peso': function (value) {
            this.drawChart();
        },
    },
    mounted() {
        const self = this;

        //Cargo checklist
        Object.keys(self.veterinaria_checklist).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.veterinaria_checklist) {

                if (self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_cancel']) {
                    self.veterinaria_checklist[value].cancel = self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_cancel'];
                }
                if (self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_check']) {
                    self.veterinaria_checklist[value].check = self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_check'];
                }
                if (self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_detail']) {
                    self.veterinaria_checklist[value].detail = self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_detail'];
                }
                if (self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_detailValue']) {
                    self.veterinaria_checklist[value].detailValue = self.GetMedicoFichaActive.metadata.veterinaria_checklist[value+'_detailValue'];
                }

            }
        });


        // Cargo la info de prenatales
        Object.keys(self.veterinaria_observacion).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.veterinaria_observacion) {
                if (self.GetMedicoFichaActive.metadata.veterinaria_observacion[value]) {
                    self.veterinaria_observacion[value].value = self.GetMedicoFichaActive.metadata.veterinaria_observacion[value];
                }
            }
        });

        Object.keys(self.veterinaria_otros).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.veterinaria_otros) {
                if (self.GetMedicoFichaActive.metadata.veterinaria_otros[value]) {
                    self.veterinaria_otros[value].value = self.GetMedicoFichaActive.metadata.veterinaria_otros[value];
                }
            }
        });

        //cargo procedimiento
        if(self.GetMedicoFichaActive.metadata.select_procedimiento){
            self.select_procedimiento=self.GetMedicoFichaActive.metadata.select_procedimiento['select_procedimiento'];
        }
        if(self.GetMedicoFichaActive.metadata.sistema_respiratorio){
            Object.keys(self.sistema_respiratorio).map(function (value) {
                           self.sistema_respiratorio[value].value = self.GetMedicoFichaActive.metadata.sistema_respiratorio[value] || '';
                        });
        }
        if(self.GetMedicoFichaActive.metadata.antecedentes_dieta){
            Object.keys(self.antecedentes_dieta).map(function (value) {
                           self.antecedentes_dieta[value].value = self.GetMedicoFichaActive.metadata.antecedentes_dieta[value] || '';
                        });
        }
        if(self.GetMedicoFichaActive.metadata.queja_principal){
            Object.keys(self.queja_principal).map(function (value) {
                           self.queja_principal[value].value = self.GetMedicoFichaActive.metadata.queja_principal[value] || '';
                        });
        }
        if(self.GetMedicoFichaActive.metadata.ojos){
            Object.keys(self.ojos).map(function (value) {
                           self.ojos[value].value = self.GetMedicoFichaActive.metadata.ojos[value] || '';
                        });
        }
        if(self.GetMedicoFichaActive.metadata.cabeza){
            Object.keys(self.cabeza).map(function (value) {
                           self.cabeza[value].value = self.GetMedicoFichaActive.metadata.cabeza[value] || '';
                        });
        }
        if(self.GetMedicoFichaActive.metadata.piel){
            Object.keys(self.piel).map(function (value) {
                           self.piel[value].value = self.GetMedicoFichaActive.metadata.piel[value] || '';
                        });
        }
        if(self.GetMedicoFichaActive.metadata.sistema_urinario){
            Object.keys(self.sistema_urinario).map(function (value) {
                           self.sistema_urinario[value].value = self.GetMedicoFichaActive.metadata.sistema_urinario[value] || '';
                        });
        }
        if(self.GetMedicoFichaActive.metadata.sistema_genital){
            Object.keys(self.sistema_genital).map(function (value) {
                           self.sistema_genital[value].value = self.GetMedicoFichaActive.metadata.sistema_genital[value] || '';
                        });
        }
        if(self.GetMedicoFichaActive.metadata.sistema_musculoesqueletico){
            Object.keys(self.sistema_musculoesqueletico).map(function (value) {
                           self.sistema_musculoesqueletico[value].value = self.GetMedicoFichaActive.metadata.sistema_musculoesqueletico[value] || '';
                        });
        }
        if(self.GetMedicoFichaActive.metadata.sistema_nervioso){
            Object.keys(self.sistema_nervioso).map(function (value) {
                           self.sistema_nervioso[value].value = self.GetMedicoFichaActive.metadata.sistema_nervioso[value] || '';
                        });
        }
        if(self.GetMedicoFichaActive.metadata.aumentos_masas){
            Object.keys(self.aumentos_masas).map(function (value) {
                           self.aumentos_masas[value].value = self.GetMedicoFichaActive.metadata.aumentos_masas[value] || '';
                        });
        }


     
        // Cargo la info de vacunas
        Object.keys(self.veterinaria_vacunas.vacunas).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas) {
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f1']) self.veterinaria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f1'];
               
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f2']) self.veterinaria_vacunas.vacunas[value].f2 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f2'];
                
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f3']) self.veterinaria_vacunas.vacunas[value].f3 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f3'];
                
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f4']) self.veterinaria_vacunas.vacunas[value].f4 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f4'];
               
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f5']) self.veterinaria_vacunas.vacunas[value].f5 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f5'];
                
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_n']) self.veterinaria_vacunas.vacunas[value].nombre = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_n'];
                
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas['observaciones']) self.veterinaria_vacunas['observaciones'] = self.GetMedicoFichaActive.metadata.veterinaria_vacunas['observaciones'];
            }
        });
    },
    methods: {
        ...mapActions({}),
        dayjs,
        Numbro,
        saveVeterinaria(item,slug){
            const self = this;
            let itemsTosave={};
            
            switch (item) {
                case 1:
                itemsTosave[slug]=self.select_procedimiento;
                    break;
                case 2:
                    Object.keys(self[slug]).map(function (value) {
                        itemsTosave[value]=self[slug][value].value;
                    });
                    break;
                default:
                    return API.showErrorAlert('Item invalido AX-6775');
                
            }

            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug,
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
        },
        onChangeDetail(item){
            item.detail=!item.detail;
        },
        handleCheckSelected(item, action){
            if(action=== 'cancel' && item.cancel && item.check){
                item.check=false;
            }else if(action=== 'check' && item.check && item.cancel){
                item.cancel=false;
            }
            this.saveCheckProcess();
        },
        handleCheckStyle(item){
            if(item.cancel){
                return "cancel-style";
            }else if (item.check) {
                return "check-style";
            }
        },
        saveCheckProcess(){
            const self = this;

            const arrToSave = {};
            Object.keys(self.veterinaria_checklist).map(function (value) {
                arrToSave[value+'_cancel'] = self.veterinaria_checklist[value].cancel;
                arrToSave[value+'_check'] = self.veterinaria_checklist[value].check;
                arrToSave[value+'_detail'] = self.veterinaria_checklist[value].detail;
                arrToSave[value+'_detailValue'] = self.veterinaria_checklist[value].detailValue;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_checklist',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveObservacion() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_observacion).map(function (value) {
                if (self.veterinaria_observacion[value].value !== '') arrToSave[value] = self.veterinaria_observacion[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_observacion',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveAlimentacion() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_alimentacion).map(function (value) {
                if (self.veterinaria_alimentacion[value].value !== '') arrToSave[value] = self.veterinaria_alimentacion[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_alimentacion',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveOtros() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_otros).map(function (value) {
                if (self.veterinaria_otros[value].value !== '') arrToSave[value] = self.veterinaria_otros[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_otros',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveVacunas() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_vacunas.vacunas).map(function (value) {
                if (self.veterinaria_vacunas.vacunas[value].f1 !== '') arrToSave[value + '_f1'] = self.veterinaria_vacunas.vacunas[value].f1;
                if (self.veterinaria_vacunas.vacunas[value].f2 !== '') arrToSave[value + '_f2'] = self.veterinaria_vacunas.vacunas[value].f2;
                if (self.veterinaria_vacunas.vacunas[value].f3 !== '') arrToSave[value + '_f3'] = self.veterinaria_vacunas.vacunas[value].f3;
                if (self.veterinaria_vacunas.vacunas[value].f4 !== '') arrToSave[value + '_f4'] = self.veterinaria_vacunas.vacunas[value].f4;
                if (self.veterinaria_vacunas.vacunas[value].f5 !== '') arrToSave[value + '_f5'] = self.veterinaria_vacunas.vacunas[value].f5;

                if (self.veterinaria_vacunas.vacunas[value].editable) {
                    if (self.veterinaria_vacunas.vacunas[value].nombre !== '') arrToSave[value + '_n'] = self.veterinaria_vacunas.vacunas[value].nombre;
                }
            });
            arrToSave['observaciones'] = self.veterinaria_vacunas['observaciones'];

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_vacunas',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },

        // Gráficos
        drawChart() {

            const self = this;
            const peso = (typeof self.GetMedicoFichaActive.cliente.peso !== 'undefined') ? self.GetMedicoFichaActive.cliente.peso : false;
            const talla = (typeof self.GetMedicoFichaActive.metadata !== 'undefined' && typeof self.GetMedicoFichaActive.metadata.datos_paciente !== 'undefined' && typeof self.GetMedicoFichaActive.metadata.datos_paciente.talla !== 'undefined') ? self.GetMedicoFichaActive.metadata.datos_paciente.talla : false;
            const genero = (typeof self.GetMedicoFichaActive.cliente.genero !== 'undefined') ? self.GetMedicoFichaActive.cliente.genero : false;
            const fechaNacimiento = (typeof self.GetMedicoFichaActive.cliente.fechaNacimiento !== 'undefined') ? self.GetMedicoFichaActive.cliente.fechaNacimiento : false;

            if (!genero || !talla || !peso || !fechaNacimiento) {
                API.showErrorAlert('Para calcular los gráficos es necesario que el paciente posea peso, talla, género y fecha de nacimiento');
            }
            else {
                API.send('POST', 'medico/utilities/oms-anthropometric-indicators', {
                        'fechaNacimiento': fechaNacimiento,
                        'genero': genero,
                        'peso': peso,
                        'talla': talla,
                        'displayWeightIn': 'lb',
                    }, function (response) {
                        self.BMIForAge = response.data.BMIForAge;
                        self.weightForAge = response.data.weightForAge;
                        self.weightForLengthHeight = response.data.weightForLengthHeight;
                        self.lengthHeightForAge = response.data.lengthHeightForAge;
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            }
        },
    },
};

</script>
