<template>
    <box title="Hábitos y observaciones" icon="fas fa-file-alt">
        <div class="row">
            <div class="col-12 col-md-6 mb-3" v-for="item in veterinaria_otros" :key="item.name">
                <Field :data="item" v-model="item.value" @change="saveOtros"></Field>
            </div>
        </div>
    </box>
    <box title="Grooming" icon="fas fa-dog" >
        <div class="row">
            <div class="col-12 col-sm-3 mb-3" v-for="item in veterinaria_grooming" :key="item.name">
                <Field :data="item" v-model="item.value" @change="saveGrooming"></Field>
            </div>
        </div>

    </box>
    <box title="Servicio" icon="fas fa-dog" >
        <div class="row">
            <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in grooming_servicio" :key="'grooming_servicio_' + key">
                <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveGroomingComportamiento"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                </label>
            </div>
        </div>
    </box>
    <box title="Le encontramos" icon="fas fa-dog" >
        <div class="row">
            <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in encontramos" :key="'encontramos' + key">
                <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveGroomingEncontramos"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                </label>
            </div>
        </div>
    </box>
    <box title="Recomendaciones" icon="fas fa-dog" >
        <box title="Gromista" icon="fas fa-dog" >
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in gRecomendaciones" :key="'gRecomendaciones' + key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="groomingRecomendaciones"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                    </label>
                </div>
            </div>
        </box>
        <box title="Veterinario" icon="fas fa-dog" >
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in vgRecomendaciones" :key="'vgRecomendaciones' + key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="groomingVRecomendaciones"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                    </label>
                </div>
            </div>
        </box>
    </box>
</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import Slider from '@vueform/slider';

import Box from "src/modules/medico/views/fichas/Box.vue";
import Field from "../../Field.vue";

// Componentes

export default {
    components: {
        Field,
        DatePicker,
        Box,
        Slider
    },
    props: [
        'type',
    ],
    data() {
        return {
            // datos paciente
            tipoFicha: '',

            // signos vitales
            showEdition: false,
            signoVital: {},

            veterinaria_grooming: {
                detalles: {
                    name: 'Detalles específicos del grooming',
                    type: 'textarea',
                    value: '',
                },
                recepcion: {
                    name: 'Hora de recepción',
                    type: 'time',
                    value: '',
                },
                entrega: {
                    name: 'Hora de entrega',
                    type: 'time',
                    value: '',
                },
                citaSugeridaB: {
                    name: 'Cita sugerida para baño',
                    type: 'date',
                    value: '',
                },
                citaSugeridaG: {
                    name: 'Cita sugerida para grooming',
                    type: 'date',
                    value: '',
                },
                groomer: {
                    name: 'Groomer o encargados',
                    type: 'text',
                    value: '',
                },
                clienteEncargado: {
                    name: 'Nombre del cliente encargado',
                    type: 'text',
                    value: '',
                }
            },
            grooming_servicio: {
                completo: {
                    name: 'Grooming completo',
                    complemento: {},
                    value: false,
                },
                medicado: {
                    name: 'Baño medicado',
                    complemento: {},
                    value: false,
                },
                banio: {
                    name: 'Baño',
                    complemento: {},
                    value: false,
                },
                corte: {
                    name: 'Corte de pelo',
                    complemento: {},
                    value: false,
                },
                exposicion: {
                    name: 'Exposición',
                    complemento: {},
                    value: false,
                },
                GCcachorro: {
                    name: 'GC Cachorro',
                    complemento: {},
                    value: false,
                }
            },
            encontramos: {
                heridas: {
                    name: 'Heridas',
                    complemento: {},
                    value: false,
                },
                golpes: {
                    name: 'Golpes',
                    complemento: {},
                    value: false,
                },
                irritacion: {
                    name: 'Piel irritada',
                    complemento: {},
                    value: false,
                },
                seborrea: {
                    name: 'Seborrea',
                    complemento: {},
                    value: false,
                },
                dermatitis: {
                    name: 'Dermatitis',
                    complemento: {},
                    value: false,
                },
                caspa: {
                    name: 'Caspa',
                    complemento: {},
                    value: false,
                },
                hongos: {
                    name: 'Hongos',
                    complemento: {},
                    value: false,
                },
                acaros: {
                    name: 'Ácaros',
                    complemento: {},
                    value: false,
                },
                pulgas: {
                    name: 'Pulgas',
                    complemento: {},
                    value: false,
                },
                garrapatas: {
                    name: 'Garrapatas',
                    complemento: {},
                    value: false,
                },
                caida: {
                    name: 'Caída de pelo',
                    complemento: {},
                    value: false,
                },
                pelo_maltratado: {
                    name: 'Pelo maltratado',
                    complemento: {},
                    value: false,
                },
                nudos: {
                    name: 'Nudos',
                    complemento: {},
                    value: false,
                },
                bajoPeso: {
                    name: 'Bajo peso',
                    complemento: {},
                    value: false,
                },
                sobrePeso: {
                    name: 'Sobre peso',
                    complemento: {},
                    value: false,
                },
                malAliento: {
                    name: 'Mal aliento',
                    complemento: {},
                    value: false,
                },
                dientesSucios: {
                    name: 'Dientes sucios',
                    complemento: {},
                    value: false,
                },
                oidosSucios: {
                    name: 'Oídos sucios',
                    complemento: {},
                    value: false,
                },
                uniasLargas: {
                    name: 'Uñas largas',
                    complemento: {},
                    value: false,
                },
                uniasEncarnadas: {
                    name: 'Uñas encarnadas',
                    complemento: {},
                    value: false,
                },
                acarosOidos: {
                    name: 'Ácaros en los oídos',
                    complemento: {},
                    value: false,
                },
                otitisOidos: {
                    name: 'Otitis: Inf. oídos',
                    complemento: {},
                    value: false,
                },
                lagrimasSecas: {
                    name: 'Otitis: Inf. oídos',
                    complemento: {},
                    value: false,
                }
            },
            gRecomendaciones: {
                corteF: {
                    name: 'Corte frecuente para evitar nudos y problemas en la piel',
                    complemento: {},
                    value: false,
                },
                corteU: {
                    name: 'Corte frecuente de uñas para evitar mal crecimiento',
                    complemento: {},
                    value: false,
                },
                antipulgas: {
                    name: 'Aplicar antipulgas - garrapatas - ácaros',
                    complemento: {},
                    value: false,
                },
                acondicionador: {
                    name: 'Aplicar acondicionador para evitar nudos',
                    complemento: {},
                    value: false,
                },
                peinarDiario: {
                    name: 'Peinar diario',
                    complemento: {},
                    value: false,
                },
                peinarTres: {
                    name: 'Peinar C/3 días',
                    complemento: {},
                    value: false,
                },
                peinarSemana: {
                    name: 'Peinar C/semana',
                    complemento: {},
                    value: false,
                },
                rMalAliento: {
                    name: 'Dar tratamiento para mal aliento',
                    complemento: {},
                    value: false,
                },
                limpiezaDental: {
                    name: 'Dr juguetes especiales para limpieza dental',
                    complemento: {},
                    value: false,
                },
                liquidoOjos: {
                    name: 'Limpieza alrededor de ojos con líquido especial',
                    complemento: {},
                    value: false,
                }
            },
            vgRecomendaciones: {
                suplePeso: {
                    name: 'Suplemento vitamínico para aumento de peso',
                    complemento: {},
                    value: false,
                },
                bajarPeso: {
                    name: 'Dieta para bajar de peso',
                    complemento: {},
                    value: false,
                },
                desparacitos: {
                    name: 'Desparacitación',
                    complemento: {},
                    value: false,
                },
                limpiezaDentalV: {
                    name: 'Limpieza dental',
                    complemento: {},
                    value: false,
                },
                tOtitis: {
                    name: 'Tratamiento para otitis',
                    complemento: {},
                    value: false,
                },
                tGolpes: {
                    name: 'Tratamiento para heridas, golpes o raspones',
                    complemento: {},
                    value: false,
                },
                tHongos: {
                    name: 'Tratamiento para hongos',
                    complemento: {},
                    value: false,
                },
                tSarnas: {
                    name: 'Tratamiento para sarnas o ácaros',
                    complemento: {},
                    value: false,
                },
                tgOtros: {
                    name: 'Otros',
                    type: 'text',
                    value: '',
                },
            },

            veterinaria_otros: {
                habitos: {
                    name: 'Hábitos o comportamientos',
                    type: 'textarea',
                    value: '',
                },
                observaciones: {
                    name: 'Observaciones',
                    type: 'textarea',
                    value: '',
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {
        'datos_paciente.peso': function (value) {
            this.calcularLbToKg();
        },
        'datos_paciente.pesoOz': function (value) {
            this.calcularLbToKg();
        },
    },
    mounted() {
        const self = this;
        this.tipoFicha = this.GetMedicoFichaActive.producto.fichaMedica;
        this.fechaNacimiento = this.GetMedicoFichaActive.cliente.fechaNacimiento;
        this.cuiPassport = this.GetMedicoFichaActive.cliente.cui_passport;
        this.genero = this.GetMedicoFichaActive.cliente.genero;
        this.familia = this.GetMedicoFichaActive.cliente.familia;
        this.raza = this.GetMedicoFichaActive.cliente.raza;
        this.caracteristicas = this.GetMedicoFichaActive.cliente.caracteristicas;

        this.datos_paciente = {
            fecha_nacimiento: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.fecha_nacimiento : '',
            tipo_sangre: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.tipo_sangre : '',
            peso: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.peso : '',
            pesoOz: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.pesoOz : 0,
            talla: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.talla : '',
            circEncef: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.circEncef : '',
            alergias_observaciones: (this.GetMedicoFichaActive.metadata.datos_paciente) ? this.GetMedicoFichaActive.metadata.datos_paciente.alergias_observaciones : '',
        };

        // Cargo la info de pediatria
        Object.keys(self.veterinaria_otros).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.veterinaria_otros) {
                if (self.GetMedicoFichaActive.metadata.veterinaria_otros[value]) {
                    self.veterinaria_otros[value].value = self.GetMedicoFichaActive.metadata.veterinaria_otros[value];
                }
            }
        });
        // Cargo la info de grooming
        Object.keys(self.veterinaria_grooming).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.veterinaria_grooming) {
                if (self.GetMedicoFichaActive.metadata.veterinaria_grooming[value]) {
                    self.veterinaria_grooming[value].value = self.GetMedicoFichaActive.metadata.veterinaria_grooming[value];
                }
            }
        });
        // Cargo la info de comportamiento
        Object.keys(self.grooming_servicio).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.grooming_servicio) {
                if (self.GetMedicoFichaActive.metadata.grooming_servicio[value]) {
                    self.grooming_servicio[value].value = self.GetMedicoFichaActive.metadata.grooming_servicio[value];
                }
            }
        });
        // Cargo la info de comportamiento
        Object.keys(self.encontramos).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.encontramos) {
                if (self.GetMedicoFichaActive.metadata.encontramos[value]) {
                    self.encontramos[value].value = self.GetMedicoFichaActive.metadata.encontramos[value];
                }
            }
        });
        // Cargo la info de comportamiento
        Object.keys(self.vgRecomendaciones).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.vgRecomendaciones) {
                if (self.GetMedicoFichaActive.metadata.vgRecomendaciones[value]) {
                    self.vgRecomendaciones[value].value = self.GetMedicoFichaActive.metadata.vgRecomendaciones[value];
                }
            }
        });
        // Cargo la info de comportamiento
        Object.keys(self.gRecomendaciones).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.gRecomendaciones) {
                if (self.GetMedicoFichaActive.metadata.gRecomendaciones[value]) {
                    self.gRecomendaciones[value].value = self.GetMedicoFichaActive.metadata.gRecomendaciones[value];
                }
            }
        });

        this.motivo_consulta = {
            motivo_principal_consulta: (this.GetMedicoFichaActive.metadata.preclinica_motivo) ? this.GetMedicoFichaActive.metadata.preclinica_motivo.motivo_principal_consulta : '',
            historial_de_la_enfermedad_actual_hpi: (this.GetMedicoFichaActive.metadata.preclinica_motivo) ? this.GetMedicoFichaActive.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi : '',
            fecha_inicio_sintomas: (this.GetMedicoFichaActive.metadata.preclinica_motivo) ? this.GetMedicoFichaActive.metadata.preclinica_motivo.fecha_inicio_sintomas : '',
        };
    },
    methods: {
        ...mapActions({
            OrderSaveMetadata: 'OrderSaveMetadata',
        }),
        dayjs,
        Numbro,

        // Motivo de consulta
        saveMetaDatosPaciente() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.motivo_consulta.id,
                    slug: 'datos_paciente',
                    detail: self.datos_paciente,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    //self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.calcularBMI();
            this.cancelEdit();
        },
        saveGrooming() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_grooming).map(function (value) {
                if (self.veterinaria_grooming[value].value !== '') arrToSave[value] = self.veterinaria_grooming[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_grooming',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveOtros() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_otros).map(function (value) {
                if (self.veterinaria_otros[value].value !== '') arrToSave[value] = self.veterinaria_otros[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_otros',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveGroomingComportamiento() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.grooming_servicio).map(function (value) {
                arrToSave[value] = self.grooming_servicio[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'grooming_servicio',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveGroomingEncontramos() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.encontramos).map(function (value) {
                arrToSave[value] = self.encontramos[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'encontramos',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        groomingRecomendaciones() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.gRecomendaciones).map(function (value) {
                arrToSave[value] = self.gRecomendaciones[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'gRecomendaciones',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        groomingVRecomendaciones() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.vgRecomendaciones).map(function (value) {
                arrToSave[value] = self.vgRecomendaciones[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'vgRecomendaciones',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveMetaIndividual() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.motivo_consulta.id,
                    slug: 'preclinica_motivo',
                    detail: self.motivo_consulta,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    //self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEdit();
        },

        // Signos vitales
        resetSignoVital() {
            this.signoVital = {
                id: 0,
                detail: {
                    fechaHora: dayjs().format('DD-MM-YYYY HH:mm'),
                    presionArterial: '',
                    frecuenciaCardiaca: '',
                    frecuenciaRespiratoria: '',
                    saturacionDeOxigeno: '',
                    temperatura: '',
                    peso: '',
                    pesoOz: '',
                    talla: '',
                    IMC: '',
                    glicemia: '',
                    estadoDeConciencia: '',
                    dolor: 0,
                }
            };
        },
        cancelEdit() {
            this.showEdition = false;
            this.resetSignoVital();
        },
        saveSignos() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.signoVital.id,
                    slug: 'preclinica_signos_v',
                    detail: self.signoVital.detail,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEdit();
        },
        addNewSignoVital() {
            this.resetSignoVital();
            this.showEdition = true;
        },
        editSignoVital(item) {
            this.resetSignoVital();
            if (!item.detail.dolor) item.detail.dolor = 0; // arreglo el dolor por si viene null
            this.signoVital = item;
            this.showEdition = true;
        },
        deletesignoVital(item, key) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },
        editClient() {
            const self = this;

            API.showConfirm('Antes de continuar', 'Esta acción editará la información del cliente en toda la plataforma, ¿desea continuar?', function (){
                const tmpClient = self.GetMedicoFichaActive.cliente;
                if (self.fechaNacimiento !== '') tmpClient.fechaNacimiento = self.fechaNacimiento;
                if (self.cuiPassport !== '') tmpClient.cui_passport = self.cuiPassport;
                if (self.genero !== '') tmpClient.genero = self.genero;
                if (self.familia !== '') tmpClient.familia = self.familia;
                if (self.raza !== '') tmpClient.raza = self.raza;
                if (self.caracteristicas !== '') tmpClient.caracteristicas = self.caracteristicas;

                API.send('POST', 'clients/edit/', tmpClient, function (response) {
                        if (typeof response.status !== 'undefined') {
                            API.showSuccessAlert(response.msg);
                            self.emitter.emit("medico-ficha-refresh");

                        } else {
                            API.showErrorNotify('Error al guardar cliente, por favor intente de nuevo');
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }, function () {
                self.fechaNacimiento = self.GetMedicoFichaActive.cliente.fechaNacimiento;
                self.cuiPassport = self.GetMedicoFichaActive.cliente.cui_passport;
                self.genero = self.GetMedicoFichaActive.cliente.genero;
                self.familia = self.GetMedicoFichaActive.cliente.familia;
                self.raza = self.GetMedicoFichaActive.cliente.raza;
                self.caracteristicas = self.GetMedicoFichaActive.cliente.caracteristicas;
            })
        },


        // calcular peso
        lbToKg(value) {
            return parseFloat(parseFloat(value) * 0.45359237).toFixed(2);
        },
        calcularLbToKg() {
            if (this.tipoFicha === 'pediatria') {
                const lb = (this.datos_paciente.pesoOz / 16) + parseFloat(this.datos_paciente.peso);
                this.pesoKg = this.lbToKg(lb);
            }
            else {
                this.pesoKg = this.lbToKg(this.datos_paciente.peso);
            }
            //this.pesoOz = parseFloat(this.datos_paciente.peso) * 16;
            this.calcularBMI();
        },
        calcularKgToLb() {
            this.datos_paciente.peso = parseFloat(parseFloat(this.pesoKg) / 0.45359237).toFixed(2);
            this.saveMetaDatosPaciente();
        },
        calcularLbToOz() {
            this.datos_paciente.peso = parseFloat(parseFloat(this.pesoOz) / 16).toFixed(2);
            this.calcularLbToKg();
            this.saveMetaDatosPaciente();
        },
        calcularBMI() {

            let peso = 0;
            if (this.tipoFicha === 'pediatria') {
                peso = (this.datos_paciente.pesoOz / 16) + parseFloat(this.datos_paciente.peso);
            }
            else {
                peso = this.datos_paciente.peso;
            }

            const tallaPulgadas = this.datos_paciente.talla / 2.54;
            const BMI = (703 * peso) / (tallaPulgadas ** 2);
            this.BMI = parseFloat(BMI).toFixed(1);

            if (isNaN(BMI)) {
                this.BMI = 0;
            }

        }
    },
};

</script>
