<template>
    <div>
        <ficha-layout title="Pediatría" color="#e89b00" :tabs="tabs" :tab-default=tabDefault enable-timeline="true">
            <template #preclinica>
                <pre-clinica></pre-clinica>
            </template>
            <template #antecedentes>
                <antecedentes></antecedentes>
            </template>
            <template #exploracion_fisica>
                <exploracion-fisica></exploracion-fisica>
            </template>
            <template #impresion_clinica>
                <impresion-clinica></impresion-clinica>
            </template>
            <template #tratamiento>
                <tratamiento></tratamiento>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
            <template #adjuntos>
                <adjuntos></adjuntos>
            </template>
            <template #historial>
                <historial></historial>
            </template>
            <template #consulta>
                <box title="Prenatales" icon="fas fa-clock">
                    <div class="row">
                        <div class="col-12 col-sm-3 mb-3" v-for="item in pediatria_prenatales" :key="item.name">
                            <Field :data="item" v-model="item.value" @change="savePrenatales"></Field>
                        </div>
                    </div>
                </box>
                <box title="Datos del nacimiento" icon="fas fa-baby">
                    <div class="row">
                        <div class="col-12 col-sm-3 mb-3" v-for="item in pediatria_natales" :key="item.name">
                            <Field :data="item" v-model="item.value" @change="saveNatales"></Field>
                        </div>
                    </div>
                </box>
                <box title="Alimentación" icon="fas fa-utensils">
                    <div class="row">
                        <div class="col-12 col-sm-3 mb-3" v-for="item in pediatria_alimentacion" :key="item.name">
                            <Field :data="item" v-model="item.value" @change="saveAlimentacion"></Field>
                        </div>
                    </div>
                </box>
                <box title="Otros" icon="fas fa-file-alt">
                    <div class="row">
                        <div class="col-12 col-sm-3 mb-3" v-for="item in pediatria_otros" :key="item.name">
                            <Field :data="item" v-model="item.value" @change="saveOtros"></Field>
                        </div>
                    </div>
                </box>
                <!--<box title="Apariencia, Pulso, Gesticulación, Actividad, Respiración" icon="fas fa-eye">
                    <div class="row">
                        <div class="col-12 col-sm-3 mb-3" v-for="item in pediatria_apariencia" :key="item.name">
                            <Field :data="item" v-model="item.value"  @change="saveApariencia"></Field>
                        </div>
                    </div>
                </box>-->
                <box title="Gráficos" icon="fas fa-chart-pie">
                    <div class="text-muted mb-4">
                        Atención, no se mostrarán gráficos que no posean información dentro de sus parámetros
                    </div>

                    <div v-if="Object.keys(weightForAge).length > 0">
                        <h6>{{weightForAge.title}}</h6>
                        <div class="row">
                            <div class="col-12 col-sm-6" v-for="item in weightForAge" :key="item">
                                <highcharts :options="item.data" class="mb-4"></highcharts>
                            </div>
                        </div>
                    </div>
                    <div v-if="Object.keys(weightForLengthHeight).length > 0">
                        <h6>{{weightForLengthHeight.title}}</h6>
                        <div class="row">
                            <div class="col-12 col-sm-6" v-for="item in weightForLengthHeight" :key="item">
                                <highcharts :options="item.data" class="mb-4"></highcharts>
                            </div>
                        </div>
                    </div>
                    <div v-if="Object.keys(lengthHeightForAge).length > 0">
                        <h6>{{lengthHeightForAge.title}}</h6>
                        <div class="row">
                            <div class="col-12 col-sm-6" v-for="item in lengthHeightForAge" :key="item">
                                <highcharts :options="item.data" class="mb-4"></highcharts>
                            </div>
                        </div>
                    </div>
                </box>
                <box title="Vacunas" icon="fas fa-syringe">
                    <table class="table">
                        <tr>
                            <td class="font-weight-bold">
                                Vacuna
                            </td>
                            <td class="font-weight-bold">Fecha #1</td>
                            <td class="font-weight-bold">Fecha #2</td>
                            <td class="font-weight-bold">Fecha #3</td>
                            <td class="font-weight-bold">Fecha #4</td>
                            <td class="font-weight-bold">Fecha #5</td>
                        </tr>
                        <tr v-for="item in pediatria_vacunas.vacunas" :key="item" class="mb-3">
                            <td>
                                <div v-if="!item.editable">
                                    {{item.nombre}}
                                </div>
                                <div v-else>
                                    <input type="text" class="form-control" v-model="item.nombre" @change="saveVacunas"/>
                                </div>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f1" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f2" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f3" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f4" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f5" @change="saveVacunas"/>
                            </td>
                        </tr>
                    </table>
                    <div class="mt-3">
                        <div class="form-group">
                            <label>Observaciones sobre vacunas</label>
                            <textarea class="form-control w-100" v-model="pediatria_vacunas.observaciones"  @change="saveVacunas"></textarea>
                        </div>
                    </div>
                </box>
            </template>
            <template #mesesxvida>
                <box title="Primer mes" icon="fas fa-calendar-check">
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida1" :key="key">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(1, 'mesesxvida1')"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                            </label>
                            <div class="form-group" v-if="item.value">
                                <label>Observacion adicional</label>
                                <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(1, 'mesesxvida1')"></textarea>
                            </div>
                        </div>
                    </div>
                </box>
               
                <box title="Segundo y tercer mes" icon="fas fa-calendar-check">
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in  mesesxvida2_3" :key="key">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(2, 'mesesxvida2_3')"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                            </label>
                            <div class="form-group" v-if="item.value">
                                <label>Observacion adicional</label>
                                <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(2, 'mesesxvida2_3')"></textarea>
                            </div>
                        </div>
                    </div>
                </box>
                <box title="Cuarto y quinto mes" icon="fas fa-calendar-check">
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in  mesesxvida4_5" :key="key">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(3, 'mesesxvida4_5')"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                            </label>
                            <div class="form-group" v-if="item.value">
                                <label>Observacion adicional</label>
                                <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(3, 'mesesxvida4_5')"></textarea>
                            </div>
                        </div>
                    </div>
                </box>
                <box title="Sexto, séptimo y octavo mes" icon="fas fa-calendar-check">
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida6_8" :key="key">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(4, 'mesesxvida6_8')"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                            </label>
                            <div class="form-group" v-if="item.value">
                                <label>Observacion adicional</label>
                                <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(4, 'mesesxvida6_8')"></textarea>
                            </div>
                        </div>
                    </div>
                </box>
                <box title="Noveno, décimo, onceavo y doceavo mes" icon="fas fa-calendar-check">
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida9_12" :key="key">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(5, 'mesesxvida9_12')"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                            </label>
                            <div class="form-group" v-if="item.value">
                                <label>Observacion adicional</label>
                                <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(5, 'mesesxvida9_12')"></textarea>
                            </div>
                        </div>
                    </div>
                </box>
                <box title="Trece a dieciocho meses" icon="fas fa-calendar-check">
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida13_18" :key="key">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(6, 'mesesxvida13_18')"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                            </label>
                            <div class="form-group" v-if="item.value">
                                <label>Observacion adicional</label>
                                <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(6, 'mesesxvida13_18')"></textarea>
                            </div>
                        </div>
                    </div>
                </box>
                <box title="Diecinueve a veinticuatro meses" icon="fas fa-calendar-check">
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida19_24" :key="key">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(7, 'mesesxvida19_24')"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                            </label>
                            <div class="form-group" v-if="item.value">
                                <label>Observacion adicional</label>
                                <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(7, 'mesesxvida19_24')"></textarea>
                            </div>
                        </div>
                    </div>
                </box>
                <box title="2 Años" icon="fas fa-calendar-check">
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida2y" :key="key">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(8, 'mesesxvida2y')"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                            </label>
                            <div class="form-group" v-if="item.value">
                                <label>Observacion adicional</label>
                                <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(8, 'mesesxvida2y')"></textarea>
                            </div>
                        </div>
                    </div>
                </box>
                <box title="3 Años" icon="fas fa-calendar-check">
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in mesesxvida3y" :key="key">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveSaveMesesxVida(9, 'mesesxvida3y')"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                            </label>
                            <div class="form-group" v-if="item.value">
                                <label>Observacion adicional</label>
                                <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveSaveMesesxVida(9, 'mesesxvida3y')"></textarea>
                            </div>
                        </div>
                    </div>
                </box>
                
            </template>
        </ficha-layout>
    </div>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import {Chart} from 'highcharts-vue';


import FichaLayout from '../components/FichaLayout.vue';
import Box from '/src/modules/medico/views/fichas/Box.vue';
import Field from '/src/modules/medico/views/fichas/Field.vue';

// componentes medicos
import Antecedentes from '../components/Antecedentes.vue';
import ExploracionFisica from '../components/ExploracionFisica.vue';
import Tratamiento from '../components/Tratamiento.vue';
import LaboratorioRadiologia from '../components/LaboratorioRadiologia.vue';
import PreClinica from '../components/PreClinica.vue';
import ImpresionClinica from '../components/ImpresionClinica.vue';
import Resumen from '../components/Resumen.vue';
import Adjuntos from '../components/Adjuntos.vue';
import Historial from '../components/HistorialPr.vue';

export default {
    components: {
        Box,
        Field,
        FichaLayout,
        Antecedentes,
        ExploracionFisica,
        Tratamiento,
        PreClinica,
        ImpresionClinica,
        Resumen,
        Adjuntos,
        Historial,

        // graficos
        highcharts: Chart
    },
    props:{
    tabDefault: {
        default: 'preclinica',
        type: String
    }
    },
    data() {
        return {
            tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                antecedentes: {
                    icon: 'fas fa-history',
                    name: 'Antecedentes',
                },
                exploracion_fisica: {
                    icon: 'fas fa-search-plus',
                    name: 'Examen físico',
                },
                consulta: {
                    icon: 'fas fa-child',
                    name: 'Pediatría',
                },
                mesesxvida: {
                    icon: 'fa-solid fa-list-check',
                    name: 'Meses por vida',
                },
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica', // CIE10
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento', // cie10 mover para acá y laboratorios y procedimientos
                },
                adjuntos: {
                    icon: 'fas fa-paperclip',
                    name: 'Adjuntos', // agregar estado de cuenta
                },
                historial: {
                    icon: 'fas fa-history',
                    name: 'Historial', // agregar estado de cuenta
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', // agregar estado de cuenta
                },
            },
            pediatria_prenatales: {
                edad_materna: {
                    name: 'Edad Materna',
                    type: 'text',
                    value: '',
                },
                numero_de_gestas: {
                    name: 'Número de gestas',
                    type: 'text',
                    value: '',
                },
                metodos_de_planificacion_familiar: {
                    name: 'Métodos de planificación familiar',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                complicaciones_durante_el_embarazo: {
                    name: 'Complicaciones durante el embarazo',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                duracion_del_embarazo: {
                    name: 'Duración del embarazo',
                    type: 'text',
                    value: '',
                },
            },
            pediatria_natales: {
                duracion_del_parto: {
                    name: 'Duración del parto',
                    type: 'text',
                    value: '',
                },
                notas: {
                    name: 'Notas',
                    type: 'text',
                    value: '',
                },
                parto_atendido: {
                    name: 'Parto atendido',
                    type: 'select',
                    value: '',
                    options: [
                        'Hospital',
                        'Médico Particular',
                        'Comadrona',
                    ]
                },
                parto_atendido_por: {
                    name: 'Nombre de quien atendió el parto',
                    type: 'text',
                    value: '',
                },
                parto_atendido_lugar: {
                    name: 'Lugar donde se realizó el parto',
                    type: 'text',
                    value: '',
                },
                peso_al_nacer: {
                    name: 'Peso al nacer (kg)',
                    type: 'text',
                    value: '',
                },
                peso_observaciones: {
                    name: 'Observaciones sobre el peso',
                    type: 'text',
                    value: '',
                },
                oxigeno_suplementario: {
                    name: 'Oxígeno suplementario',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                necesidad_de_cuidado_intensivo: {
                    name: 'Necesidad de cuidado intensivo',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                apgar_minuto: {
                    name: 'APGAR al minuto',
                    type: 'number',
                    value: '',
                },
                apgar_cinco_minutos: {
                    name: 'APGAR a los 5 minutos',
                    type: 'number',
                    value: '',
                },
                apgar_diez_minutos: {
                    name: 'APGAR a los 10 minutos',
                    type: 'number',
                    value: '',
                },
            },
            pediatria_vacunas: {
                vacunas: {
                    BCG: {
                        nombre: "BCG",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    hexavalente: {
                        nombre: "Hexavalente",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    rotavirus1: {
                        nombre: "Rotavirus (Rotarix)",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    rotavirus2: {
                        nombre: "Rotavirus (Rotatateq)",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    neumococo: {
                        nombre: "Neumococo",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    MMR: {
                        nombre: "MMR (SRP)",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    hepatitisA: {
                        nombre: "Hepatitis A",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    influenza: {
                        nombre: "Influenza",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    varicela: {
                        nombre: "Varicela",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    meningococo: {
                        nombre: "Meningococo",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    papiloma: {
                        nombre: "Papiloma",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    boostrix: {
                        nombre: "Boostrix (D.T./T.)",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    fiebreAmarilla: {
                        nombre: "Fiebre Amarilla",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    neumo23: {
                        nombre: "Neumo 23",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    toxoIde: {
                        nombre: "Toxoide",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    hepatitisB: {
                        nombre: "Hepatitis B",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    dtp: {
                        nombre: "DTP",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    polio: {
                        nombre: "Polio",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    haemophilusInfluenzaeB: {
                        nombre: "Haemophilus Influenzae B",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    tefoidea: {
                        nombre: "Tefoidea",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    antirapica: {
                        nombre: "Antirapica",
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    otraUno: {
                        nombre: "",
                        editable: true,
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                    otraDos: {
                        nombre: "",
                        editable: true,
                        f1: "",
                        f2: "",
                        f3: "",
                        f4: "",
                        f5: "",
                    },
                },
                observaciones: '',
            },
            pediatria_alimentacion: {
                alimentacion: {
                    name: 'Alimentación',
                    type: 'textarea',
                    value: '',
                },
            },
            pediatria_otros: {
                habitos: {
                    name: 'Hábitos',
                    type: 'textarea',
                    value: '',
                },
                observaciones: {
                    name: 'Observaciones',
                    type: 'textarea',
                    value: '',
                },
            },
            mesesxvida1: {
                frunce_parpados_Luz: {
                    name: '1. Frunce los parpados a la Luz',
                    value: false,
                    detail: ''
                },
                reflejo_succion: {
                    name: '2. Reflejo de succión',
                    value: false,
                    detail: ''
                },
                reflejo_moro: {
                    name: '3. Reflejo de Moro',
                    value: false,
                    detail: ''
                },
                reflejo_prehension_palmar_plantar: {
                    name: '4. Reflejo de prehension palmar y plantar',
                    value: false,
                    detail: ''
                },
                reflejo_busqueda: {
                    name: '5. Reflejo de Búsqueda',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida2_3: {
                mueve_levanta_cabeza: {
                    name: '1. Mueve y levanta la cabeza',
                    value: false,
                    detail: ''
                },
                fija_mirada: {
                    name: '2. Fija la mirada',
                    value: false,
                    detail: ''
                },
                toma_con_firmeza: {
                    name: '3. Toma con firmeza',
                    value: false,
                    detail: ''
                },
                movimiento_manos: {
                    name: '4. Movimiento de las manos',
                    value: false,
                    detail: ''
                },
                sonrisa_social: {
                    name: '5. Sonrisa social',
                    value: false,
                    detail: ''
                },
                emite_sonidos: {
                    name: '6. Emite sonidos',
                    value: false,
                    detail: ''
                },
                inicia_hábito_dormir: {
                    name: '7. Iniciar hábito de dormir',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida4_5: {
                sostiene_cabeza: {
                    name: '1. Sostiene la cabeza',
                    value: false,
                    detail: ''
                },
                se_sienta_con_ayuda: {
                    name: '2. Se sienta con ayuda',
                    value: false,
                    detail: ''
                },
                sigue_objetos_mirada: {
                    name: '3. Sigue objetos con la mirada',
                    value: false,
                    detail: ''
                },
                sonrie_personas_conocidas_verlas: {
                    name: '4. Sonríe con personas conocidas al verlas',
                    value: false,
                    detail: ''
                },
                explora_manos: {
                    name: '5. Explora con las manos',
                    value: false,
                    detail: ''
                },
                llora_rie_solo: {
                    name: '6. Llora o ríe al estar solo',
                    value: false,
                    detail: ''
                },
                balbucea: {
                    name: '7. Balbucea',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida6_8: {
                se_sienta_solo: {
                    name: '1. Se sienta solo',
                    value: false,
                    detail: ''
                },
                juega_con_pies: {
                    name: '2. Juega con sus pies',
                    value: false,
                    detail: ''
                },
                encuentra_objetos : {
                    name: '3. Encuentra objetos ',
                    value: false,
                    detail: ''
                },
                explora_la_boca: {
                    name: '4. Explora con la boca',
                    value: false,
                    detail: ''
                },
                mueve_objetos_mano_a_otra: {
                    name: '5. Mueve objetos de una mano a otra',
                    value: false,
                    detail: ''
                },
                reconoce_extraños: {
                    name: '6. Reconoce a extraños ',
                    value: false,
                    detail: ''
                },
                repite_sonidos: {
                    name: '7. Repite sonidos',
                    value: false,
                    detail: ''
                },
                puede_decir_silabas: {
                    name: '8. Puede decir sílabas',
                    value: false,
                    detail: ''
                },
                despierta_facil : {
                    name: '9. Despierta fácil ',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida9_12: {
                gatea: {
                    name: '1. Gatea',
                    value: false,
                    detail: ''
                },
                se_para_solo: {
                    name: '2. Se para solo',
                    value: false,
                    detail: ''
                },
                saluda_con_mano: {
                    name: '3. Saluda con la mano',
                    value: false,
                    detail: ''
                },
                puede_triturar_comida: {
                    name: '4. Puede triturar la comida',
                    value: false,
                    detail: ''
                },
                movimientos_de_pinza: {
                    name: '5. Movimientos de pinza',
                    value: false,
                    detail: ''
                },
                molestia_alejarse_personas_conocidas: {
                    name: '6. Molestia al alejarse de personas conocidas',
                    value: false,
                    detail: ''
                },
                curioso: {
                    name: '7. Es curioso',
                    value: false,
                    detail: ''
                },
                pronuncia_silabas: {
                    name: '8. Pronuncia sílabas',
                    value: false,
                    detail: ''
                },
                manipula_para_no_estar_olo: {
                    name: '9. Manipula para no estar solo',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida13_18: {
                camina_solo: {
                    name: '1. Camina solo',
                    value: false,
                    detail: ''
                },
                ayuda_a_vestirse: {
                    name: '2. Ayuda a vestirse',
                    value: false,
                    detail: ''
                },
                pone_atencion_a_imagenes: {
                    name: '3. Pone atención a imágenes',
                    value: false,
                    detail: ''
                },
                inicia_a_masticar: {
                    name: '4. Inicia a masticar',
                    value: false,
                    detail: ''
                },
                toma_objetos_con_sus_manos: {
                    name: '5. Toma objetos con sus manos',
                    value: false,
                    detail: ''
                },
                responde_a_su_nombre: {
                    name: '6. Responde a su nombre',
                    value: false,
                    detail: ''
                },
                dice_dos_o_tres_palabras: {
                    name: '7. Dice dos o tres palabras',
                    value: false,
                    detail: ''
                },
                se_resiste_a_dormir: {
                    name: '8. Se resiste a dormir',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida19_24: {
                camina_solo: {
                    name: '1. Camina solo',
                    value: false,
                    detail: ''
                },
                tomar_crayones: {
                    name: '2. Tomar crayones',
                    value: false,
                    detail: ''
                },
                muerde_todo_tipo_de_comida: {
                    name: '3. Muerde todo tipo de comida',
                    value: false,
                    detail: ''
                },
                puede_comer_con_cubiertos: {
                    name: '4. Puede comer con cubiertos',
                    value: false,
                    detail: ''
                },
                inicia_su_personalidad: {
                    name: '5. Inicia su personalidad',
                    value: false,
                    detail: ''
                },
                puede_deci_d_6_a_10_palabras: {
                    name: '6. Puede decir de 6 a 10 palabras',
                    value: false,
                    detail: ''
                },
                duerme: {
                    name: '7. Duerme',
                    value: false,
                    detail: ''
                },
                inicia_control_de_esfinteres: {
                    name: '8. Inicia control de esfínteres',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida2y: {
                sube_y_baja_escaleras: {
                    name: '1. Sube y baja escaleras',
                    value: false,
                    detail: ''
                },
                salta: {
                    name: '2. Salta',
                    value: false,
                    detail: ''
                },
                coloca_por_colores: {
                    name: '3. Coloca por colores',
                    value: false,
                    detail: ''
                },
                coloca_por_figuras: {
                    name: '4. Coloca por figuras',
                    value: false,
                    detail: ''
                },
                come_solidos: {
                    name: '5. Come sólidos',
                    value: false,
                    detail: ''
                },
                juega_con_ninos: {
                    name: '6. Juega con niños',
                    value: false,
                    detail: ''
                },
                sigue_instrucciones_sencillas: {
                    name: '7. Sigue instrucciones sencillas',
                    value: false,
                    detail: ''
                },
                familiarizarlo_con_el_bano: {
                    name: '8. Familiarizarlo con el baño',
                    value: false,
                    detail: ''
                },
            },
            mesesxvida3y: {
                se_para_un_pie: {
                    name: '1. Se para un pie',
                    value: false,
                    detail: ''
                },
                sube_escaleras_facilmente: {
                    name: '2. Sube escaleras fácilmente',
                    value: false,
                    detail: ''
                },
                mastica: {
                    name: '3. Mastica',
                    value: false,
                    detail: ''
                },
                puede_armar_un_puente: {
                    name: '4. Puede armar un puente',
                    value: false,
                    detail: ''
                },
                seguir_un_modelo: {
                    name: '5. Seguir un modelo',
                    value: false,
                    detail: ''
                },
                puede_dibujar_un_circulo: {
                    name: '6. Puede dibujar un círculo',
                    value: false,
                    detail: ''
                },
                muestra_afecto: {
                    name: '7. Muestra afecto',
                    value: false,
                    detail: ''
                },
                juega_interactivamente: {
                    name: '8. Juega interactivamente',
                    value: false,
                    detail: ''
                },
                aprende_a_esperar_su_turno: {
                    name: '9. Aprende a esperar su turno',
                    value: false,
                    detail: ''
                },
                habla_constantemente: {
                    name: '10. Habla constantemente',
                    value: false,
                    detail: ''
                },
                emple_frases_de_4_palabras: {
                    name: '11. Emple frases de 4 palabras',
                    value: false,
                    detail: ''
                },
                puede_aparecer_terrores_nocturnos: {
                    name: '12. Puede aparecer terrores nocturnos',
                    value: false,
                    detail: ''
                },
                control_de_esfintere: {
                    name: '13. Control de esfínteres',
                    value: false,
                    detail: ''
                },

            },
            // graficos
            weightForAge: {},
            weightForLengthHeight: {},
            lengthHeightForAge: {},
            BMIForAge: false,
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {
        'GetMedicoFichaActive.datos_paciente.peso': function (value) {
            this.drawChart();
        },
    },
    mounted() {
        const self = this;

        // Cargo la info de prenatales
        Object.keys(self.pediatria_prenatales).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.pediatria_prenatales) {
                if (self.GetMedicoFichaActive.metadata.pediatria_prenatales[value]) {
                    self.pediatria_prenatales[value].value = self.GetMedicoFichaActive.metadata.pediatria_prenatales[value];
                }
            }
        });
        // Cargo la info de natales
        Object.keys(self.pediatria_natales).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.pediatria_natales) {
                if (self.GetMedicoFichaActive.metadata.pediatria_natales[value]) {
                    self.pediatria_natales[value].value = self.GetMedicoFichaActive.metadata.pediatria_natales[value];
                }
            }
        });

        // Gráficos
        this.emitter.on("medico-ficha-changed-tab", function (tab) {
            if (tab === 'consulta') {
                self.drawChart();
            }
        });

        // Cargo la info de pediatria
        Object.keys(self.pediatria_alimentacion).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.pediatria_alimentacion) {
                if (self.GetMedicoFichaActive.metadata.pediatria_alimentacion[value]) {
                    self.pediatria_alimentacion[value].value = self.GetMedicoFichaActive.metadata.pediatria_alimentacion[value];
                }
            }
        });

        // Cargo la info de pediatria
        Object.keys(self.pediatria_otros).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.pediatria_otros) {
                if (self.GetMedicoFichaActive.metadata.pediatria_otros[value]) {
                    self.pediatria_otros[value].value = self.GetMedicoFichaActive.metadata.pediatria_otros[value];
                }
            }
        });

        // Cargo la info de vacunas
        Object.keys(self.pediatria_vacunas.vacunas).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.pediatria_vacunas) {
                if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f1']) self.pediatria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f1'];
                if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f2']) self.pediatria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f2'];
                if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f3']) self.pediatria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f3'];
                if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f4']) self.pediatria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f4'];
                if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f5']) self.pediatria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_f5'];
                if (self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_n']) self.pediatria_vacunas.vacunas[value].nombre = self.GetMedicoFichaActive.metadata.pediatria_vacunas[value + '_n'];
                if (self.GetMedicoFichaActive.metadata.pediatria_vacunas['observaciones']) self.pediatria_vacunas['observaciones'] = self.GetMedicoFichaActive.metadata.pediatria_vacunas['observaciones'];
            }
        });


        Object.keys(self.mesesxvida1).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.mesesxvida1) {

                if (self.GetMedicoFichaActive.metadata.mesesxvida1[value]) { 
                    self.mesesxvida1[value].value = self.GetMedicoFichaActive.metadata.mesesxvida1[value];
                }

                if (self.GetMedicoFichaActive.metadata.mesesxvida1[value+'_detail']) { 
                    self.mesesxvida1[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida1[value+'_detail'];
                }

            }
        });

        Object.keys(self.mesesxvida2_3).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.mesesxvida2_3) {

                if (self.GetMedicoFichaActive.metadata.mesesxvida2_3[value]) { 
                    self.mesesxvida2_3[value].value = self.GetMedicoFichaActive.metadata.mesesxvida2_3[value];
                }

                if (self.GetMedicoFichaActive.metadata.mesesxvida2_3[value+'_detail']) { 
                    self.mesesxvida2_3[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida2_3[value+'_detail'];
                }

            }
        });
        Object.keys(self.mesesxvida4_5).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.mesesxvida4_5) {

                if (self.GetMedicoFichaActive.metadata.mesesxvida4_5[value]) { 
                    self.mesesxvida4_5[value].value = self.GetMedicoFichaActive.metadata.mesesxvida4_5[value];
                }

                if (self.GetMedicoFichaActive.metadata.mesesxvida4_5[value+'_detail']) { 
                    self.mesesxvida4_5[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida4_5[value+'_detail'];
                }

            }
        });
        Object.keys(self.mesesxvida6_8).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.mesesxvida6_8) {

                if (self.GetMedicoFichaActive.metadata.mesesxvida6_8[value]) { 
                    self.mesesxvida6_8[value].value = self.GetMedicoFichaActive.metadata.mesesxvida6_8[value];
                }

                if (self.GetMedicoFichaActive.metadata.mesesxvida6_8[value+'_detail']) { 
                    self.mesesxvida6_8[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida6_8[value+'_detail'];
                }

            }
        });
        Object.keys(self.mesesxvida9_12).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.mesesxvida9_12) {

                if (self.GetMedicoFichaActive.metadata.mesesxvida9_12[value]) { 
                    self.mesesxvida9_12[value].value = self.GetMedicoFichaActive.metadata.mesesxvida9_12[value];
                }

                if (self.GetMedicoFichaActive.metadata.mesesxvida9_12[value+'_detail']) { 
                    self.mesesxvida9_12[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida9_12[value+'_detail'];
                }

            }
        });

        Object.keys(self.mesesxvida13_18).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.mesesxvida13_18) {

                if (self.GetMedicoFichaActive.metadata.mesesxvida13_18[value]) { 
                    self.mesesxvida13_18[value].value = self.GetMedicoFichaActive.metadata.mesesxvida13_18[value];
                }

                if (self.GetMedicoFichaActive.metadata.mesesxvida13_18[value+'_detail']) { 
                    self.mesesxvida13_18[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida13_18[value+'_detail'];
                }

            }
        });

        Object.keys(self.mesesxvida19_24).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.mesesxvida19_24) {

                if (self.GetMedicoFichaActive.metadata.mesesxvida19_24[value]) { 
                    self.mesesxvida19_24[value].value = self.GetMedicoFichaActive.metadata.mesesxvida19_24[value];
                }

                if (self.GetMedicoFichaActive.metadata.mesesxvida19_24[value+'_detail']) { 
                    self.mesesxvida19_24[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida19_24[value+'_detail'];
                }

            }
        });
        Object.keys(self.mesesxvida2y).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.mesesxvida2y) {

                if (self.GetMedicoFichaActive.metadata.mesesxvida2y[value]) { 
                    self.mesesxvida2y[value].value = self.GetMedicoFichaActive.metadata.mesesxvida2y[value];
                }

                if (self.GetMedicoFichaActive.metadata.mesesxvida2y[value+'_detail']) { 
                    self.mesesxvida2y[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida2y[value+'_detail'];
                }

            }
        });
        Object.keys(self.mesesxvida3y).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.mesesxvida3y) {

                if (self.GetMedicoFichaActive.metadata.mesesxvida3y[value]) { 
                    self.mesesxvida3y[value].value = self.GetMedicoFichaActive.metadata.mesesxvida3y[value];
                }

                if (self.GetMedicoFichaActive.metadata.mesesxvida3y[value+'_detail']) { 
                    self.mesesxvida3y[value].detail = self.GetMedicoFichaActive.metadata.mesesxvida3y[value+'_detail'];
                }

            }
        });
        
    },
    
    methods: {
        ...mapActions({}),
        dayjs,
        Numbro,
        saveSaveMesesxVida(data, slug){
            const self = this;
            let item
            switch (data) {
                case 1:
                    item=self.mesesxvida1
                    break;
                case 2:
                    item=self.mesesxvida2_3
                    break;
                case 3:
                    item=self.mesesxvida4_5
                    break;
                case 4:
                    item=self.mesesxvida6_8
                    break;
                case 5:
                    item=self.mesesxvida9_12
                    break;
                case 6:
                    item=self.mesesxvida13_18
                    break;
                case 7:
                    item=self.mesesxvida19_24
                    break;
                case 8:
                    item=self.mesesxvida2y
                    break;
                case 9:
                    item=self.mesesxvida3y
                    break;
                    
                default:
                API.showErrorAlert('Seleccion no valida');
                    break;
            }
            
            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(item).map(function (value) {
                arrToSave[value] = item[value].value;
                if(item[value].value===false){
                    arrToSave[value + '_detail'] = '';
                    item[value].detail = '';
                }else{
                    arrToSave[value + '_detail'] = item[value].detail;
                }
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: slug,
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
        
        },
        savePrenatales() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.pediatria_prenatales).map(function (value) {
                if (self.pediatria_prenatales[value].value !== '') arrToSave[value] = self.pediatria_prenatales[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'pediatria_prenatales',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveNatales() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.pediatria_natales).map(function (value) {
                if (self.pediatria_natales[value].value !== '') arrToSave[value] = self.pediatria_natales[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'pediatria_natales',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveAlimentacion() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.pediatria_alimentacion).map(function (value) {
                if (self.pediatria_alimentacion[value].value !== '') arrToSave[value] = self.pediatria_alimentacion[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'pediatria_alimentacion',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveOtros() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.pediatria_otros).map(function (value) {
                if (self.pediatria_otros[value].value !== '') arrToSave[value] = self.pediatria_otros[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'pediatria_otros',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveVacunas() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.pediatria_vacunas.vacunas).map(function (value) {
                if (self.pediatria_vacunas.vacunas[value].f1 !== '') arrToSave[value + '_f1'] = self.pediatria_vacunas.vacunas[value].f1;
                if (self.pediatria_vacunas.vacunas[value].f2 !== '') arrToSave[value + '_f2'] = self.pediatria_vacunas.vacunas[value].f2;
                if (self.pediatria_vacunas.vacunas[value].f3 !== '') arrToSave[value + '_f3'] = self.pediatria_vacunas.vacunas[value].f3;
                if (self.pediatria_vacunas.vacunas[value].f4 !== '') arrToSave[value + '_f4'] = self.pediatria_vacunas.vacunas[value].f4;
                if (self.pediatria_vacunas.vacunas[value].f5 !== '') arrToSave[value + '_f5'] = self.pediatria_vacunas.vacunas[value].f5;

                if (self.pediatria_vacunas.vacunas[value].editable) {
                    if (self.pediatria_vacunas.vacunas[value].nombre !== '') arrToSave[value + '_n'] = self.pediatria_vacunas.vacunas[value].nombre;
                }
            });
            arrToSave['observaciones'] = self.pediatria_vacunas['observaciones'];

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'pediatria_vacunas',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },

        // Gráficos
        drawChart() {

            const self = this;
            //const peso = (typeof self.GetMedicoFichaActive.cliente.peso !== 'undefined') ? self.GetMedicoFichaActive.cliente.peso : false;
            const clienteId = (typeof self.GetMedicoFichaActive.cliente.id !== 'undefined') ? self.GetMedicoFichaActive.cliente.id : false;
            /*const talla = (typeof self.GetMedicoFichaActive.metadata !== 'undefined' && typeof self.GetMedicoFichaActive.metadata.datos_paciente !== 'undefined' && typeof self.GetMedicoFichaActive.metadata.datos_paciente.talla !== 'undefined') ? self.GetMedicoFichaActive.metadata.datos_paciente.talla : false;
            const genero = (typeof self.GetMedicoFichaActive.cliente.genero !== 'undefined') ? self.GetMedicoFichaActive.cliente.genero : false;
            const fechaNacimiento = (typeof self.GetMedicoFichaActive.cliente.fechaNacimiento !== 'undefined') ? self.GetMedicoFichaActive.cliente.fechaNacimiento : false;
            const fechaComparativa = (typeof self.GetMedicoFichaActive.reserva.fechaReservada !== 'undefined') ? self.GetMedicoFichaActive.reserva.fechaReservada : false;*/

            API.send('POST', 'medico/utilities/oms-anthropometric-indicators/by/cliente', {
                    'clienteId': clienteId,
                }, function (response) {
                    self.BMIForAge = response.data.BMIForAge;
                    self.weightForAge = response.data.weightForAge;
                    self.weightForLengthHeight = response.data.weightForLengthHeight;
                    self.lengthHeightForAge = response.data.lengthHeightForAge;
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
    },
};

</script>
