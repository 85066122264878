<template>
    <div class="input-group">
        <input type="text" class="form-control searchField" placeholder="Escriba el NIT, correo electrónico o nombres" v-model="searchTerm" v-on:keyup.enter="onEnter"/>
        <div class="input-group-append" @click="searchTerm = ''">
            <span class="input-group-text" id="basic-addon2"><i class="fa fa-times"></i></span>
        </div>
    </div>
    <div class="text-center">
        <small>Para habilitar la creación de clientes, es necesario realizar una búsqueda para validar su existencia previa</small>
    </div>
    <div v-if="operation === 'creation' || operation === 'edition'" class="clientFastCreator">
        <hr>
        <div class="text-center">
            <h6 class="font-weight-bold text-primary mb-4">Creación de cliente</h6>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-3">
                <div class="form-group mb-1">
                    <label>NIT (*)</label>
                    <div class="input-group cfField">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <input type="checkbox" id="cfUse" aria-label="CF o consumidor final" class="mr-2" v-model="staticCF"/>
                                <label for="cfUse">CF</label>
                            </div>
                        </div>
                        <input type="text" class="form-control" placeholder="Escribe aquí" name="taxId" v-model="creationTaxId" :readonly="staticCF"/>
                    </div>
                    <div class="text-center mb-3">
                        <small>Sin espacios ni guiones</small>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6">
                <div class="form-group">
                    <label>Nombres y Apellidos</label>
                    <input type="text" class="form-control" placeholder="Escribe aquí" name="nombresApellidos" v-model="creationNombresApellidos"/>
                </div>
            </div>
            <div class="col-xs-12 col-sm-3">
                <div class="form-group">
                    <label>Fecha de nacimiento</label>
                    <input type="date" class="form-control" placeholder="Escribe aquí" name="fechaNacimiento" v-model="fechaNac"/>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4">
                <div class="form-group">
                    <label>Teléfono de contacto</label>
                    <input type="text" class="form-control" placeholder="Escribe aquí" name="phone" v-model="creationPhone"/>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4">
                <div class="form-group">
                    <label>Correo</label>
                    <input type="text" class="form-control" placeholder="Escribe aquí" name="phone" v-model="creationEmail"/>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4">
                <div class="form-group">
                    <label>Género</label>
                    <select class="form-control" v-model="creationSex">
                        <option value="F">Femenino</option>
                        <option value="M">Masculino</option>
                        <option value="N">Prefiero no decirlo</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="text-right">
            <button class="btn btn-success btn-sm mr-3" type="button" @click="registerClient"><i class="fa fa-check"></i></button>
            <button class="btn btn-outline-danger btn-sm" type="button" @click="operation = 'search'"><i class="fa fa-chevron-up"></i></button>
        </div>
        <hr>
    </div>
    <div class="search-response row">
        <div v-if="!searchResults.length && searched" class="col-12 text-center mt-3 font-weight-bold">
            <h5>No se encontraron clientes</h5>
        </div>
        <div class="col-12" v-for="item in searchResults" :key="item.id">
            <div class="media">
                <span class="mr-2"><i class="iconSearchItem fa fa-user-circle"></i></span>
                <div class="media-body">
                    <h6 class="mt-0 mb-1">
                        {{item.nombres}} {{item.apellidos}}
                        <span v-if="!item.taxId">
                                    (Sin NIT)
                                </span>
                        <span v-if="item.taxId">
                                    (NIT: {{item.taxId}})
                                </span>
                    </h6>
                    <div>
                        Correo electrónico: <span class="text-primary">{{item.email}}</span>
                    </div>
                    <div>
                        Teléfono: <span class="text-primary">{{item.telefono}}</span>
                    </div>
                </div>
                <div v-show="operation === 'search'">
                    <div class="add-option cursor-pointer text-primary mb-2" @click="select(item)">
                        <i class="fa fa-check-circle-o fa-2x"></i>
                    </div>
                    <div class="edit-option cursor-pointer text-warning" @click="edit(item)">
                        <i class="fa fa-pencil-square-o fa-2x"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import API from "src/core/Api";
import {mapActions} from "vuex";
import Tools from "src/core/Tools";

export default {
    emits: ['clientSelected', 'update:showClientSearch'],
    name: "order-search",
    components: {},
    props: ['showClientSearch', 'clientSelected','inline'],
    data() {
        return {
            operation: 'search',
            searchTerm: '',
            searched: false,
            searchResults: {},
            searchType: '',

            // form
            staticCF: false,
            creationId: 0,
            creationTaxId: '',
            creationEmail: '',
            creationNombres: '',
            creationApellidos: '',
            creationPhone: '',
            fechaNac: '',
            creationDireccion: '',
            creationSex: '',
            creationNombresApellidos: '',
        };
    },
    watch: {
        creationNombresApellidos: {
            immediate: true,
            handler(newValue) {
                const trimmedValue = newValue.trim(); // Aplicar trim() para eliminar espacios al principio y al final
                const names = trimmedValue.split(' ');
                this.creationNombres = names[0] || '';
                this.creationApellidos = names.slice(1).join(' ') || '';
            }
        },
        searchTerm: function (val) {
            const isEmail = String(val)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );

            if (val !== '') {
                if (isEmail) {
                    this.searchType = 'email';
                }
                else {
                    this.searchType = 'tax_id';
                }
                this.search();
            }
            else {
                this.searchType = '';
            }
            this.searched = false;
        },
        staticCF: function (val) {
            if (val) {
                this.creationTaxId = 'CF';
            }
            else {
                this.creationTaxId = '';
            }
        },
        creationTaxId: function (val) {
            if (val.length > 3) {
                this.staticCF = false;
            }
        }
    },
    methods: {
        ...mapActions({
            OrderPosClientSelect: 'OrderPosClientSelect',
        }),
        hideSearch: function () {
            this.searchResults = {};
            this.$emit('update:showClientSearch', false);
            this.searchTerm = '';
            this.operation = 'search';
        },
        onEnter: function() {
            this.search();
        },
        clearCreationForm() {
            this.creationTaxId = '';
            this.creationEmail = '';
            this.creationNombres = '';
            this.creationApellidos = '';
            this.creationPhone = '';
            this.creationDireccion = '';
            this.creationSex = '';
            this.creationNombresApellidos = '';
        },
        search() {
            const self = this;

            if (this.searchTerm !== '') {
                let searchUrl = '';
                const encodedSearchTerm = this.searchTerm;
                if (this.searchType === 'email') {
                    searchUrl = 'clients/search/term';
                }
                else {
                    searchUrl ='clients/search/term';
                }

                // clear creation form
                self.clearCreationForm();

                API.send('POST', searchUrl, {term: encodedSearchTerm},
                    function (response) {
                        if (response.status) {
                            self.searchResults = response.data;
                            if (response.data.length === 0) {
                                self.operation = 'creation';

                                if (self.searchType === 'email') {
                                    self.creationEmail = self.searchTerm;
                                }
                                else {
                                    self.creationNombresApellidos = self.searchTerm;
                                }
                            }
                            else {
                                self.operation = 'search';
                            }
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                        self.searched = true;
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                        self.searched = true;
                    })
            }
            else {
                API.showErrorNotify('Ingresa un término para buscar');
            }
        },
        registerClient() {

            if (!this.staticCF && this.creationTaxId === '') {
                API.showErrorNotify('Ingrese un nit válido');
            }
            else {

                // if client has nit or email
                if (this.creationTaxId !== '' || this.creationEmail !== '') {
                    const names = this.creationNombresApellidos.split(' '); // Suponiendo que los nombres y apellidos están separados por espacio
                    this.creationNombres = names[0] || ''; // El primer elemento es el nombre
                    this.creationApellidos = names.slice(1).join(' ') || ''; // El resto son apellidos

                    if (Tools.isEmptyString(this.creationNombres, 2)) {
                        API.showErrorNotify('Debe ingresar un nombre válido');
                        return false;
                    }
                    if (Tools.isEmptyString(this.creationApellidos, 2)) {
                        API.showErrorNotify('Debe ingresar un apellido válido');
                        return false;
                    }

                    let method = 'POST';
                    let url = 'clients/register/pos';

                    const self = this;
                    API.send(method, url, {
                            "taxId": self.creationTaxId,
                            "email": self.creationEmail,
                            "nombres": self.creationNombres,
                            "apellidos": self.creationApellidos,
                            "telefono": self.creationPhone,
                            "nacimiento": self.fechaNac,
                            "direccion": self.creationDireccion,
                            "genero": self.creationSex,
                            "id": self.creationId,
                        },
                        function (response) {

                            if (response.status) {
                                self.select(response.data[0]);
                                self.clearCreationForm();
                            }
                            else {
                                API.showErrorNotify(response.msg);
                            }
                        },
                        function (data) {
                            API.showErrorNotify(data.msg);
                        })
                }
                else {
                    // es CF
                    this.select({
                        "id": 0,
                        "email": "",
                        "taxId": "CF",
                        "nombres": 'Consumidor',
                        "apellidos": 'Final',
                        "direccion": 'Ciudad',
                        "telefono": "",
                        "nacimiento": "",
                    });
                }
            }

        },
        select(value) {
            this.$emit('clientSelected', value);
            this.$store.dispatch('OrderPosClientSelect', value);
            this.hideSearch();
        },
        edit(value) {
            this.creationTaxId = (value.taxId !== '') ? value.taxId : '';
            this.creationEmail = (value.email !== '') ? value.email : '';
            this.operation = 'edition';
            this.creationId = value.id;
            this.creationNombres = value.nombres;
            this.creationApellidos = value.apellidos;
            this.creationPhone = value.telefono;
            this.creationDireccion = value.direccion;
            this.creationNombresApellidos= value.nombres+' '+value.apellidos;
        },
        selectCF() {

            // es CF
            this.select({
                "id": 0,
                "email": "",
                "taxId": "CF",
                "nombres": 'Consumidor',
                "apellidos": 'Final',
                "direccion": 'Ciudad',
                "telefono": "",
            });

            /*this.operation = 'creation'
            this.staticCF = true;*/
        },
    },
};
</script>
