<script>
 import FileUploader from "src/components/files/FileUploader.vue";
 import API from "src/core/Api";
 import Tools from "src/core/Tools";
 import {mapActions, mapGetters} from "vuex";
 

export default { 
  props:{
    Category: String,
    Images: Array
  },
 data() {
        return {
        //data
        isLoading:false,
       
        imagenesUploadExtraDataForm: {
                orderId: 0,
                category: '',
            },
        attachDermaImages: {},
        imgUrls:[]
 }},
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
mounted() {
    this.imagenesUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id
    if(this.GetMedicoFichaActive.metadata.attach){
      
      this.GetMedicoFichaActive.metadata.attach[this.Category] &&
      (this.attachDermaImages= this.GetMedicoFichaActive.metadata.attach[this.Category])
      this.fetchData();
    }
   if(this.Category){
    this.imagenesUploadExtraDataForm.category=this.Category;
   }
},
 methods:{
  ...mapActions({
            OrderSaveMetadata: 'OrderSaveMetadata',
        }),
  handlerImgModal(){
    this.$emit('handlerImgModal');
  },
  agregarImagenAlLienzo(image){
    this.$emit('agregarImagenAlLienzo',image)
    this.$emit('handlerImgModal');
  },
  fileSuccess(data) {
            this.gallery = Tools.pushToObject(this.gallery, data);
            this.imgUrls=[];
            this.isLoading = true;
            this.emitter.emit("medico-ficha-refresh");
            setTimeout(() => {
              this.imagenesUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id
            if(this.GetMedicoFichaActive.metadata.attach[this.Category]){
              this.attachDermaImages= this.GetMedicoFichaActive.metadata.attach[this.Category]
              this.fetchData();
             }
             this.isLoading=false;
            }, 1000);
        },
  fetchData() {
          Object.keys(this.attachDermaImages).map(async (value) => {
            this.downloadImg(this.attachDermaImages[value].id, this.attachDermaImages[value].detail.name);
          });
      },
    downloadImg(id,name) {
    const self = this;
                // Guardo y luego emito el evento
                API.send('POST', 'orders/metadata-attachments/download', {
                        metadataId: id,
                    }, function (response) {
                        if (response.data) {
                            self.imgUrls.push({
                              url: response.data,
                              name,
                              id:id
                            });
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
    
            },
    descargarImgAlDispositivo(url, nombreArchivo){

      const enlace = document.createElement('a');
      enlace.href = url;
      enlace.download = nombreArchivo || 'imagen_descargada';
      // Ocultar el enlace en el documento
      enlace.style.display = 'none';
      // Agregar el enlace al cuerpo del documento
      document.body.appendChild(enlace);
      // Simular un clic en el enlace para iniciar la descarga
      enlace.click();
      // Eliminar el enlace después de la descarga
      document.body.removeChild(enlace);
},
    eliminarImagen(id){
      const self = this;
            // Guardo y luego emito el evento
            API.showConfirm('Espere', 'Si elimina este adjunto, no podrá recuperarlo. ¿Desea continuar?', function () {
                API.send('POST', 'orders/metadata-attachments/delete', {
                        metadataId: id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                        self.imgUrls= self.imgUrls.filter(img => img.id !== id);


                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
    },

 },
 components:{
  FileUploader
 }
 }
</script>
 
 
<template>
  <div class="ModalMain scale-up-tr">
    <div class="ModalContainer">
        <i class="fa-solid fa-xmark close" active @click="handlerImgModal"></i>
    <div class="mainbox d-flex flex-column justify-content-center align-items-center">
    
      <div v-if="Images" class="plantillas">
          <h5 class="w-100">Plantillas <i class="fa-solid fa-images"></i></h5>
          <div class="imgbox" id="imgbox">
            <div title="Click en la imagen para usar en el lienzo" v-for="(image, index) in Images" :key="index" class="cardImg" @click="agregarImagenAlLienzo(image)">
            <img :src="image" width="10" :alt="'image'+index" class="card-img-top">
            </div>
          </div>
          <small id="imgbox" class="form-text text-primary">Puedes seleccionar una imagen para usar en el lienzo</small>
      </div>
      <div v-else class="plantillas">
          <h5 class="w-100">Plantillas <i class="fa-solid fa-images"></i></h5>
          <div class="imgbox" id="imgbox">
            <small id="imgbox" class="form-text text-primary">No hay imagenes cargadas para plantilla</small>
          </div>
      </div>
      <div class="guardadas">
        <h5 class="w-100">Imagenes guardadas <i class="fa-solid fa-floppy-disk"></i></h5>
          <div class="imgbox" id="imgbox2">
            <div class="ImportImg">
            <FileUploader
                url="orders/metadata-attachments/save"
                :accepted-mime="['image/*']"
                remove-file-on-finish="1"
                :extra-data-form="imagenesUploadExtraDataForm"
                @fileSuccess="fileSuccess"
                >
            </FileUploader>
            </div>
            <div class="loadingImg" v-if="isLoading">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-else class="galeryBox">
              <div title="Click en la imagen para usar en el lienzo" v-for="(imagen, index) in imgUrls" :key="index" class="cardImg2" @click="agregarImagenAlLienzo(imagen.url)">
                <div class="iconbox">
                  <div  title="Click aquí para descargar la imagen en tu dispositivo" @click.stop="descargarImgAlDispositivo(imagen.url,imagen.name)" class="cardIcon text-primary">
                    <i class="fa-solid fa-download"></i>
                  </div>
                  <div title="Click aquí para eliminar imagen" @click.stop="eliminarImagen(imagen.id)" class="cardIcon text-danger">
                  <i class="fa-solid fa-trash-can"></i>
                </div>
                </div>
              <img title="Click en la imagen para usar en el lienzo" :src="imagen.url" width="10" :alt="imagen.name" class="card-img-top">
              </div>
            </div>

          </div>
          <div v-if="!isLoading">
            <small v-if="imgUrls.length" id="imgbox2" class="form-text text-primary">Usa tus imagenes guardadas o importa una desde tu dispositivo.</small>
            <small v-else id="imgbox2" class="form-text text-danger">No hay imagenes guardadas para usar, puedes importar una.</small>
          </div>
      </div>
    </div>
    </div>
  </div>
</template>
 

 
<style scoped>
 .mainbox{
  width: 100%;
  height: 100%;
 }
 .imgbox{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #9c9c9c43;
  padding:20px;
 }
 .loadingImg{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 140px;
 }
 .cardImg{
  cursor: pointer;
  width: 170px;
  height: 120px;
  overflow: hidden;
  transition: 0.2s;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 }

 .cardImg2{
  cursor: pointer;
  position: relative;
  width: 170px;
  height: 120px;
  overflow: hidden;
  transition: 0.2s;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 }

.iconbox{
  position: absolute;
  top: 3px;
  right: 5px;
  display: flex;
  gap:5px;
}
 .cardIcon{
  background-color:white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding:0px 5px;
  border-radius: 3px;
 }

 .cardIcon:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
 }


 .galeryBox{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: fit-content;
  max-height: 210px;
  overflow: hidden;
  overflow-y: auto;
 }

 .ImportImg{
  width: 100%;
  height: fit-content;
  max-height: 150px;
  overflow: hidden;
  transition: 0.2s;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 }

 .cardImg:hover{
  transform: scale(1.1);
 }
 .cardImg img{
  width: 100%;
  overflow: hidden;
 }


 .plantillas{
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  width: 100%;
  height: 46%;
 }
 .guardadas{
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  width: 100%;
  height: 46%;
 }

 .ModalMain{
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 50px;
    height: fit-content;
    display: flex;
    justify-content: center;
    overflow: hidden;
 }

 .ModalContainer{
    padding: 5px;
    z-index: 1;
    position: relative;
    width: 800px;
    min-height: 250px;
    height: fit-content;
    max-height: 700px;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 }

 .close{
    position: absolute;
    top: 10px;
    right: 10px;
    transition: 0.3s;
 }

 .close:hover{
    transform: rotate(180deg);
    color: #f33a3a;
 }
 .scale-up-tr {
	-webkit-animation: scale-up-tr 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-tr 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-13 17:47:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-tr
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

</style>

<style>
.filepond--root{
 margin: 0; 
}
</style>