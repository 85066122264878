<template>
     <div v-if="modalPDFViewer" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="w-100 d-flex justify-content-between align-items-center">
                        <h5 class="modal-title" id="modal-cita-titulo">PDF view</h5>
                        <button type="button" class="btn btn-danger" @click="handlerModal" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
                <div class="modal-body">
                    <iframe id="pdfViewer" :src="medicDocUrl" style="width: 100%; height: 700px;" frameborder="0"></iframe> 
                </div>
            </div>
        </div>
    </div>
    <div class="fichaMedicaComponent">
        <h2>
            Resumen de consulta
        </h2>
    </div>
    <box title="Motivo de consulta" icon="fa fa-question-circle"  v-if="typeof this.GetMedicoFichaActive.metadata.preclinica_motivo !== 'undefined'">
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label class="font-weight-bold">Motivo de Consulta: </label> {{this.GetMedicoFichaActive.metadata.preclinica_motivo.motivo_principal_consulta}}
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label class="font-weight-bold">Historial de la enfermedad actual (HPI): </label> {{this.GetMedicoFichaActive.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi}}
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label class="font-weight-bold">Fecha de inicio de síntomas:</label> {{ dayjs(this.GetMedicoFichaActive.metadata.preclinica_motivo.fecha_inicio_sintomas).format('DD-MM-YYYY H:mm:ss') || '' }}
                </div>
            </div>
        </div>
    </box>
    <box title="Signos Vitales" icon="fa fa-heartbeat">
        <div v-if="typeof GetMedicoFichaActive.metadata.preclinica_signos_v === 'undefined'">
            <div class="text-center text-danger">No tiene signos vitales ingresados</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Fecha y hora</th>
                    <th class="font-weight-bold">Presión arterial</th>
                    <th class="font-weight-bold">Frecuencia cardiaca</th>
                    <th class="font-weight-bold">Frecuencia respiratoria</th>
                    <th class="font-weight-bold">Saturación de oxígeno</th>
                    <th class="font-weight-bold">Temperatura</th>
                    <th class="font-weight-bold">Peso</th>
                    <th class="font-weight-bold">Talla</th>
                    <th class="font-weight-bold">IMC</th>
                    <th class="font-weight-bold">Glicemia</th>
                    <th class="font-weight-bold">Estado de conciencia</th>
                    <th class="font-weight-bold">Dolor</th>
                    <th class="font-weight-bold"></th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.preclinica_signos_v" :key="item.id">
                    <template v-if="typeof item.detail !== 'undefined'">
                        <td>{{ item.detail.fechaHora }}</td>
                        <td>{{ item.detail.presionArterial }}</td>
                        <td>{{ item.detail.frecuenciaCardiaca }}</td>
                        <td>{{ item.detail.frecuenciaRespiratoria }}</td>
                        <td>{{ item.detail.saturacionDeOxigeno }}</td>
                        <td>{{ item.detail.temperatura }}</td>
                        <td>{{ item.detail.peso }}</td>
                        <td>{{ item.detail.talla }}</td>
                        <td>{{ item.detail.IMC }}</td>
                        <td>{{ item.detail.glicemia }}</td>
                        <td>{{ item.detail.estadoDeConciencia }}</td>
                        <td>{{ item.detail.dolor }}/10</td>
                        <td>
                            <i @click="editSignoVital(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                            <i @click="deletesignoVital(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                        </td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
    </box>
    <box title="Impresión Clínica" icon="fas fa-laptop-medical">
      <div v-if="typeof GetMedicoFichaActive.metadata.impresion_clinica_cie10 === 'undefined' && typeof GetMedicoFichaActive.metadata.impresion_clinica === 'undefined'">
        <div class="text-center text-danger">No tiene diagnostico ingresados</div>
      </div>
      <div v-else class="w-100">
        <div class="row px-4 py-1">
            <div class="form-group text-wrap overflow-hidden">
              <label class="font-weight-bold">Diagnóstico: </label>
    
              <span v-for="item in GetMedicoFichaActive.metadata.impresion_clinica_cie10">
                  <div class="text-wrap">{{ item }} </div>
              </span>
              <span v-for="item in GetMedicoFichaActive.metadata.impresion_clinica">
                  <div class="text-wrap" v-html="nl2br(item)"></div>
              </span>

            </div>
        </div>
        <div v-if="GetMedicoFichaActive.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi" class="col-12 col-sm-6">
          <div class="form-group text-wrap">
            <label class="font-weight-bold">Historial de la enfermedad actual (HPI): </label> {{GetMedicoFichaActive.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi}}
          </div>
        </div>
      </div>

    </box>
    <box title="Tratamiento" icon="fa fa-comment-medical">
        <div v-if="(typeof GetMedicoFichaActive.metadata.tratamiento !== 'undefined')">
            <table v-if="(GetMedicoFichaActive.metadata.tratamiento.observaciones !== null)" class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Observaciones</th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="(item, index) in GetMedicoFichaActive.metadata.tratamiento" :key="index">
                    <template v-if="(typeof item !== 'undefined')">
                        <td>{{item}}</td>
                    </template>
                </tr>
                </tbody>
            </table>
            <div v-else class="text-center text-danger">No tiene datos en el tratamiento</div>
        </div>
          
        <div v-else >
            <div class="text-center text-danger">No tiene datos en el tratamiento</div>
        </div>
        <div v-if="(typeof GetMedicoFichaActive.metadata.tratamiento !== 'undefined')" class="mt-3 text-right">
            <div v-if="(GetMedicoFichaActive.metadata.tratamiento.observaciones !== null)" class="btn btn-primary" @click="printTratamiento"><i class="fas fa-print"></i> Imprimir tratamiento</div>
        </div>
    </box>
    <box title="Receta" icon="fas fa-receipt">
        <div v-if="(typeof GetMedicoFichaActive.metadata.tratamiento_receta === 'undefined')">
            <div class="text-center text-danger">No tiene datos en receta</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Medicamento</th>
                    <th class="font-weight-bold">Administración</th>
                    <th class="font-weight-bold">Dosis y frecuencia</th>
                    <th class="font-weight-bold">Observaciones</th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.tratamiento_receta" :key="item.id">
                    <template v-if="(typeof item.detail !== 'undefined')">
                        <td>{{ item.detail.medicamento }}</td>
                        <td>{{ item.detail.administracion }}</td>
                        <td>{{ item.detail.dosis }} cada {{ item.detail.cada }}, durante {{item.detail.durante}} {{item.detail.duranteTiempo}}</td>
                        <td>{{ item.detail.observaciones }}</td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-if="(typeof GetMedicoFichaActive.metadata.tratamiento_receta !== 'undefined')"  class="mt-3 text-right">
            <div class="btn btn-primary" @click="printReceta"><i class="fas fa-print"></i> Imprimir receta</div>
        </div>
    </box>
    <box title="Documentos y formularios configurados" icon="fas fa-file">
        <div class="form-group">
            <label for="searchForm">Buscar:</label>
            <input type="text" class="form-control" id="searchForm" v-model="searchText" placeholder="Ingrese la descripción">
        </div>
        <div class="form" style="max-height: 300px; overflow-y: auto;">
            <ul class="linked_account">
                <li v-for="item in filteredForms" :key="item.id">
                    <div class="row">
                        <div class="col-12">
                            <div class="media">
                                <span class="mr-3"><i class="fa fa-bank"></i></span>
                                <div class="media-body">
                                    <h5 class="mt-0 mb-1">{{item.descripcion}}</h5>
                                    <div>
                                        <div>
                                            Identificador de formulario (Token): {{item.token}}
                                        </div>
                                    </div>
                                </div>
                                <div class="edit-option d-flex aling-items-center gap-1">
                                    <div @click="downLoadForm(item)" class="p-2 text-primary" title="Ver formulario">
                                        <i class="fa-solid fa-eye"></i>
                                    </div>
                                    <div class="p-2 text-success" title="Enviar formulario a whatsapp"><i class="fa fa-whatsapp"></i></div>
                                    <div class="p-2 text-info" title="Enviar formulario al correo"><i class="fa fa-envelope"></i></div>
                                    <div class="p-2 text-danger" title="Enviar formulario por mensaje"><i class="fa fa-message"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="mt-5">
            <router-link to="/form-builder" class="btn btn-primary px-4 mr-3">Configurar nuevo formato</router-link>
        </div>
    </box>
</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';

import Box from "src/modules/medico/views/fichas/Box.vue";
import Multiselect from 'vue-multiselect';

// Componentes

export default {
    components: {
        Box,
        Multiselect,
        DatePicker,
    },
    props: [
        'type',
    ],
    data() {
        return {
            formularios: [],
            searchText: '',
            modalPDFViewer: false,
            medicDocUrl: ''
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        }),
        filteredForms() {
            // Filtra los formularios basados en la descripción y el texto de búsqueda
            const searchText = this.searchText.toLowerCase();
            return this.formularios.filter(item => item.descripcion.toLowerCase().includes(searchText));
        }
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        ...mapActions({}),
        dayjs,
        Numbro,
        nl2br(str) {
            if(str){
                return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
            return str;
        },
        handlerModal(){
            this.modalPDFViewer = !this.modalPDFViewer;
        },
        downLoadForm(item){
            const self = this;
            let docDataQuery= {
                "token": item.token,
                "operation": "generate",
                "password": "",
                "response": "url"
            };
            API.send('GET', 'orders/metadata/getDataToMetadataFields/'+self.GetMedicoFichaActive.reserva.id,{},
                function (data) {
                    if (data.status) {
                        docDataQuery.data = data.data;
                        
                        API.send('POST', 'formularios/docs-plus/generate',docDataQuery,
                        function (data) {
                            if (data.status) {
                             
                                self.medicDocUrl= data.data.url;
                                self.handlerModal();
                            }
                        },
                        function (data) {
                            
                            API.showErrorNotify(data.msg);
                        });
                            
                }
            },
            function (data) {
                API.showErrorNotify(data.msg);
            });
        },
        loadItems() {
            const self = this;
            API.send('GET', 'formularios/all', {},
                function (data) {
                    if (data.status) {
                        self.formularios = data.data;
                       
                    }
                },
                function (data) {
                    API.showErrorNotify(data.msg);
                })
        },
        printReceta() {

           const self = this;
            API.send('POST', 'medico/receta/print', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                }, function (response) {
                   
                    window.open(response.data.url);
                  
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        printTratamiento(){
            const self = this;
            API.send('POST', 'medico/tratamiento/print', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                }, function (response) {
                   
                    window.open(response.data.url);
                  
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        printSection() {

        }
    },
};

</script>

<style scoped>

    .edit-option{
        font-size: 25px;
    }
</style>