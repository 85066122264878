<script>
 
export default { 
 data() {
        return {
        //data
        edit:false
 }},
 props:{
    detail: Object
 },
 methods:{
    closeModal(){
        this.$emit('closeModal');
    },
    saveData(item){
        this.$emit('saveData',item);
    }
 }
 }
</script>
 
 
<template>
  <div class="Modal">
    <div class="Modal-box">
        <div class="Modal-content d-flex flex-column align-items-center justify-content-between ">
            <div class="group-content d-flex flex-column align-items-center">
            <div class="title mt-2">
                <div>
                    {{ detail.name }}
                    <i @click="edit=true" v-if="!edit && detail.observations!==''" class="fa-solid fa-pen-to-square ml-2 text-success cursor-pointer"></i>
                </div>
                <button @click="closeModal" type="button" class="btn btn-danger closebutton px-2 py-1"><i class="fa-solid fa-xmark"></i></button>
            </div>
            <div v-if="edit" class="observations mt-2">
                <div class="form-group">
                    <textarea v-model="detail.observations" class="form-control" placeholder="Escribe aquí"></textarea>
                </div>
            </div>
            <div v-else class="observations mt-2 ">
                <div v-if="detail.observations !== ''">
                    {{ detail.observations }}
                </div>
                <div v-else class="d-flex flex-column justify-content-center align-items-center">
                    <p>Este tratamiento aun no cuenta con ninguna observacion o nota adicional</p>
                    <button @click="edit = true" class="btn btn-primary">Agregar <i class="fa-solid fa-square-plus"></i></button>
                </div>
            </div>
            </div>
            <div class="buttons d-flex justify-content-end mb-3 mr-2">
                <button @click="saveData(detail)" type="button" class="btn btn-primary ml-2 py-2 px-3">Guardar</button>
                <button v-if="!edit" @click="edit=true" type="button" class="btn btn-success ml-2 py-2 px-3">Editar</button>
                <button @click="closeModal" type="button" class="btn btn-danger ml-2 py-2 px-3">Cerrar</button>
            </div>
        </div>
    </div>
  </div>
</template>
 
 
<style scoped>
 
 .Modal{
  position: relative;
  z-index: 9999;
}

.Modal-box{
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(3px);
}

.Modal-content{
  width: 600px;
  min-height: 300px;
  height: fit-content;
  max-height: 90vh;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
}

.group-content{
    width: 100%;
    min-height: 100%;
    max-height: fit-content;
}

.closebutton{
    right: 5px;
}

.closebutton i{
    font-size: 20px;
}

.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    padding: 10px;
    background-color: #f6f8fe;
    border-radius: 7px;
    color: #0ca7e0;
}

.observations{
    padding: 10px;
    width: 98%;
    border-radius: 7px;
}

.observations textarea{
    max-height: 180px;
}

.buttons{
    width: 98%;
}
</style>