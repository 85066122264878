<template>
<!--Dejo como backup miSaldo y MiConsumo ya que esas ventanas servirán para otra cosa-->
    <setting-layout :active="27">
        <div class="card myPlanAndPackages">
            <div class="card-header">
                <h4 class="card-title">Mis consumos</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-12 text-justify">
                        <h5>Aquí podrás encontrar los consumos realizados dentro de la plataforma</h5>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                {{dateStart}}
                                <input type="date" v-model="dateStart" class="form-control">
                            </div>
                            <div class="col-12 col-sm-6">
                                {{dateEnd}}
                                <input type="date" v-model="dateEnd" class="form-control">
                            </div>
                        </div>
                        <div class="mt-3 text-right">
                            <button @click="getPlanInfo" class="btn btn-primary"><i class="fas fa-sync"></i>Actualizar</button>
                        </div>
                        <br>
                        <div class="p-4">
                            <div>
                                <div class="mb-4">
                                    <img :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre" style="max-height: 30px"/>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 consumibleItem">
                                        <div>
                                            <h4 class="font-weight-bold">
                                                {{planUsage.orders.title}}
                                            </h4>
                                            <div>
                                                {{planUsage.orders.desc}}
                                            </div>
                                            <div class="packageItemAvailable mt-3">
                                                <div class="availableItems text-success">
                                                    {{planUsage.orders.ordenes}}
                                                    <div class="mt-2">
                                                        Ordenes generadas
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 consumibleItem">
                                        <div>
                                            <h4 class="font-weight-bold">
                                                {{planUsage.sms.title}}
                                            </h4>
                                            <div>
                                                {{planUsage.sms.desc}}
                                            </div>
                                            <div class="packageItemAvailable mt-3">
                                                <div class="availableItems text-success">
                                                    {{planUsage.sms.send}}
                                                    <div class="mt-2">
                                                        SMS enviados
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="mb-4 mt-5">
                                    <img src="staticAssets/formBuilder/docsplus.png" style="max-height: 30px">
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 consumibleItem">
                                        <div>
                                            <h4 class="font-weight-bold">
                                                {{planUsage.docs.title}}
                                            </h4>
                                            <div>
                                                {{planUsage.docs.desc}}
                                            </div>
                                            <div class="packageItemAvailable mt-3">
                                                <div class="availableItems text-success">
                                                    {{planUsage.docs.pages}}
                                                    <div class="mt-2">
                                                        Páginas generadas
                                                    </div>
                                                </div>
                                                <div class="availableItems">
                                                    {{planUsage.docs.generated}}
                                                    <div class="mt-2">
                                                        Documentos
                                                    </div>
                                                </div>
                                                <div class="availableItems">
                                                    <div class="mt-2">
                                                        <button @click="showHistory('docs')" class="btn btn-sm btn-primary"><i class="fas fa-history mr-2"></i>Ver historial</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 consumibleItem">
                                        <div>
                                            <h4 class="font-weight-bold">
                                                {{planUsage.ocr.title}}
                                            </h4>
                                            <div>
                                                {{planUsage.ocr.desc}}
                                            </div>
                                            <div class="packageItemAvailable mt-3">
                                                <div class="availableItems text-success">
                                                    {{planUsage.ocr.processed}}
                                                    <div class="mt-2">
                                                        Peticiones procesadas
                                                    </div>
                                                </div>
                                                <div class="availableItems">
                                                    {{planUsage.ocr.pages}}
                                                    <div class="mt-2">
                                                        Páginas
                                                    </div>
                                                </div>
                                                <div class="availableItems">
                                                    {{planUsage.ocr.words}}
                                                    <div class="mt-2">
                                                        Palabras detectadas
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="mb-4 mt-5">
                                    <img src="staticAssets/formBuilder/shortlink.png" style="max-height: 30px">
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-6 consumibleItem">
                                        <div>
                                            <h4 class="font-weight-bold">
                                                {{planUsage.shortlink.title}}
                                            </h4>
                                            <div>
                                                {{planUsage.shortlink.desc}}
                                            </div>
                                            <div class="packageItemAvailable mt-3">
                                                <div class="availableItems text-success">
                                                    {{planUsage.shortlink.urls}}
                                                    <div class="mt-2">
                                                        URLs acortadas
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showHistoryModal">
                    <div class="globalModal">
                        <div class="globalModalContainer">
                            <div>
                                <h5 class="modal-title" id="modal-cita-titulo">Historial</h5>
                            </div>
                            <hr>
                            <div>
                                <div class="table-responsive">
                                    <table class="table"  v-if="showHistoryType === 'docs'">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col">Año</th>
                                                <th scope="col">Mes</th>
                                                <th scope="col">Archivos Generados</th>
                                                <th scope="col">Páginas Generadas</th>
                                                <th scope="col">Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in planUsageHistory.docs" :key="'history'+item.id">
                                                <td>{{item.year}}</td>
                                                <td>{{item.month}}</td>
                                                <td class="text-right">{{item.countFiles}}</td>
                                                <td class="text-right text-success">{{item.countPages}}</td>
                                                <td class="text-right"><button class="btn btn-primary btn-sm" @click="downloadHistory(item.id)"><i class="fas fa-download mr-2"></i>Descargar archivo</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" @click="showHistoryModal = false;" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>





                </div>
                <!--<div>
                    <PaymentForm v-model:show-payment-form="showPaymentForm" @do-pay="doPay" :total-pago="totalAmount"></PaymentForm>
                </div>-->
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../components/settings/settingLayout.vue";
import PaymentForm from "src/modules/order/components/PaymentForm";
import {mapGetters} from "vuex";
import {store} from "src/store";
// import * as noUiSlider from "src/assets/publicStore/js/nouislider.min";
import API from "src/core/Api";
import Numbro from 'numbro';
import dayjs from "dayjs";


export default {
    components: {
        settingLayout,
        PaymentForm,
    },
    data() {
        return {
            dateStart: dayjs().startOf('month').format('YYYY-MM-DD'),
            dateEnd: dayjs().endOf('month').format('YYYY-MM-DD'),
            planUsage: {
                orders: {},
                sms: {},
                docs: {},
                ocr: {},
                shortlink: {},
            },
            showHistoryType: false,
            showHistoryModal: false,
            planUsageHistory: {},
        };
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    mounted() {
        this.getPlanInfo();
    },
    watch: {
    },
    methods: {
        Numbro,
        getPlanInfo() {
            const self = this;
            API.send('POST', 'users/plan/usage', {
                    dateStart: self.dateStart,
                    dateEnd: self.dateEnd,
                },
                function (response) {
                    if (response.status) {
                        self.planUsage = response.data;
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        showHistory(type) {
            const self = this;
            API.send('POST', 'users/plan/usage/history', {
                    type: type
                },
                function (response) {
                    if (response.status) {
                        self.showHistoryType = type;
                        self.showHistoryModal = true;
                        self.planUsageHistory = response.data;
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        downloadHistory(id) {
            const self = this;
            API.send('POST', 'users/plan/usage/history/download', {
                    id: id
                },
                function (response) {
                    if (response.status) {
                        window.open(response.data);
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
    },
};
</script>
